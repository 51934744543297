import React from "react";
import { Trans } from "react-i18next";

/* components */
import Icon from "./Icon";

export const CardHeaderRight = ({ onClick }) => (
  <button
    type="button"
    className="btn btn-sm btn-link text-white font-weight-bold text-decoration-none font-size-1"
    onClick={onClick}
  >
    <Trans i18nKey="common:save" />
  </button>
);

export const CardHeaderLeft = ({ onClick }) => (
  <Icon
    className="text-white font-size-1-5 flex-center size-40"
    iconType="Close"
    onClick={onClick}
  />
);

const CardHeader = React.memo(({ title, onSubmit, onDismiss }) => {
  return (
    <div className="card-body bg-primary d-flex justify-content-between align-items-center p-2">
      <CardHeaderLeft onClick={onDismiss} />
      <h1 className="text-white mb-0">{title}</h1>
      <CardHeaderRight onClick={onSubmit} />
    </div>
  );
});

CardHeader.defaultProps = {
  title: "",
  onSubmit: () => null,
  onDismiss: () => null,
};

export default CardHeader;

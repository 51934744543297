import React from "react";

/* components */
import ContactBlock from "../../../components/ContactBlock";

/* context */
import { useService } from "./index";

const ServiceContactBlock = React.memo(() => {
  const { ContactPerson } = useService();

  return <ContactBlock ContactPerson={ContactPerson} />;
});

export default ServiceContactBlock;

import React from 'react';

const FluidLayout = ({ children }) => {
  return (
    <main>
      {children}
    </main>
  );
};

export default FluidLayout;
import React, { useMemo } from "react";

import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";

import { useHistory, useParams } from "react-router-dom";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";

import { GatheringCard } from "../Agenda/List/GatheringInvitationListItem";

/* graphql */
import { QUERY_INVITED_SPEAKER } from "../../../graphql/Meetings";

const GatheringInvitation = React.memo(() => {
  const history = useHistory();

  const { InvitationID } = useParams();

  const { t } = useTranslation(["agenda", "meeting", "meetingStatus"]);

  const { data } = useQuery(QUERY_INVITED_SPEAKER, {
    variables: {
      ID: InvitationID,
    },
    onError: () => history.replace("/agenda"),
  });

  const item = get(data, "readOneProvadaSpeakerInvitation");

  const GatheringTitle = useMemo(() => {
    if (!item) {
      return "";
    }

    return get(item, "InvitedFor.Title") || `#${get(item, "InvitedFor.ID")}`;
  }, [item]);

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/agenda">
            {t("agenda:title")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>{GatheringTitle}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
      </Breadcrumbs>

      <div className="card">
        {!!item && <GatheringCard compact={false} item={item} />}
      </div>
    </DefaultLayout>
  );
});

export default GatheringInvitation;

import React, { useEffect, useState, useMemo, useContext, useRef } from "react";

/* packages */
import dayjs from "dayjs";
import Swiper from "react-id-swiper";
import classNames from "classnames";

import useLockBodyScroll from "react-use/lib/useLockBodyScroll";

import { get } from "lodash";
import { produce } from "immer";
import { Resizable } from "re-resizable";
import { useTranslation } from "react-i18next";
import { scroller } from "react-scroll";
import { useHistory } from "react-router-dom";

/* Pages */
import DefaultLayout from "../../layout/DefaultLayout";

/* components */
import Breadcrumbs from "../../components/Breadcrumbs";

import EventDays from "./EventDays";
import ReservationBanner from "./ReservationBanner";

import { SliderNext, SliderPrev } from "../../components/illustrations";

/* context */
import { useRole, useAttendeeExhibitor } from "../../contexts/ExhibitorContext";

import { useEventHoursList } from "../../contexts/EventContext";
import RoomReservationContext, {
  useReservation,
} from "../../contexts/RoomReservationContext";
import CalendarContext, {
  CalendarProvider,
  useCalendar,
  useCalendarLocations,
  useCalendarSwiper,
} from "../../contexts/CalendarContext";

/* lib */
import { useMobile } from "../../lib/hooks";
import {
  getBlockHeight,
  updateQueryString,
  getEndOfDayTimestamp,
  getBlocksFromTimestamp,
} from "../../lib/common";

/* utils */
import { BOOKING_PERIOD, BOOKING_HEIGHT } from "../../utils/globals";

const resizeDirections = {
  top: false,
  right: false,
  bottom: true,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

const useCanReserve = () => {
  const Role = useRole();
  const Exhibitor = useAttendeeExhibitor();

  return useMemo(
    () =>
      Role && Role.Code === "MANAGER" && Exhibitor && !Exhibitor.isContractor,
    [Role, Exhibitor]
  );
};

const CalendarBreadcrumbs = React.memo(() => {
  const { t } = useTranslation(["roomReservationCalendar"]);

  return (
    <Breadcrumbs>
      <Breadcrumbs.List>
        <Breadcrumbs.ListItem to="/gathering">
          {t("roomReservationCalendar:breadcrumb.one")}
        </Breadcrumbs.ListItem>
        <Breadcrumbs.ListItem>
          {t("roomReservationCalendar:breadcrumb.two")}
        </Breadcrumbs.ListItem>
      </Breadcrumbs.List>
    </Breadcrumbs>
  );
});

const EventLocationsDesktop = React.memo(() => {
  const { t } = useTranslation(["roomReservationCalendar"]);

  const { ids, byId } = useCalendarLocations();

  return (
    <div className="d-none d-md-flex flex-1">
      {ids.map((ID) => {
        const {
          [ID]: { Title, Places },
        } = byId;

        return (
          <div
            key={`location-title-${ID}`}
            className="flex-1 text-center text-primary"
          >
            <p className="text-uppercase">{Title}</p>

            {!!Places && (
              <p className="small">
                {t("roomReservationCalendar:label:numberOfSeats", {
                  count: Places,
                })}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
});

const EventLocationsMobile = React.memo(() => {
  const { t } = useTranslation(["roomReservationCalendar"]);

  const { ids, byId } = useCalendarLocations();
  const { initLocation, slideNext, slidePrev } = useCalendarSwiper();

  return (
    <div className="d-block flex-1 d-md-none">
      {!!ids.length && (
        <Swiper
          noSwiping
          slidesPerView="auto"
          containerClass="flex-1 overflow-hidden"
          getSwiper={initLocation}
        >
          {ids.map((ID) => {
            const {
              [ID]: { Title, Places },
            } = byId;

            return (
              <div
                key={`location-title-${ID}`}
                className="text-center text-primary calendar-item-width"
              >
                <p className="text-uppercase">{Title}</p>

                {!!Places && (
                  <p className="small">
                    {t("roomReservationCalendar:label:numberOfSeats", {
                      count: Places,
                    })}
                  </p>
                )}
              </div>
            );
          })}
        </Swiper>
      )}

      <SliderPrev
        className="w-2-5 p-1 position-absolute left-0 top-50 negative-translate-y-50 bg-white pl-3 z-index-1 h-100 d-flex align-items-center"
        onClick={slidePrev}
      />
      <SliderNext
        className="w-2-5 p-1 position-absolute right-0 top-50 negative-translate-y-50 bg-white pr-3 z-index-1 h-100 d-flex align-items-center"
        onClick={slideNext}
      />
    </div>
  );
});

const EventLocations = React.memo(() => {
  const isMobile = useMobile();

  return (
    <div className="d-flex py-3 px-2 border-bottom border-primary position-relative overflow-hidden">
      <div className="calendar-time-holder" />
      {isMobile ? <EventLocationsMobile /> : <EventLocationsDesktop />}
    </div>
  );
});

const CalendarTimes = React.memo(() => {
  const hours = useEventHoursList();

  const items = useMemo(() => hours.map((ts) => dayjs(ts).format("HH:mm")), [
    hours,
  ]);

  return (
    <div className="calendar-time-holder">
      {items.map((value) => {
        let children = null;

        if (value.endsWith("00")) {
          children = (
            <span className="text-primary position-relative time">{value}</span>
          );
        }

        return (
          <div
            key={`time-${value}`}
            className="time-item position-relative w-100"
            children={children}
          />
        );
      })}
    </div>
  );
});

const CalendarCellReservation = React.memo(({ locationId, index }) => {
  const {
    location: { search },
    push,
  } = useHistory();

  const [dragging, setDragging] = useState(false);

  // ! Lock body scroll if user is dragging to resize an element,
  useLockBodyScroll(dragging);

  const { reservationRef, times, reservedTimes } = useContext(CalendarContext);
  const {
    reservation: { Start, End },
  } = useContext(RoomReservationContext);

  // * Calculate the Time string based on the Reservation duration
  const Time = useMemo(
    () => `${dayjs(Start).format("HH:mm")} - ${dayjs(End).format("HH:mm")}`,
    [Start, End]
  );

  // * Calculate the height of the reservation in pixels bases on Reservation duration
  const height = useMemo(
    () => getBlockHeight(getBlocksFromTimestamp(End, Start)),
    [Start, End]
  );

  const { maxHeight, snap } = useMemo(() => {
    // * End of the day timestamp: 23:45
    let maxTimestamp = getEndOfDayTimestamp(End);

    // * List of snap points in (int/pixels) when resizing a reservation
    let snap = { x: null, y: [] };

    // * Get all times from Start of the reservation till the end of the day
    const futureTimes = [...times.slice(index, times.length), maxTimestamp];

    // * Map over the times in search of intercepting reservations
    for (let i = 0; i < futureTimes.length; i++) {
      const timestamp = futureTimes[i];

      const isReserved = !!get(reservedTimes, `${locationId}-${timestamp}`);

      if (i !== 0) {
        // ! Skip the first since reservation cannot last for 0 minutes, minimum of 15 minutes is required
        // * Collect the snap points based on available times
        snap = produce(snap, (draft) => {
          draft.y.push(
            getBlockHeight(getBlocksFromTimestamp(timestamp, Start))
          );
        });
      }

      // ! In case we git a reservation thats the max timestamp for our current resize
      if (isReserved) {
        maxTimestamp = timestamp;

        break;
      }
    }

    return {
      snap,
      maxHeight: getBlockHeight(getBlocksFromTimestamp(maxTimestamp, Start)),
    };
  }, [Start, End, reservedTimes]);

  function onResizeStart() {
    setDragging(true);
  }

  function onResizeStop(_e, _direction, _ref, dimensions) {
    setDragging(false);

    // ! Calculate End time based on element size, it just works don't question the math!
    const minutes =
      ((height + 3 + dimensions.height) / BOOKING_HEIGHT) * BOOKING_PERIOD;

    const End = dayjs(Start).add(minutes, "minute").unix() * 1000;

    const params = { End };

    return push({ search: updateQueryString({ search, params }) });
  }

  return (
    <Resizable
      ref={reservationRef}
      id="reservation-cell"
      className="calendar-reserved rounded-lg position-absolute bg-white border border-secondary flex-center z-index-1"
      size={{ height, width: "auto" }}
      snap={snap}
      enable={resizeDirections}
      maxHeight={maxHeight}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {
        //! Top drag handle. Won't be needed for now
        false && (
          <div className="calendar-reserved-expand calendar-reserved-expand-top position-absolute left-50 negative-translate-x-50 flex-center" />
        )
      }
      <p className="mb-0 text-secondary small font-weight-bold">{Time}</p>
      <div className="calendar-reserved-expand calendar-reserved-expand-bottom position-absolute left-50 negative-translate-x-50 flex-center" />
    </Resizable>
  );
});

const CalendarCell = React.memo(({ id, index, timestamp, locationId }) => {
  const {
    push,
    location: { search },
  } = useHistory();

  const canReserveRoom = useCanReserve();

  const { reservation } = useContext(RoomReservationContext);
  const { reservedTimes } = useContext(CalendarContext);

  const reserved = useMemo(() => get(reservedTimes, id), [reservedTimes, id]);

  function onCellClick() {
    if (!(canReserveRoom && !reserved)) {
      return;
    }

    let End = dayjs(timestamp).add(BOOKING_PERIOD, "minute").unix() * 1000;
    let EndNext =
      dayjs(timestamp)
        .add(BOOKING_PERIOD * 2, "minute")
        .unix() * 1000;

    const isReserved = !!get(reservedTimes, `${locationId}-${End}`);

    //! Reservation edge case. Clicking on cell above existing reservation should limit reservation to 1 block (15 mins)
    if (!isReserved) {
      End = EndNext;
    }

    //! End of day edge. Clicking on last cell item in the day should limit reservation to 1 block (15 mins)
    if (!dayjs(timestamp).isSame(End, "date")) {
      End = dayjs(timestamp).set("minutes", 45).unix() * 1000;
    }

    const params = { End, Start: timestamp, LocationID: locationId };

    return push({ search: updateQueryString({ search, params }) });
  }

  const isSelected =
    canReserveRoom &&
    !!reservation &&
    reservation.LocationID === locationId &&
    reservation.Start === timestamp &&
    !!reservation.End;
  // if (reservation.LocationID === locationId && reservation.Start === timestamp) {
  // console.log(locationId, dayjs(timestamp).format("HH:mm:ss"))
  // }

  return (
    <div
      {...{
        "data-id": id,
        "data-timestamp": timestamp,
        "data-location": locationId,
        "data-reason": reserved,
      }}
      className={classNames(
        "calendar-hour-item flex-grow-1 position-relative",
        {
          "bg-calendar-disabled": !!reserved,
          "bg-calendar-primary":
            process.env.NODE_ENV !== "production" && reserved === "CLEANUP-NEW",
          "bg-calendar-secondary":
            process.env.NODE_ENV !== "production" &&
            reserved === "PREPARATION-NEW",
        }
      )}
      onClick={onCellClick}
    >
      {isSelected && (
        <CalendarCellReservation index={index} locationId={locationId} />
      )}
    </div>
  );
});

const CalendarMobile = React.memo(() => {
  const { initCalendar, slideTo } = useCalendarSwiper();
  const {
    locations: { ids },
    times,
  } = useCalendar();

  const reservation = useReservation();

  //! Effect to sync the user to proper slide on mobile
  useEffect(() => {
    function sync() {
      if (!(!!reservation && !!reservation.LocationID)) {
        return;
      }

      const index = ids.findIndex(
        (id) => `${id}` === `${reservation.LocationID}`
      );

      if (index === -1) {
        return;
      }

      return slideTo(index);
    }

    sync();
  }, [ids, reservation]);

  return (
    <div className="d-flex w-100 d-md-none">
      {!!ids.length && (
        <Swiper
          noSwiping
          slidesPerView="auto"
          containerClass="flex-1 overflow-hidden"
          getSwiper={initCalendar}
        >
          {ids.map((ID) => (
            <div
              key={`location-${ID}`}
              className="calendar-hour-wrapper d-flex border-right border-primary-40 calendar-item-width"
            >
              <div className="calendar-hour-row flex-1">
                {times.map((timestamp, index) => (
                  <CalendarCell
                    key={`${ID}-${timestamp}`}
                    id={`${ID}-${timestamp}`}
                    index={index}
                    locationId={parseInt(ID, 10)}
                    timestamp={timestamp}
                  />
                ))}
              </div>
            </div>
          ))}
        </Swiper>
      )}
    </div>
  );
});

const CalendarDesktop = React.memo(() => {
  const {
    locations: { ids },
    times,
  } = useCalendar();

  return (
    <div className="d-none d-md-flex flex-1">
      {ids.map((ID) => (
        <div
          key={`location-${ID}`}
          className="calendar-hour-wrapper d-flex flex-grow-1 border-right border-primary-40"
        >
          <div className="calendar-hour-row flex-1">
            {times.map((timestamp, index) => (
              <CalendarCell
                key={`${ID}-${timestamp}`}
                id={`${ID}-${timestamp}`}
                index={index}
                locationId={parseInt(ID, 10)}
                timestamp={timestamp}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
});

const Calendar = React.memo(() => {
  const isMobile = useMobile();

  const scrollRef = useRef(null);

  const { reservation } = useContext(RoomReservationContext);
  const { reservationRef } = useContext(CalendarContext);

  useEffect(() => {
    if (!!reservationRef.current && !!reservation.Start) {
      scroller.scrollTo("reservation-cell", {
        smooth: true,
        offset: -(scrollRef.current.offsetTop + BOOKING_HEIGHT),
      });
    }
  }, [reservationRef.current, reservation]);

  return (
    <div
      id="reservation-calendar"
      ref={scrollRef}
      className="reservation-calendar flex-grow-1 position-relative overflow-hidden d-flex py-5 px-2"
    >
      <CalendarTimes />
      {isMobile ? <CalendarMobile /> : <CalendarDesktop />}
    </div>
  );
});

const CalendarPage = () => {
  const canReserveRoom = useCanReserve();

  return (
    <CalendarProvider>
      <DefaultLayout>
        <CalendarBreadcrumbs />

        <div className="row">
          <div className="col">
            <div className="card flex-1 d-flex flex-column">
              <div className="position-sticky top-0 z-index-2 bg-white box-shadow">
                <EventDays />
                <EventLocations />
              </div>

              <Calendar />
            </div>
          </div>
          {canReserveRoom && (
            <div className="col-lg-3">
              <ReservationBanner />
            </div>
          )}
        </div>
      </DefaultLayout>
    </CalendarProvider>
  );
};

export default CalendarPage;

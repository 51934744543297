import gql from "graphql-tag";

export const FRAGMENT_ATTENDEE_DAYS = gql`
  fragment FragmentAttendeeDays on ProvadaAttendee {
    Monday
    Tuesday
    Wednesday
    Thursday
    Friday
    Saturday
    Sunday
  }
`;

export const FRAGMENT_ATTENDEE = gql`
  fragment FragmentAttendee on ProvadaAttendee {
    ID
    Member {
      ID
      Email
      FullName
    }
    Role {
      ID
      Title
      Code
    }
    ...FragmentAttendeeDays
  }
  ${FRAGMENT_ATTENDEE_DAYS}
`;

export const QUERY_ATTENDEE = gql`
  query readOneProvadaAttendee($ID: ID) {
    readOneProvadaAttendee(ID: $ID) {
      ID
      Member {
        ID
        Email
        FullName
      }
      Role {
        ID
        Title
      }
    }
  }
`;

export const QUERY_EXHIBITOR_ATTENDEES = gql`
  query readProvadaAttendees(
    $Filter: ProvadaAttendeeFilterReadInputType!
    $Exclude: ProvadaAttendeeExcludeReadInputType
  ) {
    readProvadaAttendees(Filter: $Filter, Exclude: $Exclude) {
      edges {
        node {
          ...FragmentAttendee
        }
      }
    }
  }
  ${FRAGMENT_ATTENDEE}
`;

export const QUERY_TICKETS = gql`
  query readProvadaTickets($Filter: ProvadaTicketFilterReadInputType) {
    readProvadaTickets(Filter: $Filter) {
      edges {
        node {
          ID
          Affix
          Firstname
          Surname
          Email
          Company
          Approved
          Image {
            ID
            AbsoluteLink
          }
        }
      }
    }
  }
`;

export const QUERY_TICKET = gql`
  query readOneProvadaTicket($ID: ID) {
    readOneProvadaTicket(ID: $ID) {
      ID
      Affix
      Firstname
      Surname
      Email
      Company
      Image {
        ID
        AbsoluteLink
      }
    }
  }
`;

export const QUERY_ROLES = gql`
  query readProvadaRoles {
    readProvadaRoles {
      edges {
        node {
          ID
          Code
          Title
        }
      }
    }
  }
`;

export const MUTATION_CREATE_ATTENDEE = gql`
  mutation createProvadaAttendee($Input: ProvadaAttendeeCreateInputType!) {
    createProvadaAttendee(Input: $Input) {
      ...FragmentAttendee
    }
  }
  ${FRAGMENT_ATTENDEE}
`;

export const MUTATION_REGISTER_ATTENDEE = gql`
  mutation registerProvadaAttendee($MemberID: Int!) {
    registerProvadaAttendee(MemberID: $MemberID) {
      ...FragmentAttendee
    }
  }
  ${FRAGMENT_ATTENDEE}
`;

export const MUTATION_REGISTER_MEMBER_ATTENDEE = gql`
  mutation registerProvadaMemberAttendee(
    $Title: String!
    $Email: String!
    $RoleID: Int!
  ) {
    registerProvadaMemberAttendee(
      Email: $Email
      Name: $Title
      RoleID: $RoleID
    ) {
      ID
      Affix
      Email
      FirstName
      Surname
      FullName
    }
  }
`;

export const QUERY_ATTENDEES_SUGGESTIONS = gql`
  query readProvadaAttendeesSuggestions($ExhibitorID: Int) {
    readProvadaAttendeesSuggestions(ExhibitorID: $ExhibitorID) {
      edges {
        node {
          ID
          Email
          FullName
          Company {
            ID
            Title
          }
          Picture {
            ID
            AbsoluteLink
          }
        }
      }
    }
  }
`;

export const MUTATION_APPROVE_TICKET = gql`
  mutation approveProvadaTicket($TicketID: Int!, $RoleID: Int!) {
    approveProvadaTicket(TicketID: $TicketID, RoleID: $RoleID) {
      ID
    }
  }
`;

export const MUTATION_CANCEL_TICKET = gql`
  mutation cancelProvadaTicket($TicketID: Int!) {
    cancelProvadaTicket(TicketID: $TicketID) {
      ID
    }
  }
`;

export const MUTATION_UPDATE_ATTENDEE = gql`
  mutation updateProvadaMemberAttendee(
    $ID: Int!
    $Email: String!
    $RoleID: Int!
  ) {
    updateProvadaMemberAttendee(ID: $ID, Email: $Email, RoleID: $RoleID) {
      ID
      Email
    }
  }
`;

import React from 'react';

import Icon from './Icon';

const LinkArrowExternal = ({ to, children, hideArrow }) => {
  return (
    <a href={to} className="text-decoration-none d-inline-flex link-arrow align-items-center icon-secondary" target="_blank" rel="noopener noreferrer">
      <span className="text-primary mr-1">
        {children}
      </span>
      {
        !hideArrow &&
        <Icon className="text-secondary" iconType="ExternalLink" />
      }
    </a>
  )
}

LinkArrowExternal.defaultProps = {
  to: '', //! @todo Change to href=""
  children: ''
}

export default LinkArrowExternal;
import React, { Fragment, useState, useMemo, useRef, useCallback } from "react";

/* packages */
import { useTranslation } from "react-i18next";
import { get, map, groupBy } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";

import classNames from "classnames";
import parseHTML from "html-react-parser";

import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

/* components */
import Icon from "../../../../components/Icon";
import LinkArrowExternal from "../../../../components/LinkArrowExternal";
import ContactBlock from "../ContactBlock";

/* context */
import { useService } from "../index";

/* hooks */
import { useFileUpload } from "../../../../lib/hooks";

import {
  READ_PROVADA_SERVICE_UPLOADS,
  CREATE_PROVADA_SERVICE_UPLOAD,
  DELETE_PROVADA_SERVICE_UPLOAD,
} from "../../../../graphql/Service";
import { getIconForExtension } from "../../../../lib/common";

const ContactPersonBlock = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const { HelpDescription } = useService();

  return (
    <div className="card">
      <div className="card-body">
        <h1>{t("standservices:needhelp")}</h1>
        <div className="mb-3">{parseHTML(HelpDescription || "")}</div>
        <ContactBlock />
      </div>
    </div>
  );
});

const DeleteFile = ({ ServiceUploadID }) => {
  const { t } = useTranslation(["common"]);

  const { ID: ServiceID } = useService();

  const [menuEl, setMenuEl] = useState(null);

  const [deleteFileUpload] = useMutation(DELETE_PROVADA_SERVICE_UPLOAD, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: READ_PROVADA_SERVICE_UPLOADS, variables: { ServiceID } },
    ],
  });

  const open = !!menuEl;

  const onDeleteFile = () => {
    return deleteFileUpload({
      variables: {
        IDs: [ServiceUploadID],
      },
    });
  };

  const openMenu = (e) => {
    setMenuEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuEl(null);
  };

  return (
    <Fragment>
      <Icon
        className="font-size-1-5 size-40 flex-center"
        iconType="More"
        onClick={openMenu}
      />
      <Menu
        id="fade-menu"
        anchorEl={menuEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            onDeleteFile();
            closeMenu();
          }}
        >
          {t("common:delete")}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

const ServiceUploads = React.memo(() => {
  const [onUpload, { loading: uploading, error }] = useFileUpload("file");

  const { ID: ServiceID } = useService();

  const [onSubmitFile] = useMutation(CREATE_PROVADA_SERVICE_UPLOAD, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: READ_PROVADA_SERVICE_UPLOADS, variables: { ServiceID } },
    ],
  });

  const inputRef = useRef(null);

  const { t } = useTranslation(["common", "standservices"]);

  const { data: File, loading } = useQuery(READ_PROVADA_SERVICE_UPLOADS, {
    variables: {
      ServiceID,
    },
  });

  const isDisabled = useMemo(() => loading || uploading, [loading, uploading]);

  const serviceUploads = useMemo(
    () => map(get(File, "readProvadaServiceUploads.edges", []), "node"),
    [File]
  );

  const onFileSubmit = (e) => {
    e.preventDefault();

    const file = inputRef.current.files[0];

    if (!file) {
      return;
    }

    return onUpload(file)
      .then((response) => {
        onSubmitFile({
          variables: {
            Input: {
              ServiceID,
              FileID: response.id,
            },
          },
        });
      })
      .catch(console.log);
  };

  return (
    <Fragment>
      {serviceUploads.map(({ ID, Status, File }) => (
        <div key={File.ID} className="card-body">
          <div className="d-flex align-items-start">
            <div className="mr-3 position-relative">
              <i
                className={`font-size-2 text-primary ${getIconForExtension(
                  File.Extension || ""
                )}`}
              />
              {Status > -1 && (
                <div className="rounded-circle bg-white  position-absolute right-0 bottom-0 d-flex">
                  <i
                    className={classNames("font-size-1 text-success d-block", {
                      "text-danger if-CloseCircleFull-Material": Status === 0,
                      "text-success if-CheckmarkCircleFull-Material":
                        Status === 1,
                    })}
                  />
                </div>
              )}
            </div>
            <div className="flex-1 d-flex">
              <div className="flex-1">
                <h2>{File.Title}</h2>
                <p>
                  {File.Extension.toUpperCase()} {t("standservices:file")}{" "}
                  {File.Size}
                  <br />
                  {File.Created}
                </p>
                <LinkArrowExternal to={`${File.AbsoluteLink}`}>
                  {t("standservices:download")}
                </LinkArrowExternal>
              </div>
              <div>
                <DeleteFile ServiceUploadID={ID} />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="card-body">
        <FileStatusBadge />
        <div className="position-relative d-flex">
          <button className="btn btn-primary" disabled={isDisabled}>
            {t("standservices:fileUpload")}
          </button>
          <input
            disabled={isDisabled}
            className="opacity-0 position-absolute-0"
            ref={inputRef}
            type="file"
            name="File"
            onChange={onFileSubmit}
            accept=".doc, .docx, .pdf, .jpg, .png, .ppt, .pptx, .odt, .odp"
          />
        </div>
        {error instanceof Error && (
          <p className="mt-2 text-uppercase text-danger mb-0 mt-1 font-weight-bold">
            {error.message}
          </p>
        )}
      </div>
    </Fragment>
  );
});

const FileStatusBadge = React.memo(() => {
  const { t } = useTranslation(["fileStatus"]);

  const { ID: ServiceID } = useService();

  const { data: FileStatus } = useQuery(READ_PROVADA_SERVICE_UPLOADS, {
    variables: {
      ServiceID,
    },
  });

  const Status = useMemo(() => {
    const records = map(
      get(FileStatus, "readProvadaServiceUploads.edges", []),
      "node"
    );

    if (!records) {
      return null;
    }

    const grouped = groupBy(records, "Status");

    if (grouped["0"] && grouped["0"].length) {
      return "0";
    }

    if (grouped["-1"] && grouped["-1"].length) {
      return "-1";
    }

    if (grouped["1"] && grouped["1"].length) {
      return "1";
    }
  }, [FileStatus]);

  if (!Status) {
    return null;
  }

  return (
    <span
      className={classNames("badge position-absolute right-0 top-0", {
        "badge-secondary": Status === "0",
        "badge-primary": Status === "-1",
        "badge-leaf": Status === "1",
      })}
      children={t(`fileStatus:${Status}`)}
    />
  );
});

const ServiceDescriptionBlock = React.memo(() => {
  const { Type, Title, Description, SupplierLink, SupplierLinkText } =
    useService();

  return (
    <Fragment>
      <div className="card-body">
        <h1>{Title}</h1>
        {parseHTML(Description || "")}
        {Type === "ATTACHMENTS" && (
          <LinkArrowExternal to={SupplierLink}>
            {parseHTML(SupplierLinkText || "")}
          </LinkArrowExternal>
        )}
      </div>
    </Fragment>
  );
});

const ManualsBlock = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const { Documents } = useService();

  const FilteredDocuments = useMemo(
    () => map(get(Documents, "edges", []), "node"),
    [Documents]
  );

  const renderDocument = useCallback(
    ({ File: { ID, Title, Size, Extension, AbsoluteLink } }) => (
      <div className="card-body" key={ID}>
        <div className="d-flex">
          <div className="mr-3">
            <i className="font-size-1-5 text-primary if-PDF"></i>
          </div>
          <div className="flex-1">
            <h2>{Title}</h2>
            <p>
              {Extension.toUpperCase()} {t("standservices:file")}, {Size}
            </p>
            <LinkArrowExternal to={`${AbsoluteLink}`}>
              {" "}
              {t("standservices:download")}
            </LinkArrowExternal>
          </div>
        </div>
      </div>
    ),
    [t]
  );

  if (!FilteredDocuments.length) {
    return null;
  }

  return <div className="card">{FilteredDocuments.map(renderDocument)}</div>;
});

const ProvadaServiceDefault = React.memo(() => {
  const { Type } = useService();

  return (
    <Fragment>
      <div className="card">
        <ServiceDescriptionBlock />
        {Type === "DELIVERABLE" && <ServiceUploads />}
      </div>
      <ManualsBlock />
      <ContactPersonBlock />
    </Fragment>
  );
});

export default ProvadaServiceDefault;

import React, { Fragment } from "react";

import Select from "react-select";
import useSetState from "react-use/lib/useSetState";

import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { useAttendeeCompany } from "../../../contexts/ExhibitorContext";

import CardHeader from "../../../components/CardHeader";
import Breadcrumbs from "../../../components/Breadcrumbs";

import Countries from "../../../Data/Countries";

import {
  MUTATION_UPDATE_ATTENDEE_COMPANY_ADDRESS,
  QUERY_ATTENDEE_COMPANY,
} from "../../../graphql/User";

const companyDefaultProps = {
  ID: null,
  Country: "",
  PostalCode: "",
  HouseNumber: "",
  Addition: "",
  Street: "",
  City: "",
};

const ExposantProfileEditAddress = React.memo(
  ({ history: { push, goBack } }) => {
    const { t } = useTranslation(["common", "exposant"]);

    const [exhibitor, setExhibitor] = useSetState({ ...companyDefaultProps });

    const AttendeeCompany = useAttendeeCompany();

    useQuery(QUERY_ATTENDEE_COMPANY, {
      variables: {
        ID: get(AttendeeCompany, "ID"),
      },
      onCompleted: ({ readOneProvadaCompany: data }) => {
        if (!data) {
          return goBack();
        }

        return setExhibitor({ ...exhibitor, ...data });
      },
      onError: () => goBack(),
    });

    const [onSaveExposantData, { loading: saving }] = useMutation(
      MUTATION_UPDATE_ATTENDEE_COMPANY_ADDRESS,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_ATTENDEE_COMPANY,
            variables: { ID: get(AttendeeCompany, "ID") },
          },
        ],
      }
    );

    const onSubmit = (e) => {
      e.preventDefault();

      if (saving) {
        return;
      }

      onSaveExposantData({
        variables: {
          Input: {
            ID: exhibitor.ID,
            Country: exhibitor.Country,
            PostalCode: exhibitor.PostalCode,
            HouseNumber: exhibitor.HouseNumber,
            Addition: exhibitor.Addition,
            Street: exhibitor.Street,
            City: exhibitor.City,
          },
        },
      });

      return push("/exhibitor?saved=true");
    };

    const onChangeValue = ({ target: { name, value } }) =>
      setExhibitor({ [name]: value });

    const onChangeCountry = (event) =>
      setExhibitor({ Country: !event ? "" : event.value });

    const onDismiss = () => push("/exhibitor");

    return (
      <Fragment>
        <Breadcrumbs>
          <Breadcrumbs.List>
            <Breadcrumbs.ListItem to="/exhibitor">
              {t("common:exhibitor")}
            </Breadcrumbs.ListItem>
            <Breadcrumbs.ListItem>{t("common:address")}</Breadcrumbs.ListItem>
          </Breadcrumbs.List>
          <Breadcrumbs.Addition.Exposant />
        </Breadcrumbs>

        <div className="card">
          <CardHeader
            title={t("exposant:addressinfo")}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
          />

          <form className="card-body" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="Country" className="text-primary">
                {t("common:country")}
              </label>
              <Select
                name="Country"
                options={Countries}
                value={{ label: exhibitor.Country, value: exhibitor.Country }}
                onChange={onChangeCountry}
              />
            </div>

            <div className="form-row">
              <div className="form-group col-4">
                <label htmlFor="PostalCode" className="text-primary">
                  {t("common:postalcode")}
                </label>
                <input
                  id="PostalCode"
                  type="text"
                  name="PostalCode"
                  className="form-control"
                  value={exhibitor.PostalCode}
                  onChange={onChangeValue}
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="HouseNumber" className="text-primary">
                  {t("common:housenumber")}
                </label>
                <input
                  id="HouseNumber"
                  type="text"
                  name="HouseNumber"
                  className="form-control"
                  value={exhibitor.HouseNumber}
                  onChange={onChangeValue}
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="Addition" className="text-primary">
                  {t("common:addition")}
                </label>
                <input
                  id="Addition"
                  type="text"
                  name="Addition"
                  className="form-control"
                  value={exhibitor.Addition}
                  onChange={onChangeValue}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="Street" className="text-primary">
                {t("common:street")}
              </label>
              <input
                id="Street"
                type="text"
                name="Street"
                className="form-control"
                value={exhibitor.Street}
                onChange={onChangeValue}
              />
            </div>

            <div className="form-group">
              <label htmlFor="City" className="text-primary">
                {t("common:city")}
              </label>
              <input
                id="City"
                type="text"
                name="City"
                className="form-control"
                value={exhibitor.City}
                onChange={onChangeValue}
              />
            </div>

            <div className="d-flex">
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={saving}
                onClick={onSubmit}
              >
                {t("common:save")}
              </button>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
);

export default ExposantProfileEditAddress;

import gql from "graphql-tag";

import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";

import fragmentTypes from "../contexts/ServerContext/fragmentTypes.json";

const PAGE_UNION = map(
  get(
    find(fragmentTypes.__schema.types, {
      name: "PageWithDescendants",
    }),
    "possibleTypes"
  ),
  ({ name }) =>
    `... on ${name} {
        ID
        Title
        Link
        AbsoluteLink
    }`
);

const FILE_UNION = map(
  get(
    find(fragmentTypes.__schema.types, {
      name: "SilverStripeFileWithDescendants",
    }),
    "possibleTypes"
  ),
  ({ name }) =>
    `... on ${name} {
        ID
        Title
        Size
        Created
        MimeType
        FileType
        Extension
        LastEdited
        AbsoluteLink
    }`
);

export const FRAGMENT_FILE = gql`
    fragment FragmentFile on SilverStripeFileWithDescendants {
        ${FILE_UNION}
    }
`;

export const FRAGMENT_PAGE = gql`
    fragment FragmentPage on PageWithDescendants {
        ${PAGE_UNION}
    }
`;

const FRAGMENT_EVENT = gql`
  fragment FragmentEvent on ProvadaEvent {
    ID
    Title
    StartDate
    EndDate
    ProgramLink
    TicketsLinkVisitors
    TicketsLinkManagers
    HouseRulesTitle
    HouseRulesExcerpt
    HouseRulesContent
    RoomBookingStart
    RoomBookingEnd
    ExclusiveProfileLink
  }
`;

export const QUERY_INITIAL_EVENTS = gql`
  query readProvadaEvents {
    readProvadaEvents(
      Filter: { Active__eq: true }
      Versioning: { Mode: LATEST }
    ) {
      edges {
        node {
          ...FragmentEvent
        }
      }
    }
    readProvadaEventCurrent {
      ID
      Title
    }
  }
  ${FRAGMENT_EVENT}
`;

export const QUERY_EVENTS = gql`
  query readProvadaEvents($Filter: ProvadaEventFilterReadInputType) {
    readProvadaEvents(Filter: $Filter, Versioning: { Mode: LATEST }) {
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;

export const READ_ONE_PROVADA_EVENT = gql`
  query readOneProvadaEvent($ID: ID!) {
    readOneProvadaEvent(ID: $ID) {
      ID
      StandPromotionExcerpt
      ExclusiveProfileTitle
      ExclusiveProfileExcerpt
      ExclusiveProfileContent
      NewsletterTitle
      NewsletterExcerpt
      NewsletterContent
      BannersTitle
      BannersExcerpt
      ExpoPromotionTitle
      ExclusiveProfileExcerpt
      BannersTitle
      BannersExcerpt
      Banners {
        edges {
          node {
            Title
            Active
            Image {
              ID
              Title
              Size
              AbsoluteLink
            }
          }
        }
      }
      ExpoPromotionExcerpt
      ExpoPromotion {
        ID
        Title
        Size
        AbsoluteLink
      }
    }
  }
`;

export const QUERY_EVENT_DOCUMENTS = gql`
  query readOneProvadaEvent($ID: ID!) {
    readOneProvadaEvent(ID: $ID) {
      ID
      Documents {
        edges {
          node {
            ID
            Title
            File {
              ...FragmentFile
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const QUERY_EVENT_TASKS = gql`
  query readProvadaTasks($Filter: ProvadaTaskFilterReadInputType) {
    readProvadaTasks(Filter: $Filter, Versioning: { Mode: LATEST }) {
      edges {
        node {
          ID
          Title
          #   Type
          #   Link {
          #     ... on Page {
          #       ID
          #       Title
          #       Link
          #     }
          #   }
          Status {
            ID
            Status
            Task {
              ID
            }
          }
          Service {
            ID
            Title
            Type
          }
          Deadlines {
            edges {
              node {
                ID
                Title
                Content
                DateTime
              }
            }
          }
        }
      }
    }
  }
`;

export const MUTATION_UPDATE_TASK_STATUS = gql`
  mutation updateProvadaTaskStatus($Input: ProvadaTaskStatusUpdateInputType!) {
    updateProvadaTaskStatus(Input: $Input) {
      ID
      Status
    }
  }
`;

export const MUTATION_CREATE_TASK_STATUS = gql`
  mutation createProvadaTaskStatus($Input: ProvadaTaskStatusCreateInputType!) {
    createProvadaTaskStatus(Input: $Input) {
      ID
      Status
    }
  }
`;

export const QUERY_EVENT_PREFERRED_SUPPLIERS = gql`
  query readOneProvadaEvent($ID: ID!) {
    readOneProvadaEvent(ID: $ID) {
      ID
      SupplierTitle
      SupplierDescription
    }
  }
`;

export const QUERY_EVENT_BANNERS = gql`
  query readProvadaBanners($Filter: ProvadaBannerFilterReadInputType) {
    readProvadaBanners(Filter: $Filter) {
      edges {
        node {
          Title
          Image {
            ID
            Title
            Size
            AbsoluteLink
          }
        }
      }
    }
  }
`;

export const QUERY_EVENT_CONTACT_PERSON = gql`
  query readOneProvadaEvent($ID: ID!) {
    readOneProvadaEvent(ID: $ID) {
      ID
      ContactPerson {
        ID
        Email
        Phone
        FullName
        PublicEmail
        PublicPhone
        Picture {
          ID
          AbsoluteLink
        }
      }
    }
  }
`;

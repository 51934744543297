import React, { useMemo } from "react";

/* packages */
import dayjs from "dayjs";
import classNames from "classnames";

import { get } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation, Trans } from "react-i18next";

/* context */
import { useExhibitorLocations } from "../../contexts/ExhibitorContext";

/* components */
import { Halls } from "../../components/illustrations";

/* layout */
import DefaultLayout from "../../layout/DefaultLayout";

/* lib */
import { useSafeSetState } from "../../lib/hooks";

/* graphql */
import {
  QUERY_GATHERING_RESERVATION,
  GET_GATHERING_INFO,
} from "../../graphql/Gathering";
import {
  QUERY_RESERVATION_CANCEL,
  MUTATION_DELETE_RESERVATION,
} from "../../graphql/Location";

const reservationDefaultProps = {
  ID: null,
  Date: "",
  Start: "",
  End: "",
  Gathering: {
    ID: null,
  },
};

const RoomReservationCancel = React.memo(
  ({
    history: { replace },
    match: {
      params: { ReservationID: ID },
    },
  }) => {
    const { t } = useTranslation(["roomReservationCancel"]);

    const Locations = useExhibitorLocations();

    const [error, setError] = useSafeSetState(null);

    const { data } = useQuery(QUERY_RESERVATION_CANCEL, {
      variables: {
        ID,
      },
      onCompleted: ({ readOneProvadaReservation: data }) => {
        //! Allow canceling only un Approved reservations.
        if (!!data && !data.Approved) {
          return;
        }

        return replace("/");
      },
      onError: () => replace("/"),
    });

    const { End, Start, Gathering, Date: ReservationDate } = useMemo(
      () => get(data, "readOneProvadaReservation") || reservationDefaultProps,
      [data]
    );

    const [onCancelReservation, { loading: updating }] = useMutation(
      MUTATION_DELETE_RESERVATION,
      {
        variables: { ID: ID },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_GATHERING_RESERVATION,
            variables: {
              Filter: {
                GatheringID__eq: get(Gathering, "ID"),
              },
            },
          },
          {
            query: GET_GATHERING_INFO,
            variables: {
              ID: get(Gathering, "ID"),
            },
          },
        ],
      }
    );

    const dateString = useMemo(() => {
      if (!(!!Start && !!ReservationDate)) {
        return "";
      }

      const obj = dayjs(`${ReservationDate} ${Start}`, "YYYY-MM-DD HH:mm:ss");

      if (!obj.isValid()) {
        return "";
      }

      return obj.format("dddd DD MMMM");
    }, [Start, ReservationDate]);

    const timeString = useMemo(() => {
      if (!(!!Start && !!End)) {
        return "";
      }

      return [Start.slice(0, 5), End.slice(0, 5)].filter(Boolean).join(" - ");
    }, [Start, End]);

    function onSubmit(e) {
      e.preventDefault();

      //! Reset error if any
      if (!!error) {
        setError(null);
      }

      return onCancelReservation()
        .then(() => {
          let nextRoute = "/gathering";

          const GatheringID = parseInt(get(Gathering, "ID"), 10);

          if (!!GatheringID) {
            nextRoute += `/${GatheringID}`;
          }

          return replace(nextRoute);
        })
        .catch(setError);
    }

    return (
      <DefaultLayout>
        <div className="card">
          <div className="card-body my-3">
            <h1 className="text-center mb-3">
              <Trans i18nKey="roomReservationCancel:title" />
            </h1>
            <div className="flex-center">
              <h3 className="font-weight-normal mb-4 text-align-start">
                <Trans i18nKey="roomReservationCancel:content" />
              </h3>
            </div>

            <div className="d-flex flex-center mb-4">
              <div className="block-icon-wrapper d-flex flex-1 justify-content-end mr-3">
                <Halls className="icon-primary" />
              </div>
              <div className="flex-1">
                <h3>{`Stand ${get(Locations, "0.Title")}`}</h3>
                <p className="text-primary font-weight-bold">{dateString}</p>
                <h3 className="font-weight-normal">{timeString}</h3>
              </div>
            </div>

            <div className="d-flex flex-center flex-column">
              <button
                className={classNames(
                  "btn btn-secondary text-decoration-none",
                  { "mb-4": !!error }
                )}
                onClick={onSubmit}
                disabled={updating}
              >
                {t("roomReservationCancel:button.submit")}
              </button>
              {!!error && (
                <small className="text-danger font-weight-bold">
                  {get(error, "graphQLErrors[0].message")}
                </small>
              )}
            </div>
          </div>
        </div>
      </DefaultLayout>
    );
  }
);

export default RoomReservationCancel;

import React from "react";

import { get } from 'lodash';
import { Redirect, Route } from "react-router-dom";

/* context */
import { useRole, useAttendeeExhibitor } from '../contexts/ExhibitorContext';

// ? 1. You must pass the RolesRoute
// ? 2. You must be exhibitor at this Event. 

const ExhibitorRoute = ({ component: Component, roles, redirect, ...rest }) => {
  const Role = useRole();
  const AttendeeExhibitor = useAttendeeExhibitor();

  function render(props) {
    if (!(!!Role && !!Role.Code)) {
      return <Redirect to={redirect} />;
    }

    if (!(!roles.length || (!!roles.length && roles.includes(Role.Code)))) {
      return <Redirect to={redirect} />;
    }

    if (!(!!parseInt(get(AttendeeExhibitor, 'ID'), 10))) {
      return <Redirect to={redirect} />
    }

    return <Component {...props} />;
  }

  return (
    <Route
      {...rest}
      render={render}
    />
  );
};

ExhibitorRoute.defaultProps = {
  roles: [],
  redirect: "/",
};

export default ExhibitorRoute;

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useApolloClient, useMutation } from "@apollo/react-hooks";
import useLocalStorage from "react-use/lib/useLocalStorage";

import { CREATE_TOKEN, REFRESH_TOKEN } from "../../graphql/User";

import Loading from "../../components/Loading";

const AuthContext = React.createContext({});

AuthContext.displayName = "AuthContext";

const { Consumer, Provider } = AuthContext;

function getInitialToken() {
  if (process.env.NODE_ENV !== "production") {
    return undefined;
  }

  /**
   * In production builds we use other domain for authentication
   */
  var a = "_mp",
    b = document.cookie.match("(^|[^;]+)\\s*" + a + "\\s*=\\s*([^;]+)"),
    enc = b ? b.pop() : "",
    input = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
    output = "NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm5678901234",
    index = (x) => input.indexOf(x),
    translate = (x) => (index(x) > -1 ? output[index(x)] : x);

  return enc.split("").map(translate).join("");
}

const AuthProvider = React.memo(({ children, ...rest }) => {
  const client = useApolloClient();

  const [token, setToken, removeToken] = useLocalStorage(
    "Authorization-Token",
    getInitialToken(),
    {
      raw: true,
      serializer: (value) => value,
      deserializer: (value) => value || getInitialToken(),
    }
  );

  const [loading, setLoading] = useState(!!token);

  const [login] = useMutation(CREATE_TOKEN, {
    onCompleted: (response) => {
      const { createToken: data } = response;

      //! Since idiot who created JWT module for SS does not return an error
      //! but instead Valid: false and no Member, duh!

      if (!(!!data.Valid && !!data.Member)) {
        throw new Error("Invalid credentials.");
      }

      // IMPORTANT!
      window.localStorage.setItem("Authorization-Token", data.Token);

      setToken(data.Token);
    },
  });

  const logout = useCallback(() => {
    removeToken();

    window.localStorage.removeItem("@event/active");

    client.clearStore();
  }, [client, setToken]);

  const [onAuthenticate] = useMutation(REFRESH_TOKEN, {
    onCompleted: ({ refreshToken: data }) => {
      setToken(data.Token);
      setLoading(false);
    },
    onError: () => {
      removeToken();

      window.localStorage.removeItem("Authorization-Token");

      setLoading(false);
    },
  });

  useEffect(() => {
    if (!!token && token.length > 0) {
      onAuthenticate();
    }
  }, []);

  return (
    <Provider
      {...rest}
      value={{
        token,
        login,
        logout,
      }}
    >
      <Fragment>{loading ? <Loading /> : children}</Fragment>
    </Provider>
  );
});

const useAuth = () => useContext(AuthContext);

const useIsAuthenticated = () => {
  const { token } = useAuth();

  return !!token;
};

export { useAuth, AuthProvider, Consumer as AuthConsumer, useIsAuthenticated };

export default AuthContext;

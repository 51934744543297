import React, { Fragment, useCallback, useMemo } from "react";

import get from "lodash/get";
import map from "lodash/map";

import { useTranslation } from "react-i18next";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";

import AsyncSelect from "react-select/async";
import useSetState from "react-use/lib/useSetState";

import {
  useAttendeeCompany,
  useAttendeeExhibitor,
} from "../../../contexts/ExhibitorContext";
import { useUser } from "../../../contexts/UserContext";
import { useEvent } from "../../../contexts/EventContext";

import CardHeader from "../../../components/CardHeader";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { QUERY_EXHIBITOR_ATTENDEES } from "../../../graphql/Tickets";
import {
  MUTATION_UPDATE_EXHIBITOR_CONTACT,
  QUERY_EXHIBITOR_CONTACT,
} from "../../../graphql/User";

const companyDefaultProps = {
  Company: {
    Phone: "",
    Email: "",
    Website: "",
  },
  Exhibitor: {
    ContactPrimary: undefined,
    ContactBilling: undefined,
    ContactSecondary: undefined,
  },
};

const ExhibitorContact = React.memo(({ history: { push, goBack } }) => {
  const { t } = useTranslation(["common", "exposant"]);

  const client = useApolloClient();

  const [state, setState] = useSetState({ ...companyDefaultProps });

  const { ID: UserID } = useUser();
  const { ID: EventID } = useEvent();
  const { ID: CompanyID } = useAttendeeCompany();
  const { ID: ExhibitorID } = useAttendeeExhibitor();

  const onLoadOptions = useCallback(
    (search = "") =>
      client
        .query({
          query: QUERY_EXHIBITOR_ATTENDEES,
          variables: {
            Filter: {
              EventID__eq: EventID,
              ExhibitorID__eq: ExhibitorID,
              FullName__contains: search,
            },
            Exclude: {
              MemberID__eq: UserID, //! Important - Exclude currently logged in member
            },
          },
        })
        .then(({ data }) =>
          map(get(data, "readProvadaAttendees.edges"), "node")
        ),
    [EventID, ExhibitorID, UserID, client]
  );

  useQuery(QUERY_EXHIBITOR_CONTACT, {
    variables: {
      CompanyID,
      ExhibitorID,
    },
    onCompleted: ({
      readOneProvadaCompany: Company,
      readOneProvadaExhibitor: Exhibitor,
    }) => {
      if (!Company || !Exhibitor) {
        return goBack();
      }

      console.log(Exhibitor);

      return setState({
        Company: {
          Phone: Company.Phone || "",
          Email: Company.Email || "",
          Website: Company.Website || "",
        },
        Exhibitor: {
          ContactBilling: !!parseInt(get(Exhibitor, "ContactBilling.ID"))
            ? Exhibitor.ContactBilling
            : undefined,
          ContactPrimary: !!parseInt(get(Exhibitor, "ContactPrimary.ID"))
            ? Exhibitor.ContactPrimary
            : undefined,
          ContactSecondary: !!parseInt(get(Exhibitor, "ContactSecondary.ID"))
            ? Exhibitor.ContactSecondary
            : undefined,
        },
      });
    },
    onError: () => goBack(),
  });

  const [onUpdateExhibitor, { loading: saving }] = useMutation(
    MUTATION_UPDATE_EXHIBITOR_CONTACT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_EXHIBITOR_CONTACT,
          variables: { CompanyID, ExhibitorID },
        },
      ],
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();

    if (saving) {
      return;
    }

    const { Company, Exhibitor } = state;

    onUpdateExhibitor({
      variables: {
        CompanyInput: {
          ID: CompanyID,
          ...Company,
        },
        ExhibitorInput: {
          ID: ExhibitorID,
          ContactPrimaryID: get(Exhibitor, "ContactPrimary.ID"),
          ContactBillingID: get(Exhibitor, "ContactBilling.ID"),
          ContactSecondaryID: get(Exhibitor, "ContactSecondary.ID"),
        },
      },
    });

    return push("/exhibitor?saved=true");
  };

  const onChangeValue = ({ target: { name, value } }) =>
    setState(({ Company }) => ({
      Company: {
        ...Company,
        [name]: value,
      },
    }));

  const cancelSave = () => {
    push("/exhibitor");
  };

  const selectOptions = useMemo(
    () => ({
      loadOptions: onLoadOptions,
      getOptionValue: ({ ID }) => ID,
      placeholder: t("exposant:input.placeholder.ContactPrimary.default"),
      noOptionsMessage: ({ inputValue }) =>
        inputValue.trim().length
          ? t("exposant:input.placeholder.ContactPrimary.noOptionsEmpty")
          : t("exposant:input.placeholder.ContactPrimary.noOptionsSearch"),
      getOptionLabel: (item) =>
        [
          get(item, "Member.FullName", ""),
          `(${get(item, "Member.Email", "")})`,
        ].join(" - "),
    }),
    [onLoadOptions, t]
  );

  return (
    <Fragment>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/exhibitor">
            {t("common:exhibitor")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>{t("common:contact")}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>

      <div className="card">
        <CardHeader
          title={t("exposant:contactinfo")}
          onDismiss={cancelSave}
          onSubmit={onSubmit}
        />
        <form className="card-body" onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="Email" className="text-primary">
              {`E-mail`}
            </label>
            <input
              id="Email"
              type="email"
              name="Email"
              className="form-control"
              value={state.Company.Email}
              onChange={onChangeValue}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Phone" className="text-primary">
              {t("exposant:phone")}
            </label>
            <input
              id="Phone"
              type="text"
              name="Phone"
              className="form-control"
              value={state.Company.Phone}
              onChange={onChangeValue}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Website" className="text-primary">
              {`Website`}
            </label>
            <input
              id="Website"
              type="text"
              name="Website"
              className="form-control"
              value={state.Company.Website}
              onChange={onChangeValue}
            />
          </div>

          <div className="form-group">
            <label htmlFor="ContactPrimary" className="text-primary">
              {t("exposant:input.label.ContactPrimary")}
            </label>
            <AsyncSelect
              {...selectOptions}
              id="ContactPrimary"
              value={state.Exhibitor.ContactPrimary}
              onChange={(ContactPrimary) =>
                setState(({ Exhibitor }) => ({
                  Exhibitor: {
                    ...Exhibitor,
                    ContactPrimary,
                  },
                }))
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="ContactSecondary" className="text-primary">
              {t("exposant:input.label.ContactSecondary")}
            </label>
            <AsyncSelect
              {...selectOptions}
              id="ContactSecondary"
              value={state.Exhibitor.ContactSecondary}
              onChange={(ContactSecondary) =>
                setState(({ Exhibitor }) => ({
                  Exhibitor: {
                    ...Exhibitor,
                    ContactSecondary,
                  },
                }))
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="ContactBilling" className="text-primary">
              {t("exposant:input.label.ContactBilling")}
            </label>
            <AsyncSelect
              {...selectOptions}
              id="ContactBilling"
              value={state.Exhibitor.ContactBilling}
              onChange={(ContactBilling) =>
                setState(({ Exhibitor }) => ({
                  Exhibitor: {
                    ...Exhibitor,
                    ContactBilling,
                  },
                }))
              }
            />
          </div>
          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={onSubmit}
            >
              {t("common:save")}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
});

export default ExhibitorContact;

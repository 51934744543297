import React, { useMemo, useCallback } from "react";

import classNames from "classnames";
import get from "lodash/get";
import map from "lodash/map";

import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import DefaultLayout from "../../../layout/DefaultLayout";

/* Contexts */
import { useEvent } from "../../../contexts/EventContext";
import { useAttendee } from "../../../contexts/ExhibitorContext";

/* Components */
import Percentage from "../../../components/Percentage";
import Breadcrumbs from "../../../components/Breadcrumbs";

import LinkLikeArrow from "../../../components/LinkLikeArrow";
import LinkLikeArrowExternal from "../../../components/LinkLikeArrowExternal";

import { GET_USER_COMPLETION_DATA } from "../../../graphql/User";

import { PROFILE_COMPLETION_KEY } from "../../../utils/globals";

const WrappedLink = React.memo(({ to, ...rest }) => {
  if (to.startsWith("http")) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...rest} href={to} />;
  }

  return <Link {...rest} to={to} />;
});

const CompletionList = React.memo(() => {
  const { t, i18n } = useTranslation(["common", "profile"]);

  const {} = useEvent();

  const { data: CompletionData } = useQuery(GET_USER_COMPLETION_DATA);

  const data = useMemo(
    () =>
      map(
        get(CompletionData, "readProvadaCompletionProfiles.edges", []),
        "node"
      ),
    [CompletionData]
  );

  const locale = useMemo(() => (i18n.language || "nl_NL").slice(0, 2), [
    i18n.language,
  ]);

  const renderProgramCompletionItem = useCallback(
    ({ ID, Title, Key, Description, Completed }) => {
      const to =
        Key === PROFILE_COMPLETION_KEY.GATHERINGS
          ? Event.ProgramLink
          : "/profile/edit/public";

      const linkLabel =
        Key === "Gatherings"
          ? t("profile:complete:registerGathering")
          : t("profile:complete:editProfile");

      const status = Completed
        ? t("profile:complete:completeLabel")
        : t("profile:complete:incompleteLabel");

      return (
        <WrappedLink
          to={to}
          key={ID}
          className="todo-item card-body position-relative text-decoration-none"
          {...(Key === PROFILE_COMPLETION_KEY.GATHERINGS
            ? { target: "_blank" }
            : {})}
        >
          <h2 className="tr-opacity d-flex align-items-center justify-content-between">
            <span className="text-dark">{Title}</span>
            <span
              className={classNames("small", {
                "text-success": Completed,
                "text-warning": !Completed,
              })}
            >
              {status}
            </span>
          </h2>
          <p className="text-dark desc tr-opacity">{Description}</p>
          {Key === PROFILE_COMPLETION_KEY.GATHERINGS ? (
            <LinkLikeArrowExternal>{linkLabel}</LinkLikeArrowExternal>
          ) : (
            <LinkLikeArrow>{linkLabel}</LinkLikeArrow>
          )}
        </WrappedLink>
      );
    },
    [locale, t]
  );

  return data.map(renderProgramCompletionItem);
});

const ProfileCompletion = React.memo(() => {
  const { t } = useTranslation(["common", "profile"]);

  const Attendee = useAttendee();

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem>{t("profile:title")}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>

      <div className="card">
        <div className="card-body d-flex justify-content-between align-items-start">
          <div className="flex-1">
            <h1>{t("common:checklist")}</h1>
            <h3 className="text-secondary">
              {t("profile:complete:checklistLabel")}
            </h3>
            <p>
              {t("profile:tasksCompleted_interval", {
                count: parseInt(get(Attendee, "ProfileCompleted") || 0),
                postProcess: "interval",
              })}
            </p>
          </div>
          {Attendee && !!Attendee.ID && (
            <Percentage value={parseInt(Attendee.ProfileCompleted, 10) || 0} />
          )}
        </div>

        <CompletionList />
      </div>
    </DefaultLayout>
  );
});

export default ProfileCompletion;

import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useRole } from "../contexts/ExhibitorContext";

const RoleRoute = ({
  component: Component,
  roles = [],
  excludeRoles = [],
  redirect = "/",
  ...rest
}) => {
  const Role = useRole();

  function render(props) {
    if (!(Role && Role.Code)) {
      return <Redirect to={redirect} />;
    }

    if (
      Array.isArray(excludeRoles) &&
      !!excludeRoles.length &&
      excludeRoles.includes(Role.Code)
    ) {
      return <Redirect to={redirect} />;
    }

    if (
      !(Array.isArray(roles) && !!roles.length && roles.includes(Role.Code))
    ) {
      return <Redirect to={redirect} />;
    }

    return <Component {...props} />;
  }

  return <Route {...rest} render={render} />;
};

export default RoleRoute;

import gql from "graphql-tag";

export const FRAGMENT_LOCATION = gql`
  fragment LocationFragment on ProvadaLocation {
    ID
    Title
    Catering
    Price
    Places
  }
`;

export const FRAGMENT_CATERING_OPTION = gql`
  fragment CateringOptionFragment on ProvadaCateringOption {
    ID
    Title
    PriceOne
    PriceTwo
    HourLimit
  }
`;

export const QUERY_LOCATIONS = gql`
  query readProvadaLocations($Filter: ProvadaLocationFilterReadInputType) {
    readProvadaLocations(Filter: $Filter) {
      edges {
        node {
          ...LocationFragment
        }
      }
    }
  }
  ${FRAGMENT_LOCATION}
`;

export const QUERY_LOCATION_RESERVATIONS = gql`
  query readProvadaReservations(
    $Filter: ProvadaReservationFilterReadInputType
  ) {
    readProvadaReservations(Filter: $Filter) {
      edges {
        node {
          ID
          Date
          Start
          End
          CleanupTime
          PreparationTime
          Location {
            ID
          }
        }
      }
    }
  }
`;

export const QUERY_LOCATION = gql`
  query readOneProvadaLocation($LocationID: ID) {
    readOneProvadaLocation(ID: $LocationID) {
      ...LocationFragment
      Content
      Picture {
        ID
        AbsoluteLink
      }
    }
  }
  ${FRAGMENT_LOCATION}
`;

export const QUERY_CATERING_OPTIONS = gql`
  query readProvadaCateringOptions(
    $Filter: ProvadaCateringOptionFilterReadInputType
  ) {
    readProvadaCateringOptions(Filter: $Filter) {
      edges {
        node {
          ...CateringOptionFragment
        }
      }
    }
  }
  ${FRAGMENT_CATERING_OPTION}
`;

export const QUERY_GATHERING = gql`
  query readOneProvadaGathering($ID: ID) {
    readOneProvadaGathering(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Title
    }
  }
`;

export const QUERY_LOCATION_GATHERINGS = gql`
  query readProvadaGatherings($Filter: ProvadaGatheringFilterReadInputType) {
    readProvadaGatherings(Filter: $Filter, Versioning: { Mode: LATEST }) {
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;

export const QUERY_RESERVATION_GATHERING = gql`
  query readOneProvadaGathering($ID: ID) {
    readOneProvadaGathering(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Title
      Participants
      Attendees {
        edges {
          node {
            ID
            Status
          }
        }
      }
      Speakers {
        edges {
          node {
            ID
          }
        }
      }
    }
  }
`;

export const QUERY_RESERVATION_PARTICIPANTS = gql`
  query readProvadaGatheringAttendances($AttendeesFilter: TODO) {
    edges {
      node {
        ID
      }
    }
  }
  query readProvadaGatheringSpeakers($SpeakersFilter: TODO) {
    edges {
      node {
        ID
      }
    }
  }
`;

export const QUERY_RESERVATION = gql`
  query readOneProvadaReservation($ID: ID) {
    readOneProvadaReservation(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Date
      Start
      End
      Created
      Approved
      Catering {
        ID
        Title
      }
      Comments
      Persons
      Version
      #   CurrentStage
      Gathering {
        ID
        Title
      }
      Location {
        ...LocationFragment
        Event {
          ID
        }
        Picture {
          ID
          AbsoluteLink
        }
      }
    }
  }
  ${FRAGMENT_LOCATION}
`;

export const QUERY_RESERVATION_COMPLETE = gql`
  query readOneProvadaReservation($ID: ID) {
    readOneProvadaReservation(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Date
      Start
      End
      Version
      Gathering {
        ID
      }
      Location {
        ID
        Title
      }
    }
  }
`;

export const QUERY_RESERVATION_CANCEL = gql`
  query readOneProvadaReservation($ID: ID) {
    readOneProvadaReservation(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Date
      Start
      End
      Version
      Approved
      #   CurrentStage
      Gathering {
        ID
      }
    }
  }
`;

export const GET_AVAILABLE_ROOM_FOR_TIME = gql`
  query readProvadaLocationsAvailable(
    $Start: String!
    $End: String!
    $Date: String!
    $EventID: Int!
  ) {
    readProvadaLocationsAvailable(
      Date: $Date
      Start: $Start
      End: $End
      EventID: $EventID
      Bookable: true
    ) {
      edges {
        node {
          ID
          Title
          Price
          Places
          Picture {
            ID
            AbsoluteLink
          }
        }
      }
    }
  }
`;

export const MUTATION_CREATE_RESERVATION = gql`
  mutation createProvadaReservation(
    $Input: ProvadaReservationCreateInputType!
  ) {
    createProvadaReservation(Input: $Input) {
      ID
      Date
      Start
      End
      PreparationTime
      CleanupTime
      Approved
      Catering {
        ID
        Title
      }
      Comments
      Gathering {
        ID
        Title
      }
    }
  }
`;

export const MUTATION_UPDATE_RESERVATION = gql`
  mutation updateProvadaReservation(
    $Input: ProvadaReservationUpdateInputType!
  ) {
    updateProvadaReservation(Input: $Input) {
      ID
      Date
      Start
      End
      PreparationTime
      CleanupTime
      Approved
      Catering {
        ID
        Title
      }
      Comments
      Gathering {
        ID
        Title
      }
    }
  }
`;

export const MUTATION_DELETE_RESERVATION = gql`
  mutation deleteProvadaReservation($ID: ID!) {
    deleteProvadaReservation(ID: $ID) {
      ID
      Gathering {
        ID
      }
    }
  }
`;

import gql from "graphql-tag";
import { FRAGMENT_ATTENDEE } from "./Tickets";

export const FRAGMENT_MEETING_ATTENDEE = gql`
  fragment FragmentProvadaMeetingAttendee on ProvadaAttendee {
    ID
    PublicLink
    Member {
      ID
      FirstName
      FullName
      Function
      WorksAt
      PublicProfile
      Picture {
        ID
        AbsoluteLink
      }
    }
    Company {
      ID
      Title
    }
  }
`;

export const FRAGMENT_GATHERING = gql`
  fragment FragmentProvadaGathering on ProvadaGathering {
    ID
    Status
    Date
    Start
    End
    PublicLink
    Description
    LocationTitle
    LocationTitleLive
  }
`;

export const FRAGMENT_UPDATE_MEETING = gql`
  fragment FragmentProvadaUpdateMeeting on ProvadaMeeting {
    Status
    DeclineReason
    SuggestedTimes
  }
`;

export const FRAGMENT_MEETING = gql`
  fragment FragmentProvadaMeeting on ProvadaMeeting {
    ID
    Status
    Description
    DateTime
    Rescheduled
    DeclineReason
    SuggestedTimes
    MeetingWith {
      ...FragmentProvadaMeetingAttendee
    }
    RequestedBy {
      ...FragmentProvadaMeetingAttendee
    }
    LastUpdatedBy {
      ID
    }
  }
  ${FRAGMENT_MEETING_ATTENDEE}
`;

export const FRAGMENT_VISITOR_INVITATION = gql`
  fragment FragmentProvadaInvitedVisitor on ProvadaInvitedVisitor {
    ID
    Title
    Email
    Status
    Description
    DateTime
    InvitedFor {
      ...FragmentProvadaMeeting
    }
    InvitedBy {
      ...FragmentAttendee
      PublicLink
      Member {
        ID
        Email
        FullName
        Function
        PublicProfile
        Picture {
          AbsoluteLink
        }
      }
      Company {
        ID
        Title
      }
    }
    Event {
      ID
      Title
      TicketsLinkVisitors
      TicketsLinkManagers
    }
  }
  ${FRAGMENT_ATTENDEE}
  ${FRAGMENT_MEETING}
`;

export const FRAGMENT_SPEAKER_INVITATION = gql`
  fragment FragmentProvadaInvitedSpeaker on ProvadaInvitedSpeaker {
    ID
    Title
    Email
    Status
    Reminders
    InvitedFor {
      ID
      Title
      Date
      Start
      End
      PublicLink
    }
    InvitedBy {
      ...FragmentAttendee
      Member {
        ID
        Email
        FullName
        Function
        Picture {
          AbsoluteLink
        }
      }
      Company {
        ID
        Title
      }
    }
    Event {
      ID
      Title
      TicketsLinkVisitors
      TicketsLinkManagers
    }
  }
  ${FRAGMENT_ATTENDEE}
`;

export const QUERY_ATTENDEE_AGENDA = gql`
  query readUserAgenda(
    $MeetingFilter: ProvadaMeetingFilterReadInputType
    $GatheringSpeakerFilter: ProvadaGatheringSpeakerFilterReadInputType
    $GatheringAttendanceFilter: ProvadaGatheringAttendanceFilterReadInputType
    $AgendaFilter: ProvadaMyAgendaFilterInputType
  ) {
    readProvadaGatheringAttendances(
      Filter: $GatheringAttendanceFilter
      Versioning: { Mode: LATEST }
    ) {
      edges {
        node {
          ID
          Status
        }
      }
    }
    readProvadaMeetings(Filter: $MeetingFilter, Versioning: { Mode: LATEST }) {
      edges {
        node {
          ...FragmentProvadaMeeting
        }
      }
    }
    readProvadaGatheringSpeakers(
      Filter: $GatheringSpeakerFilter
      Versioning: { Mode: LATEST }
    ) {
      edges {
        node {
          ID
        }
      }
    }
    readProvadaMyAgenda(filter: $AgendaFilter) {
      edges {
        node {
          ... on ProvadaInvitedVisitor {
            ...FragmentProvadaInvitedVisitor
          }
          ... on ProvadaInvitedSpeaker {
            ...FragmentProvadaInvitedSpeaker
          }
        }
      }
    }
  }
  ${FRAGMENT_MEETING}
  ${FRAGMENT_VISITOR_INVITATION}
  ${FRAGMENT_SPEAKER_INVITATION}
`;

export const QUERY_MEMBER_AGENDA = gql`
  query readUserAgenda($AgendaFilter: ProvadaMyAgendaFilterInputType) {
    readProvadaMyAgenda(filter: $AgendaFilter) {
      edges {
        node {
          ... on ProvadaInvitedVisitor {
            ...FragmentProvadaInvitedVisitor
          }
          ... on ProvadaInvitedSpeaker {
            ...FragmentProvadaInvitedSpeaker
          }
        }
      }
    }
  }
  ${FRAGMENT_VISITOR_INVITATION}
  ${FRAGMENT_SPEAKER_INVITATION}
`;

export const QUERY_AGENDA = gql`
  query readProvadaMyAgenda($Filter: ProvadaMyAgendaFilterInputType) {
    readProvadaMyAgenda(filter: $Filter) {
      edges {
        node {
          ... on ProvadaMeeting {
            ...FragmentProvadaMeeting
          }
          ... on ProvadaGathering {
            ID
            Title
            Date
            Start
            End
            Type
            Status
            PublicLink
            Participants
            LocationTitle
          }
          ... on ProvadaInvitedVisitor {
            ...FragmentProvadaInvitedVisitor
          }
          ... on ProvadaInvitedSpeaker {
            ...FragmentProvadaInvitedSpeaker
          }
        }
      }
    }
  }
  ${FRAGMENT_MEETING}
  ${FRAGMENT_VISITOR_INVITATION}
  ${FRAGMENT_SPEAKER_INVITATION}
`;

export const QUERY_UPCOMING_MEETINGS = gql`
  query readProvadaMeetings($Filter: ProvadaMeetingFilterReadInputType) {
    readProvadaMeetings(Filter: $Filter) {
      edges {
        node {
          ...FragmentProvadaMeeting
        }
      }
    }
  }
  ${FRAGMENT_MEETING}
`;

export const MUTATION_UPDATE_MEETING = gql`
  mutation updateProvadaMeeting($Input: ProvadaMeetingUpdateInputType) {
    updateProvadaMeeting(Input: $Input) {
      ...FragmentProvadaMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const QUERY_MEETING = gql`
  query readOneProvadaMeeting($ID: ID) {
    readOneProvadaMeeting(ID: $ID, Versioning: { Mode: LATEST }) {
      ...FragmentProvadaMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const QUERY_GATHERING = gql`
  query readOneProvadaGathering($ID: ID) {
    readOneProvadaGathering(ID: $ID, Versioning: { Mode: LATEST }) {
      ...FragmentProvadaGathering
      Exhibitor {
        ID
        Title
        Ghost
        PublicLink
      }
    }
  }
  ${FRAGMENT_GATHERING}
`;

export const MUTATION_GATHERING_UNSUBSCRIBE = gql`
  mutation unsubscribeFromProvadaGathering($ID: Int!) {
    unsubscribeFromProvadaGathering(GatheringID: $ID) {
      ...FragmentProvadaGathering
    }
  }
  ${FRAGMENT_GATHERING}
`;

export const QUERY_ATTENDEE = gql`
  query readOneProvadaAttendee($ID: ID) {
    readOneProvadaAttendee(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      PublicLink
      Member {
        ID
        FirstName
        FullName
        Function
        WorksAt
        Picture {
          ID
          AbsoluteLink
        }

        AvailableForMeetings
      }
      Company {
        ID
        Title
      }
      Event {
        ID
        Title
      }
    }
  }
`;

export const MUTATION_CREATE_MEETING = gql`
  mutation createProvadaMeeting($Input: ProvadaMeetingCreateInputType) {
    createProvadaMeeting(Input: $Input) {
      ...FragmentProvadaMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const MUTATION_INVITE_VISITOR = gql`
  mutation createProvadaVisitorInvitation(
    $Title: String!
    $Email: String!
    $Description: String!
    $DateTime: String!
  ) {
    createProvadaVisitorInvitation(
      Title: $Title
      Email: $Email
      Description: $Description
      DateTime: $DateTime
    ) {
      ...FragmentProvadaInvitedVisitor
    }
  }
  ${FRAGMENT_VISITOR_INVITATION}
`;

export const MUTATION_REJECT_INVITATION = gql`
  mutation createProvadaVisitorInvitation(
    $Title: String!
    $Email: String!
    $Description: String!
    $DateTime: String!
  ) {
    createProvadaVisitorInvitation(
      Title: $Title
      Email: $Email
      Description: $Description
      DateTime: $DateTime
    ) {
      ...FragmentProvadaInvitedVisitor
    }
  }
  ${FRAGMENT_VISITOR_INVITATION}
`;

export const MUTATION_ACCEPT_INVITATION = gql`
  mutation createProvadaVisitorInvitation(
    $Title: String!
    $Email: String!
    $Description: String!
    $DateTime: String!
  ) {
    createProvadaVisitorInvitation(
      Title: $Title
      Email: $Email
      Description: $Description
      DateTime: $DateTime
    ) {
      ...FragmentProvadaInvitedVisitor
    }
  }
  ${FRAGMENT_VISITOR_INVITATION}
`;

export const QUERY_GATHERING_SPEAKER_INVITATION = gql`
  query readProvadaSpeakerInvites($EventID: Int!, $InvitedForID: Int!) {
    readProvadaSpeakerInvites(EventID: $EventID, InvitedForID: $InvitedForID) {
      edges {
        node {
          ...FragmentProvadaInvitedSpeaker
        }
      }
    }
  }
  ${FRAGMENT_SPEAKER_INVITATION}
`;

export const QUERY_INVITED_SPEAKERS = gql`
  query readProvadaInvitedSpeakers(
    $Filter: ProvadaInvitedSpeakerFilterReadInputType
    $Exclude: ProvadaInvitedSpeakerExcludeReadInputType
  ) {
    readProvadaInvitedSpeakers(Filter: $Filter, Exclude: $Exclude) {
      edges {
        node {
          ...FragmentProvadaInvitedSpeaker
        }
      }
    }
  }
  ${FRAGMENT_SPEAKER_INVITATION}
`;

export const QUERY_INVITED_VISITORS = gql`
  query readOneProvadaVisitorInvitation($ID: Int!) {
    readOneProvadaVisitorInvitation(ID: $ID) {
      ...FragmentProvadaInvitedVisitor
    }
  }
  ${FRAGMENT_VISITOR_INVITATION}
`;

export const QUERY_INVITED_SPEAKER = gql`
  query readOneProvadaSpeakerInvitation($ID: Int!) {
    readOneProvadaSpeakerInvitation(ID: $ID) {
      ...FragmentProvadaInvitedSpeaker
    }
  }
  ${FRAGMENT_SPEAKER_INVITATION}
`;

export const QUERY_GATHERING_SPEAKERS_INVITES = gql`
  query readProvadaSpeakerInvites($EventID: Int!, $InvitedForID: Int!) {
    readProvadaSpeakerInvites(EventID: $EventID, InvitedForID: $InvitedForID) {
      edges {
        node {
          ...FragmentProvadaInvitedSpeaker
        }
      }
    }
  }
  ${FRAGMENT_SPEAKER_INVITATION}
`;

export const MUTATION_RESEND_GATHERING_INVITATION = gql`
  mutation resendProvadaSpeakerInvitation($ID: Int!) {
    resendProvadaSpeakerInvitation(ID: $ID) {
      ID
    }
  }
`;

export const MUTATION_RESEND_VISITOR_INVITATION = gql`
  mutation resendProvadaVisitorInvitation($ID: Int!) {
    resendProvadaVisitorInvitation(ID: $ID) {
      ID
    }
  }
`;

export const MUTATION_CANCEL_VISITOR_INVITATION = gql`
  mutation deleteProvadaVisitorInvitation($ID: Int!) {
    deleteProvadaVisitorInvitation(ID: $ID) {
      ID
    }
  }
`;

export const MUTATION_DELETE_GATHERING_INVITATION = gql`
  mutation deleteProvadaSpeakerInvitation($ID: Int!) {
    deleteProvadaSpeakerInvitation(ID: $ID) {
      ID
    }
  }
`;

export const MUTATION_UPDATE_SPEAKER_INVITATION = gql`
  mutation respondProvadaSpeakerInvitation($ID: Int!, $Status: Int!) {
    respondProvadaSpeakerInvitation(ID: $ID, Status: $Status) {
      ID
    }
  }
`;

export const MUTATION_UPDATE_VISITOR_INVITATION = gql`
  mutation respondProvadaVisitorInvitation($ID: Int!, $Status: Int!) {
    respondProvadaVisitorInvitation(ID: $ID, Status: $Status) {
      ID
    }
  }
`;

import React, { Fragment, useMemo } from "react";

/* packages */
import dayjs from "dayjs";
import parseHTML from "html-react-parser";
import classNames from "classnames";

import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/react-hooks";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

/* components */
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";

/* graphql */
import {
  QUERY_GATHERING,
  MUTATION_GATHERING_UNSUBSCRIBE,
} from "../../../graphql/Meetings";

const gatheringDefaultProps = {
  ID: null,
  Title: "",
  Description: "",
  // -2 - Not applied
  // -1 - Pending
  // 0 - Declined
  // 1 - Accepted
  // 2 - Speaker
  // 3 - Moderator
  Status: -1,
  LocationTitle: "",
  LocationTitleLive: "",
  Date: "",
  Start: "",
  End: "",
  PublicLink: "",
  OrganizedByTitle: "",
};

const AgendaGathering = React.memo(
  ({
    history: { replace },
    match: {
      params: { GatheringID },
    },
  }) => {
    const { t } = useTranslation(["agenda", "meeting", "meetingStatus"]);

    const { loading, data } = useQuery(QUERY_GATHERING, {
      variables: { ID: GatheringID },
      // fetchPolicy: 'cache-and-network',
      onError: () => replace("/agenda"),
    });

    const Gathering = useMemo(
      () =>
        get(data, "readOneProvadaGathering") || {
          ...gatheringDefaultProps,
          ID: GatheringID,
        },
      [data]
    );

    const {
      Status,
      PublicLink,
      LocationTitle,
      Date: GatheringDate,
      Start,
      End,
    } = Gathering;

    const GatheringTitle = useMemo(
      () => Gathering.Title || `#${Gathering.ID}`,
      [Gathering]
    );

    const GatheringDescription = useMemo(
      () => parseHTML(Gathering.Description || ""),
      [Gathering.Description]
    );

    const [onUnsubscribe, { loading: updating, error: unsubscribeError }] =
      useMutation(MUTATION_GATHERING_UNSUBSCRIBE, {
        variables: { ID: GatheringID },
      });

    function onSubmit(e) {
      e.preventDefault();

      return onUnsubscribe().then(() => replace("/agenda"));
    }

    return (
      <DefaultLayout>
        <Breadcrumbs>
          <Breadcrumbs.List>
            <Breadcrumbs.ListItem to="/agenda">
              {t("agenda:title")}
            </Breadcrumbs.ListItem>
            <Breadcrumbs.ListItem>{GatheringTitle}</Breadcrumbs.ListItem>
          </Breadcrumbs.List>
          <Breadcrumbs.Addition.Company />
        </Breadcrumbs>

        <div className="card">
          <div className="card-body position-relative overflow-hidden p-4">
            <span
              className={classNames("badge position-absolute right-0 top-0", {
                "badge-secondary": Status === 0,
                "badge-primary": Status === -1,
                "badge-leaf": Status === 1,
                "badge-danger": Status > 1,
              })}
            >
              {t(`agendaGatheringStatus:${Status}`)}
            </span>

            <div className="d-flex py-2 flex-wrap mb-1">
              <div className="flex-1 d-flex align-items-center">
                <div className="flex-1">
                  <p className="text-primary font-weight-bold mb-0">
                    {t("agenda:gathering.title")}
                  </p>
                  <h3 className="mb-1 text-dark">{GatheringTitle}</h3>
                  {!!LocationTitle && (
                    <p className="text-gray">{LocationTitle}</p>
                  )}
                </div>
              </div>
            </div>

            {!!(
              get(Gathering, "Exhibitor.Ghost", false) &&
              get(Gathering, "Exhibitor.PublicLink")
            ) && (
              <a
                href={get(Gathering, "Exhibitor.PublicLink")}
                className="text-secondary h2 d-block mb-3"
                target="_blank"
              >
                {get(Gathering, "Exhibitor.Title")}
              </a>
            )}

            <div className="d-flex flex-wrap mb-3">
              {!!GatheringDate && (
                <Badge secondary className="mb-2 mr-2">
                  {dayjs(GatheringDate, "YYYY-MM-DD").format("dddd")}
                </Badge>
              )}
              {!!Start && !!End && (
                <Badge secondary className="mb-2 mr-2">
                  {[Start.slice(0, 5), End.slice(0, 5)].join(" - ")}
                </Badge>
              )}
            </div>

            <div className="mb-3">{GatheringDescription}</div>

            {!!PublicLink && (
              <a
                href={PublicLink}
                className="text-secondary d-block mb-3"
                target="_blank"
              >
                {t("agenda:gathering.button.public_link")}
              </a>
            )}

            {Status === -1 ||
              (Status === 1 && (
                <Fragment>
                  <div className="">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={onSubmit}
                    >
                      {updating && (
                        <span
                          className="spinner-border spinner-border-sm mr-2 mb-1"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {t("agenda:gathering.button.unsubscribe")}
                    </button>
                  </div>
                  {!!unsubscribeError && (
                    <div className="mt-3">
                      <small className="text-danger font-weight-bold">
                        {get(unsubscribeError, "graphQLErrors[0].message")}
                      </small>
                    </div>
                  )}
                </Fragment>
              ))}
          </div>
        </div>
      </DefaultLayout>
    );
  }
);

export default AgendaGathering;

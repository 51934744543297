import React, {
  useMemo,
  useEffect,
  useContext,
  useState,
  useCallback,
} from "react";

import get from "lodash/get";

import dayjs from "dayjs";

import nl from "dayjs/locale/nl";
import en from "dayjs/locale/en";

import { useQuery } from "@apollo/react-hooks";

/* packages */
import { useTranslation } from "react-i18next";

import Loading from "../components/Loading";

import { READ_CURRENT_SILVERSTRIPE_MEMBER } from "../graphql/User";

import { useAuth } from "./Auth";
import { ENABLE_USER_LANGUAGE_CHANGE } from "../utils/globals";

const UserContext = React.createContext({});

UserContext.displayName = "UserContext";

const { Provider } = UserContext;

const LOCALES_ENUM = { en, nl };

export const useUserContext = () => useContext(UserContext);

export const useUser = () => {
  const { user } = useUserContext();

  return user;
};

export const useLocale = () => {
  const { locale, onChangeLocale } = useUserContext();

  return [locale, onChangeLocale];
};

export const UserProvider = React.memo(({ children, ...rest }) => {
  const { i18n } = useTranslation();
  const { token, logout } = useAuth();

  const [CurrentLocale, setCurrentLocale] = useState("nl_NL");

  const { data, loading } = useQuery(READ_CURRENT_SILVERSTRIPE_MEMBER, {
    skip: !token,
    fetchPolicy: "network-only",
    onCompleted: ({ readSilverStripeMemberCurrent }) => {
      const MemberID = parseInt(get(readSilverStripeMemberCurrent, "ID"), 10);

      if (!MemberID) {
        logout();
      }
    },
  });

  const user = useMemo(() => get(data, "readSilverStripeMemberCurrent"), [
    data,
  ]);

  const isLoggedIn = useMemo(() => !!user && parseInt(user.ID, 10) > 0, [user]);

  const onChangeLocale = useCallback(
    (locale = "nl_NL") => {
      const dayjsLocale = locale.slice(0, 2).toLowerCase();

      if (!(!!dayjsLocale && !!LOCALES_ENUM[dayjsLocale])) {
        return;
      }

      dayjs.locale(dayjsLocale);
      dayjs.updateLocale(dayjsLocale, { weekStart: 0 });

      i18n.changeLanguage(locale);

      return setCurrentLocale(locale);
    },
    [i18n]
  );

  useEffect(() => {
    function onUpdateLocale() {
      if (!(user && user.ID)) {
        return;
      }

      if (!(!!user.Locale && typeof user.Locale === "string")) {
        return;
      }

      return onChangeLocale(user.Locale);
    }

    if (ENABLE_USER_LANGUAGE_CHANGE) {
      onUpdateLocale();
    } else {
      onChangeLocale("nl_NL");
    }
  }, [onChangeLocale, user]);

  return (
    <Provider
      {...rest}
      value={{
        user,
        locale: CurrentLocale,

        isLoggedIn,

        onChangeLocale,
      }}
    >
      {loading ? <Loading /> : children}
    </Provider>
  );
});

export default UserContext;

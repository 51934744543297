import React, { Fragment, useMemo } from "react";

/* packages */
import dayjs from "dayjs";
import classNames from "classnames";

import get from "lodash/get";

import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import useToggle from "react-use/lib/useToggle";

/* components */
import Icon from "../../../../components/Icon";
import Dialog from "../../../../components/Dialog";
import LinkArrowExternal from "../../../../components/LinkArrowExternal";

import { useUser } from "../../../../contexts/UserContext";
import { useAttendee } from "../../../../contexts/ExhibitorContext";
import { useSnackbarCustom } from "../../../../components/Notification";

import {
  MUTATION_UPDATE_VISITOR_INVITATION,
  MUTATION_RESEND_VISITOR_INVITATION,
  MUTATION_CANCEL_VISITOR_INVITATION,
  QUERY_INVITED_VISITORS,
} from "../../../../graphql/Meetings";

import UserBlock from "./UserBlock";

export const MeetingCard = React.memo((props) => {
  const {
    compact = true,
    item: invitation,
    item: { ID, Status, InvitedBy, Email, Title, Description, DateTime },
  } = props;

  const { t } = useTranslation(["visitorInvitationStatus"]);

  const snackbar = useSnackbarCustom();

  const member = useUser();
  const attendee = useAttendee();

  const isAttendee = !!(attendee && attendee.ID && !!parseInt(attendee.ID));

  const isOwner = isAttendee
    ? get(attendee, "ID") === get(invitation, "InvitedBy.ID")
    : get(member, "Email") === get(invitation, "InvitedBy.Member.Email");

  // prettier-ignore
  const isPendingTicket = Status === 1 && !parseInt(get(invitation, "InvitedFor.ID"))

  const purchaseTicketLink = get(invitation, "Event.TicketsLinkVisitors");

  const [cancel, setCancel] = useToggle(false);

  const [onReject, { loading: isRejecting }] = useMutation(
    MUTATION_UPDATE_VISITOR_INVITATION,
    {
      variables: {
        ID: parseInt(ID, 10),
        Status: 0,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        "readProvadaMyAgenda",
        {
          query: QUERY_INVITED_VISITORS,
          variables: {
            ID,
          },
        },
      ],
      onError: (error) => {
        snackbar.enqueueSnackbarError(error);
      },
    }
  );

  const [onAccept, { loading: isAccepting }] = useMutation(
    MUTATION_UPDATE_VISITOR_INVITATION,
    {
      variables: {
        ID: parseInt(ID, 10),
        Status: 1,
      },
      awaitRefetchQueries: true,
      refetchQueries: ["readProvadaMyAgenda"],
      onError: (error) => {
        snackbar.enqueueSnackbarError(error);
      },
    }
  );

  const [onCancel] = useMutation(MUTATION_CANCEL_VISITOR_INVITATION, {
    variables: {
      ID: parseInt(ID, 10),
    },
    awaitRefetchQueries: true,
    refetchQueries: ["readProvadaMyAgenda"],
    onCompleted: () => {
      snackbar.enqueueSnackbar(t("gatheringSpeakers:snackbar.cancel.title"));
    },
    onError: (error) => {
      snackbar.enqueueSnackbarError(error);
    },
  });

  const [onResend] = useMutation(MUTATION_RESEND_VISITOR_INVITATION, {
    variables: {
      ID: parseInt(ID, 10),
    },
    onCompleted: () => {
      snackbar.enqueueSnackbar(t("gatheringSpeakers:snackbar.resend.title"));
    },
    onError: (error) => {
      snackbar.enqueueSnackbarError(error);
    },
  });

  const userImage = useMemo(
    () => get(isOwner ? InvitedBy : {}, "Member.Picture.AbsoluteLink"),
    [InvitedBy, isOwner]
  );

  const CompanyTitle = useMemo(
    () =>
      [get(InvitedBy, "Member.Function"), get(InvitedBy, "Company.Title")]
        .filter(Boolean)
        .join(" - "),
    [InvitedBy]
  );

  return (
    <div
      className={classNames(
        "card-body position-relative overflow-hidden text-decoration-none px-4 pt-4",
        {
          "pb-2": Status === 1,
          "pb-4": Status !== 1,
        }
      )}
    >
      <span
        className={classNames("badge position-absolute right-0 top-0", {
          "badge-secondary": Status === 0,
          "badge-primary": Status === -1 || isPendingTicket,
        })}
      >
        {t(`visitorInvitationStatus:${Status}`)}
      </span>
      <Link
        to={`/agenda/meeting-invitation/${ID}`}
        className="d-flex py-2 flex-wrap text-decoration-none"
      >
        <UserBlock
          disabled={Status === 0}
          image={userImage}
          headline={t("agenda:meetingInvitation.headline")}
          title={isOwner ? Title : get(InvitedBy, "Member.FullName")}
          subtitle={isOwner ? Email : CompanyTitle}
        />
      </Link>
      {!!(!isOwner && !!get(InvitedBy, "Member.PublicProfile")) && (
        <LinkArrowExternal to={get(InvitedBy, "PublicLink")}>
          {t("agenda:meetingInvitation.button.public_link")}
        </LinkArrowExternal>
      )}
      {isPendingTicket && (
        <Fragment>
          <p className="my-4">
            <Trans
              i18nKey={
                isOwner
                  ? "meetingInvitation:purchaseTicket.inviter.title"
                  : "meetingInvitation:purchaseTicket.invitee.title"
              }
              values={{
                event: get(invitation, "Event.Title"),
              }}
            />
          </p>
          {!!(purchaseTicketLink && !isOwner) && (
            <a
              className="btn btn-secondary text-decoration-none mb-2"
              href={purchaseTicketLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("meetingInvitation:purchaseTicket.invitee.button")}
            </a>
          )}
        </Fragment>
      )}
      {!!(Status === -1 && !isOwner) && (
        <Fragment>
          {!compact && (
            <p className="text-primary font-weight-bold pt-4">
              {isOwner
                ? t("meetingInvitation:type.MeetingWith", {
                    title: Title,
                    date: dayjs(DateTime).format("dddd"),
                    time: dayjs(DateTime).format("HH:mm"),
                  })
                : t("meetingInvitation:type.RequestedBy", {
                    title: get(InvitedBy, "Member.FullName"),
                    date: dayjs(DateTime).format("dddd"),
                    time: dayjs(DateTime).format("HH:mm"),
                  })}
            </p>
          )}

          <div className="bg-primary-10 p-3 mt-3 mb-3">
            <p className="text-dark mb-0">{Description}</p>
          </div>

          <div className="d-flex flex-row">
            <button
              className="btn btn-outline-primary mr-2"
              type="button"
              onClick={onReject}
            >
              {isRejecting && (
                <span
                  className="spinner-border spinner-border-sm mr-2 mb-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {t("agenda:meetingInvitation.button.reject")}
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={onAccept}
            >
              {isAccepting && (
                <span
                  className="spinner-border spinner-border-sm mr-2 mb-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {t("agenda:meetingInvitation.button.accept")}
            </button>
          </div>
        </Fragment>
      )}
      {!!(Status === -1 && isOwner && !compact) && (
        <Fragment>
          <div className="mb-3 mt-3">
            <div
              className="d-inline-flex link-arrow align-items-center icon-secondary pointer"
              onClick={() => setCancel(true)}
            >
              <span className="text-primary mr-1">
                {t("agenda:meetingInvitation.button.cancel")}
              </span>
              <Icon className="text-secondary mx-1" iconType="ArrowDropRight" />
            </div>
          </div>
          <div className="mt-3">
            <div
              className="d-inline-flex link-arrow align-items-center icon-secondary pointer"
              onClick={onResend}
            >
              <span className="text-primary mr-1">
                {t("agenda:meetingInvitation.button.resend")}
              </span>
              <Icon className="text-secondary mx-1" iconType="ArrowDropRight" />
            </div>
          </div>
          <Dialog
            open={cancel}
            title={t("gatheringSpeakers:dialog.cancel.title")}
            content={
              <Trans
                i18nKey="gatheringSpeakers:dialog.cancel.content"
                values={{ title: Email }}
              />
            }
            onAccept={() => {
              setCancel(false);

              onCancel();
            }}
            onCancel={() => {
              setCancel(false);
            }}
          />
        </Fragment>
      )}
    </div>
  );
});

const VisitorInvitationListItem = React.memo(({ item }) => {
  return (
    <div className="card w-100">
      <MeetingCard item={item} />
    </div>
  );
});

export default VisitorInvitationListItem;

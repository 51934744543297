import React, { Fragment } from 'react';

/* packages */
import { useTranslation } from 'react-i18next';

import { SelectTimeProvider, SelectTimeForm, useSelectTime, useRescheduleMutation } from '../SelectTime';

/* context */
import { useMeeting } from '../../../../contexts/AgendaContext';

const MeetingAcceptRescheduleForm = React.memo(() => {
  const { t } = useTranslation(['common']);

  const [AcceptedTime] = useSelectTime();

  const [onAccept, { loading: accepting }] = useRescheduleMutation();

  function onBeforeAccept(e) {
    e.preventDefault();

    if (accepting) {
      return
    }

    return onAccept();
  }

  return (
    <Fragment>
      <div className="mb-3">
        <SelectTimeForm />
      </div>
      <div className="">
        <button
          className="btn btn-primary"
          type="button"
          onClick={onBeforeAccept}
          disabled={!AcceptedTime}
        >
          {accepting && <span className="spinner-border spinner-border-sm mr-2 mb-1" role="status" aria-hidden="true" />}
          {t("meeting:form.accept.button.submit")}
        </button>
      </div>
    </Fragment>
  );
});

const MeetingAcceptRescheduleFormWrapper = React.memo(() => {
  const { isRescheduleSuggestionRequest } = useMeeting()

  //! You can only accept if it's not you who last edited the request and there are times suggested 
  if (!isRescheduleSuggestionRequest) {
    return null;
  }

  return (
    <SelectTimeProvider>
      <MeetingAcceptRescheduleForm />
    </SelectTimeProvider>
  )
})

export default MeetingAcceptRescheduleFormWrapper;
import React, { useMemo, useCallback } from "react";

import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import classNames from "classnames";

import get from "lodash/get";
import map from "lodash/map";
import reduce from "lodash/reduce";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";

import LinkLikeArrow from "../LinkLikeArrow";

import { useEvent } from "../../contexts/EventContext";
import { READ_SERVICES } from "../../graphql/Service";

import { ICON_COMPONENT } from "../../pages/StandServices";

function getLabel(DeadlineDate) {
  const date = dayjs(DeadlineDate);

  if (date.isBefore(dayjs(), "date")) {
    return "Overdue";
  }

  if (date.isSame(dayjs(), "date")) {
    return "Today";
  }

  if (date.isSame(dayjs(), "week")) {
    return "This week";
  }

  return date.format("L");
}

function DeadlinesBlock() {
  const { t } = useTranslation(["dashboard"]);

  const { ID: EventID } = useEvent();

  const { data } = useQuery(READ_SERVICES, {
    variables: {
      EventID,
    },
  });

  const deadlines = useMemo(() => {
    const RAIServices = map(
      get(data, "readProvadaRAIServices.edges", []),
      "node"
    );

    const ProvadaServices = map(
      get(data, "readProvadaServices.edges", []),
      "node"
    );

    const services = [...ProvadaServices, ...RAIServices];

    /**
     * Merge RAI and PRovada services and get array of deadlines
     */
    const deadlines = reduce(
      services,
      (acc, service) => {
        const { RAIServiceDeadlines, ServiceDeadlines, ...restOfService } =
          service;

        const deadlines = RAIServiceDeadlines || ServiceDeadlines;

        const flatDeadlines = map(get(deadlines, "edges", []), "node");

        flatDeadlines.forEach((Deadline) => {
          acc.push({
            ...Deadline,
            Service: restOfService,
          });
        });

        return acc;
      },
      []
    );

    function sortByDeadline(deadline) {
      return dayjs(deadline.Date, "YYYY-MM-DD").valueOf();
    }

    /**
     * Order the deadlines based on upcoming date
     */
    const orderedDeadlines = orderBy(deadlines, [sortByDeadline], ["asc"]);

    /**
     * Split the deadlines into upcoming and overdue
     */
    const [overdue, upcoming] = partition(orderedDeadlines, (deadline) =>
      dayjs(deadline.Date).isBefore(dayjs(), "date")
    );

    /**
     * Get a maximum of 3 overdue deadlines
     */
    let limitedDeadlines = overdue.slice(0, 3);

    /**
     * Fill in the rest with upcoming deadlines to a maximum of 6
     */
    limitedDeadlines = limitedDeadlines.concat(
      upcoming.slice(0, 6 - limitedDeadlines.length)
    );

    limitedDeadlines = orderBy(limitedDeadlines, [sortByDeadline], ["asc"]);

    return limitedDeadlines;
  }, [data]);

  const renderDeadline = useCallback((deadline) => {
    const {
      Date: DeadlineDate,
      Service: { ID: ServiceID, Icon, Title, __typename },
    } = deadline;

    const date = dayjs(DeadlineDate);

    const isToday = date.isSame(dayjs(), "date");
    const isOverdue = date.isBefore(dayjs(), "date");

    const IconComponent = ICON_COMPONENT[Icon];

    const label = getLabel(DeadlineDate);
    const to = `/services/${
      __typename === "ProvadaRAIService" ? "rai" : "provada"
    }/${ServiceID}`;

    return (
      <Link
        to={to}
        className="card-body d-flex justify-content-between align-items-center text-decoration-none"
      >
        {IconComponent && (
          <div className="flex-center mr-3">
            <IconComponent
              className={classNames({
                "icon-primary": !isOverdue,
                "icon-gray": isOverdue,
              })}
              style={{ width: "3.125rem" }}
            />
          </div>
        )}
        <div className="flex-1 mr-4">
          <h2
            className={classNames("text-dark", {
              "text-decoration-line-through text-gray": isOverdue,
            })}
          >
            {Title}
          </h2>
        </div>
        <div className="d-flex flex-column justify-content-between align-self-stretch align-items-end ">
          <p
            className={classNames("mb-2", {
              "text-dark": !isOverdue,
              "text-decoration-line-through text-gray": isOverdue,
            })}
          >
            {dayjs(DeadlineDate).format("L")}
          </p>
          <span
            className={classNames("badge", {
              "badge-primary": isToday,
              "badge-secondary": isOverdue,
              "badge-primary-50": !isToday && !isOverdue,
            })}
          >
            {label}
          </span>
        </div>
      </Link>
    );
  }, []);

  return (
    <React.Fragment>
      <div className="card triangle triangle-top-right-leaf">
        <div className="card-body">
          <h1 className="text-dark">{t("dashboard:deadlines.title")}</h1>
        </div>
        {deadlines.map(renderDeadline)}
        <Link
          to="/services"
          className="card-body d-flex justify-content-between align-items-start text-decoration-none"
        >
          <LinkLikeArrow>{t("dashboard:deadlines:link")}</LinkLikeArrow>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default DeadlinesBlock;

import React, { Fragment, useMemo, useContext } from "react";

/* packages */
import { get } from "lodash";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

import ProvadaServiceDefault from "./Layout/Default";
import {
  Premium as PromotionPremium,
  Promotion as PromotionPage,
  Banners as PromotionBanners,
  Newsletter as PromotionNewsletter,
} from "../Promotion";

import { READ_ONE_PROVADA_SERVICE } from "../../../graphql/Service";

const ServiceContext = React.createContext({});

const { Provider } = ServiceContext;

export const useServiceContext = () => useContext(ServiceContext);
export const useService = () => {
  const { service } = useServiceContext();

  return service;
};

const PageContent = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const { Title, Type } = useService();

  //! @todo This needs to be fixed at some point since its not good to depend on the icon in order to display a proper layout
  //! We need to have standardized URL so no exceptions are allowed like we had it in previous builds

  if (Type === "INFO") {
    return (
      <Fragment>
        <Breadcrumbs>
          <Breadcrumbs.List>
            <Breadcrumbs.ListItem to="/services">
              {t("standservices:stand")}
            </Breadcrumbs.ListItem>
            <Breadcrumbs.ListItem>{Title}</Breadcrumbs.ListItem>
          </Breadcrumbs.List>
          <Breadcrumbs.Addition.Exposant />
        </Breadcrumbs>
        <PromotionPage />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/services">
            {t("standservices:stand")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>{Title}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>
      <ProvadaServiceDefault />
    </Fragment>
  );
});

const ProvadaServiceWrapper = React.memo(
  ({
    match: {
      url,
      params: { ID },
    },
    history: { goBack },
  }) => {
    const { loading, data } = useQuery(READ_ONE_PROVADA_SERVICE, {
      variables: {
        ID,
      },
      onCompleted: ({ readOneProvadaService }) => {
        if (!!readOneProvadaService) {
          return;
        }

        return goBack();
      },
      onError: () => goBack(),
    });

    const service = useMemo(
      () => get(data, "readOneProvadaService") || { Title: "", Icon: "" },
      [data]
    );

    const loaded = useMemo(() => !loading && !!service, [loading, service]);

    const isStandPromotion = useMemo(() => service.Type === "INFO", [service]);

    return (
      <Provider value={{ service }}>
        <DefaultLayout>
          {!loaded ? null : (
            <Switch>
              <Route exact path={url} component={PageContent} />
              {isStandPromotion && (
                <Fragment>
                  <Route
                    exact
                    path={`${url}/newsletter`}
                    component={PromotionNewsletter}
                  />
                  <Route
                    exact
                    path={`${url}/banners`}
                    component={PromotionBanners}
                  />
                  <Route
                    exact
                    path={`${url}/premium`}
                    component={PromotionPremium}
                  />
                </Fragment>
              )}
              <Redirect to={url} />
            </Switch>
          )}
        </DefaultLayout>
      </Provider>
    );
  }
);

export default ProvadaServiceWrapper;

import React, { Fragment, useMemo, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import useSetState from "react-use/lib/useSetState";

/** libs */
import _get from "lodash/get";

/* packages */
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* components */
import CardHeader from "../../../components/CardHeader";
import Notification from "../../../components/Notification";

/* context */
import { useGathering } from "../../../contexts/GatheringContext";

/* layout */
import {
  GET_GATHERING_ATTENDANCES,
  UPDATE_GATHERING_ATTENDANCES,
  UPDATE_GATHERING,
  GET_GATHERING_INFO,
} from "../../../graphql/Gathering";

const Pending = React.memo(() => {
  const { t } = useTranslation(["common", "gatherings"]);
  const { data: gathering } = useGathering();

  const { data: pendingAttendancesData } = useQuery(GET_GATHERING_ATTENDANCES, {
    skip: !gathering.ID,
    variables: {
      Filter: {
        GatheringID__eq: gathering.ID,
        Status__eq: "-1",
      },
    },
  });

  const [updateStatus] = useMutation(UPDATE_GATHERING_ATTENDANCES, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_GATHERING_INFO,
        variables: {
          ID: gathering.ID,
        },
      },
      {
        query: GET_GATHERING_ATTENDANCES,
        variables: {
          Filter: {
            GatheringID__eq: gathering.ID,
            Status__eq: "-1",
          },
        },
      },
      {
        query: GET_GATHERING_ATTENDANCES,
        variables: {
          Filter: {
            GatheringID__eq: gathering.ID,
            Status__eq: "1",
          },
        },
      },
    ],
  });

  const handleUpdate = (Input) => {
    updateStatus({
      variables: {
        Input: Input,
      },
    });
  };

  const pendingAttendances = useMemo(
    () =>
      _get(pendingAttendancesData, "readProvadaGatheringAttendances.edges", []),
    [pendingAttendancesData]
  );

  if (!pendingAttendances.length) {
    return null;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h2>
          {t("gatherings:requestscount", { count: pendingAttendances.length })}
        </h2>
        <p>{t("gatherings:requestapproval")}</p>
        {pendingAttendances.map(
          ({
            node: {
              ID,
              Attendee: {
                Company: { Title: CompanyTitle },
                Member: {
                  FullName,
                  WorksAt,
                  Function: FunctionTitle,
                  Picture: AbsoluteLink,
                },
              },
            },
          }) => {
            const acceptAttendee = () => {
              handleUpdate({ ID: ID, Status: "1" });
            };

            const denyAttendee = () => {
              handleUpdate({ ID: ID, Status: "0" });
            };

            return (
              <div
                className="d-flex border-bottom py-2 mb-2 last-of-type-border-none"
                key={ID}
              >
                <div>
                  <div
                    className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
                    style={{ backgroundImage: `url(${AbsoluteLink})` }}
                  />
                </div>
                <div className="flex-1 d-flex align-items-center">
                  <div className="flex-1">
                    <h3 className="mb-1">{FullName}</h3>
                    <p className="text-primary mb-2">
                      {[FunctionTitle, WorksAt || CompanyTitle]
                        .filter(Boolean)
                        .join(" - ")}
                    </p>
                    <div className="">
                      <button
                        className="btn btn-primary mr-2 mb-2 text-decoration-none"
                        onClick={acceptAttendee}
                      >
                        {t("gatherings:accept")}
                      </button>
                      <button
                        className="btn btn-outline-secondary mb-2"
                        onClick={denyAttendee}
                      >
                        {t("gatherings:deny")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
});

const Accepted = React.memo(() => {
  const { t } = useTranslation(["common", "gatherings"]);
  const { data: gathering } = useGathering();

  const { data: acceptedAttendancesData } = useQuery(
    GET_GATHERING_ATTENDANCES,
    {
      skip: !gathering.ID,
      variables: {
        Filter: {
          GatheringID__eq: gathering.ID,
          Status__eq: "1",
        },
      },
    }
  );

  const acceptedAttendances = useMemo(
    () =>
      _get(
        acceptedAttendancesData,
        "readProvadaGatheringAttendances.edges",
        []
      ),
    [acceptedAttendancesData]
  );

  if (!acceptedAttendances.length) {
    return null;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h2>
          {t("gatherings:attendeeslist")} ({acceptedAttendances.length})
        </h2>
        {acceptedAttendances.map(
          ({
            node: {
              ID,
              Attendee: {
                Company: { Title: CompanyTitle },
                Member: {
                  FullName,
                  WorksAt,
                  Function: FunctionTitle,
                  Picture: AbsoluteLink,
                },
              },
            },
          }) => {
            return (
              <div
                className="d-flex border-bottom py-2 last-of-type-border-none"
                key={ID}
              >
                <div>
                  <div
                    className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
                    style={{ backgroundImage: `url('${AbsoluteLink}')` }}
                  />
                </div>
                <div className="flex-1 d-flex align-items-center">
                  <div className="flex-1">
                    <h3 className="mb-1">{FullName}</h3>
                    <p className="mb-0 text-primary">
                      {[FunctionTitle, WorksAt || CompanyTitle]
                        .filter(Boolean)
                        .join(" - ")}
                    </p>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
});

const initRegistration = {
  Moderation: 0,
  Participants: 0,
};

const GatheringRegistration = React.memo(({ history: { push } }) => {
  const { t } = useTranslation(["common", "gatherings"]);
  const { data: gathering } = useGathering();
  const [registration, setRegistration] = useSetState(initRegistration);

  const types = {
    PROVADA: t("gatherings:register:provada"),
    EMAIL: t("gatherings:register:emailtext", { email: gathering.RegisterOn }),
    EXTERNAL: t("gatherings:register:externaltext", {
      site: gathering.RegisterOn,
    }),
    FREE: t("gatherings:register:free"),
    PRIVATE: t("gatherings:register:private"),
  };

  useEffect(() => {
    setRegistration({
      Moderation: gathering.Moderation ? 1 : 0,
      Participants: gathering.Participants,
    });
  }, [gathering]);

  const [saveRegistration, { loading: saving }] = useMutation(
    UPDATE_GATHERING,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_GATHERING_INFO,
          variables: {
            ID: gathering.ID,
          },
        },
      ],
      onCompleted: () => {
        push(`/gathering/${gathering.ID}`);
      },
    }
  );

  const handleChange = ({ currentTarget: { name, value } }) => {
    setRegistration({
      [name]: value,
    });
  };

  const saveAndInform = () => {
    if (saving) {
      return false;
    }

    saveRegistration({
      variables: {
        Input: {
          ID: gathering.ID,
          Moderation: registration.Moderation === "1",
          Participants: parseInt(registration.Participants, 10),
        },
      },
    });
  };

  return (
    <Fragment>
      <div className="card">
        <CardHeader
          title={t("gatherings:attendees")}
          onDismiss={() => push(`/gathering/${gathering.ID}`)}
          onSubmit={saveAndInform}
        />

        <div className="card-body">
          <p className="mb-0">{t("gatherings:choseothers")}</p>
        </div>

        <Link
          to={`/gathering/${gathering.ID}/registrationtype`}
          className="card-body text-decoration-none border-bottom"
        >
          <p className="text-primary">{t("gatherings:signupmethod")}</p>
          <h3 className="mb-0 text-body">
            {types[gathering.Registration] ||
              t("gatherings:attendeesinfo_empty")}
          </h3>
        </Link>

        <div className="card-body">
          {gathering.Registration !== "FREE" && (
            <div className="form-group">
              <label className="text-primary" htmlFor="moderation">
                {t("gatherings:moderation")}
              </label>
              <select
                id="moderation"
                className="form-control"
                name="Moderation"
                value={registration.Moderation || 0}
                onChange={handleChange}
              >
                <option value={0}>{t("gatherings:register:opensignup")}</option>
                <option value={1}>
                  {t("gatherings:register:approvalsignup")}
                </option>
              </select>
            </div>
          )}

          <div className="form-group">
            <label className="text-primary" htmlFor="participants">
              {t("gatherings:expectednumberofpeople")}
            </label>
            <input
              id="participants"
              type="number"
              name="Participants"
              className="form-control mb-2"
              value={registration.Participants}
              onChange={handleChange}
            />
            <small className="mb-0">{t("gatherings:speakersandpeople")}</small>
          </div>

          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-secondary"
              // disabled={isSaving}
              // onClick={onSubmit}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </div>

      {gathering.Registration === "PROVADA" && (
        <div className="row">
          <div className="col-12 col-md-6">
            <Pending />
          </div>
          <div className="col-12 col-md-6">
            <Accepted />
          </div>
        </div>
      )}

      <Notification
        type="info"
        content={t("common:saving")}
        open={saving}
        hideAfter={3000}
      />
    </Fragment>
  );
});

export default GatheringRegistration;

import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useIsAuthenticated } from "../../contexts/Auth";

const LocalPrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/login`,
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const RemotePrivateRoute = (props) => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    window.location.href = `${process.env.REACT_APP_WEBSITE_LOGIN_URL}?back=${window.location.href}`;

    return null;
  }

  return <Route {...props} />;
};

const PrivateRoute =
  process.env.NODE_ENV === "production"
    ? RemotePrivateRoute
    : LocalPrivateRoute;

export default PrivateRoute;

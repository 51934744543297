import React, { Fragment, useMemo } from "react";

/* packages */
import dayjs from "dayjs";
import classNames from "classnames";

import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";

import StatusBadge from "./Status";

import MeetingAcceptForm from "./Form/MeetingAccept";
import MeetingCancelForm from "./Form/MeetingCancel";
import MeetingRescheduleForm from "./Form/MeetingReschedule";
import MeetingAcceptReschedule from "./Form/MeetingAcceptReschedule";

/* context */
import {
  AgendaMeetingProvider,
  useMeeting,
} from "../../../contexts/AgendaContext";

/* graphql */
import { QUERY_MEETING } from "../../../graphql/Meetings";
import ButtonCalendar from "../../../components/ButtonCalendar";
import UserBlock from "../Agenda/List/UserBlock";

// -1 - Pending
// 0 - Declined
// 1 - Accepted
const meetingDefaultProps = {
  ID: null,
  Description: "",
  DateTime: null,
  RequestedBy: null,
  MeetingWith: null,
  Status: -1,
  SuggestedTimes: null,
};

const MeetingAttendeeBlock = React.memo(() => {
  const { t } = useTranslation(["meeting"]);

  const {
    Meeting: { Status, RequestedBy, MeetingWith },
    isOwner,
  } = useMeeting();

  const CompanyTitle = useMemo(
    () =>
      [
        get(isOwner ? MeetingWith : RequestedBy, "Member.Function"),
        get(isOwner ? MeetingWith : RequestedBy, "Company.Title"),
      ]
        .filter(Boolean)
        .join(" - "),
    [MeetingWith, RequestedBy, isOwner]
  );

  return (
    <UserBlock
      disabled={Status === 0}
      image={get(
        isOwner ? MeetingWith : RequestedBy,
        "Member.Picture.AbsoluteLink",
        ""
      )}
      headline={t(
        `meeting:meetingRequest.${isOwner ? "MeetingWith" : "RequestedBy"}`
      )}
      title={get(isOwner ? MeetingWith : RequestedBy, "Member.FullName", "")}
      subtitle={CompanyTitle}
    />
  );
});

const AgendaMeeting = React.memo(() => {
  const { t } = useTranslation(["agenda", "meeting", "meetingStatus"]);

  const {
    Meeting: {
      Status,
      Description,
      DateTime,
      RequestedBy,
      MeetingWith,
      DeclineReason,
    },
    isOwner,
    isOwnerRescheduleSuggestion,
    isRescheduleSuggestionRequest,
  } = useMeeting();

  const StatusLabel = useMemo(() => {
    if (Status === 0) {
      if (isOwner) {
        return t(`meeting:response.MeetingWith.${Status}`, { MeetingWith });
      }

      return t(`meeting:response.RequestedBy.${Status}`);
    }

    if (isOwnerRescheduleSuggestion) {
      return t("meeting:rescheduleRequest.MeetingWith");
    }

    if (isRescheduleSuggestionRequest) {
      return t("meeting:rescheduleRequest.RequestedBy", { RequestedBy });
    }

    if (isOwner) {
      return t(`meeting:response.MeetingWith.${Status}`, { MeetingWith });
    }

    return t(`meeting:response.RequestedBy.${Status}`);
  }, [
    t,
    Status,
    isOwnerRescheduleSuggestion,
    isRescheduleSuggestionRequest,
    isOwner,
    MeetingWith,
    RequestedBy,
  ]);

  const title = useMemo(() => {
    const start = t(`meeting:meetingRequest.MeetingWith`);

    const attendee = get(
      isOwner ? MeetingWith : RequestedBy,
      "Member.FullName",
      ""
    );

    return [start, attendee].filter(Boolean).join(" ");
  }, [MeetingWith, RequestedBy, isOwner, t]);

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/agenda">
            {t("agenda:title")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>
            {get(isOwner ? MeetingWith : RequestedBy, "Member.FullName", "")}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Company />
      </Breadcrumbs>

      <div className="card">
        <div
          className={classNames(
            "card-body position-relative overflow-hidden px-4 pt-4",
            {
              "pb-2": Status === 1,
              "pb-4": Status !== 1,
            }
          )}
        >
          <StatusBadge />

          <div className="d-flex py-2 flex-wrap mb-3">
            <MeetingAttendeeBlock />
          </div>

          <p className="text-primary font-weight-bold pb-2">
            {isOwner
              ? t("meeting:type.MeetingWith", {
                  MeetingWith,
                  Date: dayjs(DateTime, "YYYY-MM-DD HH:mm:ss"),
                  Time: dayjs(DateTime, "YYYY-MM-DD HH:mm:ss"),
                })
              : t("meeting:type.RequestedBy", {
                  RequestedBy,
                  Date: dayjs(DateTime, "YYYY-MM-DD HH:mm:ss"),
                  Time: dayjs(DateTime, "YYYY-MM-DD HH:mm:ss"),
                })}
          </p>

          <div className="bg-primary-10 p-3 mb-3">
            <p className="mb-0 text-dark">{Description}</p>
          </div>

          <p className="text-primary font-weight-bold mb-3">{StatusLabel}</p>
          {!!DeclineReason && (
            <div className="bg-primary-10 p-3 mb-3">
              <p className="mb-0 text-dark">{DeclineReason}</p>
            </div>
          )}
          <MeetingAcceptReschedule />
          <MeetingAcceptForm />
          <MeetingCancelForm />
          <MeetingRescheduleForm />
          {Status === 1 && (
            <div className="d-flex flex-row align-items-center justify-content-between border-top pt-2 mt-2">
              <p className="text-gray mb-0">
                {t("meeting:button.addToCalendar")}
              </p>
              <ButtonCalendar
                title={title}
                description={Description}
                startTime={DateTime}
                endTime={dayjs(DateTime)
                  .add(1, "hour")
                  .format("YYYY-MM-DD HH:mm:ss")}
                timezone="Europe/Amsterdam"
                location={Event.Title}
              />
            </div>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
});

const AgendaMeetingRoute = React.memo(
  ({
    history: { goBack },
    match: {
      params: { MeetingID },
    },
  }) => {
    const { data } = useQuery(QUERY_MEETING, {
      variables: { ID: MeetingID },
      fetchPolicy: "cache-and-network",
      onError: () => goBack(),
    });

    const Meeting = useMemo(() => {
      const nextState = get(data, "readOneProvadaMeeting") || {
        ...meetingDefaultProps,
        ID: MeetingID,
      };

      if (!nextState.SuggestedTimes) {
        return {
          ...nextState,
          SuggestedTimes: [],
        };
      }

      return {
        ...nextState,
        SuggestedTimes: JSON.parse(nextState.SuggestedTimes),
      };
    }, [data]);

    return (
      <AgendaMeetingProvider value={{ Meeting }}>
        <AgendaMeeting />
      </AgendaMeetingProvider>
    );
  }
);

export default AgendaMeetingRoute;

import React, { useMemo, Fragment } from "react";

import parseHTML from "html-react-parser";

import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import { useEvent } from "../../../contexts/EventContext";
import { useService } from "../ProvadaServices";

import Breadcrumbs from "../../../components/Breadcrumbs";
import ContactBlock from "../../../components/ContactBlock";

import {
  READ_ONE_PROVADA_EVENT,
  QUERY_EVENT_CONTACT_PERSON,
} from "../../../graphql/Event";

const ServiceContactBlock = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const { ID: EventID } = useEvent();

  const { data } = useQuery(QUERY_EVENT_CONTACT_PERSON, {
    variables: {
      ID: EventID,
    },
  });

  const ContactPerson = useMemo(
    () => get(data, "readOneProvadaEvent.ContactPerson"),
    [data]
  );

  return (
    <div className="card">
      <div className="card-body">
        <h1>{t("standservices:needhelp")}</h1>
        <p className="mb-3">{t("common:contactblock")}</p>
        <ContactBlock ContactPerson={ContactPerson} />
      </div>
    </div>
  );
});

const PremiumBlock = React.memo(() => {
  const { ID: EventID } = useEvent();

  const { data: EventPromotion } = useQuery(READ_ONE_PROVADA_EVENT, {
    variables: {
      ID: EventID,
    },
  });

  const event = useMemo(
    () =>
      get(EventPromotion, "readOneProvadaEvent", {
        ID: null,
        ExclusiveProfileTitle: "",
        ExclusiveProfileContent: "",
      }),
    [EventPromotion]
  );

  return (
    <div className="card">
      <div className="card-body">
        <h1>{event.ExclusiveProfileTitle}</h1>
        {parseHTML(event.ExclusiveProfileContent || "")}
      </div>
    </div>
  );
});

const Premium = React.memo(() => {
  const { t } = useTranslation(["standservices"]);

  const { ID, Title } = useService();

  return (
    <Fragment>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/services">
            {t("standservices:stand")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem to={`/services/provada/${ID}`}>
            {Title}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>
            {t("standservices:premium")}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>
      <PremiumBlock />
      <ServiceContactBlock />
    </Fragment>
  );
});

export default Premium;

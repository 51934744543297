import React, { Fragment, useState, useRef, useMemo } from "react";
import { get, map, pick } from "lodash";
import dayjs from "dayjs";
import useSetState from "react-use/lib/useSetState";
import classNames from "classnames";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useFileUpload } from "../../../lib/hooks";
import images from "../../../images";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";
import CardHeader from "../../../components/CardHeader";
import Editor from "../../../components/Editor";

/* contexts */
import { useUser } from "../../../contexts/UserContext";
import { useEvent, useEventDays } from "../../../contexts/EventContext";
import { useRole } from "../../../contexts/ExhibitorContext";
import { getBase64 } from "../../../lib/common";

import {
  GET_ALL_TAGS,
  UPDATE_MEMBER_TAGS,
  READ_CURRENT_SILVERSTRIPE_MEMBER,
  READ_ONE_SILVERSTRIPE_MEMBER,
  UPDATE_PUBLIC_DATA_MYPROFILE,
  UPDATE_PROVADA_ATTENDEE_DAYS,
  READ_PROVADA_ATTENDEE_DAYS,
  QUERY_EVENT_ATTENDEES,
  GET_USER_COMPLETION_DATA,
} from "../../../graphql/User";
import { DAYS_ENUM, ENABLE_USER_LANGUAGE_CHANGE } from "../../../utils/globals";

const userDefaultState = {
  ID: null,
  Initials: "",
  FirstName: "",
  Surname: "",
  Affix: "",
  LinkedinLink: "",
  Picture: {
    ID: null,
    AbsoluteLink: "",
  },
  WorksAt: "",
  PublicPhone: "",
  PublicEmail: "",
  PublicProfile: false,
  AvailableForMeetings: false,
};

const daysDefaultState = {
  ID: null,
  Monday: "",
  Tuesday: "",
  Wednesday: "",
  Thursday: "",
  Friday: "",
  Saturday: "",
  Sunday: "",
};

const excerptLimit = 160;

const AttendingCheckbox = React.memo(({ attending, setAttending }) => {
  const { t } = useTranslation(["common", "myprofile"]);

  const days = useEventDays();

  return (
    <fieldset>
      <label htmlFor="" className="text-primary">
        {t("myprofile:attending")}
      </label>
      <div className="form-group">
        {days.map((day) => {
          const key = DAYS_ENUM[dayjs(day).weekday() - 1];

          return (
            <Fragment key={key}>
              <input
                id={key}
                type="checkbox"
                className="d-none custom-form-btn"
                name={key}
                checked={attending[key]}
                onChange={() => {
                  setAttending({ ...attending, [key]: !attending[key] });
                }}
                value={key}
              />
              <label
                htmlFor={key}
                className="btn mr-2 mb-2 font-size-1 px-2 font-weight-normal custom-form-btn-label"
              >
                {dayjs(day).format("ddd")}
              </label>
            </Fragment>
          );
        })}
      </div>
    </fieldset>
  );
});

const TextBlock = React.memo(({ user, setUser }) => {
  const Role = useRole();
  const isSpeaker = useMemo(() => !!Role && Role.Code === "SPEAKER", [Role]);
  const { t } = useTranslation(["common", "myprofile"]);
  return isSpeaker ? (
    <Fragment>
      <div className="form-group">
        <label htmlFor="Biography" className="text-primary">
          {t("myprofile:biography")}
        </label>
        <Editor
          id="Biography"
          value={user.Biography}
          onEditorChange={(value) => setUser({ Biography: value })}
        />
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="form-group">
        <label htmlFor="Excerpt" className="text-primary">
          {t("myprofile:excerpt")}
        </label>
        <textarea
          id="Excerpt"
          type="text"
          name="Excerpt"
          rows="10"
          className="form-control"
          value={user.Excerpt}
          onChange={({ target: { value } }) => {
            return setUser({ Excerpt: value.slice(0, excerptLimit) });
          }}
        />
        <small className="form-text text-muted text-right">
          {`${(get(user, "Excerpt") || "").length}/${excerptLimit}`}
        </small>
      </div>
    </Fragment>
  );
});

const PublicProfileEdit = React.memo(({ history: { goBack, push } }) => {
  const { t } = useTranslation(["common", "myprofile"]);

  const { ID } = useUser();
  const Event = useEvent();

  const [onUpload] = useFileUpload("image");

  const photoRef = useRef(null);

  const [FilePhoto, setFilePhoto] = useState(null);

  const [user, setUser] = useSetState({ ...userDefaultState });
  const [attending, setAttending] = useState(daysDefaultState);

  const isAttending = useMemo(() => !!Event && !!Event.ID, [Event]);

  const [onUpdateAttendeeDays] = useMutation(UPDATE_PROVADA_ATTENDEE_DAYS);
  const [onUpdateTags] = useMutation(UPDATE_MEMBER_TAGS);

  const { data: tagsOptionsData, loading: loadingTags } =
    useQuery(GET_ALL_TAGS);

  //! Store user tags so we can manage them separately
  const [userTags, setUserTags] = useState([]);

  const tagsOptions = useMemo(
    () =>
      get(tagsOptionsData, "readProvadaTags.edges", []).map(
        ({ node: { ID, Title } }) => ({ ID: ID, Title: Title })
      ),
    [tagsOptionsData]
  );

  useQuery(READ_PROVADA_ATTENDEE_DAYS, {
    skip: !(!!ID && !!Event.ID),
    variables: {
      Filter: {
        MemberID__eq: ID,
        EventID__eq: Event.ID,
      },
    },
    onCompleted: ({ readProvadaAttendees: data }) => {
      setAttending({ ...attending, ...(get(data, "edges[0].node") || {}) });
    },
    onError: () => goBack(),
  });

  const { loading: loadingUser } = useQuery(READ_ONE_SILVERSTRIPE_MEMBER, {
    variables: { ID },
    onCompleted: ({ readOneSilverStripeMember: User }) => {
      //! Define initial stored tags
      const tags = map(get(User, "Tags.edges"), "node");

      setUserTags(tags);

      //! Set all other user related data
      return setUser(User);
    },
    onError: () => goBack(),
  });

  const onSaveTags = () => {
    //! @todo Possible optimization, send only if array changed

    const variables = {
      Tags: userTags.map(({ ID }) => parseInt(ID, 10)),
    };

    return onUpdateTags({ variables });
  };

  const AttendeeDaysUpdate = () => {
    return onUpdateAttendeeDays({
      variables: {
        Input: {
          ID: attending.ID,
          ...pick(attending, DAYS_ENUM),
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: READ_PROVADA_ATTENDEE_DAYS,
          variables: { MemberID: ID, EventID: Event.ID },
        },
      ],
    });
  };

  const [onSavePublicData] = useMutation(UPDATE_PUBLIC_DATA_MYPROFILE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: READ_CURRENT_SILVERSTRIPE_MEMBER }, //!Update UserContext
      { query: GET_USER_COMPLETION_DATA },
      {
        query: READ_ONE_SILVERSTRIPE_MEMBER,
        variables: { ID },
      },
      {
        query: QUERY_EVENT_ATTENDEES,
        variables: {
          Filter: {
            MemberID__eq: ID,
            EventID__eq: Event.ID,
          },
        },
      },
    ],
  });

  const onChangeTags = (value) => {
    if (!(Array.isArray(value) && !!value.length)) {
      return setUserTags([]);
    }

    // ? Why limit of 5 ?
    if (value.length <= 5) {
      return setUserTags([...value]);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    PublicDataUpdate();

    onSaveTags();

    if (isAttending) {
      AttendeeDaysUpdate();
    }

    return push("/profile");
  };

  const removePicture = (e) => {
    e.preventDefault();

    photoRef.current.value = null;

    setUser({
      ...user,
      Picture: {
        ID: null,
        AbsoluteLink: "",
      },
    });
  };

  function PublicDataUpdate() {
    let Input = {
      ID,
      Locale: user.Locale,
      FirstName: user.FirstName,
      Surname: user.Surname,
      Initials: user.Initials,
      Affix: user.Affix,
      PublicProfile: user.PublicProfile,
      AvailableForMeetings: user.AvailableForMeetings,
      PublicPhone: user.PublicPhone,
      PublicEmail: user.PublicEmail,
      WorksAt: user.WorksAt,
      LinkedinLink: user.LinkedinLink,
      Excerpt: user.Excerpt,
      Biography: user.Biography,
    };

    //! Will remove profile picture
    if (!user.Picture.ID) {
      Input = {
        ...Input,
        PictureID: null,
      };
    }

    if (!!FilePhoto) {
      return onUpload(FilePhoto)
        .then(({ id: PictureID }) => {
          Input = { ...Input, PictureID };

          return onSavePublicData({ variables: { Input } });
        })
        .catch(() => onSavePublicData({ variables: { Input } }));
    }

    return onSavePublicData({ variables: { Input } });
  }

  const onChangeProfilePicture = (Event) => {
    const File = Event.target.files[0];

    getBase64(File, (response) =>
      setUser({ Picture: { ...user.Picture, AbsoluteLink: response } })
    );

    return setFilePhoto(File);
  };

  const onToggleValue = ({ target: { checked, name } }) =>
    setUser({ [name]: checked });

  const handlePublicData = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const cancelSave = () => {
    push("/profile");
  };

  const onChangeLocale = (Locale) => setUser({ ...user, Locale });

  return (
    <Fragment>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/profile">
            {t("profile:title")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>
            {t("myprofile:title_public")}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Company />
      </Breadcrumbs>

      <div className="card">
        <CardHeader
          title={t("myprofile:editpublicdata")}
          onDismiss={cancelSave}
          onSubmit={onSubmit}
        />

        <form className="card-body" onSubmit={onSubmit}>
          {isAttending && (
            <AttendingCheckbox
              attending={attending}
              setAttending={setAttending}
            />
          )}
          <div className="flex-1 mb-4">
            <Fragment>
              <label htmlFor="Picture" className="text-primary">
                {t("myprofile:profilephoto")}
              </label>
              <div className="d-flex">
                <div
                  className="background-image bg-1-1 w-9 mr-3"
                  style={{
                    backgroundImage: `url(${
                      !!user.Picture.AbsoluteLink
                        ? user.Picture.AbsoluteLink
                        : images.noimage
                    })`,
                  }}
                />
                <div className=" d-flex flex-column justify-content-end">
                  <div className="position-relative overflow-hidden">
                    <button className="btn btn-primary">
                      {t("myprofile:changePhoto")}
                    </button>
                    <input
                      ref={photoRef}
                      className="position-absolute-0 opacity-0"
                      type="file"
                      name="Picture"
                      onChange={onChangeProfilePicture}
                      accept=".jpg, .png, .jpeg"
                    />
                  </div>
                  <button
                    className="btn btn-outline-secondary mt-2"
                    onClick={removePicture}
                  >
                    {t("myprofile:remove")}
                  </button>
                </div>
              </div>
              <small id="" className="form-text text-muted">
                {t("common:limitation")}
              </small>
            </Fragment>
          </div>

          <div className="form-group">
            <label htmlFor="FirstName" className="text-primary">
              {t("myprofile:firstname")}
            </label>
            <input
              type="text"
              name="FirstName"
              className="form-control"
              id="FirstName"
              value={user.FirstName ? user.FirstName : ""}
              onChange={handlePublicData}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Initials" className="text-primary">
              {t("myprofile:affix")}
            </label>
            <input
              id="Initials"
              type="text"
              name="Affix"
              className="form-control"
              onChange={handlePublicData}
              value={user.Affix ? user.Affix : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Surname" className="text-primary">
              {t("myprofile:lastname")}
            </label>
            <input
              type="text"
              name="Surname"
              className="form-control"
              id="Surname"
              value={user.Surname ? user.Surname : ""}
              onChange={handlePublicData}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Initials" className="text-primary">
              {t("myprofile:initials")}
            </label>
            <input
              id="Initials"
              type="text"
              name="Initials"
              className="form-control"
              onChange={handlePublicData}
              value={user.Initials ? user.Initials : ""}
            />
          </div>

          <div className="form-group">
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="PublicProfile"
                name="PublicProfile"
                className="custom-control-input custom-control-leaf"
                onChange={onToggleValue}
                checked={user.PublicProfile}
              />
              <label className="custom-control-label" htmlFor="PublicProfile">
                {t("myprofile:publicprofile")}
              </label>
            </div>

            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="AvailableForMeetings"
                name="AvailableForMeetings"
                onChange={onToggleValue}
                className="custom-control-input custom-control-leaf"
                checked={user.AvailableForMeetings}
              />
              <label
                className="custom-control-label"
                htmlFor="AvailableForMeetings"
              >
                {t("myprofile:availableformeetings")}
              </label>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="PublicPhone" className="text-primary">
              {t("myprofile:phone")}
            </label>
            <input
              type="text"
              name="PublicPhone"
              className="form-control"
              id="PublicPhone"
              value={user.PublicPhone ? user.PublicPhone : ""}
              onChange={handlePublicData}
            />
          </div>

          <div className="form-group">
            <label htmlFor="WorksAt" className="text-primary">
              {t("myprofile:worksAt")}
            </label>
            <input
              type="text"
              name="WorksAt"
              className="form-control"
              id="WorksAt"
              value={user.WorksAt ? user.WorksAt : ""}
              onChange={handlePublicData}
            />
          </div>

          <div className="form-group">
            <label htmlFor="PublicEmail" className="text-primary">
              {t("myprofile:email")}
            </label>
            <input
              type="email"
              name="PublicEmail"
              className="form-control"
              id="PublicEmail"
              value={user.PublicEmail ? user.PublicEmail : ""}
              onChange={handlePublicData}
            />
          </div>

          <div className="form-group">
            <label htmlFor="LinkedinLink" className="text-primary">
              {t("myprofile:linkedin")}
            </label>
            <input
              type="text"
              name="LinkedinLink"
              className="form-control"
              id="LinkedinLink"
              value={user.LinkedinLink ? user.LinkedinLink : ""}
              onChange={handlePublicData}
            />
          </div>
          <TextBlock user={user} setUser={setUser} />
          <div className="form-group">
            <div className="form-group mb-0">
              <label htmlFor="" className="text-primary">
                {t("common:tags")}
              </label>
              <Select
                isDisabled={!(!loadingTags && !loadingUser)}
                options={tagsOptions}
                getOptionValue={({ ID }) => ID}
                getOptionLabel={({ Title }) => Title}
                value={userTags}
                isMulti
                onChange={onChangeTags}
              />
              <small className="form-text text-muted">
                {t("myprofile:maxfive")}
              </small>
            </div>
          </div>

          {ENABLE_USER_LANGUAGE_CHANGE && (
            <div className="form-group">
              <label htmlFor="Locale" className="text-primary">
                {t("myprofile:locale")}
              </label>
              <div className="position-relative bottom-0 left-0 mr-4 mb-3 d-flex lang-select">
                <div
                  className={classNames("item d-block mr-3", {
                    "lang-current": user.Locale === "nl_NL",
                  })}
                  onClick={() => onChangeLocale("nl_NL")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="w-2-5 d-block"
                  >
                    <defs />
                    <path
                      fill="#ff4b55"
                      d="M473.655 88.276H38.345C17.167 88.276 0 105.443 0 126.621v73.471h512v-73.471c0-21.178-17.167-38.345-38.345-38.345z"
                    />
                    <path
                      fill="#41479b"
                      d="M0 385.379c0 21.177 17.167 38.345 38.345 38.345h435.31c21.177 0 38.345-17.167 38.345-38.345v-73.471H0v73.471z"
                    />
                    <path fill="#f5f5f5" d="M0 200.09h512V311.9H0z" />
                  </svg>
                </div>

                <div
                  className={classNames("item d-block mr-3", {
                    "lang-current": user.Locale === "en_US",
                  })}
                  onClick={() => onChangeLocale("en_US")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="w-2-5 d-block"
                  >
                    <defs />
                    <path
                      fill="#41479b"
                      d="M473.655 88.276H38.345C17.167 88.276 0 105.443 0 126.621V385.38c0 21.177 17.167 38.345 38.345 38.345h435.31c21.177 0 38.345-17.167 38.345-38.345V126.621c0-21.178-17.167-38.345-38.345-38.345z"
                    />
                    <path
                      fill="#f5f5f5"
                      d="M511.469 120.282c-3.022-18.159-18.797-32.007-37.814-32.007h-9.977l-163.54 107.147V88.276h-88.276v107.147L48.322 88.276h-9.977c-19.017 0-34.792 13.847-37.814 32.007l139.778 91.58H0v88.276h140.309L.531 391.717c3.022 18.159 18.797 32.007 37.814 32.007h9.977l163.54-107.147v107.147h88.276V316.577l163.54 107.147h9.977c19.017 0 34.792-13.847 37.814-32.007l-139.778-91.58H512v-88.276H371.691l139.778-91.579z"
                    />
                    <g fill="#ff4b55">
                      <path d="M282.483 88.276h-52.966v141.241H0v52.966h229.517v141.241h52.966V282.483H512v-52.966H282.483z" />
                      <path d="M24.793 421.252l186.583-121.114h-32.428L9.224 410.31a38.393 38.393 0 0015.569 10.942zM346.388 300.138H313.96l180.716 117.305a38.515 38.515 0 0012.287-13.075l-160.575-104.23zM4.049 109.475l157.73 102.387h32.428L15.475 95.842a38.499 38.499 0 00-11.426 13.633zM332.566 211.862l170.035-110.375a38.4 38.4 0 00-15.699-10.86L300.138 211.862h32.428z" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={onSubmit}
            >
              {t("common:save")}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
});

export default PublicProfileEdit;

import React from "react";

/* packages */
import { Editor } from "@tinymce/tinymce-react";

const defaultInit = {
  toolbar:
    "undo redo | styleselect | bold italic | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | link | image",
  toolbar: [
    "bold italic underline removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
    "formatselect | paste pastetext | table image link",
  ],
  link_assume_external_targets: "https", //! Force only external https links
  plugins: "table lists image autolink link",
  default_link_target: "_blank",
  branding: false,
  height: 500,
  menubar: false,
  valid_styles: {
    "*": "text-align,text-decoration",
  },
  style_formats: [
    {
      title: "Headings",
      items: [
        { title: "Heading 1", format: "h1" },
        { title: "Heading 2", format: "h2" },
        { title: "Heading 3", format: "h3" },
        { title: "Heading 4", format: "h4" },
        { title: "Heading 5", format: "h5" },
        { title: "Heading 6", format: "h6" },
      ],
    },
    {
      title: "Inline",
      items: [
        { title: "Bold", format: "bold" },
        { title: "Italic", format: "italic" },
        { title: "Underline", format: "underline" },
        { title: "Strikethrough", format: "strikethrough" },
      ],
    },
    {
      title: "Blocks",
      items: [
        { title: "Paragraph", format: "p" },
        { title: "Div", format: "div" },
      ],
    },
    {
      title: "Align",
      items: [
        { title: "Left", format: "alignleft" },
        { title: "Center", format: "aligncenter" },
        { title: "Right", format: "alignright" },
        { title: "Justify", format: "alignjustify" },
      ],
    },
  ],
  image_title: true,
  file_picker_types: "image",
};

const TextEditor = React.memo(({ init, ...rest }) => {
  return (
    <Editor
      {...rest}
      apiKey="maueyfmmp631fy2elzbk9id0qu4i6n5sbiuwacsqjvm2f0k5"
      init={{
        ...defaultInit,
        ...init,
      }}
    />
  );
});

TextEditor.defaultProps = {
  id: null,
  value: "",
  onEditorChange: () => null,
  className: "rounded",
  textareaName: "",
};

export default TextEditor;

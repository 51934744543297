import React from 'react';
import Icon from './Icon';
import { useHistory } from 'react-router-dom';


const LinkLikeArrow = (props) => {
  const { children, goTo } = props;
  const { push } = useHistory();

  const onClick = () => {
    if (goTo) {
      push(goTo);
    }
  };

  return (
    <div className="d-inline-flex link-arrow align-items-center icon-secondar pointer" onClick={onClick}>
      <span className="text-primary mr-1">{children}</span>
      {props.showArrow &&
        <Icon className="text-secondary mx-1" iconType="ArrowDropRight" />
      }
    </div>
  )
}

LinkLikeArrow.defaultProps = {
  showArrow: true,
  onClick: null
}

export default LinkLikeArrow;
import React from 'react';

const DefaultLayout = ({ children }) => {
  return (
    <main className="d-flex flex-column">
      <div className="card-wrapper flex-grow-1">
        <div className="container-fluid">
          {children}
        </div>
      </div>
    </main>
  );
};

export default DefaultLayout;
import React, { useMemo } from "react";

/* packages */
import dayjs from "dayjs";
import classNames from "classnames";

import { get, map, filter, orderBy } from "lodash";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/react-hooks";

/* context */
import { useEvent } from "../../../contexts/EventContext";
import { useAttendeeExhibitor } from "../../../contexts/ExhibitorContext";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

/* components */
import TasksCompleted from "./TasksCompleted";

import Icon from "../../../components/Icon";
import Breadcrumbs from "../../../components/Breadcrumbs";

/* graphql */
import { QUERY_TASKS_COMPLETED } from "../../../graphql/User";
import {
  QUERY_EVENT_TASKS,
  MUTATION_UPDATE_TASK_STATUS,
  MUTATION_CREATE_TASK_STATUS,
} from "../../../graphql/Event";

import { SERVICE_TYPE_URL } from "../../../utils/globals";

const ChecklistItem = React.memo(
  ({ item: { ID, Title, Deadlines, Status, Service } }) => {
    const Event = useEvent();
    const AttendeeExhibitor = useAttendeeExhibitor();

    const StatusID = parseInt(get(Status, "ID"), 10);

    // ! Status.Status
    // * -1 Pending
    // * 0 Dismissed
    // * 1 Done

    const [onCreate, { loading: creating }] = useMutation(
      MUTATION_CREATE_TASK_STATUS,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_EVENT_TASKS,
            variables: { Filter: { EventID__eq: Event.ID } },
          },
          {
            query: QUERY_TASKS_COMPLETED,
            variables: { ID: AttendeeExhibitor.ID },
          },
        ],
      }
    );

    const [onUpdate, { loading: updating }] = useMutation(
      MUTATION_UPDATE_TASK_STATUS,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_EVENT_TASKS,
            variables: { Filter: { EventID__eq: Event.ID } },
          },
          {
            query: QUERY_TASKS_COMPLETED,
            variables: { ID: AttendeeExhibitor.ID },
          },
        ],
      }
    );

    const isDisabled = useMemo(() => creating || updating, [
      creating,
      updating,
    ]);

    function onCheckChange(e) {
      e.preventDefault();

      if (isDisabled) {
        return;
      }

      if (!!StatusID) {
        return onUpdate({
          variables: {
            Input: { ID: StatusID, Status: Status.Status !== 1 ? 1 : -1 },
          },
        });
      }

      return onCreate({ variables: { Input: { TaskID: ID, Status: 1 } } });
    }

    return (
      <div className="todo-item card-body position-relative">
        <input
          id={ID}
          type="checkbox"
          className="todo-input d-none"
          name={ID}
          value={ID}
          disabled={isDisabled}
          checked={get(Status, "Status") === 1}
        />
        <label
          className="todo-content mb-0 d-flex align-items-start"
          htmlFor={ID}
        >
          <div
            className="todo-icon mr-3 flex-center tr-all"
            onClick={onCheckChange}
          >
            <Icon className="text-white" iconType="Check" />
          </div>
          <div className="flex-1">
            {!!(Service && Service.ID && !!parseInt(Service.ID, 10)) ? (
              <Link
                to={`/services/${SERVICE_TYPE_URL[Service.Type]}/${Service.ID}`}
                style={{ opacity: get(Status, "Status") === 1 ? 0.5 : 1 }}
                className={classNames("tr-opacity h2 text-body d-flex", {
                  "text-decoration-none": get(Status, "Status") !== 1,
                  "text-decoration-line-through": get(Status, "Status") === 1,
                })}
              >
                {Title}
              </Link>
            ) : (
              <h2 className="tr-opacity">{Title}</h2>
            )}
            {!!(Service && !!Service.ID && !!parseInt(Service.ID, 10)) && (
              <p className="desc tr-opacity">{Service.Title}</p>
            )}
            {Array.isArray(Deadlines) && !!Deadlines.length && (
              <p className="text-primary-50">
                {dayjs(get(Deadlines, "0.DateTimeTimestamp")).format("DD MMM")}
              </p>
            )}
          </div>
        </label>
      </div>
    );
  }
);

const ExposantProfileCompletion = React.memo(() => {
  const Event = useEvent();
  const AttendeeExhibitor = useAttendeeExhibitor();

  const { t } = useTranslation(["common", "profile"]);

  const { data: TaskData } = useQuery(QUERY_EVENT_TASKS, {
    variables: {
      Filter: {
        EventID__eq: Event.ID,
      },
    },
  });

  const Tasks = useMemo(() => {
    let nextState = map(get(TaskData, "readProvadaTasks.edges", []), "node");

    return map(nextState, ({ Deadlines, ...rest }) => ({
      ...rest,
      Deadlines: orderBy(
        filter(
          map(map(get(Deadlines, "edges", []), "node"), (props) => ({
            ...props,
            DateTimeTimestamp:
              dayjs(props.DateTime, "YYYY-MM-DD HH:mm:ss").unix() * 1000,
          })),
          ({ DateTimeTimestamp }) => dayjs(DateTimeTimestamp).isAfter()
        ),
        ["DateTimeTimestamp"],
        ["ASC"]
      ),
    }));
  }, [TaskData]);

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem>{t("common:checklist")}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>

      <div className="card">
        <div className="card-body d-flex justify-content-between align-items-start">
          <div className="flex-1">
            <h1>{t("common:checklist")}</h1>
            <h3 className="text-secondary">
              {t("profile:complete:checklistLabel")}
            </h3>
            <p>
              {t("profile:tasksCompleted_interval", {
                count: parseInt(
                  get(AttendeeExhibitor, "TasksCompleted", 0) || 0
                ),
                postProcess: "interval",
              })}
            </p>
          </div>
          <TasksCompleted />
        </div>

        {Tasks.map((item) => (
          <ChecklistItem key={item.ID} item={item} />
        ))}
      </div>
    </DefaultLayout>
  );
});

export default ExposantProfileCompletion;

import React from "react";

import { useTranslation } from "react-i18next";

/** contexts */
import { useEvent } from "../../contexts/EventContext";
import {
  useRole,
  useExhibitorGatherings,
  useAttendeeExhibitor,
  useIsManager,
  useExhibitorLocations,
} from "../../contexts/ExhibitorContext";

import Badge from "../Badge";
import RoleBlock from "../RoleBlock";
import LinkLikeArrow from "../LinkLikeArrow";
import TasksCompleted from "../../pages/ExposantProfile/ExposantProfileCompletion/TasksCompleted";

import { Link, useHistory } from "react-router-dom";

const CompanyBlock = React.memo(() => {
  const event = useEvent();
  const { push } = useHistory();

  const Role = useRole();
  const Exhibitor = useAttendeeExhibitor();

  const { t } = useTranslation(["common", "dashboard"]);

  const isManager = useIsManager();

  const { gatherings } = useExhibitorGatherings();

  const locations = useExhibitorLocations();

  const goToTaskList = (e) => {
    e.preventDefault();

    return push("/checklist");
  };

  return (
    <React.Fragment>
      <div className="card triangle triangle-top-right-secondary">
        <div className="card-body">
          <Link to="/exhibitor" className="text-decoration-none">
            <h1>{Exhibitor.Title}</h1>
            {!!event.ID && (
              <h3 className="text-secondary">
                {`${t("common:exhibitor")} ${event.Title}`}
              </h3>
            )}
            {!!(Array.isArray(locations) && locations.length > 0) && (
              <div className="d-flex flex-wrap">
                {locations.map(({ ID, Title }) => (
                  <Badge className="mb-2 mr-2" key={ID}>
                    {`${t("common:stand")} ${Title}`}
                  </Badge>
                ))}
              </div>
            )}
          </Link>
        </div>

        <Link
          to="/exhibitor"
          className="card-body d-flex justify-content-between align-items-start text-decoration-none"
        >
          <div className="flex-1">
            <h2 className="text-dark">{t("dashboard:company:title")}</h2>
            <p className="text-dark">{t("dashboard:company:edithint")}</p>
            <LinkLikeArrow>
              {t(`dashboard:company:manage.${Role.Code}`)}
            </LinkLikeArrow>
          </div>

          {isManager ? (
            <div onClick={goToTaskList}>
              <TasksCompleted />
            </div>
          ) : (
            <TasksCompleted />
          )}
        </Link>

        <RoleBlock roles={["MANAGER"]}>
          <Link to="/services" className="card-body text-decoration-none">
            <div className="flex-1">
              <h2 className="text-dark">
                {t("dashboard:standDesignAndServices")}
              </h2>
              <p className="text-dark">{t("dashboard:designPromotion")}</p>
              <LinkLikeArrow>{t("dashboard:managePosition")}</LinkLikeArrow>
            </div>
          </Link>
          <Link
            to="/services/provada/suppliers"
            className="card-body text-decoration-none"
          >
            <h2 className="text-dark">
              {t("dashboard:preferredSuppliers.title")}
            </h2>
            <p className="text-dark">
              {t("dashboard:preferredSuppliers.description")}
            </p>
            <LinkLikeArrow>
              {t("dashboard:preferredSuppliers.link")}
            </LinkLikeArrow>
          </Link>
          <Link to="/tickets" className="card-body text-decoration-none">
            <h2 className="text-dark">{t("dashboard:members.title")}</h2>
            <p className="text-dark">
              {t(`dashboard:members.description.${Role.Code}`)}
            </p>
            <LinkLikeArrow>
              {t(`dashboard:members.link.${Role.Code}`)}
            </LinkLikeArrow>
          </Link>
        </RoleBlock>

        <RoleBlock roles={["MANAGER", "EMPLOYEE"]}>
          <Link to="/gathering" className="card-body text-decoration-none">
            <h2 className="text-dark">{t("dashboard:gatherings.title")}</h2>
            <p className="text-dark">
              {t("dashboard:gatherings.description", {
                count: gatherings.length,
              })}
            </p>
            <LinkLikeArrow>
              {t(`dashboard:gatherings:link.${Role.Code}`)}
            </LinkLikeArrow>
          </Link>
        </RoleBlock>
      </div>
    </React.Fragment>
  );
});

export default CompanyBlock;

import React, { Fragment, useMemo } from "react";

/* packages */
import dayjs from "dayjs";
import useSetState from "react-use/lib/useSetState";

import { get } from "lodash";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/react-hooks";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";
import LinkArrow from "../../../components/LinkArrow";

/* context */
import { useEventDays } from "../../../contexts/EventContext";
import { useUser } from "../../../contexts/UserContext";

/* graphql */
import {
  QUERY_ATTENDEE,
  MUTATION_CREATE_MEETING,
} from "../../../graphql/Meetings";

import {
  SuggestedDateSelect,
  SuggestedTimeSelect,
  useSuggestionsDefaultOptions,
} from "../Meeting/SuggestedTimes";

const MeetingRequestForm = React.memo(() => {
  const { t } = useTranslation(["agenda", "meetingRequest"]);

  const { push } = useHistory();
  const {
    params: { AttendeeID },
  } = useRouteMatch();

  const days = useEventDays();
  const defaultTimeOptions = useSuggestionsDefaultOptions();

  const [onCreateRequest, { loading: saving, error }] = useMutation(
    MUTATION_CREATE_MEETING
  );

  const [request, setRequest] = useSetState(() => ({
    Date: dayjs(days[0]).format("YYYY-MM-DD"),
    Time: defaultTimeOptions[0],
    MeetingWithID: AttendeeID,
    Description: "",
  }));

  function onSubmit(e) {
    e.preventDefault();

    const { Date, Time, ...rest } = request;

    const variables = {
      Input: {
        ...rest,
        DateTime: `${Date} ${Time}:00`,
      },
    };

    return onCreateRequest({ variables }).then(
      ({ data: { createProvadaMeeting: Meeting } }) => {
        const ID = parseInt(get(Meeting, "ID"), 10);

        if (!!ID) {
          return push(`/agenda/meeting/${ID}`);
        }

        return push("/agenda");
      }
    );
  }

  return (
    <Fragment>
      <p className="text-dark font-weight-bold mb-3">
        {t("meetingRequest:label.requestMeeting")}
      </p>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label className="text-primary">
            {t("meeting:form.reschedule.input.label.SuggestedTimes")}
          </label>
          <div className="d-flex mb-2">
            <SuggestedDateSelect
              value={request.Date}
              onChange={(Date) => setRequest({ Date })}
            />
            <SuggestedTimeSelect
              options={defaultTimeOptions}
              value={request.Time}
              onChange={(Time) => setRequest({ Time })}
            />
          </div>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="Description" className="text-primary">
            {t("meeting:form.reschedule.input.label.Message")}
          </label>
          <input
            id="Description"
            type="text"
            className="form-control"
            name="Description"
            value={request.Description}
            onChange={({ target: { value: Description } }) =>
              setRequest({ Description })
            }
          />
        </div>
        <div className="">
          <button className="btn btn-primary" type="button" onClick={onSubmit}>
            {saving && (
              <span
                className="spinner-border spinner-border-sm mr-2 mb-1"
                role="status"
                aria-hidden="true"
              />
            )}
            {t("meetingRequest:button.submit")}
          </button>
        </div>
        {!!error && (
          <div className="mt-3">
            <small className="text-danger font-weight-bold">
              {get(error, "graphQLErrors[0].message")}
            </small>
          </div>
        )}
      </form>
    </Fragment>
  );
});

const PrivateProfile = React.memo(() => {
  const { t } = useTranslation(["agenda", "meetingRequest"]);

  return (
    <Fragment>
      <p className="text-dark font-weight-bold mb-3">
        {t("meetingRequest:label.privateProfile")}
      </p>
      <LinkArrow to="/profile/edit/public">
        {t("meetingRequest:button.profile")}
      </LinkArrow>
    </Fragment>
  );
});

const MeetingRequest = React.memo(
  ({
    history: { replace },
    match: {
      params: { AttendeeID },
    },
  }) => {
    const { t } = useTranslation(["agenda", "meetingRequest"]);

    const User = useUser();

    const { data } = useQuery(QUERY_ATTENDEE, {
      variables: { ID: AttendeeID },
      fetchPolicy: "network-only",
      onError: () => replace("/agenda"),
      onCompleted: ({ readOneProvadaAttendee: Attendee }) => {
        const AvailableForMeetings = get(
          Attendee,
          "Member.AvailableForMeetings",
          false
        );

        if (!AvailableForMeetings) {
          return replace("/agenda");
        }
      },
    });

    const CompanyTitle = useMemo(
      () =>
        [
          get(data, "readOneProvadaAttendee.Member.Function"),
          get(data, "readOneProvadaAttendee.Company.Title"),
        ]
          .filter(Boolean)
          .join(" - "),
      [data]
    );

    const CompanyEvent = useMemo(
      () =>
        [
          get(data, "readOneProvadaAttendee.Company.Title", ""),
          get(data, "readOneProvadaAttendee.Event.Title", ""),
        ]
          .filter(Boolean)
          .join(" - "),
      [data]
    );

    return (
      <DefaultLayout>
        <Breadcrumbs>
          <Breadcrumbs.List>
            <Breadcrumbs.ListItem to="/agenda">
              {t("agenda:title")}
            </Breadcrumbs.ListItem>
            <Breadcrumbs.ListItem>
              {get(data, "readOneProvadaAttendee.Member.FullName", "")}
            </Breadcrumbs.ListItem>
          </Breadcrumbs.List>
          <p className="mb-0">{CompanyEvent}</p>
        </Breadcrumbs>
        <div className="card">
          <div className="card-body position-relative overflow-hidden p-4">
            <div className="d-flex py-2 flex-wrap mb-3">
              <div>
                <div
                  className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
                  style={{
                    backgroundImage: `url(${get(
                      data,
                      "readOneProvadaAttendee.Member.Picture.AbsoluteLink",
                      ""
                    )})`,
                  }}
                />
              </div>
              <div className="flex-1 d-flex align-items-center">
                <div className="flex-1">
                  <p className="text-primary font-weight-bold mb-0">
                    {t("meetingRequest:label.requestMeetingWith")}
                  </p>
                  <h3 className="mb-1 text-dark">
                    {get(data, "readOneProvadaAttendee.Member.FullName", "")}
                  </h3>
                  {!!CompanyTitle && (
                    <p className="text-gray">{CompanyTitle}</p>
                  )}
                </div>
              </div>
            </div>
            {!!User.AvailableForMeetings ? (
              <MeetingRequestForm />
            ) : (
              <PrivateProfile />
            )}
          </div>
        </div>
      </DefaultLayout>
    );
  }
);

export default MeetingRequest;

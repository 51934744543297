import React, { Fragment, useMemo } from "react";

/* packages */
import classNames from "classnames";
import get from "lodash/get";
import dayjs from "dayjs";
import parseHTML from "html-react-parser";

import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";

import useToggle from "react-use/lib/useToggle";

import {
  MUTATION_UPDATE_SPEAKER_INVITATION,
  MUTATION_DELETE_GATHERING_INVITATION,
  MUTATION_RESEND_GATHERING_INVITATION,
  QUERY_INVITED_SPEAKER,
} from "../../../../graphql/Meetings";
import { useAttendee } from "../../../../contexts/ExhibitorContext";

import Icon from "../../../../components/Icon";
import Badge from "../../../../components/Badge";
import Dialog from "../../../../components/Dialog";
import LinkArrowExternal from "../../../../components/LinkArrowExternal";
import { useSnackbarCustom } from "../../../../components/Notification";

import UserBlock from "./UserBlock";

const StatusBadge = React.memo((props) => {
  const { Status } = props;

  const { t } = useTranslation(["agendaStatus"]);

  return (
    <span
      className={classNames("badge position-absolute right-0 top-0", {
        "badge-secondary": Status === 0,
        "badge-primary": Status === -1,
        "badge-leaf": Status === 1,
      })}
    >
      {t(`agendaStatus:${Status}`)}
    </span>
  );
});

export const GatheringCard = (props) => {
  const {
    compact = true,
    item: {
      ID,
      Email,
      Status,
      InvitedBy,
      InvitedBy: { ID: InvitedByID },
      InvitedFor: {
        ID: GatheringID,
        Title,
        PublicLink,
        Description,
        Date: GatheringDate,
        Start: GatheringStartTime,
        End: GatheringEndTime,
      },
    },
  } = props;

  const { t } = useTranslation(["common", "agenda", "gatheringType"]);

  const snackbar = useSnackbarCustom();

  const attendee = useAttendee();

  const isOwner = InvitedByID === get(attendee, "ID");

  const GatheringTitle = Title || `#${GatheringID}`;

  const GatheringDescription = useMemo(
    () => parseHTML(Description || ""),
    [Description]
  );

  const [cancel, setCancel] = useToggle(false);

  const time = dayjs(
    `${GatheringDate} ${GatheringStartTime}`,
    "YYYY-MM-DD HH:mm:ss"
  ).format(`dddd [${t("common:at")}] HH:mm`);

  const [onReject, { loading: isRejecting }] = useMutation(
    MUTATION_UPDATE_SPEAKER_INVITATION,
    {
      variables: {
        ID: parseInt(ID, 10),
        Status: 0,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        "readProvadaMyAgenda",
        {
          query: QUERY_INVITED_SPEAKER,
          variables: {
            ID,
          },
        },
      ],
      onError: (error) => {
        snackbar.enqueueSnackbarError(error);
      },
    }
  );

  const [onCancel] = useMutation(MUTATION_DELETE_GATHERING_INVITATION, {
    variables: {
      ID: parseInt(ID, 10),
    },
    awaitRefetchQueries: true,
    refetchQueries: ["readProvadaMyAgenda"],
    onCompleted: () => {
      snackbar.enqueueSnackbar(t("gatheringSpeakers:snackbar.cancel.title"));
    },
    onError: (error) => {
      snackbar.enqueueSnackbarError(error);
    },
  });

  const [onResend] = useMutation(MUTATION_RESEND_GATHERING_INVITATION, {
    variables: {
      ID: parseInt(ID, 10),
    },
    onCompleted: () => {
      snackbar.enqueueSnackbar(t("gatheringSpeakers:snackbar.resend.title"));
    },
    onError: (error) => {
      snackbar.enqueueSnackbarError(error);
    },
  });

  const [onAccept, { loading: isAccepting }] = useMutation(
    MUTATION_UPDATE_SPEAKER_INVITATION,
    {
      variables: {
        ID: parseInt(ID, 10),
        Status: 1,
      },
      awaitRefetchQueries: true,
      refetchQueries: ["readProvadaMyAgenda"],
      onError: (error) => {
        snackbar.enqueueSnackbarError(error);
      },
    }
  );

  const userImage = useMemo(
    () => get(isOwner ? InvitedBy : {}, "Member.Picture.AbsoluteLink"),
    [InvitedBy, isOwner]
  );

  const CompanyTitle = useMemo(
    () =>
      [get(InvitedBy, "Member.Function"), get(InvitedBy, "Company.Title")]
        .filter(Boolean)
        .join(" - "),
    [InvitedBy]
  );

  return (
    <Fragment>
      <div
        className={classNames(
          "card-body position-relative overflow-hidden text-decoration-none px-4 pt-4",
          {
            "pb-2": Status === 1,
            "pb-4": Status !== 1,
          }
        )}
      >
        <StatusBadge Status={Status} />
        <Link
          className="d-flex py-2 flex-wrap text-decoration-none"
          to={`/agenda/gathering-invitation/${ID}`}
        >
          <UserBlock
            disabled={Status === 0}
            image={userImage}
            headline={t("agenda:gatheringInvitation.headline")}
            title={isOwner ? Title : get(InvitedBy, "Member.FullName")}
            subtitle={isOwner ? Email : CompanyTitle}
          />
        </Link>
        {!compact && (
          <Fragment>
            <div className="d-flex flex-wrap mb-3">
              {!!GatheringDate && (
                <Badge secondary className="mb-2 mr-2">
                  {dayjs(GatheringDate, "YYYY-MM-DD").format("dddd")}
                </Badge>
              )}
              {!!GatheringStartTime && !!GatheringEndTime && (
                <Badge secondary className="mb-2 mr-2">
                  {[
                    GatheringStartTime.slice(0, 5),
                    GatheringEndTime.slice(0, 5),
                  ].join(" - ")}
                </Badge>
              )}
            </div>

            <div className="mb-3">{GatheringDescription}</div>
          </Fragment>
        )}
        <LinkArrowExternal to={PublicLink}>
          {t("agenda:gathering.button.public_link")}
        </LinkArrowExternal>
        {!!(Status === -1 && !isOwner) && (
          <Fragment>
            <p className="text-dark mb-0 my-3">
              <Trans
                i18nKey="agenda:gatheringInvitation.description"
                values={{
                  gathering: GatheringTitle,
                  time,
                  role: t("allRoles:SPEAKER.title"),
                }}
              />
            </p>
            <div className="d-flex flex-row">
              <button
                className="btn btn-outline-primary mr-2"
                type="button"
                onClick={onReject}
              >
                {isRejecting && (
                  <span
                    className="spinner-border spinner-border-sm mr-2 mb-1"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("agenda:gatheringInvitation.button.reject")}
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={onAccept}
              >
                {isAccepting && (
                  <span
                    className="spinner-border spinner-border-sm mr-2 mb-1"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("agenda:gatheringInvitation.button.accept")}
              </button>
            </div>
          </Fragment>
        )}
        {!!(Status === -1 && isOwner && !compact) && (
          <Fragment>
            <div className="mb-3 mt-3">
              <div
                className="d-inline-flex link-arrow align-items-center icon-secondary pointer"
                onClick={() => setCancel(true)}
              >
                <span className="text-primary mr-1">
                  {t("agenda:meetingInvitation.button.cancel")}
                </span>
                <Icon
                  className="text-secondary mx-1"
                  iconType="ArrowDropRight"
                />
              </div>
            </div>
            <div className="mt-3">
              <div
                className="d-inline-flex link-arrow align-items-center icon-secondary pointer"
                onClick={onResend}
              >
                <span className="text-primary mr-1">
                  {t("agenda:meetingInvitation.button.resend")}
                </span>
                <Icon
                  className="text-secondary mx-1"
                  iconType="ArrowDropRight"
                />
              </div>
            </div>
            <Dialog
              open={cancel}
              title={t("gatheringSpeakers:dialog.cancel.title")}
              content={
                <Trans
                  i18nKey="gatheringSpeakers:dialog.cancel.content"
                  values={{ title: Email }}
                />
              }
              onAccept={() => {
                setCancel(false);

                onCancel();
              }}
              onCancel={() => {
                setCancel(false);
              }}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

const GatheringInvitationListItem = React.memo((props) => {
  return (
    <div className="card w-100">
      <GatheringCard {...props} />
    </div>
  );
});

export default GatheringInvitationListItem;

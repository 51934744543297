import React, { Fragment } from 'react';

import classNames from 'classnames';

const Icon = ({ iconType, className, ...rest }) => {
  return (
    <Fragment>
      <div className="only-ios align-items-center">
        <i className={classNames(className, {
          [`if-${iconType}-iOS`]: true
        })} {...rest} />
      </div>
      <div className="not-ios align-items-center">
        <i className={classNames(className, {
          [`if-${iconType}-Material`]: true
        })} {...rest} />
      </div>
    </Fragment>
  )
}

Icon.defaultProps = {
  className: '',
  iconType: ''
};

export default Icon;
import React from "react";

const Loading = () => (
  <div
    className="position-fixed top-50 left-50 negative-translate-50"
    style={{ width: "220px" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 156.41 23.969"
      id="logo"
      className="w-100"
    >
      <defs></defs>
      <g data-name="Group 1">
        <path
          fill="#00415b"
          d="M51.232 11.508v5.789h-4.111V.235h6.811a7.93 7.93 0 013.463.721 5.431 5.431 0 012.332 2.051 5.6 5.6 0 01.826 3.018 4.937 4.937 0 01-1.787 3.99 7.415 7.415 0 01-4.9 1.494zm0-3.176h2.7a2.542 2.542 0 001.822-.6 2.221 2.221 0 00.627-1.687 2.745 2.745 0 00-.649-1.908 2.282 2.282 0 00-1.758-.727h-2.742zm17.85 2.942h-2.227v6.023h-4.113V.235h6.715a7.489 7.489 0 014.746 1.348 4.568 4.568 0 011.711 3.809 5.55 5.55 0 01-.721 2.953 5.117 5.117 0 01-2.256 1.9l3.563 6.876v.176h-4.406zm-2.227-3.177h2.6a2.339 2.339 0 001.764-.615 2.375 2.375 0 00.592-1.717 2.4 2.4 0 00-.6-1.729 2.317 2.317 0 00-1.758-.627h-2.6zm25.993 1.02a10.062 10.062 0 01-.926 4.418 6.842 6.842 0 01-2.631 2.962 7.326 7.326 0 01-3.885 1.034 7.429 7.429 0 01-3.867-1 6.831 6.831 0 01-2.631-2.865 9.772 9.772 0 01-.99-4.277v-.961a10.106 10.106 0 01.92-4.424 6.825 6.825 0 012.637-2.959 7.377 7.377 0 013.908-1.048 7.3 7.3 0 013.867 1.031 6.948 6.948 0 012.643 2.936 9.816 9.816 0 01.955 4.365zm-4.184-.715a7.175 7.175 0 00-.85-3.861 2.725 2.725 0 00-2.432-1.318q-3.094 0-3.27 4.641l-.01 1.253a7.379 7.379 0 00.83 3.856 2.721 2.721 0 002.473 1.348 2.688 2.688 0 002.4-1.324 7.178 7.178 0 00.855-3.809zm12.668 4.254l3.4-12.422h4.594l-5.744 17.063h-4.5L93.382.235h4.559zm18.047 1.454h-5.637l-.984 3.187h-4.395L114.621.235h3.867l6.305 17.062h-4.418zm-4.652-3.176h3.668l-1.84-5.918zm11.305 6.363V.235h5.5a8.085 8.085 0 014.066 1.025 7.208 7.208 0 012.818 2.895 8.739 8.739 0 011.025 4.189v.785a8.79 8.79 0 01-.99 4.2 7.239 7.239 0 01-2.789 2.907 7.907 7.907 0 01-4.014 1.061zm4.113-13.887v10.723h1.43a3.2 3.2 0 002.719-1.26 6.173 6.173 0 00.949-3.744v-.738a6.123 6.123 0 00-.949-3.727 3.256 3.256 0 00-2.762-1.254zm20.848 10.7h-5.634l-.984 3.187h-4.395L146.238.235h3.867l6.305 17.062h-4.418zm-4.652-3.176h3.668l-1.84-5.918z"
          data-name="Path 5"
        ></path>
        <path
          fill="#d60056"
          d="M101.332 12.656l3.4-12.422h4.594l-5.744 17.063h-4.5L93.382.235h4.559zm18.047 1.454h-5.637l-.984 3.187h-4.395L114.621.235h3.867l6.305 17.062h-4.418zm-4.652-3.176h3.668l-1.84-5.918z"
          data-name="Path 9"
        ></path>
        <path
          fill="#d60056"
          d="M16.704 17.297l.336-1.92a3.65 3.65 0 01-1.152.24c-.528 0-.672-.288-.624-.816l.936-11.9h-2.112L9.096 8.465 6.072 2.897h-2.14L0 17.297h2.352L5.28 6.209l2.76 4.92h1.176l4.488-4.92-.816 9.12a1.86 1.86 0 002.064 2.256 6.524 6.524 0 001.752-.288zm7.08-15.192a1.184 1.184 0 00-1.2-1.464 1.811 1.811 0 00-1.7 1.464 1.185 1.185 0 001.2 1.44 1.813 1.813 0 001.7-1.44zm-1.08 15.192l.336-1.92a3.66 3.66 0 01-1.176.24c-.528 0-.744-.264-.648-.816l1.536-8.712h-2.256l-1.564 8.88c-.308 1.8.58 2.616 2.02 2.616a6.524 6.524 0 001.752-.288zm7.752-15.192a1.206 1.206 0 00-1.2-1.464 1.811 1.811 0 00-1.7 1.464 1.174 1.174 0 001.2 1.44 1.813 1.813 0 001.7-1.44zm-3.724 19.272L29.42 6.089h-2.252l-2.664 15.12a.852.852 0 01-.936.816 2.866 2.866 0 01-1.08-.24l-.336 1.92a5.275 5.275 0 001.632.264 2.854 2.854 0 002.948-2.592zm15.844-4.08l.336-1.92a3.66 3.66 0 01-1.176.24c-.528 0-.744-.264-.648-.816l.84-4.776c.48-2.664-1.08-4.2-4.056-4.2a21.8 21.8 0 00-4.68.672l-1.896 10.8h2.256l1.632-9.216a9.579 9.579 0 012.348-.312c1.656 0 2.4.768 2.136 2.256l-.864 4.944c-.312 1.8.576 2.616 2.016 2.616a6.524 6.524 0 001.756-.288z"
          data-name="Path 6"
        ></path>
      </g>
    </svg>
  </div>
);

export default Loading;

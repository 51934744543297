import useUpdateEffect from "react-use/lib/useUpdateEffect";

import { useEvent } from "../../contexts/EventContext";

const useEventUpdateEffect = (callback = () => null) => {
  const event = useEvent();

  useUpdateEffect(() => {
    callback();
  }, [event.ID]);
};

export default useEventUpdateEffect;

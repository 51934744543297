import React from "react";

/* packages */
import dayjs from "dayjs";
import classNames from "classnames";

/* context */
import { useEventDays } from "../../contexts/EventContext";
import { useCalendar } from "../../contexts/CalendarContext";

const EventDays = React.memo(() => {
  const { selected, onChangeDay } = useCalendar();
  const days = useEventDays();

  return (
    <div className="card-body border-bottom-0 flex-grow-0">
      <div className="btn-group btn-group-calendar mb-2" role="group">
        {days.map((day) => {
          const checked = dayjs(selected).isSame(day, "date");

          return (
            <button
              key={day}
              type="button"
              data-timestamp={day}
              className={classNames("btn", {
                "btn-secondary": checked,
                "btn-outline-primary": !checked,
              })}
              onClick={() => onChangeDay(day)}
            >
              {dayjs(day).format("ddd")}
            </button>
          );
        })}
      </div>
    </div>
  );
});

export default EventDays;

import React, { Fragment, useMemo } from "react";

import get from "lodash/get";
import map from "lodash/map";
import { Link } from "react-router-dom";

import { useUser } from "../../contexts/UserContext";
import { useEvent } from "../../contexts/EventContext";
import { useExhibitorLocations } from "../../contexts/ExhibitorContext";

import Icon from "../Icon";

const ExposantBreadcrumb = React.memo(() => {
  const Event = useEvent();
  const Locations = useExhibitorLocations();

  const subtitle = useMemo(() => {
    let nextState = [get(Event, "Title", "")];

    if (Array.isArray(Locations) && Locations.length > 0) {
      nextState = [
        ["Stand", map(Locations, "Title").filter(Boolean).join(", ")]
          .filter(Boolean)
          .join(" "),
        ...nextState,
      ];
    }

    return nextState.filter(Boolean).join(" - ");
  }, [Event, Locations]);

  return <p className="mb-0">{subtitle}</p>;
});

const CompanyBreadcrumb = React.memo(() => {
  const User = useUser();
  const Event = useEvent();

  const subtitle = useMemo(
    () =>
      [get(User, "WorksAt", ""), get(Event, "Title", "")]
        .filter(Boolean)
        .join(" - "),
    [User, Event]
  );

  return <p className="mb-0">{subtitle}</p>;
});

const BreadcrumbsList = React.memo(({ children }) => {
  return (
    <nav aria-label="breadcrumb">
      <ul className="list-unstyled d-flex align-items-center flex-wrap mb-1">
        {children}
      </ul>
    </nav>
  );
});

const BreadcrumbsListItem = React.memo(({ to, children }) => {
  if (!!to && to.length) {
    return (
      <Fragment>
        <li>
          <Link to={to} className="d-block h3 mb-0 text-decoration-none">
            {children}
          </Link>
        </li>
        <li className="breadcrumb-separator flex-center">
          <Icon
            className="font-size-1 text-secondary mx-1"
            iconType="ArrowDropRight"
          />
        </li>
      </Fragment>
    );
  }

  return <li className="h3 mb-0">{children}</li>;
});

const Breadcrumbs = React.memo(({ children, className = "" }) => {
  return (
    <div className={`mb-4 px-3 ${className}`}>
      <div className="mb-4">{children}</div>
    </div>
  );
});

Breadcrumbs.List = BreadcrumbsList;
Breadcrumbs.ListItem = BreadcrumbsListItem;

Breadcrumbs.Addition = {
  Exposant: ExposantBreadcrumb,
  Company: CompanyBreadcrumb,
};

export default Breadcrumbs;

import React, { useMemo } from 'react';

/* packages */
import dayjs from 'dayjs';

import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation, Trans } from 'react-i18next';

/* context */
import { useUser } from '../../contexts/UserContext';

/* components */
import { Halls } from '../../components/illustrations';

/* layout */
import DefaultLayout from '../../layout/DefaultLayout';

/* graphql */
import { QUERY_RESERVATION_COMPLETE } from '../../graphql/Location';

const reservationDefaultProps = {
  ID: null,
  Date: '',
  Start: '',
  End: '',
  Location: {
    ID: null,
    Title: ''
  },
  Gathering: {
    ID: null,
  }
};

const RoomReservationComplete = React.memo(({ history: { replace }, match: { params: { ReservationID: ID } } }) => {
  const { t } = useTranslation(['roomReservationComplete']);

  const { Email: UserEmail } = useUser();

  const { data } = useQuery(QUERY_RESERVATION_COMPLETE, {
    variables: {
      ID
    },
    onCompleted: (data) => {
      if (!!data && !!data.readOneProvadaReservation) {
        return;
      }

      return replace('/');
    },
    onError: () => replace('/')
  });

  const {
    End,
    Start,
    Gathering,
    Date: ReservationDate,
    Location: {
      Title: LocationTitle
    }
  } = useMemo(() => get(data, 'readOneProvadaReservation') || reservationDefaultProps, [data]);

  const dateString = useMemo(() => {
    if (!(!!Start && !!ReservationDate)) {
      return "";
    }

    const obj = dayjs(`${ReservationDate} ${Start}`, 'YYYY-MM-DD HH:mm:ss');

    if (!obj.isValid()) {
      return '';
    }

    return obj.format('dddd DD MMMM');

  }, [Start, ReservationDate]);

  const timeString = useMemo(() => {
    if (!(!!Start && !!End)) {
      return "";
    }

    return [Start.slice(0, 5), End.slice(0, 5)].filter(Boolean).join(" - ");

  }, [Start, End]);

  const route = useMemo(() => {
    let nextRoute = '/gathering';

    const GatheringID = parseInt(get(Gathering, 'ID'), 10);

    if (!!GatheringID) {
      nextRoute += `/${GatheringID}`;
    };

    return nextRoute;

  }, [Gathering]);

  return (
    <DefaultLayout>
      <div className="card">

        <div className="card-body my-3">
          <h1 className="text-center mb-3">
            {t('roomReservationComplete:title.one')}
            <br />
            {t('roomReservationComplete:title.two')}
          </h1>
          <div className="flex-center">
            <h3 className="font-weight-normal mb-4 text-align-start">
              {t('roomReservationComplete:title.one')}
              <br />
              {t('roomReservationComplete:title.two')}
              <br />
              <a className="text-secondary text-decoration-none" href={`mailto:${UserEmail}`}>
                {UserEmail}
              </a>
            </h3>
          </div>

          <div className="d-flex flex-center mb-4">
            <div className="block-icon-wrapper d-flex flex-1 justify-content-end mr-3">
              <Halls className="icon-primary" />
            </div>
            <div className="flex-1">
              <h3>
                {LocationTitle}
              </h3>
              <p className="text-primary font-weight-bold">
                {dateString}
              </p>
              <h3 className="font-weight-normal">
                {timeString}
              </h3>
            </div>
          </div>

          <div className="d-flex flex-center">
            <Link replace to={route} className="btn btn-secondary text-decoration-none">
              {t('roomReservationComplete:button.submit')}
            </Link>
          </div>

        </div>
      </div>
    </DefaultLayout>
  );
});

export default RoomReservationComplete;

import React, { Fragment } from "react";

import { useHasRole } from "../contexts/ExhibitorContext";

const RoleBlock = ({ roles, children } = { roles: [] }) => {
  const hasRole = useHasRole(roles);

  if (!hasRole) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default RoleBlock;

import React, { Fragment, useMemo } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { Redirect } from "react-router-dom";

import { useTranslation } from "react-i18next";

import get from "lodash/get";
import find from "lodash/find";

import { useUser } from "../../../contexts/UserContext";
import { useEventContext } from "../../../contexts/EventContext";

import DefaultLayout from "../../../layout/DefaultLayout";
import Icon from "../../../components/Icon";
import LinkArrow from "../../../components/LinkArrow";

import {
  GATHERING_DATA_FOR_SIGNUP,
  GATHERING_SIGNUP,
} from "../../../graphql/Gathering";
import { QUERY_EVENT_ATTENDEES } from "../../../graphql/User";

const initGathering = {
  ID: null,
  Title: "",
};

const initAttendee = {
  ID: null,
};

const GatheringSignup = React.memo(
  ({
    history: { replace },
    match: {
      params: { ID },
    },
  }) => {
    const { t } = useTranslation(["common", "gatherings"]);

    const user = useUser();
    const { event, events, onChangeActiveEvent } = useEventContext();

    const [
      registerToGathering,
      {
        data: gatheringAttendanceData,
        loading: singing,
        error: signupErrorData,
      },
    ] = useMutation(GATHERING_SIGNUP, {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_EVENT_ATTENDEES,
          variables: {
            Filter: {
              MemberID__eq: user.ID,
              EventID__eq: event.ID,
            },
          },
        },
      ],
      onError: (e) => {
        // can be that user is already signup so we are showing message for him
        // can't do much except to catch it here and do nothing
        // this is preventing unhandled error https://app.activecollab.com/119944/projects/829?modal=Task-43640-829
      },
    });

    const { data: gatheringData, loading } = useQuery(
      GATHERING_DATA_FOR_SIGNUP,
      {
        variables: {
          ID: ID,
        },
        onCompleted: ({ readOneProvadaGathering: gathering }) => {
          if (!(gathering && gathering.ID)) {
            replace("/agenda");

            return;
          }

          const EventID = get(event, "ID");
          const RecordEventID = get(gathering, "Event.ID");

          if (RecordEventID === EventID) {
            if (gathering.Registration !== "PROVADA") {
              return;
            }

            registerToGathering({
              variables: {
                GatheringID: gathering.ID,
              },
            });

            return;
          }

          const RecordEvent = find(events, { ID: RecordEventID });

          if (RecordEvent) {
            onChangeActiveEvent(RecordEvent);

            if (gathering.Registration !== "PROVADA") {
              return;
            }

            registerToGathering({
              variables: {
                GatheringID: gathering.ID,
              },
            });

            return;
          }

          replace("/agenda");
        },
        onError: () => {
          replace("/agenda");
        },
      }
    );

    const gathering = useMemo(
      () => get(gatheringData, "readOneProvadaGathering") || initGathering,
      [gatheringData]
    );

    const gatheringAttendance = useMemo(
      () =>
        get(gatheringAttendanceData, "applyForProvadaGathering", initAttendee),
      [gatheringAttendanceData]
    );

    const errorMsg = useMemo(
      () => get(signupErrorData, "graphQLErrors[0].message"),
      [signupErrorData]
    );

    // if gathering data is not available redirect to agenda list - list of all gatherings user signed up
    if (!gathering.ID && !loading) {
      return <Redirect replace to="/agenda" />;
    }

    return (
      <DefaultLayout>
        <div className="card">
          <div className="card-body">
            <h1>{t("gatherings:signup:title", { title: gathering.Title })}</h1>
            {!!loading && <p>{t("common:loading")}</p>}
            {!!singing && <p>{t("gatherings:signup:siggningup")}</p>}
            {errorMsg && (
              <Fragment>
                <p>{errorMsg}</p>
              </Fragment>
            )}
            {gathering.Registration === "FREE" && (
              <p>{t("gatherings:signup:open", { title: gathering.Title })}</p>
            )}
            {gathering.Registration !== "PROVADA" &&
              gathering.Registration !== "FREE" && (
                <p>
                  {t("gatherings:signup:forbidden", { title: gathering.Title })}
                </p>
              )}
            {gatheringAttendance.ID && (
              <Fragment>
                <p>
                  {t("gatherings:signup:successmsg", {
                    title: gathering.Title,
                  })}
                </p>
                <a
                  href={gathering.PublicLink}
                  className="text-decoration-none mb-2 d-inline-flex link-arrow align-items-center icon-secondary"
                >
                  <span className="text-primary mr-1">
                    {t("gatherings:details:morelink", {
                      title: gathering.Title,
                    })}
                  </span>
                  <Icon
                    className="text-secondary mx-1"
                    iconType="ArrowDropRight"
                  />
                </a>
                <br />
              </Fragment>
            )}
            <LinkArrow to="/agenda">
              {t("gatherings:agenda:morelink")}
            </LinkArrow>
          </div>
        </div>
      </DefaultLayout>
    );
  }
);

export default GatheringSignup;

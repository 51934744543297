import React, { Fragment, useMemo, useState, useRef } from "react";

/* packages */
import dayjs from "dayjs";
import classNames from "classnames";
import useSetState from "react-use/lib/useSetState";

import { get, pick } from "lodash";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";

/* context */
import {
  useAttendeeExhibitor,
  useAttendeeCompany,
} from "../../../contexts/ExhibitorContext";

/* components */
import { Premium } from "../../../components/illustrations";

import CardHeader from "../../../components/CardHeader";
import Editor from "../../../components/Editor";
import Breadcrumbs from "../../../components/Breadcrumbs";
import LinkArrowExternal from "../../../components/LinkArrowExternal";

import { getBase64 } from "../../../lib/common";
import { useFileUpload } from "../../../lib/hooks";

/* graphql */
import {
  MUTATION_UPDATE_ATTENDEE_EXHIBITOR_INFO,
  QUERY_ATTENDEE_EXHIBITOR_INFO,
  QUERY_ATTENDEE_COMPANY_PREMIUM,
} from "../../../graphql/User";
import { useEvent } from "../../../contexts/EventContext";

const exhibitorDefaultProps = {
  ID: null,
  Title: "",
  Slogan: "",
  Profile: "",
  //   Intro: "",
  LogoURL: null,
  HeaderURL: null,
};

const companyDefaultProps = {
  Premium: false,
  Expires: null,
};

// const introLimit = 240;

const ExposantProfileEditOrganization = React.memo(
  ({ history: { push, goBack } }) => {
    const { t } = useTranslation(["common", "exposant"]);

    const [
      onUploadLogo,
      { loading: uploadingLogo, error: uploadingLogoError },
    ] = useFileUpload("image");
    const [
      onUploadHeader,
      { loading: uploadingHeader, error: uploadingHeaderError },
    ] = useFileUpload("image");

    const { ExclusiveProfileLink } = useEvent();

    const AttendeeExhibitor = useAttendeeExhibitor();
    const AttendeeCompany = useAttendeeCompany();

    const inputLogoRef = useRef(null);
    const [FileLogo, setLogo] = useState(null);

    const inputHeaderRef = useRef(null);
    const [FileHeader, setHeader] = useState(null);

    const [exhibitor, setExhibitor] = useSetState({ ...exhibitorDefaultProps });

    useQuery(QUERY_ATTENDEE_EXHIBITOR_INFO, {
      variables: {
        ID: get(AttendeeExhibitor, "ID"),
      },
      onCompleted: ({ readOneProvadaExhibitor: data }) => {
        if (!data) {
          return goBack();
        }
        return setExhibitor({ ...data });
      },
      onError: () => goBack(),
    });

    const { data: CompanyData, loading: loadingCompany } = useQuery(
      QUERY_ATTENDEE_COMPANY_PREMIUM,
      {
        variables: {
          ID: get(AttendeeCompany, "ID"),
        },
      }
    );

    const Company = useMemo(
      () => get(CompanyData, "readOneProvadaCompany") || companyDefaultProps,
      [CompanyData]
    );

    const ExpiresDate = useMemo(
      () =>
        Company && Company.Expires
          ? dayjs(Company.Expires, "YYYY-MM-DD").format("DD MMM YYYY")
          : "",
      [Company]
    );

    const [onSaveExposantData] = useMutation(
      MUTATION_UPDATE_ATTENDEE_EXHIBITOR_INFO,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_ATTENDEE_EXHIBITOR_INFO,
            variables: {
              ID: get(AttendeeExhibitor, "ID"),
            },
          },
        ],
      }
    );

    const isPremium = useMemo(() => !!Company.Premium, [Company]);

    const onSubmit = (e) => {
      e.preventDefault();

      if (loadingCompany || uploadingLogo || uploadingHeader) {
        return;
      }

      let Input = pick(exhibitor, ["ID", "Title", "LogoID"]);

      if (isPremium) {
        Input = {
          ...Input,
          ...pick(exhibitor, ["Profile", "HeaderID", "Slogan", "VideoURL"]),
        };
      }
      //   else {
      //     Input = { ...Input, Intro: exhibitor.Intro };
      //   }

      const Promises = [
        !!FileLogo
          ? onUploadLogo(FileLogo).then(({ id }) => id)
          : Promise.resolve(null),
        isPremium && !!FileHeader
          ? onUploadHeader(FileHeader).then(({ id }) => id)
          : Promise.resolve(null),
      ];

      return Promise.all(Promises)
        .then(([LogoID, HeaderID]) => {
          if (!!LogoID) {
            Input = { ...Input, LogoID };
          }

          if (!!HeaderID) {
            Input = { ...Input, HeaderID };
          }

          return onSaveExposantData({ variables: { Input } });
        })
        .then(() => push("/exhibitor?saved=true"))
        .catch((error) => null);
    };

    const onChangeValue = ({ target: { name, value } }) =>
      setExhibitor({ [name]: value });

    // const onChangeIntro = ({ target: { value } }) => {
    //   const Intro = value.replace(/(<([^>]+)>)/gi, "").slice(0, introLimit);

    //   return setExhibitor({ Intro });
    // };

    const onDismiss = () => push("/exhibitor");

    return (
      <Fragment>
        <Breadcrumbs>
          <Breadcrumbs.List>
            <Breadcrumbs.ListItem to="/exhibitor">
              {t("common:exhibitor")}
            </Breadcrumbs.ListItem>
            <Breadcrumbs.ListItem>
              {t("common:organization")}
            </Breadcrumbs.ListItem>
          </Breadcrumbs.List>
          <Breadcrumbs.Addition.Exposant />
        </Breadcrumbs>

        <div className="card">
          <CardHeader
            title={t("exposant:organizationinfo")}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
          />

          <form className="card-body" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="Logo" className="text-primary">
                {t("exposant:input.label.Logo")}
              </label>
              {!!exhibitor.LogoURL && (
                <div className="mb-3 row">
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                    <div
                      className={classNames(
                        "background-image bg-32-9 bg-gray-20 w-100 px-2 border mb-1 overflow-hidden rounded",
                        {
                          "border-danger": !!uploadingLogoError,
                        }
                      )}
                      style={{ backgroundImage: `url(${exhibitor.LogoURL})` }}
                    />
                  </div>
                </div>
              )}
              <small className="form-text text-muted mb-2">
                {t("exposant:input.disclaimer.Logo")}
              </small>
              <div className="d-flex mb-2">
                <div className="position-relative overflow-hidden mr-2 flex-grow-1 flex-md-grow-0 d-flex">
                  <input
                    id="Logo"
                    ref={inputLogoRef}
                    type="file"
                    name="Logo"
                    className={"position-absolute-0 opacity-0"}
                    accept="image/*"
                    onChange={(e) => {
                      const File = get(e, "target.files[0]");

                      if (!File) {
                        return;
                      }

                      getBase64(File, (LogoURL) => setExhibitor({ LogoURL }));

                      return setLogo(File);
                    }}
                  />
                  <button className="btn btn-primary flex-grow-1">
                    {t("exposant:input.button.Logo.change")}
                  </button>
                </div>
                {!!exhibitor.LogoURL && (
                  <div className="position-relative overflow-hidden flex-grow-1 flex-md-grow-0 d-flex">
                    <button
                      className="btn btn-outline-secondary flex-grow-1"
                      onClick={(e) => {
                        e.preventDefault();

                        inputLogoRef.current.value = null;

                        return setExhibitor({ LogoURL: "", LogoID: 0 });
                      }}
                    >
                      {t("exposant:input.button.Logo.remove")}
                    </button>
                  </div>
                )}
              </div>
              <small className="form-text text-muted">
                {t("exposant:input.helper.Logo")}
              </small>
              {!!uploadingLogoError && (
                <span className="text-danger font-weight-bold">
                  {get(uploadingLogoError, "message", "")}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="Title" className="text-primary">
                {t("exposant:companyname")}
              </label>
              <input
                id="Title"
                type="text"
                name="Title"
                className="form-control"
                value={exhibitor.Title}
                onChange={onChangeValue}
              />
            </div>
            {isPremium && (
              <Fragment>
                <div className="form-group">
                  <label htmlFor="Slogan" className="text-primary">
                    {t("exposant:slogan")}
                  </label>
                  <input
                    id="Slogan"
                    type="text"
                    name="Slogan"
                    className="form-control"
                    value={exhibitor.Slogan}
                    onChange={onChangeValue}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="VideoURL" className="text-primary">
                    {t("exposant:input.label.VideoURL")}
                  </label>
                  <input
                    id="VideoURL"
                    type="text"
                    name="VideoURL"
                    className="form-control"
                    value={exhibitor.VideoURL}
                    onChange={onChangeValue}
                  />
                  <small className="form-text text-muted">
                    {t("exposant:input.helper.VideoURL")}
                  </small>
                </div>

                <div className="form-group">
                  <label htmlFor="Profile" className="text-primary">
                    {t("exposant:companydescription")}
                  </label>
                  <Editor
                    id="Profile"
                    value={exhibitor.Profile}
                    onEditorChange={(value) => setExhibitor({ Profile: value })}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="Header" className="text-primary">
                    {t("exposant:input.label.Header")}
                  </label>
                  {!!exhibitor.HeaderURL && (
                    <div className="mb-3 row">
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                        <div
                          className={classNames(
                            "background-image bg-32-9 bg-gray-20 w-100 px-2 border mb-1 overflow-hidden rounded",
                            {
                              "border-danger": !!uploadingHeaderError,
                            }
                          )}
                          style={{
                            backgroundImage: `url(${exhibitor.HeaderURL})`,
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="d-flex mb-2">
                    <div className="position-relative overflow-hidden mr-2 flex-grow-1 flex-md-grow-0 d-flex">
                      <input
                        id="Header"
                        ref={inputHeaderRef}
                        type="file"
                        name="Header"
                        className={"position-absolute-0 opacity-0"}
                        accept="image/*"
                        onChange={(e) => {
                          const File = get(e, "target.files[0]");

                          if (!File) {
                            return;
                          }

                          getBase64(File, (HeaderURL) =>
                            setExhibitor({ HeaderURL })
                          );

                          return setHeader(File);
                        }}
                      />
                      <button className="btn btn-primary flex-grow-1">
                        {t("exposant:input.button.Header.change")}
                      </button>
                    </div>
                    {!!exhibitor.HeaderURL && (
                      <div className="position-relative overflow-hidden flex-grow-1 flex-md-grow-0 d-flex">
                        <button
                          className="btn btn-outline-secondary flex-grow-1"
                          onClick={(e) => {
                            e.preventDefault();

                            inputHeaderRef.current.value = null;

                            return setExhibitor({ HeaderURL: "", HeaderID: 0 });
                          }}
                        >
                          {t("exposant:input.button.Header.remove")}
                        </button>
                      </div>
                    )}
                  </div>
                  <small className="form-text text-muted">
                    {t("exposant:input.helper.Header")}
                  </small>
                  {!!uploadingHeaderError && (
                    <span className="text-danger font-weight-bold">
                      {get(uploadingHeaderError, "message", "")}
                    </span>
                  )}
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="flex-1">
                    <h2>{t("exposant:premium.active.title")}</h2>
                    <p>
                      {t("exposant:premium.active.description", {
                        date: ExpiresDate,
                      })}
                    </p>
                  </div>
                  <div className="block-icon-wrapper mb-2 flex-center">
                    <Premium className="icon-primary h-4" />
                  </div>
                </div>
              </Fragment>
            )}
            {/*
             * Client requested that non premium profiles do not have the option to enter Intro & Slogan
             *
             * Task #143: Premium Exhibitor profile fix
             * https://app.activecollab.com/119944/projects/1216/tasks/88122?back_path=my_work
             */}
            {/*!isPremium && (
              <Fragment>
                <div className="form-group">
                  <label htmlFor="Profile" className="text-primary">
                    {t("exposant:companydescription")}
                  </label>
                  <textarea
                    id="Intro"
                    type="text"
                    name="Intro"
                    rows="10"
                    className="form-control"
                    value={exhibitor.Intro ? exhibitor.Intro : ""}
                    onChange={onChangeIntro}
                  />
                  <small className="form-text text-muted text-right">
                    {`${(get(exhibitor, "Intro") || "").length}/${introLimit}`}
                  </small>
                </div>
              </Fragment>
            )*/}

            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-secondary"
                onClick={onSubmit}
              >
                {t("common:save")}
              </button>
            </div>
          </form>
        </div>
        {/**
         * Premium profile link is no longer a service
         *
         * Task #145: Link in CMS for Exhibitor Profile information
         * https://app.activecollab.com/119944/projects/1216/tasks/88262
         */}
        {!!(!isPremium && !!ExclusiveProfileLink) && (
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-center my-4">
                <Premium className="icon-primary h-5" />
              </div>
              <h2>{t("exposant:premium.inactive.title")}</h2>
              <p>{t("exposant:premium.inactive.description")}</p>
              <LinkArrowExternal to={ExclusiveProfileLink}>
                {t("exposant:premium.inactive.link")}
              </LinkArrowExternal>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
);

export default ExposantProfileEditOrganization;

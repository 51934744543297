import React, { useMemo, Fragment } from "react";

/* packages */
import parseHTML from "html-react-parser";
import querystring from "query-string";

import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { Switch, Link, Route, Redirect, useRouteMatch } from "react-router-dom";

/* context */
import {
  useAttendeeExhibitor,
  useAttendeeCompany,
  useRole,
} from "../../contexts/ExhibitorContext";

/* components */
import DefaultLayout from "../../layout/DefaultLayout";

import CardLink from "../../components/CardLink";
import LinkLikeArrow from "../../components/LinkLikeArrow";
import Breadcrumbs from "../../components/Breadcrumbs";
import CurrentStage from "../../components/CurrentStage";
import RoleRoute from "../../components/RoleRoute";
import LinkLikeArrowExternal from "../../components/LinkLikeArrowExternal";

import ExposantProfileEditAddress from "./ExposantProfileEditAddress";
import ExposantProfileEditContact from "./ExposantProfileEditContact";
import ExposantProfileEditOrganization from "./ExposantProfileEditOrganization";

/* graphql */
import {
  QUERY_ATTENDEE_EXHIBITOR_INFO,
  QUERY_ATTENDEE_COMPANY,
} from "../../graphql/User";

const companyDefaultProps = {
  ID: null,
  Street: "",
  PostalCode: "",
  Country: "",
  City: "",
  HouseNumber: "",
  Addition: "",
  Email: "",
  Phone: "",
  Website: "",
};

const exhibitorDefaultProps = {
  ID: null,
  Title: "",
  Profile: "",
  CurrentStage: "DRAFT",
};

const ExhibitorCard = React.memo(() => {
  const { t } = useTranslation(["exposant"]);

  const { url } = useRouteMatch();

  const Role = useRole();

  const AttendeeCompany = useAttendeeCompany();
  const AttendeeExhibitor = useAttendeeExhibitor();

  const isManager = useMemo(() => !!Role && Role.Code === "MANAGER", [Role]);

  const { data } = useQuery(QUERY_ATTENDEE_EXHIBITOR_INFO, {
    variables: {
      ID: get(AttendeeExhibitor, "ID"),
    },
  });

  const Exhibitor = useMemo(
    () => get(data, "readOneProvadaExhibitor") || exhibitorDefaultProps,
    [data]
  );

  const isPremium = useMemo(() => !!get(AttendeeCompany, "Premium", false), [
    AttendeeCompany,
  ]);

  const parsedProfile = useMemo(() => parseHTML(Exhibitor.Profile || ""), [
    Exhibitor.Profile,
  ]);

  return (
    <CardLink to={`${url}/organization`}>
      <CurrentStage top stage={Exhibitor.CurrentStage} />
      <h1>{Exhibitor.Title}</h1>
      {isPremium && <div>{parsedProfile}</div>}
      {isManager && (
        <LinkLikeArrow to={`${url}/organization`}>
          {t("exposant:update")}
        </LinkLikeArrow>
      )}
    </CardLink>
  );
});

const ExposantProfile = React.memo(
  ({ location: { search }, history: { push }, match: { url } }) => {
    const { t } = useTranslation(["common", "exposant"]);

    const isSaved = useMemo(() => !!get(querystring.parse(search), "saved"), [
      search,
    ]);

    const Role = useRole();
    const AttendeeCompany = useAttendeeCompany();
    const Exhibitor = useAttendeeExhibitor();

    const isManager = useMemo(() => !!Role && Role.Code === "MANAGER", [Role]);

    const { data: CompanyData } = useQuery(QUERY_ATTENDEE_COMPANY, {
      variables: {
        ID: get(AttendeeCompany, "ID"),
      },
    });

    const Company = useMemo(
      () => get(CompanyData, "readOneProvadaCompany") || companyDefaultProps,
      [CompanyData]
    );

    const streetString = useMemo(
      () => [Company.Street, Company.HouseNumber].filter(Boolean).join(" "),
      [Company.Street, Company.HouseNumber]
    );

    const addressString = useMemo(
      () =>
        [Company.PostalCode, Company.Addition, Company.City, Company.Country]
          .filter(Boolean)
          .join(" "),
      [Company]
    );

    return (
      <Fragment>
        <Breadcrumbs>
          <Breadcrumbs.List>
            <Breadcrumbs.ListItem>{t("common:exhibitor")}</Breadcrumbs.ListItem>
          </Breadcrumbs.List>
          <Breadcrumbs.Addition.Exposant />
        </Breadcrumbs>

        <div className="card">
          <ExhibitorCard />

          <CardLink to={`${url}/address`}>
            <h2 className="text-dark">{t("common:address")}</h2>
            {!!streetString && <p>{streetString}</p>}
            {!!addressString && <p>{addressString}</p>}
            {isManager && (
              <LinkLikeArrow to={`${url}/address`}>
                {t("exposant:update")}
              </LinkLikeArrow>
            )}
          </CardLink>

          <CardLink to={`${url}/contact`}>
            <h2 className="text-dark">{t("common:contact")}</h2>
            {!!Company.Website && <p>{Company.Website}</p>}
            {!!Company.Email && <p>{Company.Email}</p>}
            {!!Company.Phone && <p>{Company.Phone}</p>}
            {isManager && (
              <LinkLikeArrow to={`${url}/contact`}>
                {t("exposant:update")}
              </LinkLikeArrow>
            )}
          </CardLink>

          {!!(Exhibitor && !Exhibitor.Ghost && Exhibitor.PublicLink) && (
            <a
              href={Exhibitor.PublicLink}
              target="_blank"
              className="card-body text-decoration-none"
            >
              <h2 className="text-dark">{t("common:profile")}</h2>
              <LinkLikeArrowExternal>
                {t("myprofile:profileProvada")}
              </LinkLikeArrowExternal>
            </a>
          )}
        </div>
        {isSaved && (
          <div className="opened custom-modal-wrapper position-fixed top-0 right-0 bottom-0 left-0 tr-opacity">
            <Link className="overlay overlay-primary" to={url} replace />
            <div className="position-fixed left-50 top-50 bg-white rounded-lg custom-modal negative-translate-50 border-success flex-center">
              <div>
                <h1 className="text-success">{t("common:save")}</h1>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
);

const ManagerRoutes = React.memo(({ match: { url } }) => {
  return (
    <Switch>
      <Route
        path={`${url}/organization`}
        component={ExposantProfileEditOrganization}
      />
      <Route path={`${url}/contact`} component={ExposantProfileEditContact} />
      <Route path={`${url}/address`} component={ExposantProfileEditAddress} />
      <Redirect to={url} />
    </Switch>
  );
});

const ExposantProfileRoutes = React.memo(({ match: { path, url } }) => {
  return (
    <DefaultLayout>
      <Switch>
        <Route exact path={path} component={ExposantProfile} />
        <RoleRoute
          path={path}
          component={ManagerRoutes}
          roles={["MANAGER"]}
          redirect={url}
        />
        <Redirect to={path} />
      </Switch>
    </DefaultLayout>
  );
});

export default ExposantProfileRoutes;

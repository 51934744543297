import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

/* packages */
import qs from "query-string";

import dayjs from "dayjs";
import classNames from "classnames";

import get from "lodash/get";
import sum from "lodash/sum";

import { Link, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";

/** contexts */
import { useEvent, useEventDays } from "../../contexts/EventContext";
import { useUser } from "../../contexts/UserContext";
import { useAttendee } from "../../contexts/ExhibitorContext";

/* components */
import RoleBlock from "../RoleBlock";
import Percentage from "../Percentage";
import LinkLikeArrow from "../LinkLikeArrow";
import PurchaseTicketCard from "../PurchaseTicketCard";

/* graphql */
import {
  MUTATION_UPDATE_ATTENDEE_PARKING,
  QUERY_ATTENDEE_PARKING,
} from "../../graphql/User";

/* const */
import { DAYS_ENUM } from "../../utils/globals";
import {
  useQueryUserAgenda,
  useUserAgendaPendingInvitations,
} from "../../lib/hooks/useUserAgenda";

export const ParkingTicketSection = () => {
  const { t } = useTranslation(["dashboard"]);

  const { ID: AttendeeID } = useAttendee();

  const { loading, data } = useQuery(QUERY_ATTENDEE_PARKING, {
    variables: {
      ID: AttendeeID,
    },
  });

  const [onParkingTicketResponse, { loading: updatingParking }] = useMutation(
    MUTATION_UPDATE_ATTENDEE_PARKING,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_ATTENDEE_PARKING,
          variables: {
            ID: AttendeeID,
          },
        },
      ],
    }
  );

  const Attendee = useMemo(() => get(data, "readOneProvadaAttendee"), [data]);

  if (loading) {
    return null;
  }

  if (!Attendee.ParkingTicket) {
    return null;
  }

  /**
   * ParkingResponse:
   * -1 - Did not reply
   * 0  - No
   * 1  - Yes
   */
  if (Attendee.ParkingResponse === 0) {
    return null;
  }

  if (Attendee.ParkingResponse === 1) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 className="text-dark">{t(`dashboard:parking.status.title.1`)}</h2>
          <p className="text-dark mb-0">
            {t("dashboard:parking:status.content.1")}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h2 className="text-dark">{t("dashboard:parking.status.title.-1")}</h2>
        <p className="text-dark mb-3">
          {t("dashboard:parking.status.content.-1")}
        </p>
        <div className="mb-0">
          <button
            className="btn btn-outline-secondary  mr-2"
            disabled={updatingParking}
            onClick={() =>
              onParkingTicketResponse({
                variables: {
                  Input: {
                    ID: Attendee.ID,
                    ParkingResponse: 0,
                  },
                },
              })
            }
          >
            {t("common:no")}
          </button>
          <button
            className="btn btn-primary"
            disabled={updatingParking}
            onClick={() =>
              onParkingTicketResponse({
                variables: {
                  Input: {
                    ID: Attendee.ID,
                    ParkingResponse: 1,
                  },
                },
              })
            }
          >
            {t("common:yes")}
          </button>
        </div>
      </div>
    </div>
  );
};

const UserBlock = React.memo(() => {
  const { t } = useTranslation(["common", "gatherings"]);
  const { push } = useHistory();

  const User = useUser();
  const Attendee = useAttendee();

  const Event = useEvent();
  const days = useEventDays();

  const { data: AttendancesData } = useQueryUserAgenda();

  const count = useMemo(
    () => [
      get(AttendancesData, "readProvadaMeetings.edges.length", 0),
      get(AttendancesData, "readProvadaMyAgenda.edges.length", 0),
      get(AttendancesData, "readProvadaGatheringSpeakers.edges.length", 0),
      get(AttendancesData, "readProvadaGatheringAttendances.edges.length", 0),
    ],
    [AttendancesData]
  );

  // prettier-ignore
  const invitationsPendingAction = useUserAgendaPendingInvitations(AttendancesData);

  const invitationsPendingActionCount = invitationsPendingAction.length;

  const agendaQuery = useMemo(() => {
    let nextQuery = {
      Status: [-1, 1],
    };

    const firstInvitation = invitationsPendingAction[0];

    if (firstInvitation) {
      nextQuery = {
        ...nextQuery,
        ID: `${firstInvitation.__typename}-${firstInvitation.ID}`,
      };
    }

    if (firstInvitation && firstInvitation.DateTime) {
      nextQuery = {
        ...nextQuery,
        Date: dayjs(firstInvitation.DateTime).format("YYYY-MM-DD"),
      };
    }

    if (
      firstInvitation &&
      firstInvitation.InvitedFor &&
      firstInvitation.InvitedFor.Date &&
      firstInvitation.InvitedFor.Start
    ) {
      const {
        InvitedFor: { Date: GatheringDate, Start },
      } = firstInvitation;

      nextQuery = {
        ...nextQuery,
        Date: dayjs(`${GatheringDate} ${Start}`).format("YYYY-MM-DD"),
      };
    }

    return qs.stringify(nextQuery, { arrayFormat: "index", skipNull: true });
  }, [invitationsPendingAction]);

  const Total = useMemo(() => sum(count), [count]);

  // if requests pending = ‘x open request(s)’
  // else if gatherings = ‘x gatherings’
  // else = ‘no gatherings’

  const agendaLabel =
    Total > 0
      ? invitationsPendingActionCount > 0
        ? t("dashboard:agenda:edithint:meetings", {
            count: invitationsPendingActionCount,
          })
        : t("dashboard:agenda:edithint:gatherings_interval", {
            postProcess: "interval",
            count: Total,
          })
      : t("dashboard:agenda:edithint:empty");

  const isAttending = useMemo(() => !!(Attendee && Attendee.ID), [Attendee]);

  const AttendeeFunction = useMemo(() => {
    let nextState = [get(Attendee, "Role.Title")];

    if (Event && !!Event.ID) {
      nextState = [...nextState, Event.Title];
    }

    return nextState.filter(Boolean).join(" ");
  }, [Event, Attendee]);

  const goToCompletionList = (e) => {
    e.preventDefault();

    return push("/profile/completion");
  };

  return (
    <React.Fragment>
      {!!invitationsPendingActionCount && (
        <div className="card">
          <Link
            to={{
              pathname: "/agenda",
              search: agendaQuery,
            }}
            className="card-body text-decoration-none"
          >
            <h2 className="text-dark">
              {t("dashboard:user.agenda.pendingInvitations.title_interval", {
                count: invitationsPendingActionCount,
                postProcess: "interval",
              })}
            </h2>
            <LinkLikeArrow>
              {t("dashboard:user.agenda.pendingInvitations.manage")}
            </LinkLikeArrow>
          </Link>
        </div>
      )}

      <div className="card triangle triangle-top-right-primary">
        <Link to="/profile" className="card-body text-decoration-none">
          <div>
            <h1 className="text-dark">{User.FullName}</h1>
            {isAttending && (
              <h3 className="text-primary">{AttendeeFunction}</h3>
            )}
          </div>
          {isAttending && (
            <div className="d-flex flex-wrap">
              {days.map((day, index) => {
                const key = DAYS_ENUM[dayjs(day).weekday() - 1];

                return (
                  <span
                    key={`${key}-${index}`}
                    className={classNames("badge mb-2 mr-2", {
                      "badge-secondary": !!Attendee[key],
                      "badge-disabled": !Attendee[key],
                    })}
                  >
                    {dayjs(day).format("dddd")}
                  </span>
                );
              })}
            </div>
          )}
        </Link>

        {!isAttending && <PurchaseTicketCard />}

        <Link
          to="/profile"
          className="card-body d-flex justify-content-between align-items-start text-decoration-none"
        >
          <div className="flex-1">
            <h2 className="text-dark">{t("dashboard:user:title")}</h2>
            <p className="text-dark">{t("dashboard:user:edithint")}</p>
            <LinkLikeArrow>{t("dashboard:user:manage")}</LinkLikeArrow>
          </div>
          {isAttending && (
            <div onClick={goToCompletionList}>
              <Percentage
                value={parseInt(Attendee.ProfileCompleted, 10) || 0}
              />
            </div>
          )}
        </Link>

        {!!Event.ProgramLink && (
          <a
            href={Event.ProgramLink}
            target="_blank"
            rel="noopener noreferrer"
            className="card-body text-decoration-none"
          >
            <h2 className="text-dark">{t("dashboard:user.program.title")}</h2>
            <p className="text-dark">{t("dashboard:user.program.content")}</p>
            <LinkLikeArrow>{t("dashboard:user.program.manage")}</LinkLikeArrow>
          </a>
        )}

        <Link to="/agenda" className="card-body text-decoration-none">
          <h2 className="text-dark">{t("dashboard:agenda:title")}</h2>
          <p className="text-dark">{agendaLabel}</p>
          <LinkLikeArrow>{t("dashboard:agenda:manage")}</LinkLikeArrow>
        </Link>
      </div>
      <RoleBlock roles={["SPEAKER"]}>
        <ParkingTicketSection />
      </RoleBlock>
    </React.Fragment>
  );
});

UserBlock.defaultProps = {
  ID: null,
  ProfileCompleted: 0,
  Member: {
    FullName: "-",
  },
};

export default UserBlock;

import React from 'react';

import classNames from 'classnames';

import { get } from 'lodash';
import { useMutation } from '@apollo/react-hooks';

/* context */
import { useLocale, useUser } from '../contexts/UserContext';

import { MUTATION_UPDATE_LOCALE, READ_CURRENT_SILVERSTRIPE_MEMBER } from '../graphql/User';

const LocaleSwitch = React.memo(({ className }) => {
  const User = useUser();
  const [locale, onChangeLocale] = useLocale();

  const [onChangeUserLocale, { loading: updatingLocale }] = useMutation(MUTATION_UPDATE_LOCALE, {
    awaitRefetchQueries: true,

    refetchQueries: [{
      query: READ_CURRENT_SILVERSTRIPE_MEMBER
    }]
  });

  function onBeforeChangeLocale(Locale) {
    if (updatingLocale) {
      return;
    }

    onChangeLocale(Locale);
    
    return onChangeUserLocale({ variables: { Input: { ID: get(User, 'ID'), Locale } } });
  }

  return (
    <div className={`lang-select d-flex ${className}`}>
      <div className={classNames("item d-block mr-3", { "lang-current": locale === 'nl_NL' })} onClick={() => onBeforeChangeLocale("nl_NL")} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-2-5 d-block">
          <defs />
          <path fill="#ff4b55" d="M473.655 88.276H38.345C17.167 88.276 0 105.443 0 126.621v73.471h512v-73.471c0-21.178-17.167-38.345-38.345-38.345z" />
          <path fill="#41479b" d="M0 385.379c0 21.177 17.167 38.345 38.345 38.345h435.31c21.177 0 38.345-17.167 38.345-38.345v-73.471H0v73.471z" />
          <path fill="#f5f5f5" d="M0 200.09h512V311.9H0z" />
        </svg>
      </div>
      <div className={classNames("item d-block mr-3", { "lang-current": locale === 'en_US' })} onClick={() => onBeforeChangeLocale("en_US")} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-2-5 d-block">
          <defs />
          <path fill="#41479b" d="M473.655 88.276H38.345C17.167 88.276 0 105.443 0 126.621V385.38c0 21.177 17.167 38.345 38.345 38.345h435.31c21.177 0 38.345-17.167 38.345-38.345V126.621c0-21.178-17.167-38.345-38.345-38.345z" />
          <path fill="#f5f5f5" d="M511.469 120.282c-3.022-18.159-18.797-32.007-37.814-32.007h-9.977l-163.54 107.147V88.276h-88.276v107.147L48.322 88.276h-9.977c-19.017 0-34.792 13.847-37.814 32.007l139.778 91.58H0v88.276h140.309L.531 391.717c3.022 18.159 18.797 32.007 37.814 32.007h9.977l163.54-107.147v107.147h88.276V316.577l163.54 107.147h9.977c19.017 0 34.792-13.847 37.814-32.007l-139.778-91.58H512v-88.276H371.691l139.778-91.579z" />
          <g fill="#ff4b55">
            <path d="M282.483 88.276h-52.966v141.241H0v52.966h229.517v141.241h52.966V282.483H512v-52.966H282.483z" />
            <path d="M24.793 421.252l186.583-121.114h-32.428L9.224 410.31a38.393 38.393 0 0015.569 10.942zM346.388 300.138H313.96l180.716 117.305a38.515 38.515 0 0012.287-13.075l-160.575-104.23zM4.049 109.475l157.73 102.387h32.428L15.475 95.842a38.499 38.499 0 00-11.426 13.633zM332.566 211.862l170.035-110.375a38.4 38.4 0 00-15.699-10.86L300.138 211.862h32.428z" />
          </g>
        </svg>
      </div>
    </div>
  )
});

LocaleSwitch.defaultProps = {
  className: ''
}

export default LocaleSwitch;

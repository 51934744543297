import React, { Fragment, useState, useMemo } from "react";

/** libs */
import validator from "email-validator";
import get from "lodash/get";

import { useTranslation } from "react-i18next";

/** contexts */
import { useAuth } from "../../contexts/Auth";

/* layout */
import FluidLayout from "../../layout/FluidLayout";

/* components */
import LinkArrow from "../../components/LinkArrow";
import EventBlock from "../../components/EventBlock";
import LinkArrowExternal from "../../components/LinkArrowExternal";

const LoginPage = React.memo(({ history: { state, push } }) => {
  const { t } = useTranslation(["login"]);

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const isValid = useMemo(
    () => !!password.trim().length && validator.validate(email),
    [email, password]
  );

  const { login } = useAuth();

  /**
   * Try to login user and set it if all good. If not, clear user and show error.
   */
  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    //? Reset error message
    setErrorMsg("");

    return login({ variables: { Email: email, Password: password } })
      .then(() => push(get(state, "from.pathname") || "/"))
      .catch((error) => {
        setLoading(false);

        return setErrorMsg(error.message);
      });
  };

  const handleEmail = ({ target: { value } }) => setEmail(value);
  const handlePassword = ({ target: { value } }) => setPassword(value);

  return (
    <FluidLayout>
      <div className="bg-white py-4">
        <div className="container typography">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <h1>{t("login:title")}</h1>

              <form className="mb-4" onSubmit={onSubmit}>
                {!!errorMsg && <p>{errorMsg}</p>}
                <div className="form-group">
                  <label htmlFor="Email" className="text-primary">
                    {t("login:input.label.Email")}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="Email"
                    value={email}
                    placeholder="communicatie@cushwake.com"
                    onChange={handleEmail}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Password" className="text-primary">
                    {t("login:input.label.Password")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="Password"
                    value={password}
                    placeholder="********************"
                    onChange={handlePassword}
                  />
                </div>
                <input
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading || !isValid}
                  value={t("login:button.submit")}
                />
              </form>

              <div className="mb-4">
                <LinkArrow to="/forgotPassword">
                  {t("login:button.forgotPassword")}
                </LinkArrow>
              </div>

              {false && (
                <Fragment>
                  <div className="d-flex mb-4">
                    <p className="mb-0 mr-2 text-primary">of</p>
                    <div className="line-through" />
                  </div>

                  <div className="mb-4">
                    <button className="linkedin-login btn btn-linkedin btn-icon btn-icon-left position-relative w-100">
                      <div className="icon-holder">
                        <i className="text-white font-size-1-5 if-Linkedin" />
                      </div>
                      <span className="d-block w-100">
                        {t("login:button.linkedin")}
                      </span>
                    </button>
                  </div>
                </Fragment>
              )}

              <div className="mb-4">
                <LinkArrow to="/register">
                  {t("login:button.register")}
                </LinkArrow>
              </div>

              <div className="mb-5">
                <LinkArrowExternal to="https://reg.summit.nl/Default.aspx?alias=reg.summit.nl/provada">
                  {t("login:button.purchaseTicket")}
                </LinkArrowExternal>
              </div>
            </div>
          </div>

          <EventBlock />
        </div>
      </div>
    </FluidLayout>
  );
});

export default LoginPage;

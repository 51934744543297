import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';
const LinkArrow = (props) => {
  const { children, to, onClick } = props;

  var linkProps = {
    to: to
  };

  if (!!onClick && typeof onClick === "function") {
    linkProps.onClick = onClick;
  }

  return (
    <Link className="text-decoration-none d-inline-flex link-arrow align-items-center icon-secondary" {...linkProps}>
      <span className="text-primary mr-1">{children}</span>
      {props.showArrow &&
        <Icon className="text-secondary mx-1" iconType="ArrowDropRight" />
      }
    </Link>
  )
}

LinkArrow.defaultProps = {
  to: '',
  showArrow: true,
  onClick: false
}

export default LinkArrow;
import React, { Fragment, useMemo } from "react";

/* packages */
import qs from "query-string";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";

/* components */
import CardHeader from "../../components/CardHeader";

/* context */
import { useUser } from "../../contexts/UserContext";
import { useEvent } from "../../contexts/EventContext";
import {
  AttendeeRolesList,
  useAttendeeRoles,
} from "../../contexts/AttendeeRolesContext";
import {
  useAttendeeCompany,
  useAttendeeExhibitor,
} from "../../contexts/ExhibitorContext";

/* lib */
import { useSafeSetState } from "../../lib/hooks";

/* graphql */
import {
  MUTATION_REGISTER_MEMBER_ATTENDEE,
  QUERY_EXHIBITOR_ATTENDEES,
} from "../../graphql/Tickets";

const TicketInviteRoute = React.memo(
  ({
    history: {
      replace,
      push,
      goBack,
      location: { search },
    },
  }) => {
    const { t } = useTranslation();

    const { ID: UserID } = useUser();
    const { ID: EventID } = useEvent();

    const { Role } = useAttendeeRoles();

    const { ID: ExhibitorID } = useAttendeeExhibitor();
    const { Title: CompanyTitle } = useAttendeeCompany();

    const [state, setState] = useSafeSetState(() => {
      let initialState = { ID: null, Title: "", Email: "", Role: { ID: null } };

      const { Title } = qs.parse(search);

      if (typeof Title === "string" && !!Title.length) {
        initialState = { ...initialState, Title };
      }

      return initialState;
    });

    //! Add queries to be re-fetched
    const [onInviteAttendee, { loading: saving }] = useMutation(
      MUTATION_REGISTER_MEMBER_ATTENDEE,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_EXHIBITOR_ATTENDEES,
            variables: {
              Filter: {
                EventID__eq: EventID,
                ExhibitorID__eq: ExhibitorID,
              },
              Exclude: {
                MemberID__eq: UserID, //! Important - Exclude currently logged in member
              },
            },
          },
        ],
      }
    );

    const isFormValid = useMemo(() => {
      const { Title, Email } = state;

      if (!Title.trim().length) {
        return false;
      }

      if (!Email.trim().length) {
        return false;
      }

      if (!(!!Role && !!Role.ID)) {
        return false;
      }

      return true;
    }, [state]);

    const onChangeValue = ({ target: { value, name } }) =>
      setState({ [name]: value });

    function onSubmit(e) {
      e.preventDefault();

      if (!(!!isFormValid && !saving)) {
        return;
      }

      const variables = {
        RoleID: Role.ID,
        Email: state.Email,
        Title: state.Title,
      };

      return onInviteAttendee({ variables })
        .then(({ registerProvadaMemberAttendee: User }) => {
          if (!User) {
            //   console.log("Failed to create member");
          }

          return push("/tickets");
        })
        .catch((error) => {
          // console.log("Error - registerProvadaMemberAttendee: ", error.message);

          return goBack();
        });
    }

    return (
      <Fragment>
        <div className="card">
          <CardHeader
            title={t("attendeeInvite:title")}
            onDismiss={() => push(`/tickets/search`)}
            onSubmit={onSubmit}
          />

          <form className="card-body" onSubmit={onSubmit}>
            <h2 className="mb-4">{t("attendeeInvite:subtitle")}</h2>

            <div className="form-group">
              <label htmlFor="Title" className="text-primary">
                {t("attendeeInvite:input.label.Title")}
              </label>
              <input
                id="Title"
                name="Title"
                type="text"
                value={state.Title}
                className="form-control"
                onChange={onChangeValue}
              />
            </div>

            <div className="form-group">
              <label htmlFor="Email" className="text-primary">
                {t("attendeeInvite:input.label.Email")}
              </label>
              <input
                id="Email"
                name="Email"
                type="email"
                value={state.Email}
                className="form-control"
                placeholder="voorbeeld@email.com"
                onChange={onChangeValue}
              />
            </div>

            <AttendeeRolesList />

            <h2 className="font-weight-normal mb-4">
              {t("attendeeInvite:disclaimer", { CompanyTitle })}
            </h2>

            <div>
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!isFormValid}
                onClick={onSubmit}
              >
                {saving && (
                  <span
                    className="spinner-border spinner-border-sm mr-2 mb-1"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("attendeeInvite:button.submit")}
              </button>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
);

export default TicketInviteRoute;

import React, { useMemo } from "react";

/* packages */
import parseHTML from "html-react-parser";

import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";

/* components */
import DefaultLayout from "../../../layout/DefaultLayout";
import Breadcrumbs from "../../../components/Breadcrumbs";

/* context */
import { useEvent } from "../../../contexts/EventContext";

/* graphql */
import { QUERY_EVENT_PREFERRED_SUPPLIERS } from "../../../graphql/Event";

const PreferredSuppliersPage = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const { ID } = useEvent();

  const { data } = useQuery(QUERY_EVENT_PREFERRED_SUPPLIERS, {
    variables: { ID },
  });

  const event = useMemo(() => get(data, "readOneProvadaEvent", null), [data]);

  const Title = useMemo(() => parseHTML(get(event, "SupplierTitle") || ""), [
    event,
  ]);

  const Description = useMemo(
    () => parseHTML(get(event, "SupplierDescription") || ""),
    [event]
  );

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/services">
            {t("standservices:stand")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>
            {t("dashboard:preferredSuppliers.title")}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>

      <div className="card">
        <div className="card-body">
          <h1 className="mb-4">{Title}</h1>
          <div>{Description}</div>
        </div>
      </div>
    </DefaultLayout>
  );
});

export default PreferredSuppliersPage;

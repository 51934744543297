import React, { useMemo } from 'react';

import { get } from 'lodash';
import { Link } from 'react-router-dom';

import { useRole } from '../contexts/ExhibitorContext';

const CardLink = React.memo(({ to, className, roles, ...props }) => {
  const Role = useRole();

  const isLink = useMemo(() => roles.includes(get(Role, 'Code')), [Role, roles]);

  if (isLink) {
    return (
      <Link
        to={to}
        className={`card-body text-decoration-none ${className}`}
        {...props}
      />
    );
  }

  return (
    <div
      className={`card-body ${className}`}
      {...props}
    />
  );

});

CardLink.defaultProps = {
  to: '',
  className: '',

  roles: ["MANAGER"],
};

export default CardLink;

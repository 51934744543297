import React, { Fragment, useEffect } from "react";

import pick from "lodash/pick";
import useSetState from "react-use/lib/useSetState";

import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/react-hooks";

import CardHeader from "../../../components/CardHeader";
import { useSnackbarCustom } from "../../../components/Notification";

import { useGatheringIsRoomReservationSet } from "../../../contexts/GatheringContext";

import {
  UPDATE_GATHERING,
  GET_GATHERING_INFO,
  QUERY_GATHERING_ENTERTAINMENT,
} from "../../../graphql/Gathering";
import { useParams } from "react-router-dom";

const GatheringTimeAndLocation = React.memo(
  ({ history: { push, replace } }) => {
    const { t } = useTranslation(["common", "gatherings"]);

    const { ID: GatheringID } = useParams();

    const isRoomReservationSet = useGatheringIsRoomReservationSet();

    const snackbar = useSnackbarCustom();

    useQuery(QUERY_GATHERING_ENTERTAINMENT, {
      variables: {
        ID: GatheringID,
      },
      onCompleted: ({ readOneProvadaGathering: gathering }) => {
        if (!gathering) {
          return;
        }
        console.log(gathering);
        setState({
          ID: gathering.ID,
          Entertainment: gathering.Entertainment,
          EntertainmentDesc: gathering.EntertainmentDesc,
        });
      },
      onError: () => {
        replace("/gathering");
      },
    });

    const [state, setState] = useSetState({
      ID: 0,
      Entertainment: false,
      EntertainmentDesc: "",
    });

    //! Redirect user back if theres a room reservation
    //! You have nothing to do here anymore
    useEffect(() => {
      if (isRoomReservationSet) {
        replace(`/gathering/${GatheringID}`);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRoomReservationSet]);

    const [saveTime, { loading: isUpdating }] = useMutation(UPDATE_GATHERING, {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_GATHERING_INFO,
          variables: {
            ID: GatheringID,
          },
        },
      ],
    });

    const onSubmit = () => {
      if (isUpdating) {
        return;
      }

      snackbar.enqueueSnackbar("");

      let Input = pick(state, ["ID", "Entertainment"]);

      if (state.Entertainment) {
        Input = {
          ...Input,
          EntertainmentDesc: state.EntertainmentDesc || "",
        };
      }

      return saveTime({ variables: { Input } })
        .then(() => {
          push(`/gathering/${GatheringID}`);
        })
        .catch(snackbar.enqueueSnackbarError);
    };

    return (
      <Fragment>
        <div className="card">
          <CardHeader
            title={t("gatherings:entertainment.title")}
            onDismiss={() => push(`/gathering/${GatheringID}`)}
            onSubmit={onSubmit}
          />
          <div className="card-body">
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                id="Entertainment"
                name="Entertainment"
                onChange={() =>
                  setState(({ Entertainment }) => ({
                    Entertainment: !Entertainment,
                  }))
                }
                className="custom-control-input custom-control-leaf"
                checked={state.Entertainment}
              />
              <label className="custom-control-label" htmlFor="Entertainment">
                {t("gathering:input.label.Entertainment")}
              </label>
            </div>
            {state.Entertainment && (
              <div className="form-group">
                <label htmlFor="Profile" className="text-primary">
                  {t("gathering:input.label.EntertainmentDesc")}
                </label>
                <textarea
                  id="EntertainmentDesc"
                  name="EntertainmentDesc"
                  rows="4"
                  className="form-control"
                  value={state.EntertainmentDesc || ""}
                  onChange={({ currentTarget: { value } }) =>
                    setState({ EntertainmentDesc: value })
                  }
                />
              </div>
            )}
            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={isUpdating}
                onClick={onSubmit}
              >
                {isUpdating && (
                  <span
                    className="spinner-border spinner-border-sm mr-2 mb-1"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("common:save")}
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
);

export default GatheringTimeAndLocation;

import { useMemo } from "react";

import get from "lodash/get";
import map from "lodash/map";
import reduce from "lodash/reduce";

import { useQuery } from "@apollo/react-hooks";

import {
  QUERY_ATTENDEE_AGENDA,
  QUERY_MEMBER_AGENDA,
} from "../../graphql/Meetings";

import { useUser } from "../../contexts/UserContext";
import { useEvent } from "../../contexts/EventContext";
import { useAttendee } from "../../contexts/ExhibitorContext";

function isMeetingPendingAction(meeting, member, attendee) {
  if (meeting.Status !== -1) {
    return false;
  }

  const isEdited = !!parseInt(get(meeting.LastUpdatedBy, "ID"), 10);

  const isOwner = get(attendee, "ID") === get(meeting.RequestedBy, "ID");

  const isLastEditedByUser =
    get(attendee, "ID") === get(meeting.LastUpdatedBy, "ID");

  let isRescheduledSuggested = false;

  try {
    const SuggestedTimes = JSON.parse(meeting.SuggestedTimes);

    if (Array.isArray(SuggestedTimes) && SuggestedTimes.length > 0) {
      isRescheduledSuggested = true;
    }
  } catch (e) {}

  /**
   * When there's a suggested time by user,
   * we require action from user
   */
  if (!isLastEditedByUser && isRescheduledSuggested) {
    return true;
  }

  return !isOwner && !(isRescheduledSuggested || isEdited);
}

function isInvitationPendingAction(invitation, member, attendee) {
  if (invitation.Status !== -1) {
    return false;
  }

  const isAttendee = !!(attendee && attendee.ID && !!parseInt(attendee.ID));

  const isOwner = isAttendee
    ? get(attendee, "ID") === get(invitation, "InvitedBy.ID")
    : get(member, "Email") === get(invitation, "InvitedBy.Member.Email");

  return !isOwner;
}

function isPendingAction(item, member, attendee) {
  switch (item.__typename) {
    case "ProvadaMeeting": {
      return isMeetingPendingAction(item, member, attendee);
    }

    case "ProvadaInvitedVisitor":
    case "ProvadaInvitedSpeaker": {
      return isInvitationPendingAction(item, member, attendee);
    }

    default: {
      return false;
    }
  }
}

function getPendingInvitations(data = [], member, attendee) {
  return reduce(
    data,
    (acc, item) => {
      const isPending = isPendingAction(item, member, attendee);

      if (!isPending) {
        return acc;
      }

      return [...acc, item];
    },
    []
  );
}

export const useQueryUserAgenda = () => {
  const Event = useEvent();
  const Attendee = useAttendee();

  const isAttendee = !!(Attendee && Attendee.ID);

  const variables = useMemo(() => {
    let nextVariables = {
      AgendaFilter: {
        Status: [-1],
        EventID: get(Event, "ID"),
      },
    };

    if (isAttendee) {
      nextVariables = {
        ...nextVariables,
        GatheringAttendanceFilter: {
          EventID__eq: get(Event, "ID"),
          AttendeeID__eq: get(Attendee, "ID"),
          Status__gt: 0,
        },
        MeetingFilter: {
          /**
           * !Important.
           *
           * If changing filter make sue you include Status: -1
           * since it's needed for displaying `pendingMeetings`
           */
          Status__eq: -1,
        },
        GatheringSpeakerFilter: {
          EventID__eq: get(Event, "ID"),
          AttendeeID__eq: get(Attendee, "ID"),
        },
      };
    }

    return nextVariables;
  }, [Attendee, Event, isAttendee]);

  return useQuery(isAttendee ? QUERY_ATTENDEE_AGENDA : QUERY_MEMBER_AGENDA, {
    fetchPolicy: "network-only",
    variables,
  });
};

/**
 * List of all meetings that are pending user action.
 * This includes rescheduled meetings and new requests
 * from other users
 */

export const useUserAgendaPendingInvitations = (data = []) => {
  const member = useUser();
  const attendee = useAttendee();

  return useMemo(() => {
    const meetings = map(get(data, "readProvadaMeetings.edges"), "node");

    const requests = map(get(data, "readProvadaMyAgenda.edges"), "node");

    return getPendingInvitations([...meetings, ...requests], member, attendee);
  }, [attendee, member, data]);
};

import React, { Fragment, useEffect, useMemo } from "react";

/* packages */
import { get, some } from "lodash";

import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/react-hooks";

/* components */
import CardHeader from "../../components/CardHeader";

/* lib */
import { useSafeSetState } from "../../lib/hooks";

/* context */
import { useUser } from "../../contexts/UserContext";
import { useEvent } from "../../contexts/EventContext";
import { useAttendeeExhibitor } from "../../contexts/ExhibitorContext";

import {
  AttendeeRolesList,
  useAttendeeRoles,
} from "../../contexts/AttendeeRolesContext";

/* graphql */
import {
  QUERY_ATTENDEE,
  MUTATION_UPDATE_ATTENDEE,
  QUERY_EXHIBITOR_ATTENDEES,
} from "../../graphql/Tickets";

const attendeeDefaultProps = {
  ID: null,
  Role: {
    ID: null,
    Title: "",
    Code: "",
  },
  Member: {
    ID: null,
    Email: "",
  },
};

const TicketRoute = React.memo(
  ({
    history: { goBack, replace, push },
    match: {
      params: { ID },
    },
  }) => {
    const { t } = useTranslation(["attendeeConfirm", "attendeeInvite"]);

    const { ID: UserID } = useUser();
    const { ID: EventID } = useEvent();
    const { ID: ExhibitorID } = useAttendeeExhibitor();

    const { Role, Roles, setRole } = useAttendeeRoles();

    const [state, setState] = useSafeSetState({ ...attendeeDefaultProps });

    const { loading: loadingAttendee } = useQuery(QUERY_ATTENDEE, {
      variables: { ID },
      fetchPolicy: "cache-and-network",
      onCompleted: ({ readOneProvadaAttendee: Attendee }) => {
        if (!Attendee) {
          return goBack();
        }

        const MemberID = get(Attendee, "Member.ID");

        //! Important - You cannot manage yourself at this screen
        if (MemberID === UserID) {
          return replace("/tickets");
        }

        return setState({ ...Attendee });
      },
      onError: () => goBack(),
    });

    useEffect(() => {
      function onUpdateRole() {
        const RoleID = get(state, "Role.ID");

        if (!(!!Roles.length && !!RoleID)) {
          return;
        }

        const included = some(Roles, ({ ID }) => ID === RoleID);

        // * Gut who's supposed to be an MANAGER or EMPLOYEE has different role ?
        if (!included) {
          return; //! How the fuck this happened
        }

        return setRole(state.Role);
      }

      onUpdateRole();
    }, [Roles, state]);

    const [onUpdateAttendee, { loading: updatingAttendee }] = useMutation(
      MUTATION_UPDATE_ATTENDEE,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_EXHIBITOR_ATTENDEES,
            variables: {
              Filter: {
                EventID__eq: EventID,
                ExhibitorID__eq: ExhibitorID,
              },
              Exclude: {
                MemberID__eq: UserID, //! Important - Exclude currently logged in member
              },
            },
          },
        ],
      }
    );

    const isDisabled = useMemo(
      () => updatingAttendee || loadingAttendee,
      [loadingAttendee, updatingAttendee]
    );
    const isFormValid = useMemo(
      () =>
        !isDisabled &&
        !!Role.ID &&
        !!state.ID &&
        !!get(state, "Member.Email", ""),
      [Role, state, isDisabled]
    );

    const onChangeEmail = ({ target: { value: Email } }) =>
      setState(({ Member: restOfMember }) => {
        return {
          Member: {
            ...restOfMember,
            Email,
          },
        };
      });

    function onSubmit(e) {
      e.preventDefault();

      if (!isFormValid) {
        return;
      }

      const {
        Member: { Email },
      } = state;

      return onUpdateAttendee({ variables: { ID, Email, RoleID: Role.ID } })
        .then(({ data: { updateProvadaMemberAttendee: Member } }) => {
          if (!Member) {
            //! @todo Error message
          }

          return goBack();
        })
        .catch(() => {
          return goBack();
        });
    }

    return (
      <Fragment>
        <div className="card">
          <CardHeader
            title={t("attendeeConfirm:title")}
            onDismiss={() => push(`/tickets`)}
            onSubmit={onSubmit}
          />

          <form className="card-body" onSubmit={onSubmit}>
            <h2 className="mb-4">{t("attendeeConfirm:subtitle")}</h2>

            <div className="form-group">
              <label htmlFor="Email" className="text-primary">
                {t("attendeeInvite:input.label.Email")}
              </label>
              <input
                id="Email"
                name="Email"
                type="email"
                value={state.Member.Email}
                className="form-control"
                placeholder="voorbeeld@email.com"
                onChange={onChangeEmail}
              />
            </div>

            <AttendeeRolesList />

            <div>
              <button
                className="btn btn-secondary"
                type="button"
                disabled={!isFormValid}
                onClick={onSubmit}
              >
                {updatingAttendee && (
                  <span
                    className="spinner-border spinner-border-sm mr-2 mb-1"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("attendeeInvite:button.submit")}
              </button>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
);

export default TicketRoute;

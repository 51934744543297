import React from "react";

/* packages */
import classNames from "classnames";

import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StatusBadge = React.memo(({ Status }) => {
  const { t } = useTranslation(["agendaGatheringStatus"]);

  return (
    <span
      className={classNames("badge position-absolute right-0 top-0", {
        "badge-secondary": Status === 0,
        "badge-primary": Status === -1,
        "badge-leaf": Status === 1,
        "badge-danger": Status > 1,
      })}
    >
      {t(`agendaGatheringStatus:${Status}`)}
    </span>
  );
});

const GatheringCard = (props) => {
  const {
    item: { ID, Title, Status, LocationTitle },
  } = props;

  const { t } = useTranslation(["common", "agenda", "gatheringType"]);

  const { url } = useRouteMatch();

  const GatheringTitle = Title || `#${ID}`;

  return (
    <div
      className={classNames(
        "card-body position-relative overflow-hidden text-decoration-none px-4 pt-4",
        {
          "pb-2": Status === 1,
          "pb-4": Status !== 1,
        }
      )}
    >
      <StatusBadge Status={Status} />
      <Link
        className="d-flex py-2 flex-wrap text-decoration-none"
        to={`${url}/gathering/${ID}`}
      >
        <div className="flex-1 d-flex align-items-center">
          <div className="flex-1">
            <p className="text-primary font-weight-bold mb-0">
              {t("agenda:gathering.title")}
            </p>
            <h3 className="text-dark mb-0">{GatheringTitle}</h3>
            {!!LocationTitle && (
              <p className="text-gray mt-1 mb-0">{LocationTitle}</p>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

const GatheringListItem = React.memo((props) => {
  return (
    <div className="card w-100">
      <GatheringCard {...props} />
    </div>
  );
});

export default GatheringListItem;

import React, { useContext, Fragment } from "react";

import pick from "lodash/pick";

import Select from "react-select";
import useSetState from "react-use/lib/useSetState";

import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";
import CardHeader from "../../../components/CardHeader";

/* contexts */
import UserContext from "../../../contexts/UserContext";

/* const */
import Countries from "../../../Data/Countries";

/* graphql */
import {
  READ_ONE_SILVERSTRIPE_MEMBER,
  UPDATE_PUBLIC_DATA_MYPROFILE,
} from "../../../graphql/User";

const userDefaultState = {
  ID: null,
  FirstName: "",
  Affix: "",
  Surname: "",
  Gender: "",
  Phone: "",
  Mobile: "",
  WorksAt: "",
  Function: "",
  Email: "",
  Street: "",
  HouseNumber: "",
  PostalCode: "",
  City: "",
  Country: "",
};

const userKeys = Object.keys(userDefaultState);

const PrivateProfileEdit = React.memo(({ history: { push, goBack } }) => {
  const { t } = useTranslation(["common", "myprofile"]);

  const {
    user: { ID },
  } = useContext(UserContext);

  const [user, setUser] = useSetState({ ...userDefaultState });

  useQuery(READ_ONE_SILVERSTRIPE_MEMBER, {
    variables: { ID },
    onCompleted: ({ readOneSilverStripeMember: data }) =>
      setUser({ ...userDefaultState, ...data }),
    onError: () => goBack(),
  });

  const [onSavePersonalData] = useMutation(UPDATE_PUBLIC_DATA_MYPROFILE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: READ_ONE_SILVERSTRIPE_MEMBER, variables: { ID } },
    ],
  });

  const onSubmit = (e) => {
    e.preventDefault();

    onSavePersonalData({
      variables: {
        Input: pick(user, userKeys),
      },
    });

    return push("/profile");
  };

  const onChangeValue = ({ target: { value, name } }) =>
    setUser({ [name]: value });

  const handleCountry = (event) => {
    if (!event) {
      return setUser({ ...user, Country: "" });
    }

    return setUser({ ...user, Country: event.value });
  };

  const cancelSave = () => {
    push("/profile");
  };

  return (
    <Fragment>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/profile">
            {t("profile:title")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>
            {t("myprofile:title_private")}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Company />
      </Breadcrumbs>

      <div className="card">
        <CardHeader
          title={t("myprofile:editpersonaldata")}
          onDismiss={cancelSave}
          onSubmit={onSubmit}
        />

        <form className="card-body" onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="FirstName" className="text-primary">
              {t("myprofile:firstname")}
            </label>
            <input
              id="FirstName"
              type="text"
              name="FirstName"
              className="form-control"
              onChange={onChangeValue}
              value={user.FirstName ? user.FirstName : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Affix" className="text-primary">
              {t("myprofile:affix")}
            </label>
            <input
              id="Affix"
              type="text"
              name="Affix"
              className="form-control"
              onChange={onChangeValue}
              value={user.Affix ? user.Affix : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Surname" className="text-primary">
              {t("myprofile:lastname")}
            </label>
            <input
              id="Surname"
              type="text"
              name="Surname"
              className="form-control"
              onChange={onChangeValue}
              value={user.Surname ? user.Surname : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Gender" className="text-primary">
              {t("myprofile:gender")}
            </label>
            <select
              className="form-control"
              value={user.Gender}
              onChange={onChangeValue}
              name="Gender"
            >
              <option value="M">{t("myprofile:male")}</option>
              <option value="F">{t("myprofile:female")}</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="Phone" className="text-primary">
              {t("myprofile:phone")}
            </label>
            <input
              id="Phone"
              type="text"
              name="Phone"
              className="form-control"
              onChange={onChangeValue}
              value={user.Phone ? user.Phone : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Mobile" className="text-primary">
              {t("myprofile:mobile")}
            </label>
            <input
              id="Mobile"
              type="text"
              name="Mobile"
              className="form-control"
              onChange={onChangeValue}
              value={user.Mobile ? user.Mobile : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="WorksAt" className="text-primary">
              {t("myprofile:worksAt")}
            </label>
            <input
              id="WorksAt"
              type="text"
              name="WorksAt"
              className="form-control"
              onChange={onChangeValue}
              value={user.WorksAt ? user.WorksAt : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Function" className="text-primary">
              {t("myprofile:function")}
            </label>
            <input
              id="Function"
              type="text"
              name="Function"
              className="form-control"
              onChange={onChangeValue}
              value={user.Function ? user.Function : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Email" className="text-primary">
              {t("myprofile:email")}
            </label>
            <input
              id="Email"
              type="email"
              name="Email"
              className="form-control"
              onChange={onChangeValue}
              value={user.Email ? user.Email : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Street" className="text-primary">
              {t("common:street")}
            </label>
            <input
              id="Street"
              type="text"
              name="Street"
              className="form-control"
              onChange={onChangeValue}
              value={user.Street ? user.Street : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="HouseNumber" className="text-primary">
              {t("common:housenumber")}
            </label>
            <input
              id="HouseNumber"
              type="text"
              name="HouseNumber"
              className="form-control"
              onChange={onChangeValue}
              value={user.HouseNumber ? user.HouseNumber : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="PostalCode" className="text-primary">
              {t("common:postalcode")}
            </label>
            <input
              id="PostalCode"
              type="text"
              name="PostalCode"
              className="form-control"
              onChange={onChangeValue}
              value={user.PostalCode ? user.PostalCode : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="City" className="text-primary">
              {t("common:city")}
            </label>
            <input
              id="City"
              type="text"
              name="City"
              className="form-control"
              onChange={onChangeValue}
              value={user.City ? user.City : ""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="" className="text-primary">
              {t("common:country")}
            </label>
            <Select
              options={Countries}
              name="Country"
              value={{ label: user.Country, value: user.Country }}
              onChange={handleCountry}
            />
          </div>
          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={onSubmit}
            >
              {t("common:save")}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
});

export default PrivateProfileEdit;

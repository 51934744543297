import React from "react";

const Catering = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        d="M14.997 56h3v4a5.969 5.969 0 001.54 4h-8.54a1 1 0 00-.895 1.447l2 4a1 1 0 00.895.553h54a1 1 0 00.9-.553l2-4a1 1 0 00-.9-1.447h-3.556a3.96 3.96 0 00.556-2v-4a3.99 3.99 0 00-3.579-3.957 3.965 3.965 0 00.58-2.043v-4a4 4 0 00-4-4h-6a3.974 3.974 0 00-3 1.382 3.974 3.974 0 00-3-1.382h-6a4 4 0 00-4 4v4a3.959 3.959 0 00.555 2h-5.556a4 4 0 00-4 4v4a3.959 3.959 0 00.556 2h-4.556a4 4 0 01-4-4V32h7v4.586l-3.707 3.707a1 1 0 00-.293.707v6a1 1 0 001 1h8a1 1 0 001-1v-6a1 1 0 00-.293-.707l-3.707-3.707V32h23v10h2V31a1 1 0 00-1-1h-34a1 1 0 00-1 1v5h-3a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5zm24-5h10v1a2 2 0 01-2 2h-6a2 2 0 01-2-2zm20 3h-6a2 2 0 01-2-2v-1h10v1a2 2 0 01-2 2zm-15 2h6a2 2 0 012 2v1h-10v-1a2 2 0 012-2zm-2 6v-1h10v1a2 2 0 01-2 2h-6a2 2 0 01-2-2zm12 0v-1h10v1a2 2 0 01-2 2h-6a2 2 0 01-2-2zm10-4v1h-10v-1a2 2 0 012-2h6a2 2 0 012 2zm-11-12h6a2 2 0 012 2v1h-10v-1a2 2 0 012-2zm-12 0h6a2 2 0 012 2v1h-10v-1a2 2 0 012-2zm-9 10h6a2 2 0 012 2v1h-10v-1a2 2 0 012-2zm-2 6v-1h10v1a2 2 0 01-2 2h-6a2 2 0 01-2-2zm-2 4H67.38l-1 2H13.615l-1-2zm3-20h-6v-4.586l3-3 3 3zm-19-5a3 3 0 013-3h3v16h-3a3 3 0 01-3-3z"
        data-name="Path 76"
      />
      <path
        d="M35.497 14h19.5a4 4 0 004-4h-2a2 2 0 01-2 2h-19.5a3.5 3.5 0 000 7h16a1.5 1.5 0 010 3h-20.5a6.006 6.006 0 00-6 6h2a4 4 0 014-4h5.54a5.969 5.969 0 00-1.54 4h2a4 4 0 014-4h10.5a3.5 3.5 0 000-7h-16a1.5 1.5 0 010-3z"
        data-name="Path 77"
      />
    </svg>
  );
};

export default Catering;

import React from "react";

const SoundPlan = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <defs />
      <path
        fillRule="evenodd"
        d="M11.258 48.66a.841.841 0 11-1.19 0 .841.841 0 011.19 0zm64.607-34.03v22.106a6.665 6.665 0 11-2.4-5.075v-8.479h-20v13.554a6.665 6.665 0 11-2.4-5.075V14.63a1.193 1.193 0 011.2-1.184h22.402a1.191 1.191 0 011.198 1.184zm-24.8 22.107a4.343 4.343 0 10-4.4 4.341 4.343 4.343 0 004.4-4.341zm22.4 0a4.343 4.343 0 10-4.4 4.341 4.343 4.343 0 004.4-4.341zm0-20.923h-20v5h20zm-36.39 49.64a1.1 1.1 0 01-1.1 1.1 16.644 16.644 0 01-3.223-.334c-2.361-.474-6.536-1.65-9.356-4.274l-.455.455a1.1 1.1 0 01-1.395.132L6.636 52.378a1.063 1.063 0 01-.324-.363c-.123-.11-.26-.194-.378-.31a6.145 6.145 0 010-8.688 6.294 6.294 0 018.688 0c.117.12.205.255.31.38a1.05 1.05 0 01.365.325l10.15 14.913a1.1 1.1 0 01-.13 1.392l-.381.382a17.625 17.625 0 008.246 3.663 14.655 14.655 0 002.8.29l-.006-.008a1.1 1.1 0 011.1 1.1zm-24.008-15.3a3.947 3.947 0 10-5.58 0 4.04 4.04 0 005.58 0zm5.53 5.655a12.164 12.164 0 001.232-1.53l-3.64-5.34a5.97 5.97 0 01-4.336 4.34l5.367 3.653v.001a11.837 11.837 0 001.377-1.124zm4.529 3.308l-1.963-2.884a14.076 14.076 0 01-1.012 1.127c-.314.314-.645.611-.988.892l2.873 1.957z"
      />
    </svg>
  );
};

export default SoundPlan;

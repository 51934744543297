import React, { useCallback } from "react";

/* packages */
import qs from "query-string";

import dayjs from "dayjs";
import cls from "classnames";
import validator from "email-validator";

import { FormProvider, Controller, useForm } from "react-hook-form";

import get from "lodash/get";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";

/* context */
import { useEventDays } from "../../../contexts/EventContext";

/* graphql */
import { MUTATION_INVITE_VISITOR } from "../../../graphql/Meetings";

import {
  SuggestedDateSelect,
  SuggestedTimeSelect,
  useSuggestionsDefaultOptions,
} from "../Meeting/SuggestedTimes";
import { useSnackbarCustom } from "../../../components/Notification";

const MeetingRequestForm = React.memo(() => {
  const { t } = useTranslation(["agenda", "meetingRequest"]);

  const history = useHistory();

  const days = useEventDays();
  const defaultTimeOptions = useSuggestionsDefaultOptions();

  const methods = useForm({
    defaultValues: {
      Date: dayjs(days[0]).format("YYYY-MM-DD"),
      Time: defaultTimeOptions[0],
      Description: "",
    },
  });

  const snackbar = useSnackbarCustom();

  const [onInvite, { loading: saving }] = useMutation(MUTATION_INVITE_VISITOR);

  const onSubmit = useCallback(
    (input) => {
      const { Date, Time, ...rest } = input;

      const variables = {
        ...rest,
        DateTime: `${Date} ${Time}:00`,
      };

      return onInvite({ variables })
        .then(({ data: { createProvadaVisitorInvitation: Invitation } }) => {
          const searchString = qs.stringify(
            {
              Status: -1,
              Date: dayjs(Invitation.DateTime).format("YYYY-MM-DD"),
              ID: `ProvadaInvitedSpeaker-${Invitation.ID}`,
            },
            { arrayFormat: "index", skipNull: true }
          );

          return history.replace({
            pathname: "/agenda",
            search: searchString,
          });
        })
        .catch(snackbar.enqueueSnackbarError);
    },
    [history, onInvite, snackbar]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="card-body col col-12 col-md-6">
          <p className="text-dark font-weight-bold mb-3">
            {t("meetingRequest:label.visitor")}
          </p>
          <Controller
            name="Title"
            rules={{
              required: {
                value: true,
                message: t("meetingRequest:input.error.Title.required"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="form-group">
                <label className="text-primary">
                  {t("meetingRequest:input.label.Title")}
                </label>
                <input
                  {...field}
                  type="text"
                  required
                  className={cls("form-control", {
                    "is-invalid": !!error,
                  })}
                />
                <div className="invalid-feedback">{get(error, "message")}</div>
              </div>
            )}
          />

          <Controller
            name="Email"
            rules={{
              required: {
                value: true,
                message: t("meetingRequest:input.error.Email.required"),
              },
              validate: {
                email: (value) =>
                  validator.validate(value) ||
                  t("meetingRequest:input.error.Email.email"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="form-group">
                <label className="text-primary">
                  {t("meetingRequest:input.label.Email")}
                </label>
                <input
                  {...field}
                  type="email"
                  required
                  className={cls("form-control", {
                    "is-invalid": !!error,
                  })}
                />
                <div className="invalid-feedback">{get(error, "message")}</div>
              </div>
            )}
          />

          <p className="text-dark font-weight-bold mb-3">
            {t("meetingRequest:label.meetingDetails")}
          </p>
          <div className="form-group">
            <label className="text-primary">
              {t("meeting:form.reschedule.input.label.SuggestedTimes")}
            </label>
            <div className="d-flex mb-2">
              <Controller
                name="Date"
                render={({ field: { value, onChange } }) => (
                  <SuggestedDateSelect value={value} onChange={onChange} />
                )}
              />
              <Controller
                name="Time"
                render={({ field: { value, onChange } }) => (
                  <SuggestedTimeSelect
                    options={defaultTimeOptions}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>

          <Controller
            name="Description"
            render={({ field, fieldState: { error } }) => (
              <div className="form-group mb-3">
                <label htmlFor="Description" className="text-primary">
                  {t("meeting:form.reschedule.input.label.Message")}
                </label>
                <input
                  {...field}
                  type="text"
                  required
                  className={cls("form-control", {
                    "is-invalid": !!error,
                  })}
                />
                <div className="invalid-feedback">{get(error, "message")}</div>
              </div>
            )}
          />

          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {saving && (
                <span
                  className="spinner-border spinner-border-sm mr-2 mb-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {t("meetingRequest:button.submit")}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
});

const MeetingInvite = React.memo(() => {
  const { t } = useTranslation(["agenda", "meetingRequest"]);

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/agenda">
            {t("agenda:title")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>Invite visitor</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
      </Breadcrumbs>
      <div className="card">
        <MeetingRequestForm />
      </div>
    </DefaultLayout>
  );
});

export default MeetingInvite;

import React from 'react';

const Percentage = ({ value }) => {

  return (
    <div className="single-chart position-relative">
      <svg viewBox="0 0 36 36" className="circular-chart-secondary">
        <path className="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
        <path className="circle" strokeDasharray={`${value}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
      </svg>
      <span className="absolute-center font-weight-bold">{value}%</span>
    </div>
  )
}

Percentage.defaultProps = {
  value: '0'
}

export default Percentage;
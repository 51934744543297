import React from "react";

const Halls = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path d="M39 38.001h2v2h-2zM37 42.001h6v2h-6zM61.052 59.999a5.97 5.97 0 00-1.127.1 5.991 5.991 0 00-11.85 0 5.716 5.716 0 00-2.15 0 5.991 5.991 0 00-11.85 0 5.716 5.716 0 00-2.15 0 5.991 5.991 0 00-11.85 0 5.975 5.975 0 00-7.075 5.9v3a1 1 0 001 1h10a1 1 0 001-1v-3a6.002 6.002 0 00-.09-1h2.18a5.995 5.995 0 00-.09 1v3a1 1 0 001 1h10a1 1 0 001-1v-3a6.002 6.002 0 00-.09-1h2.18a5.995 5.995 0 00-.09 1v3a1 1 0 001 1h10a1 1 0 001-1v-3a6.002 6.002 0 00-.09-1h2.18a5.995 5.995 0 00-.09 1v3a1 1 0 001 1h10a1 1 0 001-1v-3a5.975 5.975 0 00-5.948-6zm-38.052 8h-8v-2a4 4 0 018 0zm4.812-4.998v-.002h-3.624a6.03 6.03 0 00-2.17-2.177 3.985 3.985 0 017.964 0 6.03 6.03 0 00-2.17 2.179zM37 67.999h-8v-2a4 4 0 018 0zm4.812-4.998v-.002h-3.624a6.03 6.03 0 00-2.17-2.177 3.985 3.985 0 017.964 0 6.03 6.03 0 00-2.17 2.179zM51 67.999h-8v-2a4 4 0 018 0zm4.812-4.998v-.002h-3.624a6.03 6.03 0 00-2.17-2.177 3.985 3.985 0 017.964 0 6.03 6.03 0 00-2.17 2.179zM65 67.999h-8v-2a4 4 0 018 0z" />
      <path d="M69 10.001H11a1 1 0 00-1 1v4a1 1 0 001 1h2v27a1 1 0 001 1h18.373l.461 3H32a1 1 0 00-1 1v4a1 1 0 001 1h16a1 1 0 001-1v-4a1 1 0 00-1-1h-.834l.461-3H66a1 1 0 001-1v-27h2a1 1 0 001-1v-4a1 1 0 00-1-1zm-22 41H33v-2h14zm-13.834-15h13.668l-1.692 11H34.858zm-3.385-2l-.5-2h21.438l-.5 2zm35.219 8H47.935l.923-6H51a1 1 0 00.97-.758l1-4a1 1 0 00-.97-1.242h-2v-3a1 1 0 00-.293-.707L47.7 24.286a2.95 2.95 0 00.3-1.285 3 3 0 10-3 3 2.95 2.95 0 001.285-.3L48 27.415v2.586H32v-2.586l1.715-1.714a2.95 2.95 0 001.285.3 3 3 0 10-3-3 2.951 2.951 0 00.3 1.285l-2.008 2.008a1 1 0 00-.292.707v3h-2a1 1 0 00-.97 1.242l1 4a1 1 0 00.97.758h2.142l.923 6H15v-26h50zm-19-19a1 1 0 11-1-1 1 1 0 011 1zm-12 0a1 1 0 111 1 1 1 0 01-1-1zm34-9H12v-2h56z" />
    </svg>
  );
};

export default Halls;

import React, { useCallback } from "react";

import get from "lodash/get";

import MUAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import { useSnackbar } from "notistack";

import { withStyles } from "@material-ui/core/styles";

import { ApolloError } from "apollo-client";

export const Alert = withStyles(() => ({
  root: {
    flex: 1,
  },
  message: {
    wordBreak: "break-word",
  },
}))(MUAlert);

export const useSnackbarCustom = () => {
  const snackbar = useSnackbar();

  const enqueueSnackbar = useCallback(
    (message, options = { variant: "info" }) => {
      const variant = options.variant || "info";

      return snackbar.enqueueSnackbar(message, {
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        autoHideDuration: 3000,
        ...options,
        variant,
        content: (key, innerMessage) => (
          <Alert key={key} severity={variant} className="text-wrap">
            {innerMessage}
          </Alert>
        ),
      });
    },
    [snackbar]
  );

  const enqueueSnackbarError = useCallback(
    (error, options) => {
      let errorMessage = error.message;

      if (error instanceof ApolloError) {
        errorMessage = get(
          error,
          "graphQLErrors[0].message",
          get(error, "message")
        );
      }
      return enqueueSnackbar(errorMessage, { ...options, variant: "error" });
    },
    [enqueueSnackbar]
  );

  return {
    enqueueSnackbar,
    enqueueSnackbarError,
  };
};

const Notification = ({ content, type, open, hideAfter }) => {
  return (
    <Snackbar open={open} autoHideDuration={hideAfter}>
      <Alert severity={type} className="text-wrap">
        {content}
      </Alert>
    </Snackbar>
  );
};

Notification.defaultProps = {
  content: "", // content to show
  type: "info", // info, warning, error, success
  open: false, // hidden by default
  hideAfter: 3000, // hide after 3sec
};

export default Notification;

import React, { Fragment, useMemo } from "react";

import get from "lodash/get";

import cls from "classnames";
import gql from "graphql-tag";
import validator from "email-validator";

import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { FormProvider, Controller, useForm } from "react-hook-form";

import { useGathering } from "../../../contexts/GatheringContext";
import { useEvent } from "../../../contexts/EventContext";

import Breadcrumbs from "../../../components/Breadcrumbs";
import { useSnackbarCustom } from "../../../components/Notification";
import { CardHeaderLeft } from "../../../components/CardHeader";

import { QUERY_GATHERING_SPEAKERS_INVITES } from "../../../graphql/Meetings";

import { GatheringBreadcrumbs } from "../GatheringEdit";

const CREATE_GATHERING_INVITATION = gql`
  mutation createProvadaSpeakerInvitation(
    $Title: String!
    $Email: String!
    $GatheringID: Int!
  ) {
    createProvadaSpeakerInvitation(
      Title: $Title
      Email: $Email
      GatheringID: $GatheringID
    ) {
      ID
      Title
      Email
      Status
    }
  }
`;

const SpeakersPage = () => {
  const { t } = useTranslation([
    "gatherings",
    "gatheringSpeakers",
    "gatheringSpeakersInvite",
  ]);

  const event = useEvent();

  const snackbar = useSnackbarCustom();

  const history = useHistory();

  const methods = useForm({
    defaultValues: {
      Title: "",
      Email: "",
    },
  });

  const { data: gathering } = useGathering();

  const gatheringTitle = useMemo(() => {
    return gathering.Title
      ? gathering.Title
      : gathering.ID
      ? `#${gathering.ID}`
      : "";
  }, [gathering.ID, gathering.Title]);

  const [createSpeakerInvitation, { loading: isSaving }] = useMutation(
    CREATE_GATHERING_INVITATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_GATHERING_SPEAKERS_INVITES,
          variables: {
            InvitedForID: parseInt(gathering.ID, 10),
            EventID: event.ID,
          },
        },
      ],
    }
  );

  const onSubmit = (variables) => {
    return createSpeakerInvitation({
      variables: {
        ...variables,
        GatheringID: parseInt(gathering.ID, 10),
      },
    })
      .then(() => {
        history.replace(`/gathering/${gathering.ID}/speakers`);
      })
      .catch(snackbar.enqueueSnackbarError);
  };

  return (
    <Fragment>
      <GatheringBreadcrumbs>
        <Breadcrumbs.ListItem to={`/gathering/${gathering.ID}/speakers`}>
          {t("gatheringSpeakers:title")}
        </Breadcrumbs.ListItem>
        <Breadcrumbs.ListItem>
          {t("gatheringSpeakersInvite:title")}
        </Breadcrumbs.ListItem>
      </GatheringBreadcrumbs>
      <FormProvider {...methods}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-body bg-primary d-flex justify-content-between align-items-center p-2">
                <CardHeaderLeft
                  onClick={() =>
                    history.push(`/gathering/${gathering.ID}/speakers`)
                  }
                />
                <h1 className="text-white mb-0">
                  {t("gatheringSpeakersInvite:title")}
                </h1>
                <div />
              </div>
              <div className="card-body">
                <Controller
                  name="Title"
                  rules={{
                    required: {
                      value: true,
                      message: t(
                        "gatheringSpeakersInvite:input.error.Title.required"
                      ),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div className="form-group">
                      <label className="text-primary">
                        {t("gatheringSpeakersInvite:input.label.Title")}
                      </label>
                      <input
                        {...field}
                        type="text"
                        required
                        className={cls("form-control", {
                          "is-invalid": !!error,
                        })}
                      />
                      <div className="invalid-feedback">
                        {get(error, "message")}
                      </div>
                    </div>
                  )}
                />

                <Controller
                  name="Email"
                  rules={{
                    required: {
                      value: true,
                      message: t(
                        "gatheringSpeakersInvite:input.error.Email.required"
                      ),
                    },
                    validate: {
                      email: (value) =>
                        validator.validate(value) ||
                        t("gatheringSpeakersInvite:input.error.Email.email"),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div className="form-group">
                      <label className="text-primary">
                        {t("gatheringSpeakersInvite:input.label.Email")}
                      </label>
                      <input
                        {...field}
                        type="email"
                        required
                        className={cls("form-control", {
                          "is-invalid": !!error,
                        })}
                      />
                      <div className="invalid-feedback">
                        {get(error, "message")}
                      </div>
                    </div>
                  )}
                />
                <div>
                  <p>
                    <Trans
                      i18nKey="gatheringSpeakersInvite:note"
                      values={{ eventTitle: event.Title, gatheringTitle }}
                    />
                  </p>
                </div>
                <div className="d-flex mt-4">
                  <button
                    disabled={isSaving}
                    type="submit"
                    className="btn btn-secondary"
                    onClick={methods.handleSubmit(onSubmit)}
                  >
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm mr-2 mb-1"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {t("gatheringSpeakersInvite:button.submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </Fragment>
  );
};

export default SpeakersPage;

import React from "react";

/* packages */
import qs from "query-string";
import dayjs from "dayjs";
import classNames from "classnames";

import xor from "lodash/xor";

import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

/* context */
import {
  AgendaProvider,
  useAgendaVariables,
} from "../../../contexts/AgendaContext";
import { useEventDays } from "../../../contexts/EventContext";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";

import AgendaList from "./List";

const AgendaEventDaysFilter = React.memo(() => {
  const { url: pathname } = useRouteMatch();

  const days = useEventDays();

  const [{ Date: selectedDate, ...rest }] = useAgendaVariables();

  return days.map((day) => {
    const currentDate = dayjs(day).format("YYYY-MM-DD");

    const searchString = qs.stringify(
      { Date: currentDate, ...rest },
      { parseNumbers: true, arrayFormat: "index" }
    );

    const isActive = selectedDate === currentDate;

    return (
      <Link
        key={`day-${day}`}
        className={classNames("h1 text-decoration-none mr-4", {
          "text-primary-40": !isActive,
          "text-primary": isActive,
        })}
        to={{ pathname, search: searchString }}
      >
        {dayjs(day).format("dddd")}
      </Link>
    );
  });
});

const AgendaStatusFilter = React.memo(() => {
  const { t } = useTranslation(["agendaStatus"]);

  const [{ Status: selectedStatus }, onChangeVariables] = useAgendaVariables();

  return [1, -1, 0].map((status) => {
    const checked = selectedStatus.includes(status);

    const onChange = ({ target: { value } }) =>
      onChangeVariables({
        Status: xor(selectedStatus, [parseInt(value, 10)]),
      });

    return (
      <div
        key={`status-${status}`}
        className="custom-control custom-checkbox mr-3"
      >
        <input
          className="custom-control-input custom-control-primary"
          type="checkbox"
          id={`status-${status}`}
          value={status}
          checked={checked}
          onChange={onChange}
        />
        <label className="custom-control-label" htmlFor={`status-${status}`}>
          {t(`agendaStatus:${status}`)}
        </label>
      </div>
    );
  });
});

const Agenda = React.memo(() => {
  const { t } = useTranslation(["agenda"]);

  return (
    <AgendaProvider>
      <DefaultLayout>
        <div className="d-flex flex-row">
          <Breadcrumbs className="d-flex flex-1">
            <Breadcrumbs.List>
              <Breadcrumbs.ListItem>{t("agenda:title")}</Breadcrumbs.ListItem>
            </Breadcrumbs.List>
            <Breadcrumbs.Addition.Company />
          </Breadcrumbs>
        </div>

        <div className="px-2 mb-4">
          <div className="d-flex flex-wrap mb-3">
            <AgendaEventDaysFilter />
          </div>
          <div className="d-flex flex-wrap mb-3">
            <AgendaStatusFilter />
          </div>
        </div>

        <AgendaList />
      </DefaultLayout>
    </AgendaProvider>
  );
});

export default Agenda;

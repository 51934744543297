import React, { Fragment, useMemo } from "react";

/* packages */
import { get } from "lodash";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

/* components */
import CardHeader from "../../components/CardHeader";

/* context */
import { useUser } from "../../contexts/UserContext";
import { useEvent } from "../../contexts/EventContext";
import { useAttendeeExhibitor } from "../../contexts/ExhibitorContext";
import {
  AttendeeRolesList,
  useAttendeeRoles,
} from "../../contexts/AttendeeRolesContext";

/* lib */
import { useSafeSetState } from "../../lib/hooks";

/* graphql */
import {
  QUERY_TICKET,
  QUERY_TICKETS,
  MUTATION_APPROVE_TICKET,
  QUERY_EXHIBITOR_ATTENDEES,
} from "../../graphql/Tickets";

const TicketConfirmRoute = React.memo(
  ({
    history: { push, replace, goBack },
    match: {
      params: { ID },
    },
  }) => {
    const { t } = useTranslation(["attendeeConfirm"]);

    const { ID: UserID } = useUser();
    const { ID: EventID } = useEvent();

    const { Role } = useAttendeeRoles();
    const { ID: ExhibitorID } = useAttendeeExhibitor();

    const [state, setState] = useSafeSetState({
      ID: null,
      Firstname: "",
      Affix: "",
      Surname: "",
      Company: "",
    });

    const [onApproveTicket, { loading: updatingTicket }] = useMutation(
      MUTATION_APPROVE_TICKET,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_TICKETS,
            variables: {
              Filter: {
                Approved__eq: false,
                ExhibitorID__eq: ExhibitorID,
              },
            },
          },
          {
            query: QUERY_EXHIBITOR_ATTENDEES,
            variables: {
              Filter: {
                EventID__eq: EventID,
                ExhibitorID__eq: ExhibitorID,
              },
              Exclude: {
                MemberID__eq: UserID, //! Important - Exclude currently logged in member
              },
            },
          },
        ],
      }
    );

    const { loading: loadingTicket } = useQuery(QUERY_TICKET, {
      variables: { ID },
      onCompleted: ({ readOneProvadaTicket: Ticket }) => {
        if (!Ticket) {
          return goBack();
        }

        return setState({ ...Ticket });
      },
      onError: () => goBack(),
    });

    const isDisabled = useMemo(() => updatingTicket || loadingTicket, [
      loadingTicket,
      updatingTicket,
    ]);
    const isFormValid = useMemo(() => !isDisabled && !!Role.ID && !!state.ID, [
      Role,
      state,
      isDisabled,
    ]);

    function onSubmit(e) {
      e.preventDefault();

      if (!isFormValid) {
        return;
      }

      return onApproveTicket({ variables: { TicketID: ID, RoleID: Role.ID } })
        .then(() => {
          goBack();
        })
        .catch((error) => {
          //   console.log("Error - approveProvadaTicket: ", error.message);

          goBack();
        });
    }

    const Title = useMemo(
      () =>
        [state.Firstname, state.Affix, state.Surname].filter(Boolean).join(" "),
      [state]
    );

    return (
      <Fragment>
        <div className="card">
          <CardHeader
            title={t("attendeeConfirm:title")}
            onDismiss={() => push(`/tickets`)}
            onSubmit={onSubmit}
          />

          <form className="card-body" onSubmit={onSubmit}>
            <h2 className="mb-3">{t("attendeeConfirm:subtitle")}</h2>

            <div className="d-flex py-2 mb-4">
              <div>
                <div
                  className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
                  style={{
                    backgroundImage: `url(${get(state, "Image.AbsoluteLink")})`,
                  }}
                />
              </div>
              <div className="flex-1 d-flex align-items-center">
                <div className="flex-1">
                  <h3 className="mb-1">{Title}</h3>
                  <p className="text-primary mb-2">{state.Company}</p>
                </div>
              </div>
            </div>

            <AttendeeRolesList />

            <div>
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={!isFormValid}
                onClick={onSubmit}
              >
                {t("attendeeConfirm:button.submit")}
              </button>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
);

export default TicketConfirmRoute;

import React, { useMemo, Fragment } from "react";

/* packages */
import { every } from "lodash";
import { useMutation } from "@apollo/react-hooks";

import useSetState from "react-use/lib/useSetState";

import { useTranslation } from "react-i18next";

import { LOGIN_URL } from "../../utils/globals";

/* layout */
import FluidLayout from "../../layout/FluidLayout";

/* components */
import EventBlock from "../../components/EventBlock";
import LinkArrowExternal from "../../components/LinkArrowExternal";

/* graphql */
import { MUTATION_REGISTER_MEMBER } from "../../graphql/User";

const memberInitialState = {
  FirstName: "",
  Affix: "",
  Surname: "",
  WorksAt: "",
  Email: "",
  Password: "",
};

const requiredFields = ["FirstName", "Surname", "WorksAt", "Email", "Password"];

const RegisterPage = React.memo(({ history: { push } }) => {
  const { t } = useTranslation(["register"]);

  const [user, setUser] = useSetState({ ...memberInitialState });

  const onChangeValue = ({ target: { value, name } }) =>
    setUser({ [name]: value });

  const [onRegisterMember, { loading: creatingMember }] = useMutation(
    MUTATION_REGISTER_MEMBER
  );

  const isFormValid = useMemo(
    () =>
      !creatingMember &&
      every(requiredFields, (key) => !!user[key].trim().length),
    [user]
  );

  function onSubmit(e) {
    e.preventDefault();

    if (!isFormValid) {
      return;
    }

    return onRegisterMember({ variables: user })
      .then(({ data: { registerProvadaMember } }) => {
        if (!registerProvadaMember) {
          return; // ! @todo Error message
        }

        return push(`/register/confirm`);
      })
      .catch((error) => {
        // console.log("Error - registerProvadaMember: ", error.message);

        return; // ! @todo Error message
      });
  }

  return (
    <FluidLayout>
      <div className="bg-white py-4">
        <div className="container typography">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <h1>{t("register:title")}</h1>

              <form className="mb-4" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="FirstName" className="text-primary">
                    {t("register:input.label.FirstName")}
                  </label>
                  <input
                    id="FirstName"
                    name="FirstName"
                    type="text"
                    className="form-control"
                    value={user.FirstName}
                    onChange={onChangeValue}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="Affix" className="text-primary">
                    {t("register:input.label.Affix")}
                  </label>
                  <input
                    id="Affix"
                    name="Affix"
                    type="text"
                    className="form-control"
                    value={user.Affix}
                    onChange={onChangeValue}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Surname" className="text-primary">
                    {t("register:input.label.Surname")}
                  </label>
                  <input
                    id="Surname"
                    name="Surname"
                    type="text"
                    className="form-control"
                    value={user.Surname}
                    onChange={onChangeValue}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="WorksAt" className="text-primary">
                    {t("register:input.label.WorksAt")}
                  </label>
                  <input
                    id="WorksAt"
                    name="WorksAt"
                    type="text"
                    className="form-control"
                    value={user.WorksAt}
                    onChange={onChangeValue}
                  />
                </div>

                <h3 className="text-primary mb-4">
                  {t("register:label.credentials")}
                </h3>

                <div className="form-group">
                  <label htmlFor="Email" className="text-primary">
                    {t("register:input.label.Email")}
                  </label>
                  <input
                    id="Email"
                    name="Email"
                    type="email"
                    className="form-control"
                    value={user.Email}
                    placeholder=""
                    onChange={onChangeValue}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Password" className="text-primary">
                    {t("register:input.label.Password")}
                  </label>
                  <input
                    id="Password"
                    name="Password"
                    type="password"
                    className="form-control"
                    value={user.Password}
                    placeholder="********************"
                    onChange={onChangeValue}
                  />
                </div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!isFormValid}
                  onClick={onSubmit}
                >
                  {creatingMember && (
                    <span
                      className="spinner-border spinner-border-sm mr-2 mb-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {t("register:button.submit")}
                </button>
              </form>

              {false && (
                <Fragment>
                  <div className="d-flex mb-4">
                    <p className="mb-0 mr-2 text-primary">of</p>
                    <div className="line-through" />
                  </div>

                  <div className="mb-4">
                    <button className="linkedin-login btn btn-linkedin btn-icon btn-icon-left position-relative w-100">
                      <div className="icon-holder">
                        <i className="text-white font-size-1-5 if-Linkedin" />
                      </div>
                      <span className="d-block w-100">
                        {t("register:button.linkedin")}
                      </span>
                    </button>
                  </div>
                </Fragment>
              )}

              <div className="mb-4">
                <a href={LOGIN_URL}>{t("register:button.login")}</a>
              </div>

              <div className="mb-5">
                <LinkArrowExternal to="https://reg.summit.nl/Default.aspx?alias=reg.summit.nl/provada">
                  {t("register:button.purchaseTicket")}
                </LinkArrowExternal>
              </div>
            </div>
          </div>

          <EventBlock />
        </div>
      </div>
    </FluidLayout>
  );
});

export default RegisterPage;

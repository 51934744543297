import React, { useContext, useState } from 'react';

/* packages */
import dayjs from 'dayjs';

import { useMutation } from '@apollo/react-hooks';

/* context */
import { useMeeting, useAgendaRefetchQueries } from '../../../contexts/AgendaContext';

/* graphql */
import { MUTATION_UPDATE_MEETING } from '../../../graphql/Meetings';

const SelectTimeContext = React.createContext();

const { Provider } = SelectTimeContext;

export const useSelectTime = () => {
  const { value, onChange } = useContext(SelectTimeContext);

  return [value, onChange];
}

export const SelectTimeForm = React.memo(() => {
  const { Meeting: { SuggestedTimes } } = useMeeting();

  const [selectedValue, onChange] = useSelectTime();

  return (
    SuggestedTimes.map((value) => {
      return (
        <div className="custom-control custom-radio mb-0" key={value}>
          <input
            id={value}
            name={value}
            type="radio"
            checked={selectedValue === value}
            value={value}
            className="custom-control-input custom-control-secondary"
            onChange={({ target: { value } }) => onChange(value)}
          />
          <label
            htmlFor={value}
            className="custom-control-label h3 mb-0 font-weight-normal"
          >
            {dayjs(value, 'YYYY-MM-DD').format("dddd HH:mm")}
          </label>
        </div>
      )
    })
  );
});

export const useRescheduleMutation = (options = {}) => {
  const { Meeting: { ID } } = useMeeting();

  const refetchQueries = useAgendaRefetchQueries();

  const [AcceptedTime] = useSelectTime();

  return useMutation(MUTATION_UPDATE_MEETING, {
    ...options,
    variables: {
      Input: {
        ID,
        AcceptedTime,
      }
    },
    awaitRefetchQueries: true,
    refetchQueries
  });
};

export const SelectTimeProvider = React.memo(({ children }) => {
  const { Meeting: { SuggestedTimes } } = useMeeting();

  const [value, setValue] = useState(() => SuggestedTimes[0]);

  return (
    <Provider
      children={children}
      value={{
        value,
        onChange: setValue
      }}
    />
  )
});

export default SelectTimeContext;

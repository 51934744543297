import React, { useContext, useMemo, useState } from "react";

/* packages */
import dayjs from "dayjs";

import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";
import useLocalStorage from "react-use/lib/useLocalStorage";

import { useQuery } from "@apollo/react-hooks";

/** contexts */
import UserContext from "./UserContext";

/** query */
import { QUERY_INITIAL_EVENTS } from "../graphql/Event";

import { getWorkingHours } from "../lib/common";

import Loading from "../components/Loading";

const EventContext = React.createContext({
  event: { ID: null },
  events: [],
  onChangeActiveEvent: () => null,
});

EventContext.displayName = "EventContext";

const { Provider } = EventContext;

export const useEventContext = () => useContext(EventContext);

export const useEvent = () => useEventContext().event;

export const useEvents = () => useEventContext().events;

export const useEventDays = () => {
  const event = useEvent();

  const days = useMemo(() => {
    let items = [];

    if (!event) {
      return items;
    }

    const { StartDate, EndDate } = event;

    if (!(!!StartDate && !!EndDate)) {
      return items;
    }

    let end = dayjs(EndDate, "YYYY-MM-DD");
    let start = dayjs(StartDate, "YYYY-MM-DD");

    if (!(start.isValid() && end.isValid())) {
      return items;
    }

    while (!start.isAfter(end, "date")) {
      items.push(start.unix() * 1000);
      start = dayjs(start).add(1, "day");
    }

    return items;
  }, [event]);

  return days;
};

export const useEventOpenHours = () => {
  const { RoomBookingStart = "09:00:00", RoomBookingEnd = "19:00:00" } =
    useEvent();

  // ? Ref: https://app.activecollab.com/119944/my-work?modal=Task-54738-829
  return {
    RoomBookingStart,
    RoomBookingEnd,
  };
};

export const useEventHoursList = (date = dayjs()) => {
  const { RoomBookingStart: from, RoomBookingEnd: to } = useEventOpenHours();

  return useMemo(() => getWorkingHours({ from, to, date }), [from, to, date]);
};

export const EventProvider = React.memo(({ children }) => {
  const { user } = useContext(UserContext);

  const [initialized, setInitialized] = useState(false);

  const [activeEvent, onChangeActiveEvent] = useLocalStorage("@event/active");

  const { data } = useQuery(QUERY_INITIAL_EVENTS, {
    skip: !(user && user.ID),
    onCompleted: ({ readProvadaEvents, readProvadaEventCurrent }) => {
      if (!(readProvadaEventCurrent && readProvadaEvents)) {
        return;
      }

      const nextEvents = filter(
        map(get(readProvadaEvents, "edges"), "node"),
        (event) => !!parseInt(event.ID)
      );

      const currentSelectedEvent = find(nextEvents, activeEvent);

      if (currentSelectedEvent) {
        setInitialized(true);
        onChangeActiveEvent(currentSelectedEvent);

        return;
      }

      const nextSelectedEvent = find(nextEvents, {
        ID: readProvadaEventCurrent.ID,
      });

      onChangeActiveEvent(nextSelectedEvent);

      setInitialized(true);
    },
  });

  const events = useMemo(
    () => map(get(data, "readProvadaEvents.edges"), "node"),
    [data]
  );

  return (
    <Provider
      value={{
        event: activeEvent || {},
        events,
        onChangeActiveEvent,
      }}
    >
      {!initialized ? <Loading /> : children}
    </Provider>
  );
});

export default EventContext;

import React, { useMemo, useContext, useEffect, useRef } from "react";

/* packages */
import qs from "query-string";
import dayjs from "dayjs";
import last from "lodash/last";
import first from "lodash/first";

import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";

import { map, get, set, keyBy, some } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";

/* context */
import { useEvent, useEventDays, useEventHoursList } from "./EventContext";
import RoomReservationContext from "./RoomReservationContext";

/* graphql */
import {
  QUERY_LOCATIONS,
  QUERY_LOCATION_RESERVATIONS,
} from "../graphql/Location";

/* lib */
import {
  getMomentFromString,
  getTimestampsBetween,
  getEndOfDayTimestamp,
  updateQueryString,
} from "../lib/common";

import { BOOKING_SETUP_PERIOD, BOOKING_PERIOD } from "../utils/globals";

const BOOKING_PERIOD_MILLISECONDS = BOOKING_PERIOD * 60 * 1000;

export const CalendarContext = React.createContext();

export const useCalendarLocations = () => {
  const { locations } = useContext(CalendarContext);

  return locations;
};

export const useCalendarLocation = (ID) => {
  const {
    byId: { [ID]: location },
  } = useCalendarLocations();

  return { ...{ ID, Title: "", Price: 0 }, ...location };
};

export const useCalendar = () => {
  return useContext(CalendarContext);
};

export const useCalendarSwiper = () => {
  const { swiperCalendar, swiperLocation } = useCalendar();

  function initCalendar(instance) {
    swiperCalendar.current = instance;

    instance.controller.control = swiperLocation.current;
  }

  function initLocation(instance) {
    swiperLocation.current = instance;

    instance.controller.control = swiperCalendar.current;
  }

  function slideTo(index = 0) {
    if (!swiperCalendar.current) {
      return;
    }

    swiperCalendar.current.slideTo(index);
  }

  function slideNext() {
    if (!swiperCalendar.current) {
      return;
    }

    swiperCalendar.current.slideNext();
  }

  function slidePrev() {
    if (!swiperCalendar.current) {
      return;
    }

    swiperCalendar.current.slidePrev();
  }

  return {
    initLocation,
    initCalendar,

    slideTo,
    slideNext,
    slidePrev,
  };
};

export const CalendarProvider = React.memo((props) => {
  const {
    push,
    replace,
    location: { search },
  } = useHistory();

  const swiperCalendar = useRef(null);
  const swiperLocation = useRef(null);

  const reservationRef = useRef(null);

  //! Get parsed query params
  const queryParams = useMemo(() => qs.parse(search, { parseNumbers: true }), [
    search,
  ]);

  const event = useEvent();
  const eventDays = useEventDays();
  const { reservation, setReservation } = useContext(RoomReservationContext);

  const selectedDay = useMemo(() => {
    // No reservation date specified
    if (!reservation.Start) {
      return eventDays[0];
    }

    // Check if selected date is in scope of enet date
    if (
      !dayjs(reservation.Start).isBetween(
        first(eventDays),
        last(eventDays),
        "day",
        "[]"
      )
    ) {
      return eventDays[0];
    }

    return reservation.Start;
  }, [eventDays, reservation.Start]);

  const { loading: locationsLoading, data: locationsData } = useQuery(
    QUERY_LOCATIONS,
    {
      variables: {
        Filter: {
          Bookable__eq: true,
          EventID__eq: event.ID, //@todo
        },
      },
    }
  );

  // ? Normalize locations data
  const locations = useMemo(() => {
    const nextLocations = map(
      get(locationsData, "readProvadaLocations.edges"),
      "node"
    );

    return {
      byId: keyBy(nextLocations, "ID"),
      ids: map(nextLocations, "ID"),
    };
  }, [locationsData]);

  //! Ger reservations for current day
  const { loading: reservationsLoading, data: reservationsData } = useQuery(
    QUERY_LOCATION_RESERVATIONS,
    {
      skip: locationsLoading,
      fetchPolicy: "network-only",
      variables: {
        Filter: {
          Date__eq: dayjs(selectedDay).format("YYYY-MM-DD"), //@todo Replace with reservation date if any
          LocationID__in: map(
            get(locationsData, "readProvadaLocations.edges"),
            "node.ID"
          ),
        },
      },
    }
  );

  // ? Normalize reservations data
  const reservations = useMemo(
    () => map(get(reservationsData, "readProvadaReservations.edges"), "node"),
    [reservationsData]
  );

  const times = useEventHoursList(selectedDay);

  const reservedTimes = useMemo(() => {
    let reserved = {};

    for (let i = reservations.length; i-- > 0; ) {
      const reservation = reservations[i];

      const TimestampStart =
        getMomentFromString(reservation.Date, reservation.Start).unix() * 1000;
      const TimestampEnd =
        getMomentFromString(reservation.Date, reservation.End).unix() * 1000;

      const CleanupEnd =
        dayjs(TimestampEnd).add(reservation.CleanupTime, "seconds").unix() *
        1000;
      const PreparationStart =
        dayjs(TimestampStart)
          .subtract(reservation.PreparationTime, "seconds")
          .unix() * 1000;

      const times = getTimestampsBetween(TimestampStart, TimestampEnd, true);
      const cleanupTimes = getTimestampsBetween(TimestampEnd, CleanupEnd, true);
      const preparationTimes = getTimestampsBetween(
        PreparationStart,
        TimestampStart,
        false
      );

      // console.log('\n')
      // console.log(`Date: ${dayjs(TimestampStart).format("DD-MM-YYYY")}`)
      // console.log(`Preparation: ${dayjs(PreparationStart).format("HH:mm:ss")} - ${dayjs(TimestampStart).format("HH:mm:ss")} - Blocks: ${getBlocksFromTimestamp(TimestampStart, PreparationStart)}`)
      // console.log(`Reservation: ${dayjs(TimestampStart).format("HH:mm:ss")} - ${dayjs(TimestampEnd).format("HH:mm:ss")} `)
      // console.log(`Cleanup: ${dayjs(TimestampEnd).format("HH:mm:ss")} - ${dayjs(CleanupEnd).format("HH:mm:ss")} - Blocks: ${getBlocksFromTimestamp(CleanupEnd, TimestampEnd)}`)

      const LocationID = get(reservation, "Location.ID");

      //! Since we support preparation times which can be longer that one block (15min) we loop through
      //! the preparation times of each reservation and disable those moments.
      for (let i = preparationTimes.length; i-- > 0; ) {
        set(reserved, `${LocationID}-${preparationTimes[i]}`, "PREPARATION");
      }

      //! Since we support cleanup times which can be longer that one block (15min) we loop through
      //! the cleanup times of each reservation and disable those moments.
      for (let i = cleanupTimes.length; i-- > 0; ) {
        set(reserved, `${LocationID}-${cleanupTimes[i]}`, "CLEANUP");
      }

      for (let i = times.length; i-- > 0; ) {
        //! Exact moment when reservation starts
        set(reserved, `${LocationID}-${times[i]}`, reservation.ID);

        //! Append default preparation for new reservation after each individual block of reservations if theres no reservation above it
        if (i === times.length - 1) {
          let timestamp = dayjs(CleanupEnd).unix() * 1000;
          let res = get(reserved, [LocationID, timestamp]);

          if (!res) {
            set(reserved, `${LocationID}-${timestamp}`, "PREPARATION-NEW");
          }
        }

        //! For the last reservation for that day append cleanup tine for new reservation
        if (i === 0) {
          let timestamp =
            dayjs(PreparationStart)
              .subtract(BOOKING_SETUP_PERIOD, "minutes")
              .unix() * 1000;

          set(reserved, `${LocationID}-${timestamp}`, "CLEANUP-NEW");
        }
      }
    }

    // ! Disable first 15 minutes of each location since that is considered preparation time for the new reservation
    const { ids } = locations;

    ids.map((ID) => {
      const start = dayjs(selectedDay).startOf("day").unix() * 1000;

      set(reserved, `${ID}-${start}`, "PREPARATION-NEW");
    });

    return reserved;
  }, [reservations]);

  useDeepCompareEffect(() => {
    const defaultParams = {
      ID: null,
      Start: null,
      End: null,
      LocationID: get(locations, "ids[0]", null),
    };

    let nextParams = { ...defaultParams, ...queryParams };

    if (
      nextParams.Start &&
      !dayjs(nextParams.Start).isBetween(
        first(eventDays),
        last(eventDays),
        "day",
        "[]"
      )
    ) {
      nextParams = {
        ...nextParams,
        Start: times[0],
        End: null,
      };
    }

    setReservation(nextParams);
  }, [search, eventDays]);

  function checkRequestedTimeOccupation(
    { Start, End, LocationID } = { Start: null, End: null }
  ) {
    if (!(Start && End && LocationID)) {
      return false;
    }

    const StartDay = dayjs(event.StartDate, "YYYY-MM-DD")
      .startOf("day")
      .set("minute", BOOKING_PERIOD);
    const EndDay = getEndOfDayTimestamp(dayjs(event.EndDate, "YYYY-MM-DD"));

    if (
      !(
        //! Check if its a valid timestamp at all
        (
          Start % BOOKING_PERIOD_MILLISECONDS === 0 &&
          End % BOOKING_PERIOD_MILLISECONDS === 0 &&
          //! Timestamp has to be within the event StartDate & EndDate
          dayjs(Start).isBetween(StartDay, EndDay, "millisecond", "[)") &&
          dayjs(End).isBetween(StartDay, EndDay, "millisecond", "(]")
        )
      )
    ) {
      return true;
    }

    const requestedTimes = getTimestampsBetween(Start, End, true);

    return some(requestedTimes, (timestamp) => {
      const key = `${LocationID}-${timestamp}`;

      return !!reservedTimes[key];
    });
  }

  //! Clear the reservation if it intercepts already reserved time.
  useEffect(() => {
    function onUpdateReservation() {
      if (reservationsLoading) {
        return;
      }

      const occupied = checkRequestedTimeOccupation({ ...reservation });

      if (!occupied) {
        return;
      }

      const params = { Start: null, End: null };

      return replace({ search: updateQueryString({ search, params }) });
    }

    onUpdateReservation();
  }, [reservationsLoading, reservation.Start, reservation.End, reservedTimes]);

  function onChangeDay(day) {
    var Start =
      dayjs(day).startOf("day").add(BOOKING_PERIOD, "minute").unix() * 1000;
    var End = null;

    //! Keep StartTime of reservation if possible
    if (!!reservation && !!reservation.Start) {
      Start =
        dayjs(reservation.Start).set("date", dayjs(day).get("date")).unix() *
        1000;
    }

    //! Keep EndTime of reservation if possible
    if (!!reservation && !!reservation.End) {
      End =
        dayjs(reservation.End).set("date", dayjs(day).get("date")).unix() *
        1000;
    }

    return push({
      search: updateQueryString({ search, params: { Start, End } }),
    });
  }

  return (
    <CalendarContext.Provider
      value={{
        swiperCalendar,
        swiperLocation,

        reservationRef,

        times,
        reservedTimes,
        selected: selectedDay,

        locations,
        reservations,

        onChangeDay,
      }}
      {...props}
    />
  );
});

export default CalendarContext;

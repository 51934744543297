const live = process.env.NODE_ENV === "production";

export const BOOKING_HEIGHT = 30; // In pixes
export const BOOKING_PERIOD = 15; //In minutes
export const BOOKING_SETUP_PERIOD = 15; //In minutes
export const BOOKING_CLEANUP_PERIOD = 15; //In minutes

/**
 * For reference. Why all this hard work is going to the bin.
 *
 * "Very sad times"
 *  - Davie504
 */

/**
 * Task #734:#734: 🐞MYPR - Exhibitors should NOT be able to attach speakers to gatherings
 * https://app.activecollab.com/119944/my-work?modal=Task-61388-829
 */
export const ENABLE_GATHERING_ATTACHING_SPEAKERS = true;

/**
 * #757: 🐞MYPR - Ordering Tickets section, remove adding exhibitionemployees
 * https://app.activecollab.com/119944/my-work?modal=Task-61612-829
 */
export const ENABLE_EMPlOYEE_INVITE = false;

/**
 * #735: 🐞MYPR - Gathering, after approval reservation disable changes for Exhibitioners
 * https://app.activecollab.com/119944/my-work?modal=Task-61395-829
 */
export const ENABLE_GATHERING_EDIT_TIME = true;

/**
 * #122: Language switcher in MYPR
 * https://app.activecollab.com/119944/projects/1216/tasks/85651?back_path=my_work
 */
export const ENABLE_USER_LANGUAGE_CHANGE = true;

/**
 * Task #800: MyPR - Catering with room reservation, amount of people
 * https://app.activecollab.com/119944/my-work?modal=Task-65021-829
 */
export const ROOM_RESERVATION_EDIT_AFTER = [1, "hour"];

/**
 * Task #800: MyPR - Catering with room reservation, amount of people
 * https://app.activecollab.com/119944/my-work?modal=Task-65021-829
 */
export const ROOM_RESERVATION_EDIT_BEFORE = [2, "week"];

/**
 * Task: #800: MyPR - Catering with room reservation, amount of people
 * https://app.activecollab.com/119944/projects/206/tasks/38888?modal=Task-65021-829
 *
 * Requested by Vince that credentials are hard coded
 */
export const ROOM_RESERVATION_CONTACT_PERSON = {
  FullName: "Stephanie Veerman",
  Email: "stephanie@provada.nl",
  Phone: "+31 (0) 30 6051424",
};

export const DAYS_ENUM = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const PROFILE_COMPLETION_KEY = {
  FIRST_NAME: "FirstName",
  SURNAME: "Surname",
  WORkS_AT: "WorksAt",
  FUNCTION: "Function",
  GATHERINGS: "Gatherings",
  PUBLIC_PHONE: "PublicPhone",
};

export const SERVICE_TYPE = {
  INFO: "INFO",
  DELIVERABLE: "DELIVERABLE",
  ATTACHMENTS: "ATTACHMENTS",
  RAI: "RAI",
  ROOM: "ROOM",
};

export const SERVICE_TYPE_URL = {
  INFO: "provada",
  DELIVERABLE: "provada",
  ATTACHMENTS: "provada",
  RAI: "rai",
  ROOM: "rai",
};

export const LOGIN_URL = live
  ? process.env.REACT_APP_WEBSITE_LOGIN_URL
  : "/login";

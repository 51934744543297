import React, { useMemo } from "react";

import get from "lodash/get";

import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";

import DefaultLayout from "../../../layout/DefaultLayout";

import { useUser } from "../../../contexts/UserContext";
import { useAttendee } from "../../../contexts/ExhibitorContext";

import Breadcrumbs from "../../../components/Breadcrumbs";
import { MeetingCard } from "../Agenda/List/VisitorInvitationListItem";

import { QUERY_INVITED_VISITORS } from "../../../graphql/Meetings";

const MeetingInvitation = React.memo(() => {
  const history = useHistory();

  const member = useUser();
  const attendee = useAttendee();

  const isAttendee = !!(attendee && attendee.ID && !!parseInt(attendee.ID));

  const { InvitationID } = useParams();

  const { t } = useTranslation(["agenda", "meeting", "meetingStatus"]);

  const { data } = useQuery(QUERY_INVITED_VISITORS, {
    variables: {
      ID: InvitationID,
    },
    onError: () => history.replace("/agenda"),
  });

  const invitation = get(data, "readOneProvadaVisitorInvitation");

  const isOwner = isAttendee
    ? get(attendee, "ID") === get(invitation, "InvitedBy.ID")
    : get(member, "Email") === get(invitation, "InvitedBy.Member.Email");

  const title = useMemo(
    () =>
      isOwner
        ? get(invitation, "Title")
        : get(invitation, "InvitedBy.Member.FullName"),
    [isOwner, invitation]
  );

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/agenda">
            {t("agenda:title")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>{title}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
      </Breadcrumbs>
      <div className="card">
        {!!invitation && <MeetingCard compact={false} item={invitation} />}
      </div>
    </DefaultLayout>
  );
});

export default MeetingInvitation;

import React from "react";

const StandPromotion = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path d="M65.78 23.225h-1.57a1.033 1.033 0 000 2.066h1.57a1.033 1.033 0 000-2.066z" />
      <path d="M60.203 23.225h-1.57a1.033 1.033 0 000 2.066h1.57a1.033 1.033 0 000-2.066z" />
      <path d="M54.487 23.225h-1.566a1.033 1.033 0 000 2.066h1.57a1.033 1.033 0 000-2.066z" />
      <path d="M34.197 44.471L29.61 34.142a1.033 1.033 0 00-1.887 0L23.137 44.47a1.033 1.033 0 101.871.874l.017-.036 1.574-3.546a1.029 1.029 0 00.236.028h3.912l1.562 3.518a1.033 1.033 0 001.904-.802l-.016-.036zm-6.694-4.745l1.164-2.619 1.163 2.62z" />
      <path d="M70.64 13.437h-8.063v-1.33a1.033 1.033 0 00-1.033-1.033h-2.6v-.564a1.033 1.033 0 10-2.067-.003v.567h-2.6a1.033 1.033 0 00-1.033 1.033v1.33h-8.58v-1.33a1.033 1.033 0 00-1.033-1.033h-2.6v-.564a1.033 1.033 0 00-2.066 0v.564h-2.6a1.033 1.033 0 00-1.033 1.033v1.33h-8.58v-1.33a1.033 1.033 0 00-1.033-1.033h-2.6v-.564a1.033 1.033 0 10-2.066 0v.564h-2.6a1.033 1.033 0 00-1.027 1.033v1.33H9.36a1.033 1.033 0 00-1.033 1.033v39.853a1.033 1.033 0 001.033 1.033h26.125v14.135a1.033 1.033 0 001.032 1.033h6.966a1.033 1.033 0 001.033-1.033v-4.389a1.033 1.033 0 00-2.066 0v3.358h-4.9v-13.1h4.9v4.28a1.033 1.033 0 102.065 0v-4.284h26.126a1.033 1.033 0 001.032-1.033V14.47a1.033 1.033 0 00-1.032-1.033zm-15.327-.3h5.2v1.654h-5.2zm-17.913 0h5.2v1.654h-5.2zm-17.911 0h5.2v1.654h-5.2zm-9.1 8.1h30.21v6.027H10.393zm59.219 32.054H10.392v-2.127h59.216zm0-4.192H10.392V29.336h59.216zm0-21.828h-26.94v-6.027h26.94zm0-8.092H10.392v-3.676h7.034v.321a1.033 1.033 0 001.033 1.033h7.264a1.033 1.033 0 001.033-1.033v-.32h8.582v.32a1.033 1.033 0 001.033 1.033h7.264a1.033 1.033 0 001.034-1.033v-.32h8.578v.32a1.033 1.033 0 001.032 1.033h7.264a1.033 1.033 0 001.034-1.033v-.32h7.03v3.675z" />
      <path d="M30.087 23.225h-15.88a1.033 1.033 0 100 2.066h15.88a1.033 1.033 0 100-2.066z" />
      <path d="M52.32 38.644a1.036 1.036 0 00-.244.03 2.006 2.006 0 01-1.989-1.8 2.282 2.282 0 014.467 0 1.033 1.033 0 102.066 0 4.322 4.322 0 10-4.3 3.88 1.038 1.038 0 00.244-.03 2.006 2.006 0 011.99 1.8 2.282 2.282 0 01-4.468 0 1.033 1.033 0 00-2.066 0 4.322 4.322 0 104.3-3.88z" />
      <path d="M39.486 33.13a6.423 6.423 0 00-2.566.531l-.02.008-.022.01-.067.034-.037.022-.047.03-.043.033-.034.027c-.016.013-.03.027-.045.041l-.028.027-.04.044-.027.032-.032.042-.028.04-.023.04-.028.048-.017.035a.575.575 0 00-.025.057l-.012.034-.02.06-.01.04-.013.056a.545.545 0 00-.008.054.151.151 0 01-.006.044.319.319 0 000 .076v10.091a.083.083 0 00.006.043c.006.015 0 .037.009.056a.291.291 0 01.013.055l.01.041.02.058.014.036.025.055.017.036.027.048.024.04.027.04.033.043.027.031.04.044.028.026.045.041.033.026.045.034.047.03.037.023q.032.018.067.033l.02.01.02.008a6.472 6.472 0 102.566-12.414zm0 10.88a4.387 4.387 0 01-1.144-.152v-8.51a4.407 4.407 0 111.144 8.661z" />
    </svg>
  );
};

export default StandPromotion;

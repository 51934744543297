import React, { Fragment, useMemo } from "react";

/** routes */
import classNames from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";

import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";

import PrivateProfileEdit from "./PrivateProfileEdit";
import PublicProfileEdit from "./PublicProfileEdit";
import ProfileCompletion from "./ProfileCompletion";
import dayjs from "dayjs";
import DefaultLayout from "../../layout/DefaultLayout";
import { READ_ONE_SILVERSTRIPE_MEMBER } from "../../graphql/User";

/** contexts */
import { useUser } from "../../contexts/UserContext";
import { useEventDays } from "../../contexts/EventContext";
import { useRole, useAttendee } from "../../contexts/ExhibitorContext";
import LinkArrow from "../../components/LinkArrow";
import LinkArrowExternal from "../../components/LinkArrowExternal";
import Icon from "../../components/Icon";
import Breadcrumbs from "../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";
import { DAYS_ENUM } from "../../utils/globals";
import images from "../../images";

const userDefaultState = {
  ID: null,
  Gender: "",
  Initials: "",
  FirstName: "",
  Affix: "",
  Surname: "",
  Email: "",
  Phone: "",
  Mobile: "",
  Street: "",
  HouseNumber: "",
  PostalCode: "",
  Addition: "",
  City: "",
  Country: "",
  LinkedinLink: "",
  Function: "",
  Affix: "",
  Locale: "",
  Biography: "",
  Excerpt: "",
  Picture: {
    ID: null,
    AbsoluteLink: "",
  },
  WorksAt: "",
  PublicPhone: "",
  PublicEmail: "",
  PublicProfile: false,
  AvailableForMeetings: false,
};

const DaysBadge = React.memo(() => {
  const Attendee = useAttendee();

  const days = useEventDays();

  return (
    <div className="d-flex flex-wrap">
      {days.map((day) => {
        const key = DAYS_ENUM[dayjs(day).weekday() - 1];

        return (
          <span
            key={key}
            className={classNames("badge mb-2 mr-2", {
              "badge-secondary": !!Attendee[key],
              "badge-disabled": !Attendee[key],
            })}
          >
            {dayjs(day).format("ddd")}
          </span>
        );
      })}
    </div>
  );
});

const BiographyBlock = React.memo(({ user }) => {
  const Role = useRole();
  const isSpeaker = useMemo(() => !!Role && Role.Code === "SPEAKER", [Role]);
  const { t } = useTranslation(["common", "myprofile"]);

  return isSpeaker ? (
    <div className="d-flex align-items-center">
      <p>{user.Biography}</p>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <p className="text-break">{user.Excerpt}</p>
    </div>
  );
});

const AttendeeLinkBlock = React.memo(() => {
  const { t } = useTranslation(["common", "myprofile"]);

  const User = useUser();
  const Attendee = useAttendee();

  return (
    User.PublicProfile &&
    Attendee &&
    Attendee.PublicLink && (
      <div className="card-body">
        <div className="d-flex align-items-center">
          <Icon
            className="font-size-1-5 text-primary mr-2 "
            iconType="Person"
          />
          <LinkArrowExternal to={Attendee.PublicLink}>
            <h3 className="mb-0 font-weight-normal">
              {t("myprofile:profileProvada")}
            </h3>
          </LinkArrowExternal>
        </div>
      </div>
    )
  );
});

const MyProfile = React.memo(({ match: { url } }) => {
  const { t } = useTranslation(["common", "myprofile"]);
  const { ID } = useUser();
  const Attendee = useAttendee();

  const { data } = useQuery(READ_ONE_SILVERSTRIPE_MEMBER, {
    variables: { ID },
  });

  const user = useMemo(
    () => get(data, "readOneSilverStripeMember") || userDefaultState,
    [data]
  );

  const UserTitle = useMemo(() => {
    let nextState = [user.Initials, user.Affix, user.Surname]
      .filter(Boolean)
      .join(" ");

    if (!!user.FirstName) {
      nextState += ` (${user.FirstName})`;
    }

    return nextState;
  }, [user]);

  return (
    <Fragment>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem>{t("profile:title")}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Company />
      </Breadcrumbs>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex mb-3">
                <div>
                  <div
                    className="background-image bg-1-1 bg-gray-20 w-5 mr-2"
                    style={{
                      backgroundImage: `url(${
                        user.Picture.AbsoluteLink || images.noimage
                      })`,
                    }}
                  />
                </div>
                <div className="flex-1 d-flex align-items-center">
                  <div className="flex-1">
                    <h2 className="mb-1">{UserTitle}</h2>
                    {!!user.Function && (
                      <h3 className="text-primary">{user.Function}</h3>
                    )}
                    {Attendee && Attendee.ID && <DaysBadge />}
                  </div>
                </div>
              </div>
              {<BiographyBlock user={user} />}

              {user.PublicProfile ? (
                <div className="d-flex align-items-center mb-2">
                  <Icon
                    className="text-leaf mr-1"
                    iconType="CheckmarkCircleFull"
                  />
                  <p className="mb-0">{t("myprofile:publicprofile")}</p>
                </div>
              ) : (
                <div className="d-flex align-items-center mb-2">
                  <Icon
                    className="text-danger mr-1"
                    iconType="CloseCircleFull"
                  />
                  <p className="mb-0">{t("myprofile:notpublicprofile")}</p>
                </div>
              )}

              {user.AvailableForMeetings ? (
                <div className="d-flex align-items-center">
                  <Icon
                    className="text-leaf mr-1"
                    iconType="CheckmarkCircleFull"
                  />
                  <p className="mb-0">{t("myprofile:availableformeetings")}</p>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <Icon
                    className="text-danger mr-1"
                    iconType="CloseCircleFull"
                  />
                  <p className="mb-0">
                    {t("myprofile:notavailableformeetings")}
                  </p>
                </div>
              )}
            </div>
            <AttendeeLinkBlock />
            {user.WorksAt !== null && (
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <Icon
                    className="font-size-1-5 text-primary mr-2 "
                    iconType="Business"
                  />
                  <h3 className="mb-0 font-weight-normal">{user.WorksAt}</h3>
                </div>
              </div>
            )}
            {user.PublicPhone !== null && (
              <div className="card-body">
                <a
                  href={`tel: ${user.PublicPhone}`}
                  className="d-flex align-items-center text-decoration-none"
                >
                  <Icon
                    className="font-size-1-5 text-primary mr-2 "
                    iconType="Phone"
                  />
                  <h3 className="mb-0 font-weight-normal">
                    {user.PublicPhone}
                  </h3>
                </a>
              </div>
            )}
            {user.PublicEmail !== null && (
              <div className="card-body">
                <a
                  href={`mailto:${user.PublicEmail}`}
                  className="d-flex align-items-center text-decoration-none"
                >
                  <Icon
                    className="font-size-1-5 text-primary mr-2"
                    iconType="Mail"
                  />
                  <h3 className="mb-0 font-weight-normal">
                    {user.PublicEmail}
                  </h3>
                </a>
              </div>
            )}
            {user.LinkedinLink !== null && (
              <div className="card-body d-flex align-center">
                <i className="font-size-1-5 text-primary mr-2 if-Linkedin"></i>
                <LinkArrowExternal to={user.LinkedinLink}>
                  <h3 className="mb-0 font-weight-normal">
                    {user.FirstName} {user.Surname}
                  </h3>
                </LinkArrowExternal>
              </div>
            )}
            <div className="card-body">
              <LinkArrow to={`${url}/edit/public`}>
                {t("myprofile:editpublicdata")}
              </LinkArrow>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h2>{t("myprofile:personaldata")}</h2>
              <p className="mb-0">{t("myprofile:description")}</p>
            </div>
            {user.FirstName !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("myprofile:firstname")}</p>
                <h3 className="mb-0 font-weight-normal">{user.FirstName}</h3>
              </div>
            )}
            {user.Initials !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("myprofile:initials")}</p>
                <h3 className="mb-0 font-weight-normal">{user.Initials}</h3>
              </div>
            )}
            {user.Affix !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("myprofile:affix")}</p>
                <h3 className="mb-0 font-weight-normal">{user.Affix}</h3>
              </div>
            )}
            {user.Surname !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("myprofile:lastname")}</p>
                <h3 className="mb-0 font-weight-normal">{user.Surname}</h3>
              </div>
            )}
            {user.Gender !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("myprofile:gender")}</p>
                <h3 className="mb-0 font-weight-normal">{user.Gender}</h3>
              </div>
            )}
            {user.Phone !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("myprofile:phone")}</p>
                <h3 className="mb-0 font-weight-normal">
                  <a
                    href={`tel:${user.Phone}`}
                    className="text-body text-decoration-none"
                  >
                    {user.Phone}
                  </a>
                </h3>
              </div>
            )}
            {user.Mobile !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("myprofile:mobile")}</p>
                <h3 className="mb-0 font-weight-normal">
                  <a
                    href={`tel:${user.Mobile}`}
                    className="text-body text-decoration-none"
                  >
                    {user.Mobile}
                  </a>
                </h3>
              </div>
            )}
            {user.Email !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("myprofile:email")}</p>
                <h3 className="mb-0 font-weight-normal">
                  <a
                    href={`mailto:${user.Email}`}
                    className="text-body text-decoration-none"
                  >
                    {user.Email}
                  </a>
                </h3>
              </div>
            )}
            {user.WorksAt !== null && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("common:company")}</p>
                <h3 className="mb-0 font-weight-normal">{user.WorksAt}</h3>
              </div>
            )}
            {(user.Street !== null ||
              user.Country !== null ||
              user.City !== null) && (
              <div className="card-body">
                <p className="mb-0 text-primary">{t("common:address")}</p>
                {user.Street !== null && (
                  <h3 className="mb-0 font-weight-normal">
                    {user.Street} {user.HouseNumber} {user.Addition}
                  </h3>
                )}
                {user.PostalCode !== null && (
                  <h3 className="mb-0 font-weight-normal">{user.PostalCode}</h3>
                )}
                {user.City !== null && (
                  <h3 className="mb-0 font-weight-normal"> {user.City}</h3>
                )}
                {user.Country !== null && (
                  <h3 className="mb-0 font-weight-normal">{user.Country}</h3>
                )}
              </div>
            )}
            <div className="card-body">
              <LinkArrow to={`${url}/edit/private`}>
                {t("myprofile:editpersonaldata")}
              </LinkArrow>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

const ProfileRoutes = React.memo(({ match: { path } }) => {
  return (
    <DefaultLayout>
      <Switch>
        <Route exact path={path} component={MyProfile} />
        <Route
          exact
          path={`${path}/edit/private`}
          component={PrivateProfileEdit}
        />
        <Route
          exact
          path={`${path}/edit/public`}
          component={PublicProfileEdit}
        />
        <Route
          exact
          path={`${path}/completion`}
          component={ProfileCompletion}
        />
        <Redirect to={path} />
      </Switch>
    </DefaultLayout>
  );
});

export default ProfileRoutes;

import React, { Fragment } from "react";

import classNames from "classnames";
import images from "../../../../images";

const defaultProps = {
  image: "",
  disabled: false,
  title: "",
  subtitle: "",
  headline: "",
};

const UserBlock = (props = defaultProps) => {
  const { image, disabled, title, subtitle, headline } = props;

  return (
    <Fragment>
      <div>
        <div
          className={classNames(
            "background-image bg-1-1 bg-gray-20 w-3-5 mr-2",
            { "image-grayscale": disabled }
          )}
          style={{
            backgroundImage: `url(${image || images.noimage})`,
          }}
        />
      </div>
      <div className="flex-1 d-flex align-items-center">
        <div className="flex-1">
          <p
            className={classNames("text-primary font-weight-bold mb-0", {
              "text-decoration-line-through": disabled,
            })}
          >
            {headline}
          </p>
          <h3
            className={classNames("text-dark mb-0", {
              "text-decoration-line-through": disabled,
            })}
          >
            {title}
          </h3>
          {subtitle && (
            <p
              className={classNames("text-gray mt-1 mb-0", {
                "text-decoration-line-through": disabled,
              })}
            >
              {subtitle}
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UserBlock;

import React, { useMemo } from "react";

import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import queryString from "query-string";

dayjs.extend(utc);
dayjs.extend(timezone);

const formatTime = function (date) {
  return date.toISOString().replace(/-|:|\.\d+/g, "");
};

const defaultProps = {
  startTime: undefined,
  endTime: undefined,
  timezone: "Europe/Amsterdam",
  title: undefined,
  description: undefined,
  location: undefined,
};

export const getGoogleURL = (props = defaultProps) => {
  const startTime = formatTime(
    dayjs(props.startTime).tz(props.timezone).toDate()
  );
  const endTime = formatTime(dayjs(props.endTime).tz(props.timezone).toDate());

  return queryString.stringifyUrl(
    {
      url: "https://www.google.com/calendar/render",
      query: {
        action: "TEMPLATE",
        text: props.description,
        dates: [startTime, endTime].filter(Boolean).join("/"),
        timezone: props.timezone,
        details: props.description,
        location: props.location,
        sprop: "name:",
      },
    },
    { skipNull: true }
  );
};

export const getOutlookLiveURL = (props = defaultProps) => {
  const startTime = dayjs(props.startTime).tz(props.timezone).toISOString();
  const endTime = dayjs(props.endTime).tz(props.timezone).toISOString();

  return queryString.stringifyUrl(
    {
      url: "https://outlook.live.com/calendar/0/action/compose",
      query: {
        rru: "addevent",
        subject: props.title,
        startdt: startTime,
        enddt: endTime,
        body: props.description,
        location: props.location,
      },
    },
    { skipNull: true }
  );
};

export const getOffice365URL = (props = defaultProps) => {
  const startTime = dayjs(props.startTime).tz(props.timezone).toISOString();
  const endTime = dayjs(props.endTime).tz(props.timezone).toISOString();

  return queryString.stringifyUrl(
    {
      url: "https://outlook.office365.com/owa/",
      query: {
        path: "/calendar/action/compose",
        rru: "addevent",
        subject: props.title,
        startdt: startTime,
        enddt: endTime,
        body: props.description,
        location: props.location,
      },
    },
    { skipNull: true }
  );
};

export const getYahooURL = (props = defaultProps) => {
  const startTime = dayjs(props.startTime).tz(props.timezone);
  const endTime = dayjs(props.endTime).tz(props.timezone);

  const st = formatTime(startTime.toDate());

  const durationMinutes = endTime.diff(startTime, "minutes");

  const hourDuration =
    durationMinutes < 600
      ? "0" + Math.floor(durationMinutes / 60)
      : Math.floor(durationMinutes / 60) + "";

  const minuteDuration =
    durationMinutes % 60 < 10
      ? "0" + (durationMinutes % 60)
      : (durationMinutes % 60) + "";

  const duration = hourDuration + minuteDuration;

  return queryString.stringifyUrl(
    {
      url: "http://calendar.yahoo.com/",
      query: {
        st,
        v: 60,
        view: "d",
        type: 20,
        dur: duration,
        desc: props.description,
        title: props.title,
        in_loc: props.location,
      },
    },
    { skipNull: true }
  );
};

export const getICSUrl = (props = defaultProps) => {
  const startTime = formatTime(
    dayjs(props.startTime).tz(props.timezone).toDate()
  );
  const endTime = formatTime(dayjs(props.endTime).tz(props.timezone).toDate());

  const cal = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    "URL:" + window.document.URL,
    "DTSTART:" + startTime,
    "DTEND:" + endTime,
    "SUMMARY:" + props.title,
    "DESCRIPTION:" + props.description,
    "LOCATION:" + props.location,
    "UID:" + "-" + window.document.URL,
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");

  const href = encodeURI("data:text/calendar;charset=utf8," + cal);

  return href;
};

const ButtonCalendar = (props = defaultProps) => {
  return (
    <div className="d-flex flex-row">
      <a
        target="_blank"
        rel="noopener noreferrer"
        title="Google Calendar"
        className="text-decoration-none mr-2"
        href={getGoogleURL(props)}
      >
        <i className="text-gray font-size-1-5 if-Calendar-google" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        title="MS Outlook Live"
        className="text-decoration-none mr-2"
        href={getOutlookLiveURL(props)}
      >
        <i className="text-gray font-size-1-5 if-Outlook" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        title="MS Office 365"
        className="text-decoration-none mr-2"
        href={getOffice365URL(props)}
      >
        <i className="text-gray font-size-1-5 if-Microsoft-office" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        title="Yahoo! Calendar"
        className="text-decoration-none mr-2"
        href={getYahooURL(props)}
      >
        <i className="text-gray font-size-1-5 if-Yahoo" />
      </a>

      <a
        className="text-decoration-none"
        download="Calendar-event.ics"
        title="Download iCal"
        href={getICSUrl(props)}
      >
        <i className="text-gray font-size-1-5 if-Calendar-iOS" />
      </a>
    </div>
  );
};

export default ButtonCalendar;

// <span class="start d-none">$Date.format(MM/dd/y) $Start.format(HH:mm)</span>
// <span class="end d-none">$Date.format(MM/dd/y) $End.format(HH:mm)</span>
// <span class="timezone d-none">Europe/Amsterdam</span>
// <span class="title d-none">$Title</span>
// <span class="description d-none">$Description.Summary</span>
// <span class="location d-none">$Event.Title<% if $LocationTitle %>, $LocationTitle<% end_if %></span>

import React from "react";

/* packages */
import dayjs from "dayjs";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

/* components */
import Icon from "../../components/Icon";

/* context */
import { useReservation } from "../../contexts/RoomReservationContext";
import { useCalendarLocation } from "../../contexts/CalendarContext";

/* lib */
import { round, updateQueryString } from "../../lib/common";

/* const */
import { BOOKING_PERIOD } from "../../utils/globals";

const ReservationBanner = React.memo(() => {
  const { t } = useTranslation(["roomReservationCalendar"]);

  const {
    location: { search, pathname },
    push,
  } = useHistory();

  const reservation = useReservation();
  const location = useCalendarLocation(reservation.LocationID);

  if (
    !(
      !!reservation &&
      !!reservation.Start &&
      !!reservation.End &&
      !reservation.ID
    )
  ) {
    return null;
  }

  function onReservationClear() {
    const params = {
      End: null,
      Start:
        dayjs(reservation.Start)
          .startOf("day")
          .add(BOOKING_PERIOD, "minute")
          .unix() * 1000,
    };

    //! Clear teh query params
    return push({ search: updateQueryString({ search, params }) });
  }

  const { Start, End } = reservation;
  const { Title, Price, Places } = location;

  const hours = Math.max(dayjs(End).diff(dayjs(Start), "hour"), 1);

  return (
    <div className="calendar-card right-0 bottom-0 left-0 bg-white z-index-1 box-shadow rounded-lg-top">
      <div className="card-body bg-primary d-flex justify-content-between align-items-center p-2">
        <Icon
          className="text-white font-size-1-5 flex-center size-40"
          iconType="Close"
          onClick={onReservationClear}
        />
        <h1 className="text-white mb-0">{Title}</h1>
        <Icon
          className="text-white font-size-1-5 flex-center size-40"
          iconType="Check"
          onClick={() => push(`${pathname}/reservation`)}
        />
      </div>

      <div className="flex-1 px-5 py-3">
        <h3 className="font-weight-normal">
          {`${dayjs(Start).format("dddd HH:mm")} - ${dayjs(End).format(
            "HH:mm"
          )}`}
        </h3>
        {!!Places && (
          <p className="text-primary">
            {t("roomReservationCalendar:label:numberOfSeats", {
              count: Places,
            })}
          </p>
        )}
        {false && (
          <p className="text-primary mb-0">
            {/* //! @todo Room type or something  */}
            {`presentatieopstelling`}
          </p>
        )}
        {!!Price && (
          <p className="text-primary">
            {t("roomReservationCalendar:label.priceFrom", {
              price: round(round(Price) * hours),
            })}
          </p>
        )}
      </div>
    </div>
  );
});

export default ReservationBanner;

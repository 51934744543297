import React, { Fragment, useMemo } from "react";

import dayjs from "dayjs";
import classNames from "classnames";

import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import {
  AgendaMeetingProvider,
  useMeeting,
} from "../../../../contexts/AgendaContext";
import { useEvent } from "../../../../contexts/EventContext";

import ButtonCalendar from "../../../../components/ButtonCalendar";
import LinkArrowExternal from "../../../../components/LinkArrowExternal";

import StatusBadge from "../../Meeting/Status";

import MeetingAccept from "../../Meeting/Form/MeetingAccept";
import MeetingCancel from "../../Meeting/Form/MeetingCancel";
import MeetingReschedule from "../../Meeting/Form/MeetingReschedule";
import MeetingAcceptReschedule from "../../Meeting/Form/MeetingAcceptReschedule";

import UserBlock from "./UserBlock";

const ReplySection = React.memo(() => {
  const {
    Meeting: { Status, SuggestedTimes, Description, DeclineReason },
    isOwner,
  } = useMeeting();

  return (
    <Fragment>
      {Status === -1 &&
        ((!isOwner && !SuggestedTimes.length) ||
          (isOwner && !!SuggestedTimes.length)) && (
          <div className="bg-primary-10 p-3 mt-3 mb-3">
            <p className="text-dark">{DeclineReason || Description}</p>
          </div>
        )}

      <MeetingAcceptReschedule />
      <MeetingAccept />

      {Status === -1 &&
        ((!isOwner && !SuggestedTimes.length) ||
          (isOwner && !!SuggestedTimes.length)) && (
          <Fragment>
            <MeetingCancel />
            <MeetingReschedule />
          </Fragment>
        )}
    </Fragment>
  );
});

// ["2020-06-16 00:15:00","2020-06-17 00:30:00","2020-06-18 00:45:00"]
const MeetingInfoSection = React.memo(() => {
  const { t } = useTranslation(["meeting"]);

  const {
    Meeting: { ID, RequestedBy, MeetingWith, Status },
    isOwner,
  } = useMeeting();

  const {
    location: { pathname },
  } = useHistory();

  const CompanyTitle = useMemo(
    () =>
      [
        get(isOwner ? MeetingWith : RequestedBy, "Member.Function"),
        get(isOwner ? MeetingWith : RequestedBy, "Company.Title"),
      ]
        .filter(Boolean)
        .join(" - "),
    [MeetingWith, RequestedBy, isOwner]
  );

  const userImage = useMemo(
    () =>
      get(isOwner ? MeetingWith : RequestedBy, "Member.Picture.AbsoluteLink"),
    [MeetingWith, RequestedBy, isOwner]
  );

  return (
    <Fragment>
      <Link
        className="d-flex py-2 flex-wrap text-decoration-none"
        to={`${pathname}/meeting/${ID}`}
      >
        <UserBlock
          image={userImage}
          headline={get(
            isOwner ? MeetingWith : RequestedBy,
            "Member.FullName",
            ""
          )}
          title={get(
            isOwner ? MeetingWith : RequestedBy,
            "Member.FullName",
            ""
          )}
          subtitle={CompanyTitle}
          disabled={Status === 0}
        />
      </Link>
      <LinkArrowExternal
        to={get(isOwner ? MeetingWith : RequestedBy, "PublicLink", "")}
      >
        {t("agenda:meeting.button.public_link")}
      </LinkArrowExternal>
    </Fragment>
  );
});

const MeetingCard = React.memo(() => {
  const { t } = useTranslation([]);

  const Event = useEvent();

  const {
    Meeting: { Status, DateTime, Description, MeetingWith, RequestedBy },
    isOwner,
  } = useMeeting();

  const title = useMemo(() => {
    const start = t(`meeting:meetingRequest.MeetingWith`);

    const attendee = get(
      isOwner ? MeetingWith : RequestedBy,
      "Member.FullName",
      ""
    );

    return [start, attendee].filter(Boolean).join(" ");
  }, [MeetingWith, RequestedBy, isOwner, t]);

  return (
    <div
      className={classNames(
        "card-body position-relative overflow-hidden text-decoration-none px-4 pt-4",
        {
          "pb-2": Status === 1,
          "pb-4": Status !== 1,
        }
      )}
    >
      <StatusBadge />
      <MeetingInfoSection />
      {Status !== 0 && <ReplySection />}
      {Status === 1 && (
        <div className="d-flex flex-row align-items-center justify-content-between border-top pt-2 mt-2">
          <p className="text-gray mb-0">{t("meeting:button.addToCalendar")}</p>
          <ButtonCalendar
            title={title}
            description={Description}
            startTime={DateTime}
            endTime={dayjs(DateTime)
              .add(1, "hour")
              .format("YYYY-MM-DD HH:mm:ss")}
            timezone="Europe/Amsterdam"
            location={Event.Title}
          />
        </div>
      )}
    </div>
  );
});

const MeetingListItem = React.memo(({ item: Meeting }) => {
  return (
    <AgendaMeetingProvider value={{ Meeting }}>
      <div className="card w-100">
        <MeetingCard />
      </div>
    </AgendaMeetingProvider>
  );
});

export default MeetingListItem;

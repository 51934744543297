import React from "react";

const Electricity = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 80 80"
      {...props}
    >
      <path d="M36 25a11 11 0 10-11 11 11 11 0 0011-11zm-20 0a9 9 0 119 9 9 9 0 01-9-9z" />
      <path d="M19 27h4v2h-4z" />
      <path d="M27 27h4v2h-4z" />
      <path d="M23 20h4v2h-4z" />
      <path d="M25 44a11 11 0 1011 11 11 11 0 00-11-11zm0 20a9 9 0 119-9 9 9 0 01-9 9z" />
      <path d="M19 57h4v2h-4z" />
      <path d="M27 57h4v2h-4z" />
      <path d="M23 50h4v2h-4z" />
      <path d="M69 21h-1v-7a3 3 0 00-6 0v7h-2v-7a3 3 0 00-6 0v7h-1a1 1 0 00-1 1v12a5.006 5.006 0 005 5v2a3 3 0 003 3v21a3 3 0 01-3 3h-3a3 3 0 01-3-3V44a5.006 5.006 0 00-5-5h-6V13a3 3 0 00-3-3H13a3 3 0 00-3 3v54a3 3 0 003 3h24a3 3 0 003-3V41h6a3 3 0 013 3v21a5.006 5.006 0 005 5h3a5.006 5.006 0 005-5V44a3 3 0 003-3v-2a5.006 5.006 0 005-5V22a1 1 0 00-1-1zM12 37.388l4 1.333v2.558l-4 1.333zM37 68H13a1 1 0 01-1-1V44.721l5.316-1.772A1 1 0 0018 42v-4a1 1 0 00-.684-.949L12 35.279V13a1 1 0 011-1h24a1 1 0 011 1v22.279l-5.316 1.772A1 1 0 0032 38v4a1 1 0 00.684.949L38 44.721V67a1 1 0 01-1 1zm1-30.612v5.224l-4-1.333v-2.558zM64 14a1 1 0 012 0v7h-2zm-8 0a1 1 0 012 0v7h-2zm7 27a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2h4zm5-7a3 3 0 01-3 3h-8a3 3 0 01-3-3V23h14z" />
      <path d="M59 31h2.234l-2.091 3.485 1.714 1.03 3-5A1 1 0 0063 29h-1.919l2.7-3.375-1.562-1.25-4 5A1 1 0 0059 31z" />
    </svg>
  );
};

export default Electricity;

import React, { Fragment, useMemo } from "react";

/* packages */
import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";

/* graphql */
import { READ_ONE_SILVERSTRIPE_SITE_CONFIG } from "../graphql/FAQ";
import { getContactInfo } from "../lib/common";

const ContactInfo = React.memo(({ Email, Phone }) => (
  <Fragment>
    {!!Email.length && (
      <p>
        <a href={`mailto:${Email}`} className="text-decoration-none">
          {Email}
        </a>
      </p>
    )}
    {!!Phone.length && (
      <p>
        <a href={`tel:${Phone}`} className="text-decoration-none">
          {Phone}
        </a>
      </p>
    )}
  </Fragment>
));

const ContactBlock = React.memo(({ ContactPerson }) => {
  const { data: siteConfigData } = useQuery(READ_ONE_SILVERSTRIPE_SITE_CONFIG, {
    variables: { ID: 1 },
    fetchPolicy: "cache-and-network",
  });

  const { FullName, Email, Phone, Picture } = useMemo(
    () =>
      getContactInfo(
        ContactPerson,
        get(siteConfigData, "readOneSilverStripeSiteConfig.HelpContactPerson"),
        get(siteConfigData, "readOneSilverStripeSiteConfig")
      ),
    [ContactPerson, siteConfigData]
  );

  return (
    <Fragment>
      {!!FullName.length ? (
        <div className="d-flex">
          {!!Picture && (
            <div
              className="w-3-5 h-3-5 mr-2 rounded-circle background-image bg-gray-20"
              style={{ backgroundImage: `url(${Picture})` }}
            />
          )}
          <div className="flex-1">
            <h2>{FullName}</h2>
            <ContactInfo Email={Email} Phone={Phone} />
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-around">
          <ContactInfo Email={Email} Phone={Phone} />
        </div>
      )}
    </Fragment>
  );
});

export default ContactBlock;

import { Route } from "react-router-dom";

import Private from "./Private";
import EventGathering from "./EventGathering";

// @component ./Private.js
Route.Private = Private;

// @component ./EventGathering.js
Route.EventGathering = EventGathering;

export default Route;

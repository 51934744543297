import React from "react";

const AVRental = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <defs />
      <path d="M53.911 37.502a1.265 1.265 0 000-2.53H38.733a1.265 1.265 0 10-.002 2.53h15.18z" />
      <path d="M58.97 59.002a1.265 1.265 0 00-1.265 1.265V71.65a3.794 3.794 0 01-3.794 3.794H26.085a3.8 3.8 0 01-3.794-3.794V51.414a1.265 1.265 0 00-2.53-.002v20.24a6.332 6.332 0 006.324 6.324H53.91a6.331 6.331 0 006.324-6.324V60.267a1.265 1.265 0 00-1.264-1.265z" />
      <path d="M27.35 51.415a1.265 1.265 0 000 2.53h10.118a1.265 1.265 0 10.003-2.53H27.35z" />
      <path d="M52.646 57.739H33.674a1.265 1.265 0 000 2.53h18.972a1.265 1.265 0 000-2.53z" />
      <path d="M38.733 65.326a1.265 1.265 0 00-1.264-1.265H27.35a1.265 1.265 0 000 2.53h10.118a1.265 1.265 0 001.265-1.265z" />
      <path d="M53.91 65.326a1.265 1.265 0 00-1.264-1.265h-8.853a1.265 1.265 0 000 2.53h8.853a1.265 1.265 0 001.265-1.265z" />
      <path d="M27.35 69.122a1.265 1.265 0 000 2.53h7.59a1.265 1.265 0 10.002-2.53H27.35z" />
      <path d="M41.263 69.122a1.265 1.265 0 10-.002 2.53h7.591a1.265 1.265 0 10.003-2.53h-7.592z" />
      <path d="M34.94 41.295V31.177a6.331 6.331 0 00-6.324-6.324v-1.265a3.795 3.795 0 00-7.59 0v1.265H8.38a6.331 6.331 0 00-6.324 6.324v10.118a6.332 6.332 0 006.324 6.324h20.236a6.331 6.331 0 006.325-6.324zM23.557 23.588a1.265 1.265 0 112.53 0v1.265h-2.53zM4.585 41.295V31.177a3.794 3.794 0 013.794-3.794h20.236a3.794 3.794 0 013.795 3.794v10.118a3.794 3.794 0 01-3.794 3.794H8.378a3.8 3.8 0 01-3.793-3.794z" />
      <path d="M23.555 34.971a1.265 1.265 0 00-1.265 1.266 3.794 3.794 0 11-3.794-3.795 1.265 1.265 0 10.003-2.53h-.002a6.324 6.324 0 106.324 6.325 1.265 1.265 0 00-1.266-1.266z" />
      <path d="M9.643 41.296a1.265 1.265 0 11-1.265-1.265 1.265 1.265 0 011.265 1.265z" />
      <path d="M77.302 12.37a1.264 1.264 0 00-1.276.017L70.004 16h-3.445a6.317 6.317 0 00-4.637-6.073 5.07 5.07 0 10-8.571-.253h-3.466a3.751 3.751 0 00.232-1.265 3.794 3.794 0 10-6.472 2.688 6.3 6.3 0 00-2.255 3.639h-5.185a1.264 1.264 0 00-1.254 1.422l.632 5.059a1.265 1.265 0 002.509-.314l-.454-3.637h3.626v7.589h-3.795a1.265 1.265 0 000 2.53h3.922a6.336 6.336 0 006.2 5.059h12.648a6.331 6.331 0 006.324-6.324h3.443l6.024 3.613a1.264 1.264 0 001.915-1.084V13.47a1.264 1.264 0 00-.643-1.101zM55.175 7.147a2.53 2.53 0 112.53 2.53 2.53 2.53 0 01-2.53-2.53zm-8.852 0a1.265 1.265 0 11-1.265 1.265 1.265 1.265 0 011.264-1.265zM64.03 26.119a3.794 3.794 0 01-3.795 3.794H47.589a3.8 3.8 0 01-3.795-3.794V16a3.794 3.794 0 013.795-3.794h12.647A3.794 3.794 0 0164.03 16zm2.53-7.59h2.53v5.06h-2.53zm8.852 7.886l-3.793-2.276V17.98l3.793-2.277z" />
      <path d="M57.706 14.736h-7.59a1.265 1.265 0 000 2.53h7.59a1.265 1.265 0 011.265 1.265v2.53a1.265 1.265 0 01-1.265 1.265h-7.59a1.265 1.265 0 000 2.53h7.59a3.794 3.794 0 003.794-3.794v-2.53a3.794 3.794 0 00-3.792-3.796z" />
      <path d="M66.004 36.45a1.266 1.266 0 00-1.18-.127L52.401 41.29h-8.61a1.265 1.265 0 10-.002 2.53h7.591v7.589h-7.589v-2.53a1.265 1.265 0 10-2.53-.003v2.533a2.53 2.53 0 002.53 2.53h8.61l12.421 4.969a1.264 1.264 0 001.736-1.175V37.502a1.264 1.264 0 00-.555-1.052zm-1.975 19.416l-10.117-4.048v-8.406l10.117-4.047z" />
      <path d="M70.352 41.295a1.269 1.269 0 00.7-.212l3.794-2.53a1.265 1.265 0 00-1.4-2.1l-3.794 2.53a1.265 1.265 0 00.7 2.317z" />
      <path d="M71.055 52.895a1.265 1.265 0 10-1.4 2.1l3.794 2.53a1.265 1.265 0 001.4-2.1z" />
      <path d="M76.677 45.09h-5.06a1.265 1.265 0 000 2.53h5.06a1.265 1.265 0 000-2.53z" />
    </svg>
  );
};

AVRental.defaultProps = {
  className: "",
};

export default AVRental;

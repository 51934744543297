import React from 'react';

import classNames from 'classnames';

const Badge = ({ children, disabled, primary, secondary, leaf, className }) => {
  const badgeClass = classNames(
    'badge',
    {
      'badge-leaf': leaf,
      'badge-primary': primary,
      'badge-disabled': disabled,
      'badge-secondary': secondary,
    },
    className,
  );

  return (
    <span className={badgeClass}>
      {children}
    </span>
  )
}

Badge.defaultProps = {
  children: '',

  leaf: false,
  primary: true,
  disabled: false,
  secondary: false
}

export default Badge;

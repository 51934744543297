import React, { useMemo, useContext } from "react";

/* packages */
import get from "lodash/get";
import map from "lodash/map";
import { useQuery } from "@apollo/react-hooks";

/** contexts */
import { useUser } from "./UserContext";
import { useEvent } from "./EventContext";

/** query */
import {
  QUERY_EVENT_ATTENDEES,
  QUERY_ATTENDEE_EXHIBITOR,
  GET_SUB_EXHIBITORS,
} from "../graphql/User";
import { GET_EXHIBITOR_GATHERINGS } from "../graphql/Gathering";

import Loading from "../components/Loading";

const ExhibitorContext = React.createContext();

ExhibitorContext.displayName = "ExhibitorContext";

const { Provider } = ExhibitorContext;

export function useRole() {
  const { Attendee } = useContext(ExhibitorContext);

  return get(Attendee, "Role") || null;
}

export const useHasRole = (roles = []) => {
  const role = useRole();

  if (!role) {
    return false;
  }

  return roles.includes(role.Code);
};

export const useIsAttendee = () => {
  const attendee = useAttendee();

  return useMemo(
    () => !!(attendee && attendee.ID && !!parseInt(attendee.ID)),
    [attendee]
  );
};

export const useIsVisitor = () => useHasRole(["VISITOR"]);

export const useIsSpeaker = () => useHasRole(["SPEAKER"]);

export const useIsManager = () => useHasRole(["MANAGER"]);

export const useIsEmployee = () => useHasRole(["EMPLOYEE"]);

export const useIsMainExhibitor = () => {
  const Exhibitor = useAttendeeExhibitor();

  return !!Exhibitor.ID && !parseInt(get(Exhibitor, "MainExhibitor.ID"), 10);
};

export const useIsSubExhibitor = () => {
  const Exhibitor = useAttendeeExhibitor();

  return !!Exhibitor.ID && !!parseInt(get(Exhibitor, "MainExhibitor.ID"), 10);
};

export const useIsSubExhibitorEmployee = () => {
  const isSubExhibitor = useIsSubExhibitor();
  const isEmployee = useIsEmployee();

  return isSubExhibitor && isEmployee;
};

export const useIsSubExhibitorManager = () => {
  const isSubExhibitor = useIsSubExhibitor();
  const isManager = useIsManager();

  return isSubExhibitor && isManager;
};

export const useIsMainExhibitorEmployee = () => {
  const isMainExhibitor = useIsMainExhibitor();
  const isEmployee = useIsEmployee();

  return isMainExhibitor && isEmployee;
};

export const useIsMainExhibitorManager = () => {
  const isMainExhibitor = useIsMainExhibitor();
  const isManager = useIsManager();

  return isMainExhibitor && isManager;
};

export function useAttendee() {
  const { Attendee } = useContext(ExhibitorContext);

  return Attendee;
}

export function useAttendeeCompany() {
  const { Attendee } = useContext(ExhibitorContext);

  return get(Attendee, "Company");
}

export function useSubExhibitors() {
  const Event = useEvent();
  const Exhibitor = useAttendeeExhibitor();

  const { data: subExhibitorsData, loading: subExLoading } = useQuery(
    GET_SUB_EXHIBITORS,
    {
      skip: !Exhibitor.ID,
      variables: {
        Filter: {
          EventID__eq: Event.ID,
          MainExhibitorID__eq: Exhibitor.ID,
        },
      },
    }
  );

  const subExhibitors = map(
    get(subExhibitorsData, "readProvadaExhibitors.edges", []),
    "node"
  );

  return { subExhibitors, subExLoading };
}

export function useExhibitorGatherings() {
  const Event = useEvent();
  const Exhibitor = useAttendeeExhibitor();

  const { subExhibitors, subExLoading } = useSubExhibitors();

  const MainExhibitorID =
    parseInt(get(Exhibitor, "MainExhibitor.ID"), 10) || false;

  const Filter = useMemo(() => {
    if (MainExhibitorID) {
      return {
        ExhibitorID__in: [Exhibitor.ID, MainExhibitorID],
        EventID__eq: Event.ID,
      };
    }

    if (subExhibitors.length > 0) {
      return {
        ExhibitorID__in: [Exhibitor.ID, ...map(subExhibitors, ({ ID }) => ID)],
        EventID__eq: Event.ID,
      };
    }

    return {
      ExhibitorID__eq: Exhibitor.ID,
      EventID__eq: Event.ID,
    };
  }, [MainExhibitorID, subExhibitors, Exhibitor.ID, Event.ID]);

  // get all gatherings for the exhibitor, skipping if not exhibitor is set
  const { data: gatheringsData, loading: gatheringLoading } = useQuery(
    GET_EXHIBITOR_GATHERINGS,
    {
      skip: !Exhibitor.ID,
      fetchPolicy: "network-only",
      variables: {
        Filter,
      },
    }
  );

  // set gatherings data
  const gatherings = useMemo(
    () => get(gatheringsData, "readProvadaGatherings.edges", []),
    [gatheringsData]
  );

  return {
    gatherings,
    subExLoading,
    gatheringLoading,
  };
}

export function useAttendeeExhibitor() {
  const Attendee = useAttendee();
  const { Exhibitor: ExhibitorData } = useContext(ExhibitorContext);

  const Exhibitor = useMemo(() => {
    const isContractor = !!parseInt(get(ExhibitorData, "MainExhibitor.ID"), 10);

    // prettier-ignore
    const isContractorManager = isContractor && get(Attendee, "Role.Code") === "MANAGER";

    // prettier-ignore
    const isContractorEmployee = isContractor && get(Attendee, "Role.Code") === "EMPLOYEE";

    return {
      ...(ExhibitorData || {}),
      isContractor,
      isContractorManager,
      isContractorEmployee,
    };
  }, [Attendee, ExhibitorData]);

  return Exhibitor;
}

export const useExhibitorLocations = () => {
  const Exhibitor = useAttendeeExhibitor();

  return map(get(Exhibitor, "Locations.edges"), "node");
};

const ExhibitorProvider = React.memo(({ children }) => {
  const User = useUser();
  const Event = useEvent();

  const { data: AttendeesData, loading: loadingAttendees } = useQuery(
    QUERY_EVENT_ATTENDEES,
    {
      skip: !(!!User.ID && !!Event.ID),
      variables: {
        Filter: {
          MemberID__eq: User.ID,
          EventID__eq: Event.ID,
        },
      },
    }
  );

  const Attendee = useMemo(
    () => get(AttendeesData, "readProvadaAttendees.edges[0].node"),
    [AttendeesData]
  );

  const AttendeeExhibitor = useMemo(
    () => get(Attendee, "Exhibitor"),
    [Attendee]
  );

  const { data: ExhibitorData, loading: loadingExhibitor } = useQuery(
    QUERY_ATTENDEE_EXHIBITOR,
    {
      skip: !get(AttendeeExhibitor, "ID"),
      variables: {
        ID: get(AttendeeExhibitor, "ID"),
      },
    }
  );

  const Exhibitor = useMemo(
    () => get(ExhibitorData, "readOneProvadaExhibitor"),
    [ExhibitorData]
  );

  return (
    <Provider
      value={{
        Attendee,
        Exhibitor,

        attendee: Attendee,
        exhibitor: Exhibitor,
      }}
    >
      {!Event.ID ? (
        children
      ) : loadingExhibitor || loadingAttendees ? (
        <Loading />
      ) : (
        children
      )}
    </Provider>
  );
});

export { ExhibitorProvider };

export default ExhibitorContext;

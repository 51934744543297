import gql from "graphql-tag";

// Fragments
export const FRAGMENT_GATHERING = gql`
  fragment GatheringFragment on ProvadaGathering {
    ID
    Title
    Date
    Start
    End
    Type
    CurrentStage
    Participants
    MaxCapacity
    LocationTitle
    Attendees {
      pageInfo {
        totalCount
      }
    }
    Speakers {
      pageInfo {
        totalCount
      }
    }
  }
`;

export const SPEAKER_FRAGMENT = gql`
  fragment SpeakerFragment on ProvadaAttendee {
    ID
    Exhibitor {
      ID
      Title
    }
    Member {
      ID
      Email
      FullName
      Function
      WorksAt
      Picture {
        ID
        AbsoluteLink
      }
    }
  }
`;

// Queries
export const GET_EXHIBITOR_GATHERINGS = gql`
  query readProvadaGatherings($Filter: ProvadaGatheringFilterReadInputType) {
    readProvadaGatherings(
      Filter: $Filter
      Versioning: { Mode: LATEST }
      sortBy: [{ direction: ASC, field: Start }]
    ) {
      edges {
        node {
          ...GatheringFragment
          Version
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FRAGMENT_GATHERING}
`;

export const GET_GATHERING_INFO = gql`
  query readOneProvadaGathering($ID: ID!) {
    readOneProvadaGathering(ID: $ID, Versioning: { Mode: LATEST }) {
      ...GatheringFragment
      Description
      Language
      Registration
      RegisterOn
      Moderation
      StandID
      Featured
      PublicLink
      OrganizedByTitle
      Entertainment
      EntertainmentDesc
      Event {
        ID
      }
      Attendees {
        edges {
          node {
            ID
            Status
          }
        }
      }
      Exhibitor {
        ID
        Title
      }
      Tags {
        edges {
          node {
            ID
            Title
          }
        }
      }
      Speakers {
        edges {
          node {
            ID
            FullName
            Attendee {
              ID
            }
          }
        }
      }
      Moderator {
        ID
        Member {
          ID
          FullName
        }
      }
      Reservation {
        ID
        Location {
          ID
          Title
        }
      }
    }
  }
  ${FRAGMENT_GATHERING}
`;

export const QUERY_GATHERING_ENTERTAINMENT = gql`
  query readOneProvadaGathering($ID: ID!) {
    readOneProvadaGathering(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Entertainment
      EntertainmentDesc
    }
  }
`;

export const SEARCH_SPEAKERS = gql`
  query readProvadaAttendees($Filter: ProvadaAttendeeFilterReadInputType) {
    readProvadaAttendees(Filter: $Filter, Versioning: { Mode: LATEST }) {
      edges {
        node {
          ...SpeakerFragment
        }
      }
    }
  }
  ${SPEAKER_FRAGMENT}
`;

export const GET_GATHERING_MODERATOR = gql`
  query readOneProvadaGathering($ID: ID!) {
    readOneProvadaGathering(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Moderator {
        ID
        Member {
          ID
          FullName
        }
      }
    }
  }
`;

export const GET_GATHERING_SPEAKERS = gql`
  query readProvadaGatheringSpeakers(
    $Filter: ProvadaGatheringSpeakerFilterReadInputType
  ) {
    readProvadaGatheringSpeakers(
      Filter: $Filter
      Versioning: { Mode: LATEST }
    ) {
      edges {
        node {
          ID
          Attendee {
            ...SpeakerFragment
          }
          Gathering {
            ID
          }
        }
      }
    }
  }
  ${SPEAKER_FRAGMENT}
`;

export const GET_ALL_TAGS = gql`
  query readProvadaTags {
    readProvadaTags(Versioning: { Mode: LATEST }) {
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;

export const GET_GATHERING_ATTENDANCES = gql`
  query readProvadaGatheringAttendances(
    $Filter: ProvadaGatheringAttendanceFilterReadInputType
    $Versioning: VersionedInputType
  ) {
    readProvadaGatheringAttendances(Filter: $Filter, Versioning: $Versioning) {
      edges {
        node {
          ID
          Status
          Attendee {
            ID
            Member {
              ID
              FullName
              WorksAt
              Picture {
                ID
                AbsoluteLink
              }
            }
            Company {
              ID
              Title
            }
          }
        }
      }
    }
  }
`;

export const GATHERING_DATA_FOR_SIGNUP = gql`
  query readOneProvadaGathering($ID: ID!) {
    readOneProvadaGathering(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Title
      Registration
      PublicLink
      Event {
        ID
      }
    }
  }
`;

export const QUERY_GATHERING_RESERVATION = gql`
  query readProvadaReservations(
    $Filter: ProvadaReservationFilterReadInputType
  ) {
    readProvadaReservations(Filter: $Filter) {
      edges {
        node {
          ID
          Date
          Start
          End
          Location {
            ID
            Title
          }
        }
      }
    }
  }
`;

// Mutations
export const CREATE_NEW_GATHERING = gql`
  mutation createProvadaGathering($Input: ProvadaGatheringCreateInputType!) {
    createProvadaGathering(Input: $Input) {
      ...GatheringFragment
    }
  }
  ${FRAGMENT_GATHERING}
`;

export const DELETE_GATHERING = gql`
  mutation deleteProvadaGathering($ID: ID!) {
    deleteProvadaGathering(ID: $ID) {
      ID
    }
  }
`;

export const UPDATE_GATHERING = gql`
  mutation updateProvadaGathering($Input: ProvadaGatheringUpdateInputType!) {
    updateProvadaGathering(Input: $Input) {
      ...GatheringFragment
    }
  }
  ${FRAGMENT_GATHERING}
`;

export const ADD_SPEAKER_TO_GATHERING = gql`
  mutation createProvadaGatheringSpeaker(
    $Input: ProvadaGatheringSpeakerCreateInputType!
  ) {
    createProvadaGatheringSpeaker(Input: $Input) {
      ID
    }
  }
`;

export const REMOVE_SPEAKER = gql`
  mutation deleteProvadaGatheringSpeaker($IDs: [ID]!) {
    deleteProvadaGatheringSpeaker(IDs: $IDs) {
      ID
    }
  }
`;

export const UPDATE_GATHERING_ATTENDANCES = gql`
  mutation updateProvadaGatheringAttendance(
    $Input: ProvadaGatheringAttendanceUpdateInputType!
  ) {
    updateProvadaGatheringAttendance(Input: $Input) {
      ID
    }
  }
`;

export const GATHERING_SIGNUP = gql`
  mutation applyForProvadaGathering($GatheringID: Int!) {
    applyForProvadaGathering(GatheringID: $GatheringID) {
      ID
    }
  }
`;

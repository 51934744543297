import React from 'react';

const Calendar = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" className={`${className}`}>
      <path d="M70.01 48.6a1.003 1.003 0 001-1V20.41a8.519 8.519 0 00-8.5-8.5H62v-1.27a1.737 1.737 0 00-1.73-1.73h-2.54A1.737 1.737 0 0056 10.64v1.27H43v-1.27a1.737 1.737 0 00-1.73-1.73h-2.54A1.737 1.737 0 0037 10.64v1.27H24v-1.27a1.737 1.737 0 00-1.73-1.73h-2.54A1.737 1.737 0 0018 10.64v1.27h-.49a8.512 8.512 0 00-8.5 8.5V62.6a8.506 8.506 0 008.5 8.5h37.5a1.04 1.04 0 00.7-.29l15-15a1.006 1.006 0 00.22-1.09.987.987 0 00-.92-.62h-7.5a8.506 8.506 0 00-8.5 8.5v6.5h-36.5a6.506 6.506 0 01-6.5-6.5V20.41a6.506 6.506 0 016.5-6.5H18v1.27a1.73 1.73 0 001.73 1.73h2.54A1.73 1.73 0 0024 15.18v-1.27h13v1.27a1.73 1.73 0 001.73 1.73h2.54A1.73 1.73 0 0043 15.18v-1.27h13v1.27a1.73 1.73 0 001.73 1.73h2.54A1.73 1.73 0 0062 15.18v-1.27h.51a6.513 6.513 0 016.5 6.5V47.6a.997.997 0 001 1zm-14 14a6.506 6.506 0 016.5-6.5h5.08L56.01 67.69zM22 14.91h-2v-4h2zm19 0h-2v-4h2zm19 0h-2v-4h2z" />
      <path d="M25 26.51a2 2 0 00-2-2h-4a2 2 0 00-2 2v4a2.006 2.006 0 002 2h4a2.006 2.006 0 002-2zm-2 4h-4v-4h4zM37.67 26.51a2 2 0 00-2-2h-4a1.993 1.993 0 00-2 2v4a2 2 0 002 2h4a2.006 2.006 0 002-2zm-2 4h-4v-4h4zM63 26.51a2 2 0 00-2-2h-4a2 2 0 00-2 2v4a2.006 2.006 0 002 2h4a2.006 2.006 0 002-2zm-2 4h-4v-4h4zM50.33 26.51a1.993 1.993 0 00-2-2h-4a2 2 0 00-2 2v4a2.006 2.006 0 002 2h4a2 2 0 002-2zm-2 4h-4v-4h4zM25 39.51a2 2 0 00-2-2h-4a2 2 0 00-2 2v4a2.006 2.006 0 002 2h4a2.006 2.006 0 002-2zm-2 4h-4v-4h4zM37.67 39.51a2 2 0 00-2-2h-4a1.993 1.993 0 00-2 2v4a2 2 0 002 2h4a2.006 2.006 0 002-2zm-2 4h-4v-4h4zM61 37.51h-4a2 2 0 00-2 2v4a2.006 2.006 0 002 2h4a2.006 2.006 0 002-2v-4a2 2 0 00-2-2zm0 6h-4v-4h4zM50.33 39.51a1.993 1.993 0 00-2-2h-4a2 2 0 00-2 2v4a2.006 2.006 0 002 2h4a2 2 0 002-2zm-2 4h-4v-4h4zM23 50.51h-4a2 2 0 00-2 2v4a2.006 2.006 0 002 2h4a2.006 2.006 0 002-2v-4a2 2 0 00-2-2zm0 6h-4v-4h4zM35.67 50.51h-4a1.993 1.993 0 00-2 2v4a2 2 0 002 2h4a2.006 2.006 0 002-2v-4a2 2 0 00-2-2zm0 6h-4v-4h4zM48.33 50.51h-4a2 2 0 00-2 2v4a2.006 2.006 0 002 2h4a2 2 0 002-2v-4a1.993 1.993 0 00-2-2zm0 6h-4v-4h4z" />
    </svg>
  )
}

Calendar.defaultProps = {
  className: ''
};

export default Calendar;
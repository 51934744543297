import React, { useMemo, } from 'react';

/* packages */
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { get, map } from 'lodash';

import DefaultLayout from '../../layout/DefaultLayout';

/* components */
import Breadcrumbs from '../../components/Breadcrumbs';
import LinkArrowExternal from '../../components/LinkArrowExternal';

/* context */
import { useEvent } from '../../contexts/EventContext';

/* lib */
import { getIconForExtension } from '../../lib/common';

/* graphql */
import { QUERY_EVENT_DOCUMENTS } from '../../graphql/Event';

const DocumentsPage = React.memo(() => {
  const { t } = useTranslation(['dashboard']);

  const Event = useEvent();

  const { data: EventDocumentsData, loading: loadingDocuments } = useQuery(QUERY_EVENT_DOCUMENTS, {
    skip: !Event.ID,
    variables: {
      ID: Event.ID
    }
  });

  const Documents = useMemo(() => map(get(EventDocumentsData, 'readOneProvadaEvent.Documents.edges'), 'node'), [EventDocumentsData]);

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/help">
            {t('dashboard:help.title')}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>
            {t('dashboard:documents.title')}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>

      <div className="card">

        <div className="card-body">
          <div className="d-flex align-items-start justify-content-between">
            <h1 className="flex-1">
              {t('dashboard:documents.title')}
            </h1>
          </div>
          <p className="mb-0">
            {t('dashboard:documents.description')}
          </p>
        </div>

        {
          (!!Event.ID && !loadingDocuments && !Documents.length) && (
            <div className="card-body">
              <p className="mb-0">
                {`No documents`}
              </p>
            </div>
          )
        }

        {
          Documents.map(({ ID, Title, File: { Size, AbsoluteLink, Extension } }) => {
            // const FileType = AbsoluteLink.split('.').pop().toUpperCase();
            const FileType = (Extension || "").toUpperCase();
            
            return (
              <div className="card-body" key={ID}>
                <div key={ID} className="d-flex">
                  <div className="mr-3">
                    <i className={`font-size-1-5 text-primary ${getIconForExtension(Extension)}`} />
                  </div>
                  <div className="flex-1">
                    <h2>
                      {Title}
                    </h2>
                    <p>
                      {t('common:fileInfo', { FileType, Size })}
                    </p>
                    <LinkArrowExternal to={AbsoluteLink}>
                      {t('common:download')}
                    </LinkArrowExternal>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

    </DefaultLayout>
  );
});

export default DocumentsPage;

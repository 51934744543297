import React from 'react';

/* packages */
import { useTranslation } from 'react-i18next';

/* context */
import { useMeetingAcceptMutation, useMeeting } from '../../../../contexts/AgendaContext';

const MeetingAcceptForm = React.memo(() => {
  const { t } = useTranslation();

  const [onAccept, { loading }] = useMeetingAcceptMutation();

  function onBeforeAccept(e) {
    e.preventDefault();

    if (loading) {
      return;
    }

    return onAccept();
  }

  return (
    <div className="">
      <button
        className="btn btn-primary"
        type="button"
        onClick={onBeforeAccept}
      >
        {loading && <span className="spinner-border spinner-border-sm mr-2 mb-1" role="status" aria-hidden="true" />}
        {t("meeting:form.accept.button.submit")}
      </button>
    </div>
  );
});

const MeetingAcceptFormWrapper = React.memo((props) => {
  const { Meeting: { Status, SuggestedTimes }, isOwner, isEdited } = useMeeting();

  // ! Cant accept if its not pending
  if (Status !== -1) {
    return null;
  }

  // ! Cant accept if your owner of the meeting, you can only accept reschedule
  if (isOwner) {
    return null;
  }

  // ! Cant accept if meeting is edited or has SuggestedTimes
  if (!!SuggestedTimes.length || isEdited) {
    return null
  }

  return (
    <MeetingAcceptForm {...props} />
  );
});

export default MeetingAcceptFormWrapper;

import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useSetState, useUpdateEffect } from "react-use/lib";

import { useTranslation } from "react-i18next";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";

import useDebounce from "react-use/lib/useDebounce";

import classNames from "classnames";

import _get from "lodash/get";
import _map from "lodash/map";
import _find from "lodash/find";

import { splitAt } from "../../../lib/common";

import Icon from "../../../components/Icon";
import CardHeader from "../../../components/CardHeader";
import Notification from "../../../components/Notification";

import { useEvent } from "../../../contexts/EventContext";
import { useGathering } from "../../../contexts/GatheringContext";

import {
  GET_GATHERING_INFO,
  UPDATE_GATHERING,
  REMOVE_SPEAKER,
  GET_GATHERING_SPEAKERS,
  ADD_SPEAKER_TO_GATHERING,
  GET_GATHERING_MODERATOR,
  SEARCH_SPEAKERS,
} from "../../../graphql/Gathering";
import { GET_SUB_EXHIBITORS } from "../../../graphql/User";
import { useAttendeeExhibitor } from "../../../contexts/ExhibitorContext";

import { GatheringBreadcrumbs } from "../GatheringEdit";

const SearchSpeakers = React.memo(({ onChange, value, disabled }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  const onChangeValue = ({ target: { value: val } }) => onChange(val);

  return (
    <div className="form-group">
      <label htmlFor="search-speaker" className="text-primary">
        {t("gatherings:addspeaker")}
      </label>
      <input
        id="search-speaker"
        type="text"
        value={value}
        className="form-control"
        placeholder={t("gatherings:search:fullname")}
        onChange={onChangeValue}
        disabled={disabled}
      />
    </div>
  );
});

SearchSpeakers.defaultProps = {
  value: "",
  onChange: () => null,
};

const PresentSpeakers = React.memo(({ speakers, removeSpeaker }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  if (!speakers.length) {
    return <p>{t("gatherings:nospeakersyet")}</p>;
  }

  return (
    <div className="form-group">
      {speakers.map((gatheringSpeaker) => {
        const {
          Remove,
          Attendee: {
            ID: AttendeeID,
            WorksAt,
            Member: { FullName, Picture },
            Exhibitor: { Title },
          },
        } = gatheringSpeaker;

        const AbsoluteLink = _get(Picture, "AbsoluteLink", "");

        const removeSpeakerItm = () => {
          removeSpeaker(gatheringSpeaker);
        };

        const iconType = Remove ? "RemoveCircleFull" : "Close";

        return (
          <div
            className="d-flex border-bottom py-2 last-of-type-border-none"
            key={`${AttendeeID}`}
            onClick={removeSpeakerItm}
          >
            <div>
              <div
                className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
                style={{ backgroundImage: `url(${AbsoluteLink})` }}
              ></div>
            </div>
            <div className="flex-1 d-flex align-items-center">
              <div className="flex-1">
                <h3 className="mb-1">{FullName}</h3>
                <p className="mb-0 text-primary">{WorksAt ? WorksAt : Title}</p>
              </div>
              <Icon
                className={classNames("text-primary font-size-1-5", {
                  "text-danger": Remove,
                })}
                iconType={iconType}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
});

const Moderator = React.memo(({ speakers, moderator, setModerator }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  if (!speakers.length) {
    return <p>{t("gatherings:choosespeakersfirst")}</p>;
  }

  const moderatorChanged = (e) => {
    const moderator = _find(speakers, (speaker) => {
      return (
        parseInt(speaker.Attendee.ID, 10) ===
        parseInt(e.currentTarget.value, 10)
      );
    });

    const attendee = _get(moderator, "Attendee");
    if (attendee && attendee.ID) {
      return setModerator({
        ID: attendee.ID,
        FullName: _get(attendee, "Member.FullName", initModerator.FullName),
      });
    }

    return setModerator(initModerator);
  };

  return (
    <select
      className="form-control"
      id="moderator-update"
      value={moderator.ID || 0}
      onChange={moderatorChanged}
    >
      <option value={0}>{t("gatherings:nomoderator")}</option>
      {speakers.map(
        ({
          Attendee: {
            ID,
            Member: { FullName },
          },
        }) => {
          return (
            <option value={ID} key={ID}>
              {FullName}
            </option>
          );
        }
      )}
    </select>
  );
});

const SpeakerResults = React.memo(({ search, results, addSpeaker }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  if (!results.length) {
    return <p>{t("common:noresults")}</p>;
  }

  return results.map((speaker) => {
    const {
      node: {
        ID,
        Exhibitor: { Title },
        Member: { WorksAt, FullName, Picture },
      },
    } = speaker;

    const Index = (FullName || "").toLowerCase().indexOf(search.toLowerCase());
    const [first, searched, second] = splitAt(Index, search)(FullName);

    const AbsoluteLink = _get(Picture, "AbsoluteLink", "");

    const addSpeakerHandler = () => {
      addSpeaker(speaker);
    };

    return (
      <div
        className="d-flex border-bottom py-2 last-of-type-border-none"
        key={ID}
        onClick={addSpeakerHandler}
      >
        <div>
          <div
            className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
            style={{ backgroundImage: `url(${AbsoluteLink})` }}
          ></div>
        </div>
        <div className="flex-1 d-flex align-items-center">
          <div className="flex-1">
            <h3 className="mb-1">
              <span className="text-primary-50 font-weight-normal">
                {first}
              </span>
              <span
                className="text-primary-50 font-weight-bold"
                style={{ textDecoration: "underline" }}
              >
                {searched}
              </span>
              <span className="text-primary-50 font-weight-normal">
                {second}
              </span>
            </h3>
            <p className="mb-0 text-primary">{WorksAt ? WorksAt : Title}</p>
          </div>
          <Icon className="text-primary font-size-1-5" iconType="Add" />
        </div>
      </div>
    );
  });
});

SpeakerResults.defaultProps = {
  results: [],
  search: "",
  addSpeaker: () => null,
};

const initModerator = {
  ID: 0,
  Title: "",
};

const GatheringSpeakers = React.memo(({ history: { push, goBack } }) => {
  // get needed information from contexts
  const { t } = useTranslation(["common", "gatherings"]);

  const event = useEvent();

  const { data: gathering } = useGathering();

  const { ID: exhibitorID } = useAttendeeExhibitor();

  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);

  const [speakers, setSpeakers] = useState([]);
  const [moderator, setModerator] = useSetState(initModerator);

  useQuery(GET_GATHERING_SPEAKERS, {
    skip: !gathering.ID,
    variables: {
      Filter: {
        GatheringID__eq: gathering.ID,
      },
    },
    onCompleted: ({ readProvadaGatheringSpeakers: { edges } }) => {
      const speakersArr = edges.map(({ node: speaker }) => speaker);
      setSpeakers(speakersArr);
    },
  });

  const { data: currentlySetMod } = useQuery(GET_GATHERING_MODERATOR, {
    skip: !gathering.ID,
    variables: {
      ID: gathering.ID,
    },
    onCompleted: ({ readOneProvadaGathering: { Moderator: Mod } }) => {
      const ID = _get(Mod, "ID", initModerator.ID);
      if (ID !== moderator.ID) {
        setModerator({
          ID: ID,
          FullName: _get(Mod, "Member.FullName") || initModerator.Title,
        });
      }
    },
  });

  const currentModeratorID = useMemo(
    () =>
      _get(
        currentlySetMod,
        "readOneProvadaGathering.Moderator.ID",
        initModerator.ID
      ),
    [currentlySetMod]
  );

  const addSpeaker = (speakerNode) => {
    setSearch("");

    const { node: speaker } = speakerNode;
    const exists = speakers.find(
      ({ Attendee: { ID } }) => parseInt(speaker.ID, 10) === parseInt(ID, 10)
    );
    if (exists) {
      const newSpeakers = speakers.map((itm) => {
        if (parseInt(speaker.ID, 10) === parseInt(itm.ID, 10)) {
          return {
            ...itm,
            Add: !itm.Add,
          };
        }

        return itm;
      });

      return setSpeakers(newSpeakers);
    }

    // since speakers are differnt object that attendee, missing ID prop and Attendee subobj
    const speakerAdd = {
      ID: null,
      Add: true,
      Attendee: {
        ...speaker,
      },
    };

    setSpeakers([...speakers, speakerAdd]);
  };

  const removeSpeaker = (speaker) => {
    // if no ID set we need to remove it from speakers
    if (!speaker.ID) {
      const newSpeakers = speakers.filter(
        ({ Attendee: { ID } }) => ID !== speaker.Attendee.ID
      );
      return setSpeakers(newSpeakers);
    }

    // if there is, toggle remove
    const newSpeakers = speakers.map((itm) => {
      if (parseInt(speaker.ID, 10) === parseInt(itm.ID, 10)) {
        return {
          ...itm,
          Remove: !itm.Remove,
        };
      }

      return itm;
    });

    setSpeakers(newSpeakers);
  };

  // save speakers on form submit (top right checkmark)
  const [saveModerator, { loading: savingGathering }] = useMutation(
    UPDATE_GATHERING,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_GATHERING_MODERATOR,
          variables: {
            ID: gathering.ID,
          },
        },
      ],
    }
  );

  const [saveSpeaker, { loading: savingSpeakers }] = useMutation(
    ADD_SPEAKER_TO_GATHERING,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_GATHERING_INFO,
          variables: {
            ID: gathering.ID,
          },
        },
        {
          query: GET_GATHERING_SPEAKERS,
          variables: {
            Filter: {
              GatheringID__eq: gathering.ID,
            },
          },
        },
      ],
    }
  );

  const [removeSpeakers, { loading: removingSpeakers }] = useMutation(
    REMOVE_SPEAKER,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_GATHERING_SPEAKERS,
          variables: {
            Filter: {
              GatheringID__eq: gathering.ID,
            },
          },
        },
        {
          query: GET_GATHERING_INFO,
          variables: {
            ID: gathering.ID,
          },
        },
      ],
    }
  );

  useUpdateEffect(() => {
    if (!(savingGathering || savingSpeakers || removingSpeakers)) {
      goBack();
    }
  }, [savingGathering, savingSpeakers, removingSpeakers]);

  const onSubmit = () => {
    if (savingGathering || savingSpeakers || removingSpeakers) {
      return;
    }

    // add speakers
    const addSpeakerIDs = speakers
      .filter(({ Add }) => !!Add)
      .map(({ Attendee: { ID } }) => ID);
    if (addSpeakerIDs.length) {
      addSpeakerIDs.forEach((ID) => {
        saveSpeaker({
          variables: {
            Input: {
              GatheringID: gathering.ID,
              AttendeeID: ID,
              EventID: event.ID,
            },
          },
        });
      });
    }

    // remove speakers
    const removeSpeakerIDs = speakers
      .filter(({ Remove }) => !!Remove)
      .map(({ ID }) => ID);
    if (removeSpeakerIDs.length) {
      removeSpeakers({
        variables: {
          IDs: removeSpeakerIDs,
        },
      });
    }

    // in case user removed speaker from the list that was moderator, but didn't unset moderator, we must unset it manually
    const removeModeratorID = speakers
      .filter(
        ({ Remove, Attendee: { ID: AttendeeID } }) =>
          !!Remove && moderator.ID === AttendeeID
      )
      .map(({ Attendee: { ID: AttendeeID } }) => AttendeeID)[0];
    // also update moderator if current one is different than the one in state
    if (
      removeModeratorID === moderator.ID ||
      currentModeratorID !== moderator.ID
    ) {
      saveModerator({
        variables: {
          Input: {
            ID: gathering.ID,
            ModeratorID: removeModeratorID ? initModerator.ID : moderator.ID, // since moderator can be empty as well
          },
        },
      });
    }
  };

  useEffect(() => {
    if (search.length) {
      setSearching(true);
    }
  }, [search]);

  // collect all subexhibitors for gathering attendees
  const { data: subExhibitorsData, loading: loadingSubExhibitors } = useQuery(
    GET_SUB_EXHIBITORS,
    {
      skip: !exhibitorID,
      variables: {
        Filter: {
          MainExhibitorID__eq: exhibitorID,
        },
      },
    }
  );

  // save subexhibitor ids for searching attendees later
  const exhibitorIDs = useMemo(() => {
    const subExhibitorIDs =
      _get(subExhibitorsData, "readProvadaExhibitors.edges", []) || [];
    if (!subExhibitorIDs.length) {
      return [exhibitorID];
    }

    return [exhibitorID, ...subExhibitorIDs.map(({ node: { ID } }) => ID)];
  }, [subExhibitorsData]);

  // search for speakers - attendees
  const [makeSpeakerSearch, { data: searchedSpeakersData }] = useLazyQuery(
    SEARCH_SPEAKERS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        Filter: {
          ExhibitorID__in: exhibitorIDs,
          FullName__contains: search,
        },
      },
      onCompleted: () => {
        setSearching(false);
      },
    }
  );

  // found speakers
  const searchedSpeakers = useMemo(() => {
    const results =
      _get(searchedSpeakersData, "readProvadaAttendees.edges", []) || [];

    // if no results just return empty array
    if (!results.length) {
      return [];
    }

    // get current speakers, if nobody attached just return all results
    const currSpeakersIDs = _map(speakers, "Attendee.ID");
    if (!currSpeakersIDs.length) {
      return results;
    }

    // if there are speakers, remove attached speakers from the results
    return results.filter(({ node: { ID } }) => {
      return currSpeakersIDs.indexOf(ID) === -1;
    });
  }, [searchedSpeakersData]);

  // make a search when search string is changed
  useDebounce(
    () => {
      if (!search.length) {
        return;
      }

      makeSpeakerSearch();
    },
    1000,
    [search]
  );

  return (
    <Fragment>
      <GatheringBreadcrumbs />
      <div className="card">
        <CardHeader
          title={t("gatherings:speakers")}
          onDismiss={() => push(`/gathering/${gathering.ID}`)}
          onSubmit={onSubmit}
        />

        <div className="card-body">
          <SearchSpeakers
            value={search}
            onChange={setSearch}
            disabled={!!loadingSubExhibitors}
          />
          {!search.length && (
            <Fragment>
              <div className="form-group">
                <label className="text-primary">
                  {t("gatherings:addedspeakers")}
                </label>
                <PresentSpeakers
                  speakers={speakers}
                  removeSpeaker={removeSpeaker}
                />
              </div>

              <div className="form-group mb-0">
                <label className="text-primary" htmlFor="moderator-update">
                  {t("gatherings:moderator")}
                </label>
                <Moderator
                  speakers={speakers}
                  moderator={moderator}
                  setModerator={setModerator}
                />
              </div>
            </Fragment>
          )}
          {!!search.length && (
            <SpeakerResults
              search={search}
              results={searchedSpeakers}
              addSpeaker={addSpeaker}
            />
          )}
          <div className="d-flex mt-4">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={onSubmit}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </div>

      <Notification open={searching} content={t("common:loading")} />
      <Notification
        open={savingGathering || savingSpeakers || removingSpeakers}
        content={t("common:saving")}
      />
    </Fragment>
  );
});

export default GatheringSpeakers;

import React, { Fragment, useMemo, useState } from "react";

/* packages */
import classNames from "classnames";

import { get, map, filter, find } from "lodash";

import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import { splitAt } from "../../lib/common";

/* components */
import Icon from "../../components/Icon";
import CardHeader from "../../components/CardHeader";

/* context */
import { useUser } from "../../contexts/UserContext";
import { useEvent } from "../../contexts/EventContext";
import { useAttendeeRoles } from "../../contexts/AttendeeRolesContext";
import { useAttendeeExhibitor } from "../../contexts/ExhibitorContext";

/* graphql */
import {
  QUERY_ATTENDEES_SUGGESTIONS,
  QUERY_EXHIBITOR_ATTENDEES,
  MUTATION_REGISTER_ATTENDEE,
} from "../../graphql/Tickets";

const TicketsSearch = React.memo(({ history: { goBack, push } }) => {
  const { t } = useTranslation(["attendeeSearch"]);

  const { ID: UserID } = useUser();
  const { ID: EventID } = useEvent();

  const { Roles } = useAttendeeRoles();
  const { ID: ExhibitorID } = useAttendeeExhibitor();

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);

  const [onRegisterAttendee] = useMutation(MUTATION_REGISTER_ATTENDEE);

  const { data: SuggestionsData, loading } = useQuery(
    QUERY_ATTENDEES_SUGGESTIONS,
    {
      skip: !ExhibitorID,
      variables: { ExhibitorID },
      fetchPolicy: "cache-and-network",
    }
  );

  const Attendees = useMemo(
    () =>
      map(
        get(SuggestionsData, "readProvadaAttendeesSuggestions.edges", []),
        "node"
      ),
    [SuggestionsData]
  );

  const Suggestions = useMemo(() => {
    if (!search.length) {
      return Attendees;
    }

    const searchToLower = search.toLowerCase();

    const results = filter(
      Attendees,
      (item) =>
        (item["FullName"] || "").toLowerCase().indexOf(searchToLower) !== -1
    );

    // return sortBy(results, ({ ID }) => !selected.includes(ID));

    return results;
  }, [Attendees, search]);

  const isDisabled = useMemo(
    () => !(!loading && selected.length && !!Roles.length),
    [loading, selected, Roles]
  );

  function onSubmit(e) {
    e.preventDefault();

    if (isDisabled) {
      return goBack();
    }

    for (let i = 0; i < selected.length; i++) {
      const MemberID = selected[i];
      const Attendee = find(Attendees, { ID: MemberID });

      if (!Attendee) {
        return;
      }

      let options = {
        variables: {
          MemberID,
        },
      };

      //! @todo investigate on adding query on every option.
      if (i === selected.length - 1) {
        options = {
          ...options,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: QUERY_ATTENDEES_SUGGESTIONS,
              variables: {
                ExhibitorID,
              },
            },
            {
              query: QUERY_EXHIBITOR_ATTENDEES,
              variables: {
                Filter: {
                  EventID__eq: EventID,
                  ExhibitorID__eq: ExhibitorID,
                },
                Exclude: {
                  MemberID__eq: UserID, //! Important - Exclude currently logged in member
                },
              },
            },
          ],
        };
      }

      onRegisterAttendee(options).catch(() => null);
    }

    return push("/tickets");
  }

  const onChangeSearch = ({ target: { value } }) => setSearch(value);

  return (
    <Fragment>
      <div className="card">
        <CardHeader
          title={t("attendeeSearch:title")}
          onDismiss={() => push(`/tickets`)}
          onSubmit={onSubmit}
        />

        <div className="card-body">
          <div className="form-group">
            <label htmlFor="search" className="text-primary">
              {t("attendeeSearch:input.label.search")}
            </label>
            <input
              id="search"
              name="search"
              type="text"
              value={search}
              className="form-control"
              placeholder={t("attendeeSearch:input.placeholder.search")}
              onChange={onChangeSearch}
            />
          </div>

          <div className="form-group">
            <label className="text-primary">
              {t("attendeeSearch:label.suggestions")}
            </label>
            {Suggestions.map(({ ID, FullName, Company, Picture }) => {
              const Index = (FullName || "")
                .toLowerCase()
                .indexOf(search.toLowerCase());

              const [first, searched, second] = splitAt(
                Index,
                search
              )(FullName);

              const isSelected = selected.includes(ID);

              function onToggleSelected() {
                setSearch("");

                if (isSelected) {
                  return setSelected(selected.filter((key) => key !== ID));
                }

                return setSelected([...selected, ID]);
              }

              return (
                <div
                  key={ID}
                  className="d-flex border-bottom py-2 last-of-type-border-none"
                  onClick={onToggleSelected}
                >
                  <div>
                    <div
                      className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
                      style={{
                        backgroundImage: `url(${get(Picture, "AbsoluteLink")})`,
                      }}
                    />
                  </div>
                  <div className="flex-1 d-flex align-items-center">
                    <div className="flex-1">
                      <h3 className="mb-1">
                        {!search.length ? (
                          FullName
                        ) : (
                          <Fragment>
                            <span className="text-primary-50 font-weight-normal">
                              {first}
                            </span>
                            <span
                              className="text-primary-50 font-weight-bold"
                              style={{ textDecoration: "underline" }}
                            >
                              {searched}
                            </span>
                            <span className="text-primary-50 font-weight-normal">
                              {second}
                            </span>
                          </Fragment>
                        )}
                      </h3>
                      {!!Company && !!Company.Title && (
                        <p className="mb-0 text-primary">{Company.Title}</p>
                      )}
                    </div>
                    <Icon
                      iconType={isSelected ? "CheckmarkCircleFull" : "Add"}
                      className={classNames("text-primary font-size-1-5", {
                        "text-primary": !isSelected,
                        "text-success": isSelected,
                      })}
                    />
                  </div>
                </div>
              );
            })}
            {!!search.length && (
              <Link
                to={`/tickets/invite?Title=${search}`}
                className="d-flex border-bottom py-2 last-of-type-border-none text-decoration-none"
              >
                <div className="background-image bg-1-1 w-3-5 mr-2">
                  <i className="h1 text-dark absolute-center if-PersonAdd-iOS" />
                  {false && (
                    <Icon
                      iconType={"PersonAdd"}
                      className="h1 text-dark absolute-center"
                    />
                  )}
                </div>
                <div className="flex-1 d-flex align-items-center">
                  <div className="flex-1">
                    <h3
                      className="mb-1"
                      style={{ textDecoration: "underline" }}
                    >
                      {search}
                    </h3>
                    <p className="mb-0 text-primary">
                      {t("attendeeSearch:label.invite")}
                    </p>
                  </div>
                  <Icon className="text-primary font-size-1-5" iconType="Add" />
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default TicketsSearch;

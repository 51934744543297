import React from "react";

import { get } from "lodash";
import { Redirect, Route } from "react-router-dom";

/* context */
import { useRole, useAttendeeExhibitor } from "../contexts/ExhibitorContext";

// ? 1. You must pass the RolesRoute
// ? 2. You must pass the ExhibitorRoute
// ? 3. You must be MainContractor

const MainExhibitorRoute = ({
  component: Component,
  roles,
  redirect,
  ...rest
}) => {
  const Role = useRole();
  const AttendeeExhibitor = useAttendeeExhibitor();

  function render(props) {
    if (!(!!Role && !!Role.Code)) {
      console.log("MainExhibitorRoute: #1");
      return <Redirect to={redirect} />;
    }

    if (!(!roles.length || (!!roles.length && roles.includes(Role.Code)))) {
      console.log("MainExhibitorRoute: #2");
      return <Redirect to={redirect} />;
    }

    if (!!!parseInt(get(AttendeeExhibitor, "ID"), 10)) {
      console.log("MainExhibitorRoute: #3");
      return <Redirect to={redirect} />;
    }

    if (!!parseInt(get(AttendeeExhibitor, "MainExhibitor.ID"), 10)) {
      console.log("MainExhibitorRoute: #4");
      return <Redirect to={redirect} />;
    }

    return <Component {...props} />;
  }

  return <Route {...rest} render={render} />;
};

MainExhibitorRoute.defaultProps = {
  roles: [],
  redirect: "/",
};

export default MainExhibitorRoute;

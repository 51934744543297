import React, { Fragment, useContext, useMemo, useEffect } from "react";

/* packages */
import ReactSelect from "react-select";

import dayjs from "dayjs";
import parseHTML from "html-react-parser";
import classNames from "classnames";
import useToggle from "react-use/lib/useToggle";

import { produce } from "immer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { map, get, pick, find } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";

/* components */
import Icon from "../../components/Icon";
import Badge from "../../components/Badge";
import ContactBlock from "../../components/ContactBlock";
import { CardHeaderRight } from "../../components/CardHeader";

/* context */
import RoomReservationContext, {
  useReservation,
} from "../../contexts/RoomReservationContext";

/* graphql */
import { GET_GATHERING_INFO } from "../../graphql/Gathering";
import {
  QUERY_GATHERING,
  QUERY_RESERVATION,
  QUERY_LOCATION_GATHERINGS,
  MUTATION_CREATE_RESERVATION,
  MUTATION_UPDATE_RESERVATION,
} from "../../graphql/Location";

import { QUERY_GATHERING_RESERVATION } from "../../graphql/Gathering";

/* layout */
import DefaultLayout from "../../layout/DefaultLayout";

/* lib */
import { useScrollToTop, useSafeSetState } from "../../lib/hooks";
import {
  getMomentFromString,
  getLocationPrice,
  getCateringOptionPrice,
  getCateringPrice,
} from "../../lib/common";
import {
  ROOM_RESERVATION_CONTACT_PERSON,
  ROOM_RESERVATION_EDIT_BEFORE,
} from "../../utils/globals";
import { useEventContext } from "../../contexts/EventContext";

const RoomReservationDuration = React.memo(() => {
  const { Start, End } = useReservation();

  const duration = useMemo(() => {
    let EndMoment = dayjs(End);
    let StartMoment = dayjs(Start);

    return `${StartMoment.format("HH:mm")} - ${EndMoment.format(
      "HH:mm"
    )} (${EndMoment.from(StartMoment, true)})`;
  }, [Start, End]);

  return <h3 className="font-weight-normal mb-0">{duration}</h3>;
});

const RoomReservationLocation = React.memo(() => {
  const [visible, toggle] = useToggle(false);

  const { t } = useTranslation(["roomReservationCalendar", "recordStage"]);

  const {
    location: { Title, Content, Places },
    // reservation: { ID: ReservationID, CurrentStage },
  } = useContext(RoomReservationContext);

  const locationString = useMemo(() => {
    if (!Places) {
      return "";
    }

    return [
      t("roomReservationCalendar:label.numberOfSeats", { count: Places }),
      //! @todo Room type thingy 'vergaderopstelling'
    ].join(", ");
  }, [Places]);

  const isVisible = useMemo(
    () => typeof Content === "string" && !!Content.length && visible,
    [Content, visible]
  );

  return (
    <Fragment>
      <div className={"card-body"}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h1 className="mb-0">{Title}</h1>
          <Icon
            iconType="InformationCircleOutline"
            className="font-size-1-5"
            onClick={toggle}
          />
        </div>
        <p className="text-primary mb-0">{locationString}</p>
        {isVisible && <div>{parseHTML(Content || "")}</div>}
        {
          // {!!ReservationID && (
          //     <span
          //       className={classNames("badge mt-2", {
          //         "badge-leaf": CurrentStage === "LIVE",
          //         "badge-secondary": CurrentStage === "DRAFT",
          //       })}
          //     >
          //       {t(`recordStage:${CurrentStage}`)}
          //     </span>
          //   )}
        }
      </div>
    </Fragment>
  );
});

const RoomReservationSchedule = React.memo(() => {
  const reservation = useReservation();
  const {
    location: { Price },
  } = useContext(RoomReservationContext);

  const LocationPrice = useMemo(
    () => getLocationPrice({ ...reservation, Location: { Price } }),
    [Price, reservation.Start, reservation.End]
  );

  return (
    <div className="card-body">
      <h2>{dayjs(reservation.Start).format("dddd")}</h2>
      <div className="d-flex justify-content-between justify-content-center ">
        <RoomReservationDuration />
        <p className="font-weight-bold text-gray mb-0">
          {`€ ${LocationPrice.toFixed(2)}`}
        </p>
      </div>
    </div>
  );
});

const RoomReservationCateringDefaultOption = React.memo(() => {
  const { t } = useTranslation(["common"]);
  const {
    setReservation,
    reservation: { CateringID },
    isReservationEditable,
  } = useContext(RoomReservationContext);

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div className="custom-control custom-radio mb-0">
        <input
          id={"CateringOption-0"}
          name={"CateringOption-0"}
          type="radio"
          value="0"
          checked={CateringID === "0"}
          disabled={!isReservationEditable}
          className="custom-control-input custom-control-secondary"
          onChange={() => setReservation({ CateringID: "0" })}
        />
        <label
          htmlFor={"CateringOption-0"}
          className="custom-control-label h3 mb-0 font-weight-normal"
        >
          {t("common:none")}
        </label>
      </div>
    </div>
  );
});

const RoomReservationCatering = React.memo(() => {
  const {
    reservation,
    cateringOptions,
    reservation: { CateringID },
    isReservationEditable,
    setReservation,
  } = useContext(RoomReservationContext);

  const { t } = useTranslation(["cateringOption"]);

  return cateringOptions.map((option) => {
    const key = `CateringOption-${option.ID}`;

    return (
      <div
        key={key}
        className="d-flex align-items-center justify-content-between mb-3"
      >
        <div className="custom-control custom-radio mb-0">
          <input
            id={key}
            name={key}
            type="radio"
            checked={CateringID === option.ID}
            value={option.ID}
            disabled={!isReservationEditable}
            className="custom-control-input custom-control-secondary"
            onChange={({ target: { value: CateringID } }) =>
              setReservation({ CateringID })
            }
          />
          <label
            htmlFor={key}
            className="custom-control-label h3 mb-0 font-weight-normal"
          >
            {`${t(`cateringOption:${option.Title}`)} (${getCateringOptionPrice(
              reservation,
              option
            ).toFixed(2)} p.p.)`}
          </label>
        </div>
        {CateringID === option.ID && (
          <p className="font-weight-bold text-gray mb-0">
            {`€ ${getCateringPrice(reservation, option).toFixed(2)}`}
          </p>
        )}
      </div>
    );
  });
});

const RoomReservationTotal = React.memo(() => {
  const { t } = useTranslation(["roomReservation"]);
  const {
    reservation,
    location: { Price },
    reservation: { CateringID, Persons, Participants },
    cateringOptions,
  } = useContext(RoomReservationContext);

  const selected = useMemo(
    () => find(cateringOptions, ({ ID }) => ID === CateringID),
    [cateringOptions, CateringID]
  );

  const Total = useMemo(() => {
    const locationPrice = getLocationPrice({
      ...reservation,
      Location: { Price },
    });

    const cateringPrice = getCateringPrice(reservation, selected);

    return cateringPrice + locationPrice;
  }, [reservation, selected]);

  return (
    <div className="card-body">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2 className="mb-0">{t("roomReservation:label.total")}</h2>
        <Badge>{`€ ${Total}`}</Badge>
      </div>
      <p className="text-primary mb-0">
        {t(
          `roomReservation:label.${
            !!Participants ? "price" : "estimatedPrice"
          }`,
          { count: Persons }
        )}
      </p>
    </div>
  );
});

const RoomReservationGathering = React.memo(() => {
  const { t } = useTranslation(["roomReservation"]);
  const {
    reservation: { GatheringID, Gathering },
    setReservation,
  } = useContext(RoomReservationContext);

  useQuery(QUERY_GATHERING, {
    variables: { ID: GatheringID },
    onCompleted: ({ readOneProvadaGathering: Gathering }) => {
      //! In case an invalid gathering id has been passed down the query params we will clear the GatheringID
      //! This means user can create a reservation for that period and select gathering that fits within the time frame
      if (!Gathering) {
        setReservation({ GatheringID: null });
      }

      return setReservation({ Gathering });
    },
    onError: () => setReservation({ GatheringID: null }),
  });

  const defaultValue = useMemo(() => {
    if (!Gathering) {
      return "";
    }

    if (Gathering.Title) {
      return Gathering.Title;
    }

    if (Gathering.ID) {
      return `${t("gatherings:gathering")} ${Gathering.ID}`;
    }

    return "";
  }, [Gathering, t]);

  return (
    <div className="form-group">
      <label htmlFor="GatheringID" className="text-primary">
        {t("roomReservation:input.label.Gathering")}
      </label>
      <input
        id="GatheringID"
        name="GatheringID"
        type="text"
        className="form-control"
        disabled
        defaultValue={defaultValue}
      />
    </div>
  );
});

const RoomReservationGatheringSelect = React.memo(() => {
  const { t } = useTranslation(["roomReservation"]);
  const {
    reservation: {
      ID: ReservationID,
      Gathering,
      Date: ReservationDate,
      Start,
      End,
    },
    setReservation,
  } = useContext(RoomReservationContext);

  const { data, loading } = useQuery(QUERY_LOCATION_GATHERINGS, {
    skip: !(!!ReservationDate && !!Start && !!End),
    variables: {
      Filter: {
        Date__eq: dayjs(ReservationDate).format("YYYY-MM-DD"),
        End__gte: dayjs(End).format("HH:mm:ss"),
        Start__gte: dayjs(Start).format("HH:mm:ss"),
      },
    },
  });

  const gatherings = useMemo(
    () => map(get(data, "readProvadaGatherings.edges", []), "node"),
    [data]
  );

  const options = useMemo(() => {
    let nextOptions = [
      { ID: null, Title: t("roomReservation:input.default.Gathering") },
    ];

    if (Array.isArray(gatherings) && !!gatherings.length) {
      nextOptions = [...nextOptions, ...gatherings];
    }

    return nextOptions;
  }, [Gathering, gatherings]);

  return (
    <div className="form-group">
      <label htmlFor="Gathering" className="text-primary">
        {t("roomReservation:input.label.Gathering")}
      </label>
      <ReactSelect
        // defaultValue={options[0]}
        value={Gathering && !!Gathering.ID ? Gathering : options[0]}
        isLoading={loading}
        options={options}
        isDisabled={!!ReservationID}
        getOptionLabel={({ Title }) => Title}
        getOptionValue={({ ID }) => ID}
        onChange={(action) => setReservation({ Gathering: action })}
      />
      {(!Gathering || (!!Gathering && !Gathering.ID)) && (
        <small className="form-text text-muted">
          {t("roomReservation:input.helper.Gathering")}
        </small>
      )}
    </div>
  );
});

const RoomReservationPersons = React.memo(() => {
  const { t } = useTranslation(["roomReservation"]);
  const {
    setReservation,
    isReservationEditable,
    reservation: { Persons },
  } = useContext(RoomReservationContext);

  return (
    <div className="form-group">
      <label htmlFor="Persons" className="text-primary">
        {t("roomReservation:input.label.Persons")}
      </label>
      <input
        id="Persons"
        name="Persons"
        type="number"
        className="form-control"
        value={Persons || ""}
        disabled={!isReservationEditable}
        onChange={({ target: { value } }) => setReservation({ Persons: value })}
      />
    </div>
  );
});

const RoomReservationComments = React.memo(() => {
  const { t } = useTranslation(["roomReservation"]);
  const {
    setReservation,
    isReservationEditable,
    reservation: { Comments },
  } = useContext(RoomReservationContext);

  return (
    <div className="form-group">
      <label htmlFor="Comments" className="text-primary">
        {t("roomReservation:input.label.Comments")}
      </label>
      <input
        id="Comments"
        name="Comments"
        type="text"
        className="form-control"
        value={Comments || ""}
        disabled={!isReservationEditable}
        onChange={({ target: { value } }) =>
          setReservation({ Comments: value })
        }
      />
    </div>
  );
});

const RoomReservationEdit = React.memo(
  ({
    history: { push, goBack, replace },
    match: {
      url: currentURL,
      params: { ReservationID: ID },
    },
  }) => {
    const { t } = useTranslation(["roomReservation"]);

    const { event, events, onChangeActiveEvent } = useEventContext();

    const {
      reservation,
      reservationEditableUntil,
      location,
      setReservation,
      isReservationValid,
      isReservationEditable,
    } = useContext(RoomReservationContext);

    const [error, setError] = useSafeSetState(null);

    useEffect(() => {
      //! Redirect user if there is no reservation
      //! Consider what happens when we do the dit in future

      if (!((!!reservation && !!get(reservation, "LocationID")) || !!ID)) {
        goBack();
      }
    }, []);

    useScrollToTop();

    const { data } = useQuery(QUERY_RESERVATION, {
      skip: !ID,
      variables: {
        ID,
      },
      fetchPolicy: "network-only",
      onCompleted: ({ readOneProvadaReservation: reservation }) => {
        // Reservation not found, redirect
        if (!(reservation && reservation.ID)) {
          return goBack();
        }

        const EventID = get(event, "ID");
        const RecordEventID = get(reservation, "Location.Event.ID");

        if (RecordEventID === EventID) {
          return;
        }

        const RecordEvent = find(events, { ID: RecordEventID });

        if (RecordEvent) {
          return onChangeActiveEvent(RecordEvent);
        }

        return replace("/gathering");
      },
      onError: () => {
        return goBack();
      },
    });

    useEffect(() => {
      const nextReservation = get(data, "readOneProvadaReservation");

      if (nextReservation) {
        setReservation(
          produce(nextReservation, (draft) => {
            draft.Persons = Math.max(1, nextReservation.Persons);
            draft.Date = dayjs(draft.Date, "YYYY-MM-DD").unix() * 1000;
            draft.End =
              getMomentFromString(draft.Date, draft.End).unix() * 1000;
            draft.Start =
              getMomentFromString(draft.Date, draft.Start).unix() * 1000;
            //! Need to set the ID in order to fetch location info
            draft.LocationID = get(draft, "Location.ID", null);
            draft.CateringID = get(draft, "Catering.ID", "0");
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const [onCreateRoomReservation, { loading: isCreatingReservation }] =
      useMutation(MUTATION_CREATE_RESERVATION);

    const [onUpdateRoomReservation, { loading: isUpdatingReservation }] =
      useMutation(MUTATION_UPDATE_RESERVATION);

    function onSubmit(e) {
      e.preventDefault();

      /**
       * Do not allow edits of the room reservation if it is outside of the edit time frame
       */
      if (!isReservationEditable) {
        return;
      }

      /**
       * Invalid reservation? No can't do mister
       */
      if (!isReservationValid) {
        return;
      }

      if (!!error) {
        setError(null);
      }

      let Input = pick(reservation, ["CateringID", "Persons", "Comments"]);

      // ! Make SilverStripe happy, does not like nulls, likes zeros
      if (!Input.CateringID) {
        Input = {
          ...Input,
          CateringID: 0,
        };
      }

      // * Update Reservation
      if (!!reservation.ID) {
        Input = { ...Input, ID: reservation.ID };

        let options = {
          variables: { Input },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              // ? /gathering/:ID
              query: QUERY_GATHERING_RESERVATION,
              variables: {
                Filter: {
                  GatheringID__eq: get(reservation, "GatheringID", null),
                },
              },
            },
          ],
        };

        return onUpdateRoomReservation(options)
          .then((data) => {
            let nextRoute = "/gathering";

            const GatheringID = parseInt(
              get(data, "data.updateProvadaReservation.Gathering.ID"),
              10
            );

            if (!!GatheringID) {
              nextRoute += `/${GatheringID}`;
            }

            return push(nextRoute);
          })
          .catch(setError);
      }

      //! Add all other needed properties
      Input = {
        ...Input,
        LocationID: get(reservation, "LocationID", null),
        GatheringID:
          get(reservation, "Gathering.ID", null) ||
          get(reservation, "GatheringID", null),

        Date: dayjs(reservation.Start).format("YYYY-MM-DD"),
        Start: dayjs(reservation.Start).format("HH:mm:ss"),
        End: dayjs(reservation.End).format("HH:mm:ss"),
      };

      let options = {
        variables: { Input },
      };

      const GatheringID = get(reservation, "GatheringID");

      //! Important: Re-fetch gathering query in case we're creating a reservation coming form the gathering flow
      //! If we're creating a new Gathering, this will not be required since its not cached.
      if (!!GatheringID) {
        options = {
          ...options,
          awaitRefetchQueries: true,
          //! @todo Can be optimized to re-fetch only if Start, End changes
          refetchQueries: [
            {
              query: GET_GATHERING_INFO,
              variables: {
                ID: GatheringID,
              },
            },
            {
              // ? /gathering/:ID
              query: QUERY_GATHERING_RESERVATION,
              variables: {
                Filter: {
                  GatheringID__eq: GatheringID,
                },
              },
            },
          ],
        };
      }

      return onCreateRoomReservation(options)
        .then((data) => {
          const GatheringID = parseInt(
            get(data, "data.createProvadaReservation.Gathering.ID"),
            10
          );
          const ReservationID = parseInt(
            get(data, "data.createProvadaReservation.ID"),
            10
          );

          //! Replace current route
          replace(`/gathering/${GatheringID}`);

          //! Important: Clear reservation form session storage! :)
          // clearReservation();

          //! Navigate to competition screen
          return push(
            `/gathering/${GatheringID}/reservation/${ReservationID}/complete`
          );
        })
        .catch(setError);
    }

    const backgroundImageSource = useMemo(
      () => get(location, "Picture.AbsoluteLink", null),
      [location]
    );

    return (
      <DefaultLayout>
        <div className="card">
          <div className="card-body bg-primary d-flex justify-content-between align-items-center p-2">
            <Icon
              className="text-white font-size-1-5 flex-center size-40"
              iconType="Close"
              onClick={() => goBack()}
            />
            <h1 className="text-white mb-0">{t("roomReservation:title")}</h1>
            {!isReservationEditable ? (
              <div className="size-40" />
            ) : (
              <CardHeaderRight onClick={onSubmit} />
            )}
          </div>
          {!!backgroundImageSource && (
            <div
              className="background-image bg-16-9 overflow-hidden"
              style={{
                backgroundImage: `url("${backgroundImageSource}")`,
                maxHeight: 172,
              }}
            />
          )}
          <form onSubmit={onSubmit}>
            <fieldset>
              <RoomReservationLocation />
              <RoomReservationSchedule />

              {!!(location && location.Catering !== "NONE") && (
                <div className="card-body">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <h2 className="mb-0">
                        {t("roomReservation:input.label.Catering")}
                      </h2>
                      <Icon
                        className="font-size-1-5"
                        iconType="InformationCircleOutline"
                      />
                    </div>
                    {location.Catering === "MANDATORY" && (
                      <p className="text-primary mb-0">
                        {t("roomReservation:input.helper.Catering")}
                      </p>
                    )}
                  </div>
                  {location.Catering === "OPTIONAL" && (
                    <RoomReservationCateringDefaultOption />
                  )}
                  <RoomReservationCatering />
                </div>
              )}

              <RoomReservationTotal />
              <div className="card-body">
                {/**
                 * * When coming from the gathering flow we have GatheringID set and are not allowed to update teh gathering.
                 * * In other cases user can select one gathering from the list of fitting ones or none, which will create gathering for teh reservation
                 */}
                {!!reservation.GatheringID ? (
                  <RoomReservationGathering />
                ) : (
                  <RoomReservationGatheringSelect />
                )}

                <RoomReservationPersons />
                <RoomReservationComments />
                {/**
                 * Do not allow edits of the room reservation if it is outside of the edit time frame
                 */}
                {isReservationEditable && (
                  <Fragment>
                    <div className="d-flex">
                      <button
                        className={classNames("btn mb-0", {
                          "mb-4": !!error,
                          "btn-secondary": !reservation.ID,
                          "btn-primary": !!reservation.ID,
                        })}
                        type="button"
                        disabled={
                          !isReservationValid ||
                          isCreatingReservation ||
                          isUpdatingReservation
                        }
                        onClick={onSubmit}
                      >
                        {!!(isCreatingReservation || isUpdatingReservation) && (
                          <span
                            className="spinner-border spinner-border-sm mr-2 mb-1"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {t(
                          `roomReservation:button.${
                            !!reservation.ID ? "edit" : "create"
                          }`
                        )}
                      </button>
                    </div>
                    {!!error && (
                      <small className="text-danger font-weight-bold">
                        {get(error, "graphQLErrors[0].message")}
                      </small>
                    )}
                  </Fragment>
                )}
              </div>

              {!!(!!reservation.ID && !reservation.Approved) && (
                <div className="card-body bg-secondary-10">
                  <h1>
                    {t("roomReservation:label.cancelRoomReservationTitle")}
                  </h1>
                  <p className="text-primary mb-4">
                    {t("roomReservation:label.cancelRoomReservationContent")}
                  </p>
                  <div className="d-flex flex-center">
                    <Link
                      to={`${currentURL}/cancel`}
                      className="btn btn-secondary mb-0 text-decoration-none"
                    >
                      {t("roomReservation:button.cancel")}
                    </Link>
                  </div>
                </div>
              )}
            </fieldset>
          </form>
        </div>
        {!!(!reservation.Approved && isReservationEditable) && (
          <div className="card">
            <div className="card-body">
              <h1>
                {t("roomReservation:label.editAllowedRoomReservationTitle")}
              </h1>
              <p className="text-primary mb-4">
                {t("roomReservation:label.editAllowedRoomReservationContent", {
                  date: reservationEditableUntil.format("dddd, DD MMMM HH:mm"),
                })}
              </p>
            </div>
          </div>
        )}
        {!!(!!reservation.ID && !!reservation.Approved) && (
          <div className="card">
            <div className="card-body">
              <h1>{t("roomReservation:label.cancelRoomReservationTitle")}</h1>
              <p className="text-primary mb-4">
                {t("roomReservation:label.cancelRoomReservationContact")}
              </p>
              <ContactBlock ContactPerson={ROOM_RESERVATION_CONTACT_PERSON} />
            </div>
          </div>
        )}
        {!!(
          !!reservation.ID &&
          !reservation.Approved &&
          !isReservationEditable
        ) && (
          <div className="card">
            <div className="card-body">
              <h1>
                {t("roomReservation:label.editLockedRoomReservationTitle", {
                  count: ROOM_RESERVATION_EDIT_BEFORE[0],
                })}
              </h1>
              <p className="text-primary mb-4">
                {t("roomReservation:label.cancelRoomReservationContact")}
              </p>
              <ContactBlock ContactPerson={ROOM_RESERVATION_CONTACT_PERSON} />
            </div>
          </div>
        )}
      </DefaultLayout>
    );
  }
);

export default RoomReservationEdit;

import React, {useMemo} from 'react';

/* packages */
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';

/* components */
import Percentage from '../../../components/Percentage';

/* context */
import { useAttendeeExhibitor } from '../../../contexts/ExhibitorContext';

/* graphql */
import { QUERY_TASKS_COMPLETED } from '../../../graphql/User';

const TasksCompleted = React.memo(() => {
  const Exhibitor = useAttendeeExhibitor();

  const { data } = useQuery(QUERY_TASKS_COMPLETED, {
    skip: !get(Exhibitor, 'ID'),
    variables: {
      ID: get(Exhibitor, 'ID')
    }
  });

  const Progress = useMemo(() => (parseInt(get(data, 'readOneProvadaExhibitor.TasksCompleted'), 10) || 0),[data])

  return (
    <Percentage
      // key={Progress}
      value={Progress}
    />
  );
});

export default TasksCompleted;

/* packages */
import qs from "query-string";

import dayjs from "dayjs";
import { head, get, groupBy } from "lodash";

import { BOOKING_PERIOD, BOOKING_HEIGHT } from "../utils/globals";

// extend(qs, {
//   update: ({ search, params } = { search: '', params: {} }) => {
//     const current = qs.parse(search, { parseNumbers: true });

//     let nextParams = { ...current, ...params };

//     return qs.stringify(nextParams, { skipNull: true });
//   }
// })

export const round = (value = 0, decimals = 2) => {
  const val =
    Math.round(parseFloat(value * Math.pow(10, decimals).toFixed(2))) /
    Math.pow(10, decimals);

  return isNaN(val) ? 0 : val;
};

export const sum = (prev, curr) => prev + curr;

export const priceToString = (value = 0) => Number.parseFloat(value).toFixed(2);

export function roundTime(instance) {
  return instance.set("second", 0).set("millisecond", 0);
}

export function getEndOfDayTimestamp(day) {
  return (
    roundTime(dayjs(day).endOf("day"))
      .set("minute", 60 - BOOKING_PERIOD)
      .unix() * 1000
  );
}

export function getTimestampsBetween(start, end, exclude = false) {
  let timestampStart = roundTime(dayjs(start)).unix() * 1000;
  let timestampEnd = roundTime(dayjs(end)).unix() * 1000;

  let items = [];

  while (
    exclude ? timestampStart < timestampEnd : timestampStart <= timestampEnd
  ) {
    items.push(timestampStart);
    timestampStart =
      dayjs(timestampStart).add(BOOKING_PERIOD, "minutes").unix() * 1000;
  }

  return items;
}

function setTime(date = dayjs(), time) {
  const [hours, minutes] = time.split(":");

  return date
    .set("hour", hours)
    .set("minute", minutes)
    .set("second", 0)
    .set("millisecond", 0);
}

export const getWorkingHours = (
  props = { date: dayjs(), from: "9:00:00", to: "19:00:00" }
) => {
  const { date, from, to } = {
    date: dayjs(),
    from: "09:00:00",
    to: "19:00:00",
    ...props,
  };

  const fromObj = setTime(dayjs(date), from);
  const toObj = setTime(dayjs(date), to);

  return getTimestampsBetween(fromObj, toObj);
};

export function getTimesForDay(day = dayjs(), exclude = true) {
  const start = dayjs(day).startOf("day").unix() * 1000;
  const end = getEndOfDayTimestamp(day);

  return getTimestampsBetween(start, end, exclude);
}

export function geEventDays({ StartDate, EndDate }) {
  let items = [];

  if (!(!!StartDate && !!EndDate)) {
    return items;
  }

  let start = dayjs(StartDate, "YYYY-MM-DD");
  let end = dayjs(EndDate, "YYYY-MM-DD");

  if (!(start.isValid() && end.isValid())) {
    return items;
  }

  while (start.isSameOrBefore(end, "date")) {
    items.push(start.format("YYYY-MM-DD"));
    start = dayjs(start).add(1, "day");
  }

  return items;
}

export function getMomentFromString(DateString, TimeString) {
  if (!TimeString) {
    return false;
  }

  const date = DateString ? dayjs(new Date(DateString)) : dayjs();
  const [hours, minutes] = TimeString.split(":");

  return date
    .set("hour", hours)
    .set("minute", minutes)
    .set("second", 0)
    .set("millisecond", 0);
}

export function getBlocksFromTimestamp(timestampStart, timestampEnd) {
  return (timestampStart - timestampEnd) / 60000 / BOOKING_PERIOD;
}

export function getBlockHeight(blocks) {
  return blocks * BOOKING_HEIGHT - 3;
}

export function getLocationPrice(
  { Start, End, Location } = { Start: 0, End: 0, Location: { Price: 0 } }
) {
  return round(
    round(round(Location.Price) / (60 / BOOKING_PERIOD)) *
      getBlocksFromTimestamp(End, Start)
  );
}

export function getCateringOptionPrice(
  { Start, End } = { Start: 0, End: 0 },
  { PriceOne, PriceTwo, HourLimit } = { PriceOne: 0, PriceTwo: 0, HourLimit: 4 }
) {
  const hours = (End - Start) / 3600000;

  if (hours < HourLimit) {
    return round(PriceOne);
  }

  return round(PriceTwo);
}

export function getCateringPrice(
  { Persons } = { ID: null, Start: 0, End: 0, Persons: 1 },
  {} = { PriceOne: 0, PriceTwo: 0, HourLimit: 4 }
) {
  const price = getCateringOptionPrice(...arguments);

  return round(price * Persons);
}

export function updateQueryString(
  { search, params } = { search: "", params: {} }
) {
  const current = qs.parse(search, { parseNumbers: true });

  let nextParams = { ...current, ...params };

  return qs.stringify(nextParams, { skipNull: true });
}

export function splitAt(index, search) {
  return (x) => [
    x.slice(0, index),
    x.slice(index, index + search.length),
    x.slice(index + search.length, x.length),
  ];
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getBase64(file, cb) {
  if (!(file instanceof Blob)) {
    return cb(null);
  }

  let reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function () {
    cb(reader.result);
  };

  reader.onerror = function (error) {};
}

export function getIconForExtension(Extension) {
  switch (Extension) {
    case "doc":
    case "docx":
      return "if-Word";

    case "zip":
    case "rar":
      return "if-Archive";

    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return "if-Image";

    case "ppt":
      return "if-PowerPoint";

    case "pdf":
      return "if-PDF";

    default:
      return "if-File";
  }
}

export function getCommonUploadStatus(Files) {
  const grouped = groupBy(Files, "Status");

  if (!(Files && Files.length > 0)) {
    return;
  }

  if (grouped["0"] && grouped["0"].length) {
    return 0;
  }

  if (grouped["-1"] && grouped["-1"].length) {
    return -1;
  }

  return 1;
}

export function getContactInfo() {
  let payload = {
    Email: "",
    Phone: "",
    Picture: "",
    FullName: "",
  };

  for (let contact of Array.from(arguments)) {
    if (!contact) {
      continue;
    }

    if (!!contact.ID) {
      if (!parseInt(contact.ID)) {
        continue;
      }
    }

    payload = {
      FullName: contact.FullName || "",
      Picture: get(contact, "Picture.AbsoluteLink") || "",
      Email:
        head(
          [
            get(contact, "Email"),
            get(contact, "PublicEmail"),
            get(contact, "DefaultHelpEmail"),
          ].filter(Boolean)
        ) || "",
      Phone:
        head(
          [
            get(contact, "Phone"),
            get(contact, "PublicPhone"),
            get(contact, "DefaultHelpPhone"),
          ].filter(Boolean)
        ) || "",
    };

    break;
  }

  return payload;
}

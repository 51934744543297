import React, { useMemo } from "react";

/* packages */
import useSetState from "react-use/lib/useSetState";

import { omit } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { useUser } from "../../contexts/UserContext";

import DefaultLayout from "../../layout/DefaultLayout";

import Icon from "../../components/Icon";
import CardHeader from "../../components/CardHeader";

import {
  EMAIL_NOTIFICATIONS,
  UPDATE_EMAIL_NOTIFICATIONS,
} from "../../graphql/User";

const NotificationsPage = React.memo(({ history: { push, goBack } }) => {
  const { t } = useTranslation(["common", "header"]);

  const { EmailNotifications } = useUser();

  const [state, setState] = useSetState({});

  useQuery(EMAIL_NOTIFICATIONS, {
    skip: !EmailNotifications.ID,
    variables: {
      ID: EmailNotifications.ID,
    },
    onCompleted: ({ readOneProvadaEmailNotifications: notifications }) => {
      if (!notifications) {
        return goBack();
      }

      return setState(omit(notifications, ["ID", "__typename"]));
    },
    onError: () => goBack(),
  });

  const [onSaveEmailNotifications] = useMutation(UPDATE_EMAIL_NOTIFICATIONS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: EMAIL_NOTIFICATIONS,
        variables: { ID: EmailNotifications.ID },
      },
    ],
  });

  const NotificationKeys = useMemo(() => Object.keys(state) || [], [state]);

  const onDismiss = () => push("/");

  const onSubmit = (e) => {
    e.preventDefault();

    return onSaveEmailNotifications({
      variables: {
        Input: { ID: EmailNotifications.ID, ...state },
      },
    })
      .then(() => push("/"))
      .catch((error) => null);
  };

  const onChangeValue = ({ target: { value: key } }) =>
    setState({ [key]: !state[key] });

  return (
    <DefaultLayout>
      <div className="card">
        <CardHeader
          title={t("header:notifications")}
          onDismiss={onDismiss}
          onSubmit={onSubmit}
        />

        <form className="card-body" onSubmit={onSubmit}>
          <div className="my-4">
            {NotificationKeys.map((key) => (
              <div key={key} className="todo-item p-3 position-relative">
                <input
                  className="todo-input d-none"
                  id={key}
                  type="checkbox"
                  name={key}
                  value={key}
                  checked={state[key]}
                  onChange={onChangeValue}
                />
                <label
                  className="todo-content asd mb-0 d-flex align-items-start"
                  htmlFor={key}
                >
                  <div className="todo-icon mr-3 flex-center tr-all">
                    <Icon className="text-white" iconType="Check" />
                  </div>
                  <div>
                    <h3 className="tr-opacity">{t(`notifications:${key}`)}</h3>
                  </div>
                </label>
              </div>
            ))}
          </div>
        </form>
      </div>
    </DefaultLayout>
  );
});

export default NotificationsPage;

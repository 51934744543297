import React, { useMemo, useContext, Fragment } from "react";

import dayjs from "dayjs";

import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LinkLikeArrow from "../../../components/LinkLikeArrow";
import LinkArrowExternal from "../../../components/LinkArrowExternal";

/* context */
import EventContext from "../../../contexts/EventContext";
import { useAttendeeExhibitor } from "../../../contexts/ExhibitorContext";

import { READ_ONE_PROVADA_EVENT } from "../../../graphql/Event";

const ExclusiveProfileLabel = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const AttendeeExhibitor = useAttendeeExhibitor();

  const children = useMemo(() => {
    let value = t("standservices:standpromotionlabel");

    const Expires = get(AttendeeExhibitor, "Company.Expires");

    if (!!Expires) {
      const expireDay = dayjs(Expires, "YYYY-MM-DD");

      if (!expireDay.isBefore(dayjs())) {
        value = `${t("standservices:activeUntil")} ${expireDay.format(
          "DD MMM YYYY"
        )}`;
      }
    }

    return value;
  }, [t, AttendeeExhibitor]);

  return <LinkLikeArrow>{children}</LinkLikeArrow>;
});

const defaultProps = {
  ID: null,
  StandPromotionExcerpt: "",
  ExclusiveProfileTitle: "",
  ExclusiveProfileExcerpt: "",
  NewsletterTitle: "",
  NewsletterExcerpt: "",
  NewsletterContent: "",
  BannersTitle: "",
  BannersExcerpt: "",
  ExpoPromotionTitle: "",
  ExpoPromotionExcerpt: "",
  ExpoPromotion: {
    ID: null,
    Title: "",
    Size: "",
    AbsoluteLink: "",
  },
};

const StandPromotionPage = React.memo(() => {
  const { url } = useRouteMatch();

  const { t } = useTranslation(["common", "standservices"]);

  const {
    event: { ID: EventID },
  } = useContext(EventContext);

  const { data: eventData } = useQuery(READ_ONE_PROVADA_EVENT, {
    variables: {
      ID: EventID,
    },
  });

  const event = useMemo(
    () => get(eventData, "readOneProvadaEvent", defaultProps),
    [eventData]
  );

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h1>{t("standservices:standPromotion")}</h1>
              <p>{event.StandPromotionExcerpt}</p>
            </div>

            <Link
              className="card-body text-decoration-none"
              to={`${url}/premium`}
            >
              <h2 className="text-dark">{event.ExclusiveProfileTitle}</h2>
              <p className="text-dark">{event.ExclusiveProfileExcerpt}</p>
              <ExclusiveProfileLabel />
            </Link>

            <Link
              className="card-body text-decoration-none"
              to={`${url}/newsletter`}
            >
              <h2 className="text-dark">{event.NewsletterTitle}</h2>
              <p className="text-dark">{event.NewsletterExcerpt}</p>
              <LinkLikeArrow>
                {t("standservices:standpromotionlabel")}
              </LinkLikeArrow>
            </Link>

            <Link
              className="card-body text-decoration-none"
              to={`${url}/banners`}
            >
              <h2 className="text-dark">{event.BannersTitle}</h2>
              <p className="text-dark">{event.BannersExcerpt}</p>
              <LinkLikeArrow>{t("standservices:download")}</LinkLikeArrow>
            </Link>
            {!!get(event, "ExpoPromotion.AbsoluteLink") && (
              <div className="card-body">
                <h2>{event.ExpoPromotionTitle}</h2>
                <p>{event.ExpoPromotionExcerpt}</p>

                <LinkArrowExternal
                  download
                  to={event.ExpoPromotion.AbsoluteLink}
                >
                  {t("standservices:download")}
                </LinkArrowExternal>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default StandPromotionPage;

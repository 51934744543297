import React, { useContext, useMemo, useRef, useEffect, useState } from "react";

/* packages */
import qs from "query-string";
import dayjs from "dayjs";

import get from "lodash/get";
import last from "lodash/last";
import first from "lodash/first";
import castArray from "lodash/castArray";

import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

/* contexts */
import { useEvent, useEventDays } from "./EventContext";
import { useAttendee } from "./ExhibitorContext";

/* graphql */
import {
  QUERY_MEETING,
  QUERY_AGENDA,
  MUTATION_UPDATE_MEETING,
} from "../graphql/Meetings";

const AgendaContext = React.createContext();
const MeetingContext = React.createContext();

export const VISIBILITY_STATUS = {
  CANCEL: "cancel",
  RESCHEDULE: "reschedule",
};

export const useAgenda = () => useContext(AgendaContext);

export const useAgendaVariables = () => {
  const { variables, onChangeVariables } = useAgenda();

  return [variables, onChangeVariables];
};

export const useAgendaRefetchQueries = () => {
  const Agenda = useAgenda();

  const {
    Meeting: { ID },
  } = useMeeting();

  return useMemo(() => {
    var nextRefetchQueries = [
      {
        query: QUERY_MEETING,
        variables: { ID },
      },
    ];

    if (!!Agenda && !!Agenda.variables) {
      nextRefetchQueries = [
        ...nextRefetchQueries,
        {
          query: QUERY_AGENDA,
          variables: { Filter: Agenda.variables },
        },
      ];
    }

    return nextRefetchQueries;
  }, [ID, Agenda]);
};

export const AgendaProvider = React.memo((props) => {
  const Event = useEvent();

  const days = useEventDays();

  const {
    location: { search, pathname },
    push,
    replace,
  } = useHistory();

  const params = useMemo(
    () => qs.parse(search, { parseNumbers: true, arrayFormat: "index" }),
    [search]
  );

  const initialStatus = useRef(
    params.Status ? castArray(params.Status) : [-1, 1]
  );

  const variables = useMemo(() => {
    const nextStatus = castArray(params.Status);

    let nextVariables = {
      EventID: get(Event, "ID"),
      Status: nextStatus,
      Date: dayjs(days[0]).format("YYYY-MM-DD"),
    };

    /**
     * Here to ensure you cannot select a date which is not in scope of the event
     */
    if (params.Date) {
      const isInScope = dayjs(params.Date).isBetween(
        first(days),
        last(days),
        "day",
        "[]"
      );

      if (isInScope) {
        nextVariables = {
          ...nextVariables,
          Date: dayjs(params.Date).format("YYYY-MM-DD"),
        };
      }
    }

    return nextVariables;
  }, [params, Event, days]);

  function onChangeVariables(nextVariables) {
    return push({
      search: qs.stringify(
        { ...variables, ...nextVariables },
        { arrayFormat: "index", skipNull: true }
      ),
    });
  }

  //! Set query params initially
  useEffect(() => {
    replace({
      url: pathname,
      search: qs.stringify(
        {
          ...params,
          ...variables,
          Status: params.Status || initialStatus.current,
        },
        {
          parseNumbers: true,
          arrayFormat: "index",
        }
      ),
    });
  }, []);

  return (
    <AgendaContext.Provider
      {...props}
      value={{
        variables,
        onChangeVariables,
      }}
    />
  );
});

export const useMeetingAction = () => {
  const { opened, setOpened } = useContext(MeetingContext);

  return [opened, setOpened];
};

export const useMeeting = () => {
  return useContext(MeetingContext);
};

export const useMeetingAcceptMutation = () => {
  const {
    Meeting: { ID },
  } = useMeeting();

  const refetchQueries = useAgendaRefetchQueries();

  return useMutation(MUTATION_UPDATE_MEETING, {
    variables: {
      Input: {
        ID,
        Status: 1,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries,
  });
};

export const useMeetingCancelMutation = () => {
  const refetchQueries = useAgendaRefetchQueries();

  return useMutation(MUTATION_UPDATE_MEETING, {
    awaitRefetchQueries: true,
    refetchQueries,
  });
};

export const useMeetingIsOwner = (Meeting) => {
  const Attendee = useAttendee();

  return useMemo(
    () => get(Attendee, "ID") === get(Meeting.RequestedBy, "ID"),
    [Attendee, Meeting.RequestedBy]
  );
};

export const useMeetingOwnerLastEdited = (Meeting) => {
  const Attendee = useAttendee();

  const isOwner = useMeetingIsOwner(Meeting);

  return useMemo(() => {
    const ID = parseInt(get(Meeting.LastUpdatedBy, "ID"), 10);

    if (!ID) {
      return isOwner;
    }

    return get(Attendee, "ID") === get(Meeting.LastUpdatedBy, "ID");
  }, [Attendee, Meeting.LastUpdatedBy, isOwner]);
};

export const AgendaMeetingProvider = React.memo(
  ({ children, value: { Meeting } }) => {
    const { LastUpdatedBy } = Meeting;

    const [opened, setOpened] = useState(null);

    const isEdited = useMemo(
      () => !!parseInt(get(LastUpdatedBy, "ID"), 10),
      [LastUpdatedBy]
    );

    const isOwner = useMeetingIsOwner(Meeting);
    const isOwnerLastEdit = useMeetingOwnerLastEdited(Meeting);

    const isRescheduledSuggested = useMemo(
      () => Meeting.Status === -1 && Meeting.SuggestedTimes.length,
      [Meeting]
    );

    return (
      <MeetingContext.Provider
        children={children}
        value={{
          Meeting,

          opened,
          setOpened,

          isOwner,
          isEdited,

          isOwnerLastEdit,
          isOwnerRescheduleSuggestion:
            isOwnerLastEdit && isRescheduledSuggested, //! Logged in user suggested new times
          isRescheduleSuggestionRequest:
            !isOwnerLastEdit && isRescheduledSuggested, //! Is user requesting us to reschedule
        }}
      />
    );
  }
);

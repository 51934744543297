import React from "react";

import { Route, Redirect, Switch } from "react-router-dom";

import DefaultLayout from "../../layout/DefaultLayout";

import ConnectedAccounts from "./ConnectedAccounts";

const SettingsRouter = React.memo(({ match: { path } }) => {
  return (
    <DefaultLayout>
      <Switch>
        <Route
          exact
          path={`${path}/connected-accounts`}
          component={ConnectedAccounts}
        />
        <Redirect to={"/"} />
      </Switch>
    </DefaultLayout>
  );
});

export default SettingsRouter;

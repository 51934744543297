import React, { useMemo } from "react";

/* packages */
import { get } from "lodash";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

/* components */
import ContactBlock from "../../../components/ContactBlock";

/* graphql */
import { READ_ONE_PROVADA_RAI_SERVICE } from "../../../graphql/Service";

const ServiceContactBlock = React.memo(() => {
  const { ID } = useParams();

  const { data } = useQuery(READ_ONE_PROVADA_RAI_SERVICE, {
    variables: { ID },
  });

  const ContactPerson = useMemo(
    () => get(data, "readOneProvadaRAIService.ContactPerson"),
    [data]
  );

  return <ContactBlock ContactPerson={ContactPerson} />;
});

export default ServiceContactBlock;

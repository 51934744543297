import React, {
  useEffect,
  Fragment,
  useRef,
  useMemo,
  useCallback,
} from "react";

import useSetState from "react-use/lib/useSetState";

import { useMutation } from "@apollo/react-hooks";

/* packages */
import { useTranslation } from "react-i18next";

/* components */
import CardHeader from "../../../components/CardHeader";
import Notification from "../../../components/Notification";

/* context */
import { useGathering } from "../../../contexts/GatheringContext";

/** graphql */
import {
  UPDATE_GATHERING,
  GET_GATHERING_INFO,
} from "../../../graphql/Gathering";

import { GatheringBreadcrumbs } from "../GatheringEdit";

const GatheringRegistrationType = React.memo(({ history: { push } }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  const { data: gathering } = useGathering();

  const inputRef = useRef(null);

  const types = useMemo(() => {
    return [
      {
        Registration: "PROVADA",
        Label: t("gatherings:register:provada"),
      },
      {
        Registration: "EMAIL",
        RegisterOn: "",
        Label: t("gatherings:register:email"),
        AdditionalLabel: t("gatherings:register:emaillabel"),
        AdditionalType: "email",
      },
      {
        Registration: "EXTERNAL",
        RegisterOn: "",
        Label: t("gatherings:register:external"),
        AdditionalLabel: t("gatherings:register:websitelabel"),
        AdditionalType: "text",
      },
      {
        Registration: "FREE",
        Label: t("gatherings:register:free"),
      },
      {
        Registration: "PRIVATE",
        Label: t("gatherings:register:private"),
      },
    ];
  }, [t]);

  const [type, setType] = useSetState(types[0]);

  const [saveRegistrationType, { loading: saving }] = useMutation(
    UPDATE_GATHERING,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_GATHERING_INFO,
          variables: {
            ID: gathering.ID,
          },
        },
      ],
      onCompleted: () => {
        push(`/gathering/${gathering.ID}/registration`);
      },
    }
  );

  useEffect(() => {
    const { Registration, RegisterOn } = gathering;

    setType({
      Registration: Registration,
      RegisterOn: RegisterOn || "",
    });
  }, [gathering]);

  const renderItem = useCallback(
    (item) => {
      const { Label, Registration, AdditionalLabel, AdditionalType } = item;

      const checked = type.Registration === Registration;
      const disabled = !gathering.ID ? true : false;

      const onChangeRegistration = ({ currentTarget: { value } }) => {
        setType({
          ...type,
          Registration,
          RegisterOn:
            value === gathering.Registration
              ? gathering.RegisterOn && gathering.RegisterOn.trim()
              : "",
        });
      };

      const onChangeRegistrationOn = ({ currentTarget: { value } }) => {
        setType({
          ...type,
          RegisterOn: value.trim(),
        });
      };

      return (
        <Fragment key={Registration}>
          <div className="custom-control custom-radio mb-3">
            <input
              type="radio"
              id={Registration}
              value={Registration}
              name="customRadio"
              className="custom-control-input custom-control-secondary"
              onChange={onChangeRegistration}
              checked={checked}
              disabled={disabled}
            />
            <label className="custom-control-label" htmlFor={Registration}>
              {Label}
            </label>
          </div>

          {!!(AdditionalLabel && checked) && (
            <div className="form-group">
              <label className="text-primary" htmlFor={`${Registration}-FOR`}>
                {AdditionalLabel}
              </label>
              <input
                ref={inputRef}
                type={AdditionalType}
                id={`${Registration}-FOR`}
                value={type.RegisterOn}
                className="form-control"
                placeholder="email@voorbeeld.nl"
                onChange={onChangeRegistrationOn}
              />
            </div>
          )}
        </Fragment>
      );
    },
    [gathering.ID, gathering.RegisterOn, gathering.Registration, setType, type]
  );

  const onSubmit = () => {
    if (saving) {
      return;
    }

    if (
      (type.Registration === "EMAIL" || type.Registration === "EXTERNAL") &&
      !type.RegisterOn
    ) {
      inputRef.current && inputRef.current.focus();

      return;
    }

    let input = {
      ID: gathering.ID,
      Registration: type.Registration,
      RegisterOn:
        type.Registration === "EMAIL" || type.Registration === "EXTERNAL"
          ? type.RegisterOn
          : "",
    };

    // if type is FREE, also update moderation to 0
    if (type.Registration === "FREE") {
      input = {
        ...input,
        Moderation: false,
      };
    }

    return saveRegistrationType({
      variables: {
        Input: input,
      },
    });
  };

  return (
    <Fragment>
      <GatheringBreadcrumbs />
      <div className="card">
        <CardHeader
          title={t("gatherings:signupmethod")}
          onDismiss={() => push(`/gathering/${gathering.ID}/registration`)}
          onSubmit={onSubmit}
        />
        <div className="card-body">
          {types.map(renderItem)}
          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-secondary"
              disabled={saving}
              onClick={onSubmit}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </div>

      <Notification
        type="info"
        content={t("common:saving")}
        open={saving}
        hideAfter={3000}
      />
    </Fragment>
  );
});

export default GatheringRegistrationType;

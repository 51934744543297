import React, { useState, useMemo } from "react";

/* packages */
import { get, pick } from "lodash";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";

/* components */
import Icon from "../../../../components/Icon";

import {
  SuggestedTimesProvider,
  SuggestedTimesFormGroup,
  useSuggestions,
} from "../SuggestedTimes";

/* context */
import {
  useMeeting,
  useMeetingAction,
  useAgendaRefetchQueries,
  VISIBILITY_STATUS,
} from "../../../../contexts/AgendaContext";

/* lib */
import { getMomentFromString } from "../../../../lib/common";

/* graphql */
import { MUTATION_UPDATE_MEETING } from "../../../../graphql/Meetings";

const MeetingRescheduleAction = React.memo(() => {
  const { t } = useTranslation(["meeting"]);

  const { isOwner } = useMeeting();

  const [, setOpened] = useMeetingAction();

  return (
    <div className="mb-2">
      <div
        className="d-inline-flex link-arrow align-items-center icon-secondary pointer"
        onClick={() => setOpened(VISIBILITY_STATUS.RESCHEDULE)}
      >
        <span className="text-primary mr-1">
          {t(
            `meeting:form.reschedule.button.action.${
              isOwner ? "MeetingWith" : "RequestedBy"
            }`
          )}
        </span>
        <Icon className="text-secondary mx-1" iconType="ArrowDropRight" />
      </div>
    </div>
  );
});

const MeetingRescheduleForm = React.memo(() => {
  const {
    Meeting: { ID },
  } = useMeeting();

  const refetchQueries = useAgendaRefetchQueries();

  const { t } = useTranslation(["meeting"]);

  const [, setOpened] = useMeetingAction();

  const [DeclineReason, setDeclineReason] = useState("");

  const {
    onSuggestionAdd,
    value: [SuggestedTimes],
  } = useSuggestions();

  const [onRescheduleMeeting, { loading: updating, error }] = useMutation(
    MUTATION_UPDATE_MEETING,
    { awaitRefetchQueries: true, refetchQueries }
  );

  function onSubmit(e) {
    e.preventDefault();

    if (!(!updating && !!SuggestedTimes.length)) {
      return;
    }

    let Input = { ID };

    if (!!DeclineReason.trim().length) {
      Input = {
        ...Input,
        DeclineReason,
      };
    }

    Input = {
      ...Input,
      SuggestedTimes: SuggestedTimes.map(({ date, time }) =>
        getMomentFromString(date, time).format("YYYY-MM-DD HH:mm:ss")
      ),
    };

    return onRescheduleMeeting({ variables: { Input } }).then(() =>
      setOpened(false)
    );
  }

  return (
    <form className="" onSubmit={onSubmit}>
      <h3 className="text-dark mb-3">{t("meeting:form.reschedule.title")}</h3>
      <div className="form-group mb-3">
        <label htmlFor="DeclineReason" className="text-primary">
          {t("meeting:form.reschedule.input.label.Message")}
        </label>
        <input
          id="DeclineReason"
          type="text"
          className="form-control"
          name="DeclineReason"
          value={DeclineReason}
          onChange={({ target: { value } }) => setDeclineReason(value)}
        />
      </div>

      <div className="form-group">
        <label className="text-primary">
          {t("meeting:form.reschedule.input.label.SuggestedTimes")}
        </label>
        <SuggestedTimesFormGroup />
      </div>

      <div className="form-group">
        <a
          className="d-flex align-items-center link-secondary font-weight-bold text-decoration-none text-secondary"
          onClick={onSuggestionAdd}
        >
          <Icon className="mr-2" iconType="Add" />
          <span>{t("agenda:meeting.button.suggest")}</span>
        </a>
      </div>

      <div className="">
        <button
          className="btn btn-primary"
          type="button"
          onClick={onSubmit}
          disabled={!SuggestedTimes.length}
        >
          {updating && (
            <span
              className="spinner-border spinner-border-sm mr-2 mb-1"
              role="status"
              aria-hidden="true"
            />
          )}
          {t("meeting:form.reschedule.button.submit")}
        </button>
      </div>
      {!!error && (
        <div className="mt-3">
          <small className="text-danger font-weight-bold">
            {get(error, "graphQLErrors[0].message")}
          </small>
        </div>
      )}
    </form>
  );
});

const MeetingRescheduleFormWrapper = React.memo(() => {
  const {
    Meeting: { Status },
  } = useMeeting();

  const [opened] = useMeetingAction();

  //! Cant reschedule if canceled
  if (Status === 0) {
    return null;
  }

  if (opened === VISIBILITY_STATUS.RESCHEDULE) {
    return (
      <SuggestedTimesProvider>
        <MeetingRescheduleForm />
      </SuggestedTimesProvider>
    );
  }

  return <MeetingRescheduleAction />;
});

export default MeetingRescheduleFormWrapper;

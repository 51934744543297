import React, { Fragment, useMemo } from "react";

import { get } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import parseHTML from "html-react-parser";

/* layout */
import DefaultLayout from "../../../layout/DefaultLayout";

/* context */
import { useLocale } from "../../../contexts/UserContext";
import LinkArrowExternal from "../../../components/LinkArrowExternal";

/* components */
import LinkArrow from "../../../components/LinkArrow";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ContactBlock from "./ContactBlock";

import images from "../../../images";

import { READ_ONE_PROVADA_RAI_SERVICE } from "../../../graphql/Service";
import { useAttendeeExhibitor } from "../../../contexts/ExhibitorContext";

const ContactPersonBlock = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  return (
    <div className="card">
      <div className="card-body">
        <h1>{t("standservices:needhelp")}</h1>
        <p className="mb-3">{t("standservices:raihelpdescription")}</p>
        <ContactBlock />
      </div>
    </div>
  );
});

const RAILink = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const AttendeeExhibitor = useAttendeeExhibitor();

  if (!AttendeeExhibitor) {
    return null;
  }

  return (
    <div className="card-body">
      <div className="d-flex">
        <div
          className="services-card-image background-image mr-2"
          style={{ backgroundImage: `url(${images.rai_service})` }}
        ></div>
        <div className="flex-1">
          <h2>{t("standservices:rai")}</h2>
          <LinkArrowExternal to={AttendeeExhibitor.RAILink}>
            {t("standservices:orderViaWeb")}
          </LinkArrowExternal>
        </div>
      </div>
    </div>
  );
});

const RAIServices = React.memo(
  ({
    match: {
      params: { ID },
    },
    history: { goBack },
  }) => {
    const [Locale] = useLocale();

    const { t } = useTranslation(["common", "standservices"]);

    const { data } = useQuery(READ_ONE_PROVADA_RAI_SERVICE, {
      variables: {
        ID: ID,
      },
      skip: !!!ID,
      onCompleted: ({ readOneProvadaRAIService }) => {
        if (!readOneProvadaRAIService) {
          goBack();
        }
      },
    });

    const service = useMemo(
      () =>
        get(data, "readOneProvadaRAIService") || {
          ID: null,
          Title: "",
          Description: "",
        },
      [data]
    );

    if (!service) {
      return null;
    }

    return (
      <DefaultLayout>
        <Breadcrumbs>
          <Breadcrumbs.List>
            <Breadcrumbs.ListItem to="/services">
              {t("standservices:raiServices")}
            </Breadcrumbs.ListItem>
            <Breadcrumbs.ListItem>{service.Title}</Breadcrumbs.ListItem>
          </Breadcrumbs.List>
          <Breadcrumbs.Addition.Exposant />
        </Breadcrumbs>

        <div className="row">
          <div className="col-12 col-md-8">
            <div className="card">
              <div className="card-body">
                <h1>{`${service.Title}`}</h1>
                <div>{parseHTML(service.Description || "")}</div>
              </div>
              {service.Type === "ROOM" ? (
                <Fragment>
                  <div className="card-body">
                    <h2>{t("standservices:roomAvailability")}</h2>
                    <LinkArrow to="/gathering/rooms">
                      {t("standservices:viewAvailableRooms")}
                    </LinkArrow>
                  </div>
                  <div className="card-body">
                    <h2>{t("standservices:mymeetings")}</h2>
                    <LinkArrow to="/gathering">
                      {t("standservices:meetingOverview")}
                    </LinkArrow>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <RAILink />
                  <div className="card-body">
                    <h2>{t("standservices:raiConditions")}</h2>
                    <LinkArrowExternal to="https://www.rai.nl/voorwaarden-en-reglementen/algemene-voorwaarden-exhibitor-services/">
                      {t("standservices:generalConditions")}
                    </LinkArrowExternal>
                  </div>
                  <div className="card-body">
                    <h2>{t("standservices:raiFAQ")}</h2>
                    {Locale.slice(0, 2) === "en" ? (
                      <LinkArrowExternal to="https://www.rai.nl/en/frequently-asked-questions/">
                        {t("standservices:exhibitorFAQ")}
                      </LinkArrowExternal>
                    ) : (
                      <LinkArrowExternal to="https://www.rai.nl/veelgestelde-vragen/">
                        {t("standservices:exhibitorFAQ")}
                      </LinkArrowExternal>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <ContactPersonBlock ID={ID} />
          </div>
        </div>
      </DefaultLayout>
    );
  }
);

export default RAIServices;

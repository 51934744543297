import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Badge from './Badge';

const CurrentStage = React.memo(({ stage, top }) => {
	const { t } = useTranslation(['common', 'gatherings']);

	const badgeColor = useMemo(() => stage === "DRAFT" ? { primary: false, secondary: true } : { primary: false, leaf: true }, [stage]);
	const topClasses = "position-absolute right-0 top-0";

	return <Badge {...badgeColor} className={classNames({ [topClasses]: top })}>{t(`gatherings:${stage}`)}</Badge>;
});

CurrentStage.defaultProps = {
	stage: 'DRAFT', // DRAFT, LIVE
	top: true, // sticking it to top right
}

export default CurrentStage;
import React from "react";

const Wifi = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path d="M40.004 46.452a10 10 0 1010 10 10 10 0 00-10-10zm0 17.447a7.449 7.449 0 117.449-7.449 7.449 7.449 0 01-7.45 7.45z" />
      <path d="M55.724 41.404a22.258 22.258 0 00-31.442 0 4.09 4.09 0 005.783 5.783 14.071 14.071 0 0119.876 0 4.09 4.09 0 005.783-5.783zm-1.8 3.98a1.539 1.539 0 01-2.177 0 16.623 16.623 0 00-23.482 0 1.54 1.54 0 01-2.177-2.177 19.7 19.7 0 0127.836 0 1.541 1.541 0 01-.002 2.177z" />
      <path d="M63.266 33.863a32.9 32.9 0 00-46.525 0 4.09 4.09 0 005.783 5.783 24.75 24.75 0 0134.96 0 4.09 4.09 0 005.783-5.783h-.001zm-1.8 3.98a1.539 1.539 0 01-2.177 0 27.3 27.3 0 00-38.566 0 1.54 1.54 0 01-2.177-2.177 30.348 30.348 0 0142.918 0 1.541 1.541 0 01-.001 2.176z" />
      <path d="M70.804 26.321a43.531 43.531 0 00-61.562-.047l-.047.047a4.092 4.092 0 005.787 5.788 35.426 35.426 0 0150.043 0 4.09 4.09 0 005.779-5.788zm-1.8 3.98a1.539 1.539 0 01-2.177 0 37.979 37.979 0 00-53.65 0 1.539 1.539 0 01-2.173-2.177 40.982 40.982 0 0157.957-.043l.043.043a1.541 1.541 0 010 2.177z" />
      <path d="M44.912 55.91a1.275 1.275 0 00-1.451 1.07 3.515 3.515 0 01-3.457 2.967 1.275 1.275 0 10-.008 2.55h.008a6.082 6.082 0 005.978-5.137 1.275 1.275 0 00-1.07-1.45z" />
      <path d="M44.897 52.898a6.083 6.083 0 00-1.014-1.086 1.275 1.275 0 00-1.659 1.937l.022.018a3.512 3.512 0 01.588.63 1.275 1.275 0 002.063-1.5z" />
    </svg>
  );
};

export default Wifi;

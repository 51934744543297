import dayjs from "dayjs";

import i18n from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";

import XHR from "i18next-xhr-backend";
import BackendAdapter from "i18next-multiload-backend-adapter";

import { initReactI18next } from "react-i18next";

import en from "./lang/en.json";
import nl from "./lang/nl.json";

const namespaces = Object.keys(en);

const resources = { en_US: en, nl_NL: nl };

i18n
  .use(BackendAdapter)
  .use(intervalPlural)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: process.env.NODE_ENV === "production" ? undefined : resources, // Preload resources for production

    fallbackLng: "en_US",
    whitelist: ["en_US", "nl_NL"],

    ns: namespaces,
    defaultNS: "common",

    load: "all",
    preload: ["en_US", "nl_NL"],

    // set default languate
    lng: "nl_NL",

    keySeparator: ".", // we do not use keys in form messages.welcome

    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        if (format === "uppercase") {
          return value.toUpperCase();
        }

        if (value instanceof dayjs) {
          return dayjs(value).format(format);
        }

        return value;
      },
    },

    react: {
      wait: true,
    },

    backend: {
      backend: XHR,
      backendOption: {
        loadPath: `${process.env.REACT_APP_WEBSITE_URL}/ajax/microcopy?lng={{lng}}&ns={{ns}}`,
        allowMultiLoading: true,
      },
    },
  });

export default i18n;

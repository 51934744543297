import React from 'react';

/* packages */
import { useTranslation } from 'react-i18next';

import { LOGIN_URL } from '../../utils/globals';

/* layout */
import FluidLayout from '../../layout/FluidLayout';
import DefaultLayout from '../../layout/DefaultLayout';

const RegistrationConfirm = React.memo(({ history: { push, replace }, match: { params: { ID } } }) => {
  const { t } = useTranslation(['registrationConfirm']);

  function onSubmit() {
    window.location.href = LOGIN_URL;
    return null;
  }

  return (
    <DefaultLayout>
      <div className="card">

        <div className="card-body my-3">
          <h1 className="text-center mb-3">
            {t('registrationConfirm:title')}
          </h1>
          <div className="flex-center">
            <h3 className="font-weight-normal mb-4 text-align-start">
              {t('registrationConfirm:content')}
            </h3>
          </div>

          <div className="d-flex flex-center">
            <button className="btn btn-secondary text-decoration-none" onClick={onSubmit}>
              {t('registrationConfirm:button.submit')}
            </button>
          </div>

        </div>
      </div>
    </DefaultLayout>
  );
});

export default RegistrationConfirm;


import React, { useMemo, useContext, useEffect, Fragment } from "react";

/* packages */
import qs from "query-string";
import dayjs from "dayjs";
import classNames from "classnames";

import { get, map } from "lodash";

import { Switch, Link, Redirect, Route, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { scroller } from "react-scroll";

/* components */
import CardLink from "../../components/CardLink";
import Breadcrumbs from "../../components/Breadcrumbs";
import LinkLikeArrow from "../../components/LinkLikeArrow";
import PurchaseTicketCard from "../../components/PurchaseTicketCard";

/* context */
import EventContext, {
  useEvent,
  useEventDays,
} from "../../contexts/EventContext";

import { useUser } from "../../contexts/UserContext";
import { AttendeeRolesProvider } from "../../contexts/AttendeeRolesContext";
import {
  useAttendeeCompany,
  useAttendeeExhibitor,
} from "../../contexts/ExhibitorContext";

/* layout */
import RoleRoute from "../../components/RoleRoute";
import DefaultLayout from "../../layout/DefaultLayout";

/* graphql */
import {
  QUERY_TICKETS,
  QUERY_EXHIBITOR_ATTENDEES,
  MUTATION_CANCEL_TICKET,
} from "../../graphql/Tickets";

/* pages */
import TicketRoute from "./Ticket";
import TicketInviteRoute from "./TicketInvite";
import TicketConfirmRoute from "./TicketConfirm";
import TicketsSearchRoute from "./TicketsSearch";

import { DAYS_ENUM, ENABLE_EMPlOYEE_INVITE } from "../../utils/globals";

const TicketListItem = React.memo(({ item }) => {
  const { t } = useTranslation(["tickets"]);

  const { ID: ExhibitorID } = useAttendeeExhibitor();

  const { ID, Company, Image } = item;

  const Title = useMemo(
    () => [item.Firstname, item.Affix, item.Surname].filter(Boolean).join(" "),
    [item]
  );

  const backgroundImage = useMemo(() => get(Image, "AbsoluteLink"), [Image]);

  const [onCancelTicket, { loading: updatingTicket }] = useMutation(
    MUTATION_CANCEL_TICKET,
    {
      variables: { TicketID: ID },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_TICKETS,
          variables: {
            Filter: {
              Approved__eq: false,
              ExhibitorID__eq: ExhibitorID,
            },
          },
        },
      ],
    }
  );

  return (
    <div
      id={`TicketID-${ID}`}
      className="d-flex border-bottom py-2 last-of-type-border-none "
    >
      <div>
        <div
          className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
      </div>
      <div className="flex-1 d-flex align-items-center">
        <div className="flex-1">
          <h3 className="mb-1">{Title}</h3>
          <p className="text-primary mb-2">{Company}</p>
          <div className="mb-3">
            <Link
              to={`/tickets/confirm/${ID}`}
              className="btn btn-primary mr-2 text-decoration-none"
            >
              {t("tickets:ticket.button.submit")}
            </Link>
            <button
              className="btn btn-outline-secondary"
              onClick={onCancelTicket}
              disabled={updatingTicket}
            >
              {t("tickets:ticket.button.cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

TicketListItem.defaultProps = {
  item: {
    ID: null,
    Name: "",
    Affix: "",
    Surname: "",
    Company: "",
    Image: {
      ID: null,
      AbsoluteLink: "",
    },
  },
};

const TicketsList = React.memo(({ Tickets }) => {
  const { t } = useTranslation(["tickets"]);

  const { Title: CompanyTitle } = useAttendeeCompany();

  const {
    location: { search },
  } = useHistory();

  const { ID: TicketID } = useMemo(
    () => qs.parse(search, { parseNumbers: true }),
    []
  );

  useEffect(() => {
    if (TicketID && Tickets.length) {
      scroller.scrollTo(`TicketID-${TicketID}`, {
        smooth: true,
      });
    }
  }, [TicketID, Tickets]);

  return (
    <div className="card">
      <div className="card-body border-bottom-0">
        <h2>
          {t("tickets:ticket.title_interval", {
            postProcess: "interval",
            count: Tickets.length,
          })}
        </h2>
        {!!Tickets.length && (
          <p>{t("tickets:ticket.content", { CompanyTitle })}</p>
        )}
      </div>
      <div className="card-body">
        {Tickets.map((item) => (
          <TicketListItem key={item.ID} item={item} />
        ))}
      </div>
    </div>
  );
});

TicketsList.defaultProps = {
  Tickets: [],
};

const EmployeeCard = React.memo(
  ({
    item: {
      ID,
      Member: { FullName },
      Role: { Code: RoleCode },
      ...rest
    },
  }) => {
    const { t } = useTranslation(["allRoles"]);

    const days = useEventDays();

    return (
      <CardLink to={`/tickets/${ID}`}>
        <h1 className="text-dark">{FullName}</h1>
        <h3 className="text-primary">{t(`allRoles:${RoleCode}.title`)}</h3>
        <div className="d-flex flex-wrap">
          {days.map((day) => {
            const key = DAYS_ENUM[dayjs(day).weekday() - 1];

            return (
              <span
                key={key}
                className={classNames("badge mb-2 mr-2", {
                  "badge-secondary": !!rest[key],
                  "badge-disabled": !rest[key],
                })}
              >
                {dayjs(day).format("dddd")}
              </span>
            );
          })}
        </div>
      </CardLink>
    );
  }
);

const TicketsAttendees = React.memo(() => {
  const { t } = useTranslation(["tickets", "allRoles"]);
  const { ID: UserID } = useUser();

  const { ID: ExhibitorID } = useAttendeeExhibitor();
  const { ID: EventID } = useEvent();

  const AttendeeCompany = useAttendeeCompany();

  const { data: ExhibitorAttendees } = useQuery(QUERY_EXHIBITOR_ATTENDEES, {
    skip: !ExhibitorID,
    variables: {
      Filter: {
        EventID__eq: EventID,
        ExhibitorID__eq: ExhibitorID,
      },
      Exclude: {
        MemberID__eq: UserID, //! Important - Exclude currently logged in member
      },
    },
  });

  const Attendees = useMemo(
    () =>
      map(get(ExhibitorAttendees, "readProvadaAttendees.edges", []), "node"),
    [ExhibitorAttendees]
  );
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <h2>{t("tickets:attendee.title")}</h2>
          <p>
            {t("tickets:attendee.content", {
              CompanyTitle: get(AttendeeCompany, "Title", ""),
            })}
          </p>
        </div>
        {Attendees.map((item) => (
          <EmployeeCard key={item.ID} item={item} />
        ))}
      </div>
    </div>
  );
});

const TicketsRoute = React.memo(({ match: { url } }) => {
  const { t } = useTranslation(["tickets"]);

  const { ID: ExhibitorID } = useAttendeeExhibitor();
  const {
    event: { Title: EventTitle },
  } = useContext(EventContext);

  const { data: TicketsData } = useQuery(QUERY_TICKETS, {
    skip: !!!ExhibitorID,
    variables: {
      Filter: {
        Approved__eq: false,
        ExhibitorID__eq: ExhibitorID,
      },
    },
  });

  const Tickets = useMemo(
    () => map(get(TicketsData, "readProvadaTickets.edges", []), "node"),
    [TicketsData]
  );

  return (
    <div className="row">
      <div className={classNames("col-12", { "col-md-6": !!Tickets.length })}>
        <div className="card">
          {ENABLE_EMPlOYEE_INVITE && (
            <Link
              to={`${url}/search`}
              className="card-body text-decoration-none"
            >
              <h2 className="text-dark">{t("tickets:employees.title")}</h2>
              <p className="text-dark">
                {t("tickets:employees.content", { EventTitle })}
              </p>
              <LinkLikeArrow>{t("tickets:employees.link")}</LinkLikeArrow>
            </Link>
          )}
          <PurchaseTicketCard />
        </div>
      </div>

      {!!Tickets.length && (
        <div className="col-12 col-md-6">
          <TicketsList Tickets={Tickets} />
        </div>
      )}

      <TicketsAttendees />
    </div>
  );
});

const AttendeesRoute = React.memo(({ match: { path } }) => {
  return (
    <AttendeeRolesProvider>
      <Switch>
        {ENABLE_EMPlOYEE_INVITE && (
          <Fragment>
            <Route
              exact
              path={`${path}/search`}
              component={TicketsSearchRoute}
            />
            <Route path={`${path}/invite`} component={TicketInviteRoute} />
          </Fragment>
        )}
        <Route
          exact
          path={`${path}/confirm/:ID(\\d+)`}
          component={TicketConfirmRoute}
        />
        <Route exact path={`${path}/:ID(\\d+)`} component={TicketRoute} />
        <Redirect to={path} />
      </Switch>
    </AttendeeRolesProvider>
  );
});

const TicketsRouter = React.memo(({ match: { path, url } }) => {
  const { t } = useTranslation(["tickets"]);

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem>{t("tickets:title")}</Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>

      <Switch>
        <Route exact path={path} component={TicketsRoute} />
        <RoleRoute
          path={path}
          component={AttendeesRoute}
          roles={["MANAGER"]}
          redirect={url}
        />
        <Redirect to={path} />
      </Switch>
    </DefaultLayout>
  );
});

export default TicketsRouter;

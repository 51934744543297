import React, { Fragment, useMemo, useCallback } from "react";

import { get, map, last } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

/* layout */

/* context */
import LinkArrowExternal from "../../../components/LinkArrowExternal";
import { useService } from "../ProvadaServices";

/* components */
import Breadcrumbs from "../../../components/Breadcrumbs";

import { useEvent } from "../../../contexts/EventContext";

import {
  QUERY_EVENT_BANNERS,
  READ_ONE_PROVADA_EVENT,
} from "../../../graphql/Event";

const RAIServices = React.memo(({ history: { goBack } }) => {
  const { t } = useTranslation(["standservices"]);

  const { ID: EventID } = useEvent();
  const { ID, Title: ServiceTitle } = useService();

  const { data: EventData } = useQuery(READ_ONE_PROVADA_EVENT, {
    variables: {
      ID: EventID,
    },
  });

  const { BannersTitle, BannersExcerpt } = useMemo(
    () =>
      get(EventData, "readOneProvadaEvent", {
        BannersTitle: "",
        BannersExcerpt: "",
      }),
    [EventData]
  );

  const { data: BannersData } = useQuery(QUERY_EVENT_BANNERS, {
    variables: {
      Input: {
        Active__eq: true,
        EventID__eq: EventID,
      },
    },
  });

  const Banners = useMemo(
    () => map(get(BannersData, "readProvadaBanners.edges", []), "node"),
    [BannersData]
  );

  const renderBanner = useCallback(
    ({ Title, Image: { ID, Size, AbsoluteLink } }) => (
      <div className="card-body" key={ID}>
        <div className="d-flex">
          <div className="mr-3">
            <i className="font-size-1-5 text-primary if-Image"></i>
          </div>
          <div className="flex-1">
            <h2>{Title}</h2>
            <p>
              {`${last(AbsoluteLink.split(".") || "").toUpperCase()}`}{" "}
              {t("standservices:file")}, {Size}
            </p>
            <LinkArrowExternal to={`${AbsoluteLink}`}>
              {" "}
              {t("standservices:download")}
            </LinkArrowExternal>
          </div>
        </div>
      </div>
    ),
    []
  );

  return (
    <Fragment>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/services">
            {t("standservices:stand")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem to={`/services/provada/${ID}`}>
            {ServiceTitle}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>
            {t("standservices:banners")}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h2>{BannersTitle}</h2>
              <p>{BannersExcerpt}</p>
            </div>
          </div>
          <div className="card">{Banners.map(renderBanner)}</div>
        </div>
      </div>
    </Fragment>
  );
});

export default RAIServices;

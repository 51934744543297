

import React from 'react';

const Premium = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.954 83.879" className={className}>
      <g id="premium" transform="translate(-36.395)">
        <path id="Path_208" d="M103.6 108.931a2.508 2.508 0 0 0-2.166-1.251h-42.59a2.508 2.508 0 0 0-2.166 1.251l-3.039 5.264a1.23 1.23 0 0 0 2.131 1.23l2.341-4.054 8.883 15.386H49.227l3.756-6.506a1.23 1.23 0 1 0-2.131-1.23l-4.545 7.873a1.99 1.99 0 0 0 .239 2.325l32.073 35.922a2 2 0 0 0 1.486.666 2 2 0 0 0 1.486-.664l32.139-35.923a1.99 1.99 0 0 0 .241-2.326zm-20.865 17.826H71.256l8.883-15.386 8.883 15.386h-.107a1.23 1.23 0 1 0 0 2.461h.592l-9.4 31.9-9.342-31.9h11.97a1.23 1.23 0 0 0 0-2.461zm8.419-1.23l-8.884-15.386h17.767zm.92 3.691h18.356l-27.371 30.594zm18.977-2.461H93.284l8.883-15.386zm-50.81-16.616h17.767l-8.883 15.386zm7.959 19.077l8.961 30.6-27.32-30.6z" className="cls-1" data-name="Path 208" transform="translate(-8.064 -90.039)" />
        <path id="Path_209" d="M350.574 384.463a1.231 1.231 0 0 0-.185-.1 12.011 12.011 0 0 1-2.141-1.212 1.23 1.23 0 0 0-1.435 2 13.653 13.653 0 0 0 1.187.761 11.5 11.5 0 0 0-5.4 6.374 11.5 11.5 0 0 0-5.4-6.373 11.5 11.5 0 0 0 5.4-6.374l.038.111a1.23 1.23 0 1 0 2.324-.809 8.99 8.99 0 0 1-.245-.828l-.511-2.089a1.654 1.654 0 0 0-3.212 0l-.511 2.089a9.468 9.468 0 0 1-5.674 6.352 1.23 1.23 0 0 0-.185.1 1.739 1.739 0 0 0 0 2.893 1.226 1.226 0 0 0 .185.1 9.468 9.468 0 0 1 5.674 6.352l.511 2.089a1.653 1.653 0 0 0 3.212 0l.511-2.089a9.468 9.468 0 0 1 5.674-6.352 1.25 1.25 0 0 0 .185-.1 1.739 1.739 0 0 0 0-2.894z" className="cls-1" data-name="Path 209" transform="translate(-248.723 -313.281)" />
        <path id="Path_210" d="M423.681 77.906a1.223 1.223 0 0 0-.185-.1 4.084 4.084 0 0 1-2.515-2.79l-.258-1.053a1.441 1.441 0 0 0-1.4-1.1 1.44 1.44 0 0 0-1.4 1.1l-.258 1.053a4.083 4.083 0 0 1-2.515 2.79 1.228 1.228 0 0 0-.185.1 1.487 1.487 0 0 0 0 2.474 1.229 1.229 0 0 0 .184.1 4.083 4.083 0 0 1 2.515 2.79l.258 1.053a1.44 1.44 0 0 0 1.4 1.1 1.44 1.44 0 0 0 1.4-1.1l.258-1.053a4.084 4.084 0 0 1 2.515-2.79 1.225 1.225 0 0 0 .184-.1 1.487 1.487 0 0 0 0-2.474zm-4.36 3.007a6.355 6.355 0 0 0-1.614-1.77 6.352 6.352 0 0 0 1.614-1.77 6.354 6.354 0 0 0 1.614 1.77 6.35 6.35 0 0 0-1.613 1.77z" className="cls-1" data-name="Path 210" transform="translate(-315.995 -60.925)" />
        <path id="Path_211" d="M41.2 14.253l.369 1.509a1.536 1.536 0 0 0 2.984 0l.369-1.509a6.508 6.508 0 0 1 3.9-4.356 1.218 1.218 0 0 0 .185-.1 1.6 1.6 0 0 0 0-2.658 1.221 1.221 0 0 0-.185-.1 6.508 6.508 0 0 1-3.9-4.356l-.369-1.509a1.536 1.536 0 0 0-2.984 0l-.374 1.506a6.507 6.507 0 0 1-3.9 4.356 1.22 1.22 0 0 0-.185.1 1.6 1.6 0 0 0 0 2.659 1.238 1.238 0 0 0 .184.1 6.507 6.507 0 0 1 3.9 4.356zm1.861-9.459A8.611 8.611 0 0 0 46.3 8.466a8.613 8.613 0 0 0-3.242 3.672 8.614 8.614 0 0 0-3.242-3.672 8.613 8.613 0 0 0 3.24-3.672z" className="cls-1" data-name="Path 211" />
      </g>
    </svg>
  )
}

Premium.defaultProps = {
  className: ''
};

export default Premium;
import React, { useMemo, useContext, useEffect } from 'react';

/* packages */
import { get, filter, map } from 'lodash';

import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

/* graphql */
import { QUERY_ROLES } from '../graphql/Tickets';

/* lib */
import { useSafeSetState } from '../lib/hooks';

let AttendeeRolesContext;

const { Consumer, Provider } = AttendeeRolesContext = React.createContext();

const ROLES_ENUM = ['MANAGER', 'EMPLOYEE'];

const AttendeeRolesConsumer = React.memo((props) => {
  return (
    <Consumer>
      {context => {
        if (!context) {
          throw new Error('Using AttendeeRolesConsumer outside of AttendeeRolesProvider');
        }

        return props.children(context);
      }}
    </Consumer>
  );
});

const AttendeeRolesProvider = React.memo((props) => {
  const { data: RolesData } = useQuery(QUERY_ROLES);

  const Roles = useMemo(() => filter(map(get(RolesData, 'readProvadaRoles.edges', []), 'node'), ({ Code }) => ROLES_ENUM.includes(Code)), [RolesData]);

  const [Role, setRole] = useSafeSetState({ ID: null, Code: '' });

  useEffect(() => {
    if (!!Roles.length && !Role.ID) {
      setRole(Roles[0])
    }

  }, [Roles]);

  return (
    <Provider
      value={{
        Role,
        Roles,

        setRole
      }}
      {...props}
    />
  );
});

function useAttendeeRoles() {
  return useContext(AttendeeRolesContext);
};

const AttendeeRolesList = React.memo(() => {
  const { t } = useTranslation(['userRole']);

  const { Roles, Role, setRole } = useAttendeeRoles();

  if (!(Array.isArray(Roles) && Roles.length)) {
    return null;
  }

  return (
    <div className="form-group">
      {
        Roles.map(({ ID, Code }) => (
          <div key={ID} className="mb-3 pointer" onClick={() => setRole({ ID })}>
            <div className="custom-control custom-radio mb-0">
              <input
                type="radio"
                id={`Role-${ID}`}
                name={`Role-${ID}`}
                checked={!!(Role && Role.ID == ID)}
                value={ID}
                className="custom-control-input custom-control-secondary"
                onChange={({ target: { value: Role } }) => setRole({ ID: Role })}
              />
              <label
                htmlFor={`Role-${ID}`}
                className="custom-control-label h3 mb-0 font-weight-normal"
              >
                {t(`userRole:${Code}.title`)}
              </label>
              <p className="text-primary-50">
                {t(`userRole:${Code}.description`)}
              </p>
            </div>
          </div>

        ))
      }
    </div>
  );
});

export {
  useAttendeeRoles,
  AttendeeRolesList,
  AttendeeRolesProvider,
  AttendeeRolesConsumer,
};

export default AttendeeRolesContext;
import gql from "graphql-tag";

import { FRAGMENT_FILE } from "./Event";

export const READ_ONE_PROVADA_RAI_SERVICE = gql`
  query readOneProvadaRAIService($ID: ID!) {
    readOneProvadaRAIService(ID: $ID) {
      ID
      Title
      Description
      Type
      ContactPerson {
        ID
        Email
        Phone
        FullName
        PublicEmail
        PublicPhone
        Picture {
          ID
          AbsoluteLink
        }
      }
    }
  }
`;

export const READ_ONE_PROVADA_SERVICE = gql`
  query readOneProvadaService($ID: ID!) {
    readOneProvadaService(ID: $ID) {
      ID
      Title
      Description
      SupplierLink
      SupplierLinkText
      Type
      HelpDescription
      Icon
      ContactPerson {
        ID
        Email
        Phone
        FullName
        PublicEmail
        PublicPhone
        Picture {
          ID
          AbsoluteLink
        }
      }
      Documents {
        edges {
          node {
            ID
            Title
            File {
              ...FragmentFile
            }
          }
        }
      }
      ServiceDeadlines {
        edges {
          node {
            ID
            Date
          }
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const READ_PROVADA_SERVICE_UPLOADS = gql`
  query readProvadaServiceUploads($ServiceID: ID!) {
    readProvadaServiceUploads(Filter: { ServiceID__eq: $ServiceID }) {
      edges {
        node {
          ID
          Status
          File {
            ...FragmentFile
          }
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const READ_PROVADA_SERVICES = gql`
  query readProvadaServices($Filter: ProvadaServiceFilterReadInputType) {
    readProvadaServices(Filter: $Filter) {
      edges {
        node {
          ID
          Title
          Icon
          Description
          Active
          Type
          ContactPerson {
            FullName
            Email
            Phone
          }
          Uploads {
            edges {
              node {
                ID
                Status
                File {
                  ...FragmentFile
                }
              }
            }
          }
          ServiceDeadlines {
            edges {
              node {
                ID
                Date
                Discount
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const READ_ONE_SILVERSTRIPE_SITE_CONFIG = gql`
  query readOneSilverStripeSiteConfig($ID: ID!) {
    readOneSilverStripeSiteConfig(ID: $ID) {
      DefaultHelpPhone
      DefaultHelpEmail
      HelpContactPerson {
        ID
        FullName
        PublicEmail
        PublicPhone
        Picture {
          ID
          AbsoluteLink
        }
      }
    }
  }
`;

export const READ_ONE_PROVADA_SERVICE_UPLOAD = gql`
  query readOneProvadaServiceUpload($ID: ID!) {
    readOneProvadaServiceUpload(ID: $ID) {
      ID
      Status
      File {
        ...FragmentFile
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const READ_PROVADA_RAI_SERVICES = gql`
  query readProvadaRAIServices($EventID: ID!) {
    readProvadaRAIServices(
      Filter: { EventID__eq: $EventID, Active__eq: true }
    ) {
      edges {
        node {
          ID
          Title
          Icon
          Description
          Active
          Type
          ContactPerson {
            FullName
            Email
            Phone
          }
          RAIServiceDeadlines {
            edges {
              node {
                ID
                Date
                Discount
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PROVADA_SERVICE_UPLOAD = gql`
  mutation createProvadaServiceUpload(
    $Input: ProvadaServiceUploadCreateInputType!
  ) {
    createProvadaServiceUpload(Input: $Input) {
      ID
      File {
        ...FragmentFile
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const DELETE_PROVADA_SERVICE_UPLOAD = gql`
  mutation deleteProvadaServiceUpload($IDs: [ID]!) {
    deleteProvadaServiceUpload(IDs: $IDs) {
      ID
    }
  }
`;

export const READ_SERVICES = gql`
  query readServices($EventID: ID!) {
    readProvadaServices(Filter: { EventID__eq: $EventID, Active__eq: true }) {
      edges {
        node {
          ID
          Title
          Icon
          Type
          Uploads {
            edges {
              node {
                ID
                Status
                File {
                  ...FragmentFile
                }
              }
            }
          }
          ServiceDeadlines {
            edges {
              node {
                ID
                Date
                Discount
              }
            }
          }
        }
      }
    }
    readProvadaRAIServices(
      Filter: { EventID__eq: $EventID, Active__eq: true }
    ) {
      edges {
        node {
          ID
          Title
          Icon
          Type
          RAIServiceDeadlines {
            edges {
              node {
                ID
                Date
                Discount
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

import React, { useMemo, Fragment, useEffect } from "react";

import dayjs from "dayjs";
import get from "lodash/get";
import pick from "lodash/pick";
import useSetState from "react-use/lib/useSetState";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUpdateEffect } from "react-use";
import { useQuery, useMutation } from "@apollo/react-hooks";

import CardHeader from "../../../components/CardHeader";
import LinkArrow from "../../../components/LinkArrow";
import Notification from "../../../components/Notification";

import {
  useAttendeeExhibitor,
  useIsMainExhibitorManager,
} from "../../../contexts/ExhibitorContext";

import {
  useGathering,
  useGatheringIsRoomReservationSet,
} from "../../../contexts/GatheringContext";
import {
  useEvent,
  useEventDays,
  useEventHoursList,
  useEventOpenHours,
} from "../../../contexts/EventContext";

import {
  UPDATE_GATHERING,
  GET_GATHERING_INFO,
} from "../../../graphql/Gathering";
import { GET_AVAILABLE_ROOM_FOR_TIME } from "../../../graphql/Location";
import { QUERY_EXHIBITOR_STANDS } from "../../../graphql/User";

import { BOOKING_PERIOD } from "../../../utils/globals";
import { map } from "lodash";
import { GatheringBreadcrumbs } from "../GatheringEdit";

const TimeSelect = ({ data, onChange }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  const dates = useEventHoursList(data.Date);

  // Remove first BOOKING_PERIOD minuets since that is considered preparation time
  // set start, remove last since end end time and start time will have option to set same times
  //   const timesStart = useMemo(() => dates.slice(0).slice(1, -1), [dates]);

  const timesStart = useMemo(() => dates.slice(0), [dates]);

  //! @todo improve this by slicing out just the times, no need for filter - Aleksandar
  // generate end based on start, only times after start should be shown
  const timesEnd = useMemo(() => {
    const dateList = dates.slice(1);
    const currentStart = dayjs(`${data.Date} ${data.Start}`);

    return dateList.filter((time) => {
      let timeFrame = dayjs(time);

      if (!data.Start || (data.Start && timeFrame.isAfter(currentStart))) {
        return true;
      }

      return false;
    });
  }, [timesStart, data.Start]);

  return (
    <div className="form-row form-group">
      <div className="col">
        <div className="form-group mb-0">
          <label htmlFor="time-select-start" className="text-primary">
            {t("gatherings:starttime")}
          </label>
          <select
            id="time-select-start"
            name="Start"
            className="form-control"
            onChange={onChange}
            value={data.Start.slice(0, 5)}
          >
            {timesStart.map((time) => {
              const timeFrame = dayjs(time).format("HH:mm");

              return <option key={time}>{timeFrame}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="col">
        <div className="form-group mb-0">
          <label htmlFor="time-select-end" className="text-primary">
            {t("gatherings:endtime")}
          </label>
          <select
            id="time-select-end"
            name="End"
            className="form-control"
            onChange={onChange}
            value={data.End.slice(0, 5)}
          >
            {timesEnd.map((time) => {
              const timeFrame = dayjs(time).format("HH:mm");

              return <option key={time}>{timeFrame}</option>;
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

const StandOptions = ({ data, onChange }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  const exhibitor = useAttendeeExhibitor();

  const { data: standsData } = useQuery(QUERY_EXHIBITOR_STANDS, {
    variables: {
      ExhibitorID: exhibitor.ID,
    },
  });

  const stands = useMemo(
    () =>
      map(
        get(standsData, "readProvadaExhibitorStands.edges"),
        ({ node: { Exhibitors, ...rest } }) => ({
          ...rest,
          Exhibitors: map(get(Exhibitors, "edges"), "node"),
        })
      ),
    [standsData]
  );

  return (
    <div className="form-group">
      <label className="text-primary" htmlFor="stand-options">
        {t("gatherings:location")}
      </label>
      <select
        id="stand-options"
        className="form-control"
        name="StandID"
        value={data.StandID}
        onChange={onChange}
      >
        <option value="">Choose here</option>
        {stands.map(({ ID, Title }) => (
          <option value={ID} key={ID}>{`${t("common:stand")} ${Title}`}</option>
        ))}
      </select>
    </div>
  );
};

const GatheringTimeAndLocation = React.memo(
  ({ history: { push, replace }, match: { url: currentPath } }) => {
    const { t } = useTranslation(["common", "gatherings"]);

    const { ID: EventID } = useEvent();

    const days = useEventDays();

    const { data: gathering } = useGathering();

    const isRoomReservationSet = useGatheringIsRoomReservationSet();

    const OpeningHours = useEventOpenHours();

    const isMainExhibitorManager = useIsMainExhibitorManager();

    const defaultGathering = useMemo(
      () => ({
        ID: 0,
        Date: "",
        StandID: 0,
        Start: OpeningHours.RoomBookingStart,
        End: OpeningHours.RoomBookingEnd,
      }),
      [OpeningHours]
    );

    const [timeAndLocation, setTimeAndLocation] = useSetState({
      ...defaultGathering,
    });

    //! Redirect user back if theres a room reservation
    //! You have nothing to do here anymore
    useEffect(() => {
      if (isRoomReservationSet) {
        replace(`/gathering/${gathering.ID}`);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRoomReservationSet]);

    // 18 jun, 12:30 13:30
    const { data: locationsAvailable, loading: locationsLoading } = useQuery(
      GET_AVAILABLE_ROOM_FOR_TIME,
      {
        skip: !(
          isMainExhibitorManager &&
          timeAndLocation.Date &&
          timeAndLocation.Start &&
          timeAndLocation.End
        ),
        fetchPolicy: "network-only",
        variables: {
          EventID,
          Date: timeAndLocation.Date,
          Start: timeAndLocation.Start,
          End: timeAndLocation.End,
        },
      }
    );

    const locations = useMemo(
      () => get(locationsAvailable, "readProvadaLocationsAvailable.edges", []),
      [locationsAvailable]
    );

    const [saveTime, { loading: isSaving, error }] = useMutation(
      UPDATE_GATHERING,
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_GATHERING_INFO,
            variables: {
              ID: gathering.ID,
            },
          },
        ],
      }
    );

    useEffect(() => {
      setTimeAndLocation({
        ...defaultGathering,
        ID: gathering.ID,
        Date: gathering.Date || dayjs(days[0]).format("YYYY-MM-DD"),
        Start: gathering.Start || defaultGathering.Start,
        End: gathering.End || defaultGathering.End,
        StandID: gathering.StandID,
      });
    }, [gathering.ID]);

    // when Start is changed, update End
    useUpdateEffect(() => {
      const start = dayjs(`${timeAndLocation.Date} ${timeAndLocation.Start}`);
      const end = dayjs(`${timeAndLocation.Date} ${timeAndLocation.End}`);
      if (start.isAfter(end)) {
        const newEnd = start.add(BOOKING_PERIOD, "minute");
        setTimeAndLocation({
          ...timeAndLocation,
          End: `${newEnd.format("HH:mm")}:00`,
        });
      }
    }, [timeAndLocation.Start]);

    const updateTimes = ({ currentTarget: { name, value } }) => {
      setTimeAndLocation({
        ...timeAndLocation,
        [name]: `${value}:00`,
      });
    };

    const updateData = ({ currentTarget: { name, value } }) => {
      setTimeAndLocation({
        ...timeAndLocation,
        [name]: value,
      });
    };

    const gatheringDay = () => {
      const eventDate = timeAndLocation.Date
        ? dayjs(timeAndLocation.Date).format("dddd")
        : "";

      return (
        <div className="form-group d-flex flex-row">
          {days.map((day, itx) => {
            let weekday = dayjs(day).format("dddd"),
              date = dayjs(day).format("YYYY-MM-DD"),
              checked = weekday === eventDate;

            return (
              <div key={day}>
                <input
                  id={`day-${itx}`}
                  type="radio"
                  className="d-none custom-form-btn"
                  name="Date"
                  checked={checked}
                  onChange={updateData}
                  value={date}
                />
                <label
                  htmlFor={`day-${itx}`}
                  className="text-capitalize btn btn-outline-primary mr-2 mb-2 font-size-1 px-2 font-weight-normal"
                >
                  {weekday}
                </label>
              </div>
            );
          })}
        </div>
      );
    };

    const roomReservation = () => {
      const { Date: GatheringDate, Start, End } = timeAndLocation;

      if (!(GatheringDate && Start && End)) {
        return <p className="mb-3">{t("gatherings:datetimemissing")}</p>;
      }

      if (locationsLoading) {
        return <p className="mb-3">{t("common:loading")}</p>;
      }

      if (!locations.length) {
        return <p className="mb-3">{t("gatherings:noavailablerooms")}</p>;
      }

      return (
        <Fragment>
          <div className="form-group mb-3">
            {locations.map(
              ({
                node: {
                  ID,
                  Title,
                  Price,
                  Places,
                  Picture: { AbsoluteLink },
                },
              }) => {
                const Start =
                  dayjs(
                    `${timeAndLocation.Date} ${timeAndLocation.Start}`,
                    "YYYY-MM-DD HH:mm:ss"
                  ).unix() * 1000;
                const End =
                  dayjs(
                    `${timeAndLocation.Date} ${timeAndLocation.End}`,
                    "YYYY-MM-DD HH:mm:ss"
                  ).unix() * 1000;

                return (
                  <Link
                    key={ID}
                    className={`d-flex border-bottom py-2 last-of-type-border-none text-decoration-none`}
                    to={{
                      pathname: `${currentPath}/rooms`,
                      search: `LocationID=${ID}&Start=${Start}&End=${End}`,
                    }}
                  >
                    <div>
                      <div
                        className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
                        style={{ backgroundImage: `url(${AbsoluteLink})` }}
                      />
                    </div>
                    <div className="flex-1 d-flex flex-column justify-content-center">
                      <div className="d-flex justify-content-between mb-1">
                        <h3 className="mb-0 flex-1 text-dark">{Title}</h3>
                        <h3 className="mb-0 text-primary-40 font-weight-normal">
                          {`€ ${Price},- p.u.`}
                        </h3>
                      </div>
                      <p className="text-gray mb-0">
                        {`${Places} ${t("gatherings:seats")}`}
                      </p>
                    </div>
                  </Link>
                );
              }
            )}
          </div>
        </Fragment>
      );
    };

    const onSubmit = () => {
      if (isSaving) {
        return;
      }

      const Input = pick(timeAndLocation, [
        "ID",
        "Date",
        "Start",
        "End",
        "StandID",
      ]);

      return saveTime({ variables: { Input } }).then(() => {
        push(`/gathering/${gathering.ID}`);
      });
    };

    const timeString = useMemo(() => {
      const start = dayjs(`${timeAndLocation.Date} ${timeAndLocation.Start}`);
      const end = dayjs(`${timeAndLocation.Date} ${timeAndLocation.End}`);

      return `${start.format("dddd")} ${start.format("HH:mm")} - ${end.format(
        "HH:mm"
      )}`;
    }, [timeAndLocation]);

    return (
      <Fragment>
        <GatheringBreadcrumbs />
        <div className="card">
          <CardHeader
            title={t("gatherings:timeandlocation")}
            onDismiss={() => push(`/gathering/${gathering.ID}`)}
            onSubmit={onSubmit}
          />
          <div className="card-body">
            {gatheringDay()}
            <TimeSelect data={timeAndLocation} onChange={updateTimes} />
            <StandOptions data={timeAndLocation} onChange={updateData} />
            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={isSaving}
                onClick={onSubmit}
              >
                {t("common:save")}
              </button>
            </div>
          </div>
        </div>

        {isMainExhibitorManager && (
          <div className="card">
            <div className="card-body">
              <h3 className="text-bold">{t("gatherings:needroom")}</h3>
              <label className="text-primary">
                {t("gatherings:availableroomsfortime", { time: timeString })}
              </label>
              {roomReservation()}
              <div className="line-through mb-3"></div>
              <p>{t("gatherings:moreroomsatothertimes")}.</p>
              <LinkArrow to={`/gathering/rooms`}>
                {t("gatherings:overviewofavailablerooms")}
              </LinkArrow>
            </div>
          </div>
        )}

        <Notification
          type="error"
          content={get(error, "graphQLErrors[0].message")}
          open={!!get(error, "graphQLErrors[0].message")}
          hideAfter={3000}
        />
        <Notification
          type="info"
          content={t("common:saving")}
          open={isSaving}
          hideAfter={3000}
        />
      </Fragment>
    );
  }
);

export default GatheringTimeAndLocation;

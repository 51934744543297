import React, { useMemo } from "react";

/* packages */
import classNames from "classnames";

import { useTranslation } from "react-i18next";

/* context */
import { useMeeting } from "../../../contexts/AgendaContext";

export const StatusBadge = React.memo(() => {
  const { t } = useTranslation(["meetingStatus"]);

  const {
    Meeting,
    Meeting: { Status, Rescheduled },
  } = useMeeting();

  const StatusEnum = useMemo(() => {
    if (Rescheduled) return 4;
    // if (isRescheduleSuggestionRequest) return 2;
    // if (isOwnerRescheduleSuggestion) return 3;

    return Status;
  }, [Meeting]);

  return (
    <span
      className={classNames("badge position-absolute right-0 top-0", {
        "badge-secondary": Status === 0,
        "badge-primary": Status === -1,
        "badge-leaf": Status === 1,
      })}
    >
      {t(`meetingStatus:${StatusEnum}`)}
    </span>
  );
});

export default StatusBadge;

import gql from "graphql-tag";
import { FRAGMENT_ATTENDEE_DAYS } from "./Tickets";

export const CREATE_TOKEN = gql`
  mutation createToken($Email: String!, $Password: String!) {
    createToken(Email: $Email, Password: $Password) {
      Valid
      Member {
        ID
        FirstName
        Surname
        Email
        Token
      }
      Token
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      Valid
      Member {
        ID
        FirstName
        Surname
        Email
        Token
      }
      Token
    }
  }
`;

export const READ_PROVADA_ATTENDEE_DAYS = gql`
  query readProvadaAttendees($Filter: ProvadaAttendeeFilterReadInputType!) {
    readProvadaAttendees(Filter: $Filter) {
      edges {
        node {
          ID
          Monday
          Tuesday
          Wednesday
          Thursday
          Friday
          Saturday
          Sunday
        }
      }
    }
  }
`;

// Possible role codes: VISITOR, SPEAKER, EMPLOYEE, MANAGER
export const GET_USER_ATTENDEE_DASHBOARD = gql`
  query readProvadaAttendees($MemberID: ID!, $EventID: ID!) {
    readProvadaAttendees(
      Filter: { MemberID__eq: $MemberID, EventID__eq: $EventID }
    ) {
      edges {
        node {
          ID
          ProfileCompleted
          Role {
            ID
            Title
            Code
          }
          GatheringAttendances {
            edges {
              node {
                ID
                Status
              }
            }
          }
          Exhibitor {
            ID
          }
        }
      }
    }
  }
`;

export const GET_USER_COMPLETION_DATA = gql`
  query readProvadaCompletionProfiles {
    readProvadaCompletionProfiles {
      edges {
        node {
          ID
          Title
          Description
          #   Type
          Key
          Score
          Completed
        }
      }
    }
  }
`;

export const GET_COMPLETION_PERCENTAGE = gql`
  query readProvadaAttendees($MemberID: ID!, $EventID: ID!) {
    readProvadaAttendees(
      Filter: { MemberID__eq: $MemberID, EventID__eq: $EventID }
    ) {
      edges {
        node {
          ProfileCompleted
        }
      }
    }
  }
`;

export const GET_EXHIBITOR_FOR_DASHBOARD = gql`
  query readOneProvadaExhibitor($ID: ID!) {
    readOneProvadaExhibitor(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Title
      ProfileCompleted
      Locations {
        edges {
          node {
            ID
            Title
          }
        }
      }
      Gatherings {
        edges {
          node {
            ID
          }
        }
      }
    }
  }
`;

export const READ_ONE_SILVERSTRIPE_MEMBER = gql`
  query readOneSilverStripeMember($ID: ID!) {
    readOneSilverStripeMember(ID: $ID) {
      ID
      Gender
      Initials
      FirstName
      Surname
      FullName
      Email
      Phone
      Mobile
      Street
      HouseNumber
      PostalCode
      Addition
      City
      Country
      LinkedinLink
      Function
      Affix
      Locale
      WorksAt
      Biography
      Excerpt
      Tags {
        edges {
          node {
            ID
            Title
          }
        }
      }
      Picture {
        ID
        AbsoluteLink
      }
      WorksAt
      PublicPhone
      PublicEmail
      PublicProfile
      AvailableForMeetings
      EmailNotifications {
        ID
      }
    }
  }
`;

export const GET_SUB_EXHIBITORS = gql`
  query readProvadaExhibitors($Filter: ProvadaExhibitorFilterReadInputType) {
    readProvadaExhibitors(Filter: $Filter) {
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;

export const QUERY_EXHIBITOR_STANDS = gql`
  query readProvadaExhibitorStands($ExhibitorID: ID!) {
    readProvadaExhibitorStands(ExhibitorID: $ExhibitorID) {
      edges {
        node {
          ID
          Title
          Exhibitors {
            edges {
              node {
                ID
                Title
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PUBLIC_DATA_MYPROFILE = gql`
  mutation updateSilverStripeMember(
    $Input: SilverStripeMemberUpdateInputType!
  ) {
    updateSilverStripeMember(Input: $Input) {
      ID
      Biography
      Excerpt
      FirstName
      Surname
      Initials
      Affix
      PublicProfile
      AvailableForMeetings
      PublicPhone
      PublicEmail
      WorksAt
      LinkedinLink
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation changeProvadaMemberPassword(
    $CurrentPassword: String!
    $NewPassword: String!
  ) {
    changeProvadaMemberPassword(
      CurrentPassword: $CurrentPassword
      NewPassword: $NewPassword
    ) {
      ID
    }
  }
`;

export const UPDATE_PERSONAL_DATA_MYPROFILE = gql`
  mutation updateSilverStripeMember(
    $Input: SilverStripeMemberUpdateInputType!
  ) {
    updateSilverStripeMember(Input: $Input) {
      ID
      FirstName
      Initials
      FirstName
      Surname
      Email
      Phone
      Mobile
      Street
      HouseNumber
      PostalCode
      City
      Country
    }
  }
`;

export const UPDATE_PROVADA_ATTENDEE_DAYS = gql`
  mutation updateProvadaAttendee($Input: ProvadaAttendeeUpdateInputType!) {
    updateProvadaAttendee(Input: $Input) {
      ID
      Monday
      Tuesday
      Wednesday
      Thursday
      Friday
      Saturday
      Sunday
    }
  }
`;

export const GET_ATTENDEE_EXHIBITOR_INFO = gql`
  query readProvadaAttendees($MemberID: ID!, $EventID: ID!) {
    readProvadaAttendees(
      Filter: { MemberID__eq: $MemberID, EventID__eq: $EventID }
    ) {
      edges {
        node {
          ID
          Company {
            ID
            Title
          }
          Exhibitor {
            ID
            Title
          }
          Role {
            ID
            Code
          }
        }
      }
    }
  }
`;

export const GET_EXPOSANT_STANDSERVICES = gql`
  query readOneProvadaAttendee($ID: ID!) {
    readOneProvadaAttendee(ID: $ID) {
      ID
      Exhibitor {
        ID
        Title
        LogoURL
        RAILink
        Company {
          ID
          Premium
          Expires
        }
      }
    }
  }
`;

export const MUTATION_REGISTER_MEMBER = gql`
  mutation registerProvadaMember(
    $Email: String!
    $FirstName: String!
    $Affix: String
    $Surname: String!
    $WorksAt: String!
    $Password: String!
  ) {
    registerProvadaMember(
      Email: $Email
      FirstName: $FirstName
      Affix: $Affix
      Surname: $Surname
      WorksAt: $WorksAt
      Password: $Password
    ) {
      ID
      Affix
      Email
      FirstName
      Surname
      FullName
    }
  }
`;

export const FRAGMENT_EMAIL_NOTIFICATIONS = gql`
  fragment FragmentEmailNotifications on ProvadaEmailNotifications {
    ID
    # GATHERING_APPLIED
    # GATHERING_CHANGED
    # GATHERING_CREATED
    # GATHERING_REMOVED
    # GATHERING_DENIED
    # GATHERING_PUBLISHED
    # GATHERING_CANCELLED
    # GATHERING_SIGNEDUP
    # GATHERING_NEW_SIGNUP
    # SPEAKER_ONBOARDING
    # DEADLINE_COMING_UP
    # TASK_NEW_REQUIRED
    # ROOMBOOKING_CHANGED
    # ROOMBOOKING_CREATED
    # ROOMBOOKING_REMOVED
    # ROOMBOOKING_REVIEWED
    ATTENDEE_CREATED
    ATTENDEE_REMOVED
    DEADLINE_CREATED
    DEADLINE_COMING
    EXHIBITOR_AUDITED
    GATHERING_APPLIED
    GATHERING_CHANGED
    GATHERING_CREATED
    GATHERING_REMOVED
    GATHERING_REVIEWED
    MEETING_CHANGED
    MEETING_CREATED
    MEETING_REMOVED
    ROOMBOOKING_CHANGED
    ROOMBOOKING_CREATED
    ROOMBOOKING_REMOVED
    ROOMBOOKING_REVIEWED
    SERVICEUPLOAD_CHANGED
    SPEAKER_CREATED
    SPEAKER_REMOVED
    TICKET_APPROVED
    TICKET_ASSIGNED
    TICKET_DECLINED
    TICKET_REASSIGNED
  }
`;

export const UPDATE_EMAIL_NOTIFICATIONS = gql`
  mutation updateProvadaEmailNotifications(
    $Input: ProvadaEmailNotificationsUpdateInputType!
  ) {
    updateProvadaEmailNotifications(Input: $Input) {
      ...FragmentEmailNotifications
    }
  }
  ${FRAGMENT_EMAIL_NOTIFICATIONS}
`;

export const EMAIL_NOTIFICATIONS = gql`
  query readOneProvadaEmailNotifications($ID: ID!) {
    readOneProvadaEmailNotifications(ID: $ID) {
      ...FragmentEmailNotifications
    }
  }
  ${FRAGMENT_EMAIL_NOTIFICATIONS}
`;

export const QUERY_EVENT_ATTENDEES = gql`
  query readProvadaAttendees($Filter: ProvadaAttendeeFilterReadInputType) {
    readProvadaAttendees(Filter: $Filter, Versioning: { Mode: LATEST }) {
      edges {
        node {
          ...FragmentAttendeeDays
          ID
          ProfileCompleted
          PublicLink
          ParkingTicket
          ParkingResponse
          Company {
            ID
            Title
            Premium
          }
          Exhibitor {
            ID
            Title
          }
          Role {
            ID
            Code
            Title
          }
        }
      }
    }
  }
  ${FRAGMENT_ATTENDEE_DAYS}
`;

export const QUERY_ATTENDEE_GATHERING_ATTENDANCES = gql`
  query readProvadaGatheringAttendances(
    $Filter: ProvadaGatheringAttendanceFilterReadInputType
  ) {
    readProvadaGatheringAttendances(
      Filter: $Filter
      Versioning: { Mode: LATEST }
    ) {
      edges {
        node {
          ID
          Status
        }
      }
    }
  }
`;

export const FRAGMENT_ATTENDEE_EXHIBITOR = gql`
  fragment FragmentAttendeeExhibitor on ProvadaExhibitor {
    ID
    Title
    Slogan
    Profile
    CurrentStage
  }
`;

export const QUERY_ATTENDEE_EXHIBITOR = gql`
  query readOneProvadaExhibitor($ID: ID!) {
    readOneProvadaExhibitor(ID: $ID, Versioning: { Mode: LATEST }) {
      ...FragmentAttendeeExhibitor
      ProfileCompleted
      Ghost
      PublicLink
      RAILink
      Locations {
        edges {
          node {
            ID
            Title
          }
        }
      }
      Company {
        ID
        Title
        Expires
      }
      MainExhibitor {
        ID
        Title
      }
      Subexhibitors {
        edges {
          node {
            ID
            Title
          }
        }
      }
    }
  }
  ${FRAGMENT_ATTENDEE_EXHIBITOR}
`;

export const QUERY_ATTENDEE_EXHIBITOR_INFO = gql`
  query readOneProvadaExhibitor($ID: ID!) {
    readOneProvadaExhibitor(ID: $ID, Versioning: { Mode: LATEST }) {
      ...FragmentAttendeeExhibitor
      LogoURL
      HeaderURL
      VideoURL
    }
  }
  ${FRAGMENT_ATTENDEE_EXHIBITOR}
`;

export const MUTATION_UPDATE_ATTENDEE_EXHIBITOR_INFO = gql`
  mutation updateProvadaExhibitor($Input: ProvadaExhibitorUpdateInputType!) {
    updateProvadaExhibitor(Input: $Input) {
      ...FragmentAttendeeExhibitor
      VideoURL
    }
  }
  ${FRAGMENT_ATTENDEE_EXHIBITOR}
`;

export const FRAGMENT_ATTENDEE_COMPANY = gql`
  fragment FragmentAttendeeCompany on ProvadaCompany {
    ID
    Title
    Email
    Phone
    Website
    City
    Country
    Street
    HouseNumber
    PostalCode
    Addition
    Premium
  }
`;

export const QUERY_ATTENDEE_COMPANY = gql`
  query readOneProvadaCompany($ID: ID!) {
    readOneProvadaCompany(ID: $ID, Versioning: { Mode: LATEST }) {
      ...FragmentAttendeeCompany
    }
  }
  ${FRAGMENT_ATTENDEE_COMPANY}
`;

export const FRAGMENT_CONTACT_PERSON = gql`
  fragment FragmentContactPerson on ProvadaAttendee {
    ID
    Member {
      ID
      FullName
      Email
    }
  }
`;

export const QUERY_EXHIBITOR_CONTACT = gql`
  query readExhibitorContact($CompanyID: ID!, $ExhibitorID: ID!) {
    readOneProvadaCompany(ID: $CompanyID, Versioning: { Mode: LATEST }) {
      ID
      Email
      Phone
      Website
    }
    readOneProvadaExhibitor(ID: $ExhibitorID, Versioning: { Mode: LATEST }) {
      ID
      ContactPrimary {
        ...FragmentContactPerson
      }
      ContactBilling {
        ...FragmentContactPerson
      }
      ContactSecondary {
        ...FragmentContactPerson
      }
    }
  }
  ${FRAGMENT_CONTACT_PERSON}
`;

export const MUTATION_UPDATE_EXHIBITOR_CONTACT = gql`
  mutation updateExhibitorContact(
    $CompanyInput: ProvadaCompanyUpdateInputType!
    $ExhibitorInput: ProvadaExhibitorUpdateInputType!
  ) {
    updateProvadaCompany(Input: $CompanyInput) {
      ID
      Phone
      Email
      Website
    }
    updateProvadaExhibitor(Input: $ExhibitorInput) {
      ID
      ContactPrimary {
        ...FragmentContactPerson
      }
      ContactBilling {
        ...FragmentContactPerson
      }
      ContactSecondary {
        ...FragmentContactPerson
      }
    }
  }
  ${FRAGMENT_CONTACT_PERSON}
`;

export const MUTATION_UPDATE_ATTENDEE_COMPANY_ADDRESS = gql`
  mutation updateProvadaCompany($Input: ProvadaCompanyUpdateInputType!) {
    updateProvadaCompany(Input: $Input) {
      ...FragmentAttendeeCompany
    }
  }
  ${FRAGMENT_ATTENDEE_COMPANY}
`;

export const QUERY_ATTENDEE_COMPANY_PREMIUM = gql`
  query readOneProvadaCompany($ID: ID!) {
    readOneProvadaCompany(ID: $ID, Versioning: { Mode: LATEST }) {
      Premium
      Expires
    }
  }
`;

export const GET_ATTENDEE_SPEECHES = gql`
  query readProvadaGatheringSpeakers(
    $Filter: ProvadaGatheringSpeakerFilterReadInputType
  ) {
    readProvadaGatheringSpeakers(
      Filter: $Filter
      Versioning: { Mode: LATEST }
    ) {
      edges {
        node {
          ID
          Gathering {
            ID
            Title
            Date
            Start
            End
            Type
            LocationTitle
            PublicLink
          }
        }
      }
    }
  }
`;

export const READ_CURRENT_SILVERSTRIPE_MEMBER = gql`
  query readSilverStripeMemberCurrent {
    readSilverStripeMemberCurrent {
      ID
      Gender
      Initials
      FirstName
      Surname
      FullName
      Email
      Phone
      Mobile
      Street
      HouseNumber
      PostalCode
      Addition
      City
      Country
      LinkedinLink
      Function
      Affix
      Locale
      WorksAt
      Picture {
        ID
        AbsoluteLink
      }
      WorksAt
      PublicPhone
      PublicEmail
      PublicProfile
      AvailableForMeetings
      EmailNotifications {
        ID
      }
    }
  }
`;

export const MUTATION_UPDATE_LOCALE = gql`
  mutation updateSilverStripeMember(
    $Input: SilverStripeMemberUpdateInputType!
  ) {
    updateSilverStripeMember(Input: $Input) {
      ID
      Locale
    }
  }
`;

export const GET_ALL_TAGS = gql`
  query readProvadaTags {
    readProvadaTags(Versioning: { Mode: LATEST }) {
      edges {
        node {
          ID
          Title
        }
      }
    }
  }
`;

export const UPDATE_MEMBER_TAGS = gql`
  mutation updateProvadaMemberTags($Tags: [Int]) {
    updateProvadaMemberTags(Tags: $Tags) {
      ID
      Tags {
        edges {
          node {
            ID
            Title
          }
        }
      }
    }
  }
`;

export const QUERY_TASKS_COMPLETED = gql`
  query readOneProvadaExhibitor($ID: ID!) {
    readOneProvadaExhibitor(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      TasksCompleted
    }
  }
`;

export const QUERY_MEMBER_CONNECTIONS = gql`
  query readSilverStripeMemberCurrent {
    readSilverStripeMemberCurrent {
      ID
      LinkedIn {
        ID
      }
    }
  }
`;

export const MUTATION_DISCONNECT_LINKED_IN = gql`
  mutation disconnectLinkedInAccount {
    disconnectLinkedInAccount {
      ID
      LinkedIn {
        ID
      }
    }
  }
`;

export const QUERY_ATTENDEE_PARKING = gql`
  query readOneProvadaAttendee($ID: ID) {
    readOneProvadaAttendee(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      ParkingTicket
      ParkingResponse
    }
  }
`;

export const MUTATION_UPDATE_ATTENDEE_PARKING = gql`
  mutation updateProvadaAttendee($Input: ProvadaAttendeeUpdateInputType!) {
    updateProvadaAttendee(Input: $Input) {
      ID
      ParkingTicket
      ParkingResponse
    }
  }
`;

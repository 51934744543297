import React from "react";

import get from "lodash/get";

import { useTranslation } from "react-i18next";

import LinkLikeArrowExternal from "./LinkLikeArrowExternal";

import { useEvent } from "../contexts/EventContext";
import { useIsManager } from "../contexts/ExhibitorContext";

const PurchaseTicketSection = () => {
  const { t } = useTranslation(["tickets"]);

  const Event = useEvent();
  const isManager = useIsManager();

  const href = isManager
    ? get(Event, "TicketsLinkManagers")
    : get(Event, "TicketsLinkVisitors");

  if (!href) {
    return null;
  }

  return (
    <a
      className="card-body text-decoration-none"
      rel="noopener noreferrer"
      target="_blank"
      href={href}
    >
      <h2 className="text-dark">{t("tickets:summit.title")}</h2>
      <p className="text-dark">{t("tickets:summit.content")}</p>
      <LinkLikeArrowExternal>{t("tickets:summit.link")}</LinkLikeArrowExternal>
    </a>
  );
};

export default PurchaseTicketSection;

import gql from "graphql-tag";

export const READ_PROVADA_FAQ_CATEGORIES = gql`
  query readProvadaFAQCategories {
    readProvadaFAQCategories {
      edges {
        node {
          ID
          Title
          Active
          Items {
            edges {
              node {
                ID
                Title
                Content
              }
            }
          }
        }
      }
    }
  }
`;

export const READ_PROVADA_FAQ_ITEMS = gql`
  query readProvadaFAQItems(
    $sortBy: [ReadProvadaFAQItemsSortInputType]
    $limit: Int
  ) {
    readProvadaFAQItems(sortBy: $sortBy, limit: $limit) {
      edges {
        node {
          ID
          Title
          Content
          Active
          Views
        }
      }
    }
  }
`;

export const READ_ONE_PROVADA_FAQ_ITEM = gql`
  query readOneProvadaFAQItem($ID: ID!) {
    readOneProvadaFAQItem(ID: $ID) {
      ID
      Title
      Content
    }
  }
`;

export const READ_ONE_SILVERSTRIPE_SITE_CONFIG = gql`
  query readOneSilverStripeSiteConfig($ID: ID!) {
    readOneSilverStripeSiteConfig(ID: $ID) {
      DefaultHelpPhone
      DefaultHelpEmail
      HelpContactPerson {
        ID
        Email
        Phone
        PublicPhone
        PublicEmail
        FullName
        Picture {
          ID
          AbsoluteLink
        }
      }
    }
  }
`;

import React from "react";

/* packages */
import { Route, Redirect, Switch } from "react-router-dom";

import Agenda from "./Agenda";
import AgendaMeeting from "./Meeting";
import AgendaMeetingRequest from "./MeetingRequest";
import MeetingInvite from "./MeetingInvite";
import MeetingInvitation from "./MeetingInvitation";
import AgendaGathering from "./Gathering";
import GatheringInvitation from "./GatheringInvitation";

import { useIsAttendee } from "../../contexts/ExhibitorContext";

const AgendaRouter = React.memo(({ match: { path } }) => {
  const isAttendee = useIsAttendee();

  return (
    <Switch>
      <Route exact path={path} component={Agenda} />
      {isAttendee && (
        <React.Fragment>
          <Route
            exact
            path={`${path}/meeting-request`}
            component={MeetingInvite}
          />
          <Route
            exact
            path={`${path}/meeting/:MeetingID`}
            component={AgendaMeeting}
          />
          <Route
            exact
            path={`${path}/request/:AttendeeID`}
            component={AgendaMeetingRequest}
          />
          <Route
            exact
            path={`${path}/gathering/:GatheringID`}
            component={AgendaGathering}
          />
        </React.Fragment>
      )}
      <Route
        exact
        path={`${path}/meeting-invitation/:InvitationID`}
        component={MeetingInvitation}
      />
      <Route
        exact
        path={`${path}/gathering-invitation/:InvitationID`}
        component={GatheringInvitation}
      />
      <Redirect to={path} />
    </Switch>
  );
});

export default AgendaRouter;

import React, { useMemo } from "react";

import { get } from "lodash";

import DefaultLayout from "../../layout/DefaultLayout";

import { useRole, useAttendeeExhibitor } from "../../contexts/ExhibitorContext";

import UserBlock from "../../components/dashboard/UserBlock";
import HelpBlock from "../../components/dashboard/HelpBlock";
import CompanyBlock from "../../components/dashboard/CompanyBlock";
import DeadlinesBlock from "../../components/dashboard/DeadlinesBlock";

const DashboardPage = React.memo(() => {
  const Role = useRole();
  const AttendeeExhibitor = useAttendeeExhibitor();

  const isAttendee = useMemo(
    () => !!parseInt(get(AttendeeExhibitor, "ID"), 10),
    [AttendeeExhibitor]
  );

  const isExhibitor = useMemo(() => {
    if (!isAttendee) {
      return false;
    }

    return !!Role && ["MANAGER", "EMPLOYEE", "SPEAKER"].includes(Role.Code);
  }, [Role, isAttendee]);

  const isExhibitorManager = useMemo(() => {
    if (!isAttendee) {
      return false;
    }

    return !!Role && ["MANAGER"].includes(Role.Code);
  }, [Role, isAttendee]);

  if (isExhibitorManager) {
    return (
      <DefaultLayout>
        <div className="row">
          <div className="col-12 col-md-6 col-xl-4">
            <UserBlock />
            <HelpBlock />
          </div>

          <div className="col-12 col-md-6 col-xl-4">
            <DeadlinesBlock />
          </div>

          <div className="col-12 col-md-6 col-xl-4">
            <CompanyBlock />
          </div>
        </div>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <div className="row">
        <div className="col-12 col-md-6 col-xl-4">
          <UserBlock />
        </div>

        {isExhibitor && (
          <div className="col-12 col-md-6 col-xl-4">
            <CompanyBlock />
          </div>
        )}

        <div className="col-12 col-md-6 col-xl-4">
          <HelpBlock />
        </div>
      </div>
    </DefaultLayout>
  );
});

export default DashboardPage;

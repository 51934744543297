import React, {
  useContext,
  useEffect,
  Fragment,
  useMemo,
  useCallback,
} from "react";

import _get from "lodash/get";

import Select from "react-select";
import useSetState from "react-use/lib/useSetState";

import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";

import CardHeader from "../../../components/CardHeader";

import { useGathering } from "../../../contexts/GatheringContext";
import {
  GET_GATHERING_INFO,
  UPDATE_GATHERING,
  GET_ALL_TAGS,
} from "../../../graphql/Gathering";

import { GatheringBreadcrumbs } from "../GatheringEdit";

const initData = {
  Type: "",
  Tags: [],
};

const TAGS_LIMIT = 5;

const GatheringCategories = ({ history: { push } }) => {
  const { t } = useTranslation(["common", "gatherings"]);

  const { data: gathering } = useGathering();

  const [categories, setCategories] = useSetState({ ...initData });

  const categoryOptions = [
    { value: "CONTENT", label: t("gatherings:content") },
    { value: "NETWORKING", label: t("gatherings:networking") },
    { value: "CONTRACT", label: t("gatherings:contract") },
  ];

  useEffect(() => {
    const TagValues = _get(gathering, "Tags.edges").map(
      ({ node: { ID, Title } }) => ({ ID: ID, Title: Title })
    );
    setCategories({
      ...categories,
      Type: gathering.Type,
      Tags: TagValues,
    });
  }, [gathering.ID]);

  // get tags arr
  const { data: tagsData } = useQuery(GET_ALL_TAGS);

  const tags = useMemo(
    () =>
      _get(tagsData, "readProvadaTags.edges", []).map(
        ({ node: { ID, Title } }) => ({ ID: ID, Title: Title })
      ),
    [tagsData]
  );

  const [saveData, { loading: saving }] = useMutation(UPDATE_GATHERING, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_GATHERING_INFO,
        variables: {
          ID: gathering.ID,
        },
      },
    ],
    onCompleted: () => {
      push(`/gathering/${gathering.ID}`);
    },
  });

  const onSubmit = () => {
    // tags must be string of array values, ex "['1','2','3']"
    const tagsArr = categories.Tags.map(({ ID }) => ID);

    saveData({
      variables: {
        Input: {
          ID: gathering.ID,
          Type: categories.Type,
          Tags: JSON.stringify(tagsArr),
        },
      },
    });
  };

  const handleTags = (tagsArr) => {
    if (!(tagsArr && !!tagsArr.length)) {
      return setCategories({
        Tags: [],
      });
    }

    if (tagsArr.length <= TAGS_LIMIT) {
      setCategories({
        Tags: tagsArr,
      });
    }
  };

  const renderCategoryItem = useCallback(
    (item) => {
      const { value, label } = item;

      const handleCategory = (e) => {
        setCategories({
          ...categories,
          Type: e.currentTarget.value,
        });
      };

      return (
        <Fragment key={`cat-${value}`}>
          <input
            id={`cat-${value}`}
            type="radio"
            className="d-none custom-form-btn"
            name="Type"
            value={value}
            onChange={handleCategory}
            checked={value === categories.Type}
          />
          <label
            htmlFor={`cat-${value}`}
            className="btn mr-2 mb-2 font-size-1 px-2 font-weight-normal custom-form-btn-label"
          >
            {label}
          </label>
        </Fragment>
      );
    },
    [categories, setCategories]
  );

  return (
    <Fragment>
      <GatheringBreadcrumbs />
      <div className="card">
        <CardHeader
          title={t("gatherings:categories")}
          onDismiss={() => push(`/gathering/${gathering.ID}`)}
          onSubmit={onSubmit}
        />
        <div className="card-body">
          <div className="form-group">
            {categoryOptions.map(renderCategoryItem)}
          </div>
          <div className="form-group">
            <label htmlFor="" className="text-primary">
              {t("common:tags")}
            </label>
            <Select
              isMulti
              options={tags}
              getOptionValue={({ ID }) => ID}
              getOptionLabel={({ Title }) => Title}
              value={categories.Tags}
              onChange={handleTags}
            />
            <small className="form-text text-muted">
              {t("gatherings:categoriespage:maxthree", { count: TAGS_LIMIT })}
            </small>
          </div>
          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-secondary"
              disabled={saving}
              onClick={onSubmit}
            >
              {saving && (
                <span
                  className="spinner-border spinner-border-sm mr-2 mb-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {t("common:save")}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GatheringCategories;

import React from "react";

import gql from "graphql-tag";

import { useQuery } from "@apollo/react-hooks";
import { Route } from "react-router-dom";

const QUERY_GATHERING = gql`
  query readOneProvadaGathering($ID: ID!) {
    readOneProvadaGathering(ID: $ID, Versioning: { Mode: LATEST }) {
      ID
      Event {
        ID
      }
    }
  }
`;

const GatheringRoute = ({ component: Component, ...rest }) => {
  const {
    computedMatch: {
      params: { ID },
    },
  } = rest;

  useQuery(QUERY_GATHERING, { variables: { ID } });

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default GatheringRoute;

import React, { Fragment, useEffect, useMemo, useCallback } from "react";

import { get } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import useSetState from "react-use/lib/useSetState";

import CardHeader from "../../components/CardHeader";
import Breadcrumbs from "../../components/Breadcrumbs";

import {
  QUERY_MEMBER_CONNECTIONS,
  MUTATION_DISCONNECT_LINKED_IN,
} from "../../graphql/User";

const openWindow = function (url, title, win, w, h) {
  const y = Math.min(
    win.top.outerHeight,
    win.top.outerHeight / 2 + win.top.screenY - h / 2
  );

  const x = Math.min(
    win.top.outerWidth,
    win.top.outerWidth / 2 + win.top.screenX - w / 2
  );

  return win.open(
    url,
    title,
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=${w},height=${h},top=${y},left=${x}`
  );
};

const ConnectedAccounts = React.memo(({ history: { push } }) => {
  const { t } = useTranslation(["tickets"]);

  const [connection, setConnection] = useSetState({
    error: false,
    message: "",
  });

  const { loading, data, refetch: queryConnections } = useQuery(
    QUERY_MEMBER_CONNECTIONS
  );

  const [onDisconnect, { loading: disconnecting }] = useMutation(
    MUTATION_DISCONNECT_LINKED_IN,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_MEMBER_CONNECTIONS,
        },
      ],
    }
  );

  const connectionsData = useMemo(
    () => get(data, "readSilverStripeMemberCurrent") || { LinkedIn: null },
    [data]
  );

  const isLinkedInConnected = useMemo(
    () => !!parseInt(get(connectionsData, "LinkedIn.ID"), 10),
    [connectionsData]
  );

  const onDismiss = useCallback(() => push(`/`), [push]);
  const onSubmit = useCallback(() => push(`/`), [push]);

  const onConnect = (e) => {
    e.preventDefault();

    if (loading || disconnecting) {
      return;
    }

    if (isLinkedInConnected) {
      return onDisconnect();
    }

    const child = openWindow(
      `${process.env.REACT_APP_WEBSITE_LOGIN_URL}/linkedin/connect`,
      "LinkedIn Login",
      window,
      1000,
      800
    );

    if (!child) {
      return setConnection({
        error: true,
        message: "Something went wrong. Please try again.",
      });
    }

    return setConnection({ error: false, message: "" });
  };

  useEffect(() => {
    const receiveMessage = (e) => {
      if (get(e, "data.origin") !== "linkedin") {
        return;
      }

      setConnection({
        error: !!get(e, "data.error", true),
        message: (
          get(e, "data.message") || "Something went wrong. Please try again."
        ).trim(),
      });

      return queryConnections();
    };

    window.addEventListener("message", receiveMessage);

    return () => {
      return window.removeEventListener("message", receiveMessage);
    };
  }, []);

  return (
    <Fragment>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem to="/settings">
            {t("settings:title")}
          </Breadcrumbs.ListItem>
          <Breadcrumbs.ListItem>
            {t("connectedAccounts:title")}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
      </Breadcrumbs>
      <div className="card">
        <CardHeader
          title={t("connectedAccounts:title")}
          onDismiss={onDismiss}
          onSubmit={onSubmit}
        />
        <div className="card-body">
          <p className="text-dark mb-0">{t("connectedAccounts:description")}</p>
        </div>
        <a
          className="card-body d-flex justify-content-between text-gray text-decoration-none"
          href={`${process.env.REACT_APP_WEBSITE_LOGIN_URL}/linkedin/connect`}
          onClick={onConnect}
        >
          <div className="">
            <h2 className="text-dark">{"LinkedIn"}</h2>
            <p className="text-primary-50">
              {t(
                isLinkedInConnected
                  ? "common:connected"
                  : "common:not_connected"
              )}
            </p>
          </div>
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={onConnect}
          >
            {disconnecting && (
              <span
                className="spinner-border spinner-border-sm mr-2 mb-1"
                role="status"
                aria-hidden="true"
              />
            )}
            {t(isLinkedInConnected ? "common:disconnect" : "common:connect")}
          </button>
        </a>
        {!!connection.message && (
          <div
            className={classNames("alert m-3", {
              "alert-success": !connection.error,
              "alert-danger": connection.error,
            })}
            role="alert"
          >
            {connection.message}
          </div>
        )}
      </div>
    </Fragment>
  );
});

export default ConnectedAccounts;

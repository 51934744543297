import React, { useCallback, useEffect, useMemo, useState } from "react";

import Swiper from "react-id-swiper";

import get from "lodash/get";
import findIndex from "lodash/findIndex";

import { useEventContext } from "../contexts/EventContext";

const EventsSwitch = () => {
  const [eventsSwiper, setEventsSwiper] = useState(null);
  const [thumbSwiper, setThumbSwiper] = useState(null);

  const { event, events, onChangeActiveEvent } = useEventContext();

  const initialSlide = useMemo(
    () => findIndex(events, { ID: get(event, "ID") }) || 0,
    []
  );

  /**
   * Ensure if the event changes we wipe to correct slide
   */
  useEffect(() => {
    const nextIndex = findIndex(events, { ID: get(event, "ID") }) || 0;

    if (eventsSwiper && eventsSwiper.activeIndex !== nextIndex) {
      eventsSwiper.slideTo(nextIndex);
    }
  }, [event.ID]);

  const renderEvent = useCallback(
    ({ ID, Title }) => (
      <div
        key={ID}
        className="font-weight-normal px-3 d-flex flex-center align-items-center"
      >
        <h2
          className="text-primary text-uppercase mb-0 text-center"
          style={{ fontWeight: "inherit" }}
        >
          {Title}
        </h2>
      </div>
    ),
    []
  );

  const props = {
    initialSlide,
    centeredSlides: true,
    // centeredSlidesBounds: true,
    slideToClickedSlide: true,
    watchSlidesVisibility: true,
    slidesPerView: 2,
    breakpoints: {
      540: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
    },
  };

  useEffect(() => {
    if (
      eventsSwiper !== null &&
      eventsSwiper.controller &&
      thumbSwiper !== null &&
      thumbSwiper.controller
    ) {
      eventsSwiper.controller.control = thumbSwiper;
      thumbSwiper.controller.control = eventsSwiper;
    }
  }, [eventsSwiper, thumbSwiper]);

  if (events.length <= 1) {
    return null;
  }

  return (
    <div className="d-block flex-1 mt-2">
      <div className="d-block flex-1 mb-1">
        <Swiper
          {...props}
          on={{
            slideChange() {
              onChangeActiveEvent(events[this.activeIndex]);
            },
          }}
          getSwiper={setEventsSwiper}
          slideActiveClass="font-weight-bold"
        >
          {events.map(renderEvent)}
        </Swiper>
      </div>
      <Swiper
        {...props}
        getSwiper={setThumbSwiper}
        slideActiveClass="slide-visible"
        slideVisibleClass="invisible"
      >
        {events.map(({ ID }) => (
          <div key={ID} className="d-flex flex-center">
            <div className="triangle-top-secondary" />
          </div>
        ))}
      </Swiper>
    </div>
  );
};

export default EventsSwitch;

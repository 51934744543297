import React, { useMemo } from "react";

/* packages */
import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloProvider } from "@apollo/react-hooks";

import { LOGIN_URL } from "../../utils/globals";

import fragmentTypes from "./fragmentTypes.json";

//! If you ever read this you will know why it is needed
//! https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces

// fetch(`${process.env.REACT_APP_WEBSITE_LOGIN_URL}/graphql`, {
//   method: "POST",
//   headers: { "Content-Type": "application/json" },
//   body: JSON.stringify({
//     variables: {},
//     query: `
//           {
//             __schema {
//               types {
//                 kind
//                 name
//                 possibleTypes {
//                   name
//                 }
//               }
//             }
//           }
//         `,
//   }),
// })
//   .then((result) => result.json())
//   .then((result) => {
//     // here we're filtering out any type information unrelated to unions or interfaces
//     const filteredData = result.data.__schema.types.filter(
//       (type) => type.possibleTypes !== null
//     );
//     result.data.__schema.types = filteredData;
//     console.log(JSON.stringify(result.data));
//   });

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
});

const createClient = (uri = "") => {
  const cache = new InMemoryCache({ fragmentMatcher });

  const httpLink = new HttpLink({ uri });

  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (process.env.NODE_ENV === "development") {
        graphQLErrors.map((error) => console.log(error));
      }
      // what do we do if graphQL error occures?
      return;
    }

    if (networkError && networkError.statusCode === 403) {
      window.location.href = LOGIN_URL;
      return null;
    }
  });

  const authMiddleware = setContext((_request, previousContext) => {
    const token = window.localStorage.getItem("Authorization-Token");

    let activeEvent;

    try {
      activeEvent = JSON.parse(window.localStorage.getItem("@event/active"));
    } catch (e) {}

    if (!token) {
      return previousContext;
    }

    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        Authorization: token ? `Bearer ${token}` : "",
        ...(!!(activeEvent && activeEvent.ID)
          ? {
              Event: activeEvent.ID,
            }
          : {}),
      },
    };
  });

  return new ApolloClient({
    link: errorHandler.concat(authMiddleware.concat(httpLink)),
    cache,
  });
};

export const ServerProvider = ({ children }) => {
  const client = useMemo(
    () => createClient(`${process.env.REACT_APP_WEBSITE_URL}/graphql/`),
    []
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const LoginServerProvider = ({ children }) => {
  const client = useMemo(
    () => createClient(`${process.env.REACT_APP_WEBSITE_LOGIN_URL}/jwt/`),
    []
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

import React, { useState } from 'react';

/* packages */
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

/* components */
import Icon from '../../../../components/Icon';

/* context */
import { useMeeting, useMeetingAction, useMeetingCancelMutation, VISIBILITY_STATUS } from '../../../../contexts/AgendaContext';

const MeetingCancelAction = React.memo(() => {
  const { t } = useTranslation(['meeting']);

  const { isOwner } = useMeeting();

  const [, setOpened] = useMeetingAction();

  return (
    <div className="mb-3 mt-3">
      <div className="d-inline-flex link-arrow align-items-center icon-secondary pointer" onClick={() => setOpened(VISIBILITY_STATUS.CANCEL)}>
        <span className="text-primary mr-1">
          {t(`meeting:form.cancel.button.action.${isOwner ? 'MeetingWith' : 'RequestedBy'}`)}
        </span>
        <Icon className="text-secondary mx-1" iconType="ArrowDropRight" />
      </div>
    </div>
  );
});

const MeetingCancelForm = React.memo(() => {
  const { t } = useTranslation(['meeting']);

  const { Meeting: { ID } } = useMeeting();

  const [, setOpened] = useMeetingAction();

  const [DeclineReason, setDeclineReason] = useState('');

  const [onCancelMeeting, { loading: updating, error }] = useMeetingCancelMutation();

  function onSubmit(e) {
    e.preventDefault();

    if (updating) {
      return;
    }

    return onCancelMeeting({ variables: { Input: { ID, Status: 0, DeclineReason } } })
      .then(() => setOpened(null))
  }

  return (
    <form className="mt-3" onSubmit={onSubmit}>
      <h3 className="text-dark mb-3">
        {t('meeting:form.cancel.title')}
      </h3>
      <div className="form-group mb-3">
        <label htmlFor="DeclineReason" className="text-primary">
          {t('meeting:form.cancel.input.label.Message')}
        </label>
        <input
          id="DeclineReason"
          type="text"
          className="form-control"
          name="DeclineReason"
          value={DeclineReason}
          onChange={({ target: { value } }) => setDeclineReason(value)}
        />
      </div>
      <div className="mb-3">
        <button className="btn btn-primary" type="button" onClick={onSubmit}>
          {updating && <span className="spinner-border spinner-border-sm mr-2 mb-1" role="status" aria-hidden="true" />}
          {t("meeting:form.cancel.button.submit")}
        </button>
      </div>
      {
        !!error && (
          <div className="mt-3">
            <small className="text-danger font-weight-bold">
              {get(error, 'graphQLErrors[0].message')}
            </small>
          </div>
        )
      }
    </form>
  );
});

const MeetingCancelFormWrapper = React.memo(() => {
  const { Meeting: { Status } } = useMeeting();

  const [opened] = useMeetingAction();

  //! Cant cancel already canceled meeting
  if (Status === 0) {
    return null;
  }

  if (opened === VISIBILITY_STATUS.CANCEL) {
    return (
      <MeetingCancelForm />
    );
  }

  return (
    <MeetingCancelAction />
  );
});

export default MeetingCancelFormWrapper;

import React, { Fragment, useEffect } from "react";

import _pick from "lodash/pick";
import useSetState from "react-use/lib/useSetState";

import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import CardHeader from "../../../components/CardHeader";
import Editor from "../../../components/Editor";

import { useGathering } from "../../../contexts/GatheringContext";
import {
  GET_GATHERING_INFO,
  UPDATE_GATHERING,
} from "../../../graphql/Gathering";

import { GatheringBreadcrumbs } from "../GatheringEdit";

const initialData = {
  ID: null,
  Title: "",
  Description: "",
  Language: "",
};

const GatheringDescription = () => {
  const { t } = useTranslation(["common", "gatherings"]);

  const history = useHistory();

  const { data: gathering } = useGathering();

  const [state, setState] = useSetState({ ...initialData });

  useEffect(() => {
    const update = _pick(gathering, ["ID", "Title", "Description", "Language"]);

    setState({
      ...initialData,
      ...update,
    });
  }, [gathering]);

  const [saveTime, { loading: isSaving }] = useMutation(UPDATE_GATHERING, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_GATHERING_INFO,
        variables: {
          ID: gathering.ID,
        },
      },
    ],
    onCompleted: () => {
      history.replace(`/gathering/${gathering.ID}`);
    },
  });

  const onSubmit = () => {
    if (isSaving) {
      return;
    }

    return saveTime({ variables: { Input: state } });
  };

  const handleChange = (e) => {
    if (typeof e === "string") {
      return setState({
        ...state,
        Description: e,
      });
    }

    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  return (
    <Fragment>
      <GatheringBreadcrumbs />
      <div className="card">
        <CardHeader
          title={t("gatherings:topic")}
          onDismiss={() => history.push(`/gathering/${gathering.ID}`)}
          onSubmit={onSubmit}
        />

        <div className="card-body">
          <form className="">
            <div className="form-group">
              <label htmlFor="title-name" className="text-primary">
                {t("common:title")}
              </label>
              <input
                id="title-name"
                type="text"
                className="form-control"
                name="Title"
                value={state.Title}
                onChange={handleChange}
              />
              <small className="form-text text-muted">
                {t("gatherings:titledescription")}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="description-area" className="text-primary">
                {t("common:description")}
              </label>
              <Editor
                id="description-area"
                textareaName="Description"
                value={state.Description}
                onEditorChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label className="text-primary" htmlFor="language-switch">
                {t("common:language")}
              </label>
              <select
                id="language-switch"
                className="form-control"
                value={state.Language}
                name="Language"
                onChange={handleChange}
              >
                <option value={"nl_NL"}>{t("common:netherlands")}</option>
                <option value={"en_US"}>{t("common:english")}</option>
              </select>
              <small className="form-text text-muted">
                {t("gatherings:description:languagedesc")}.
              </small>
            </div>
            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={isSaving}
                onClick={onSubmit}
              >
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm mr-2 mb-1"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("common:save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default GatheringDescription;

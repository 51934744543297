import React, { useContext, useMemo } from "react";

import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import pick from "lodash/pick";
import filter from "lodash/filter";

import { useQuery } from "@apollo/react-hooks";
import { useParams, useHistory } from "react-router-dom";

import Loading from "../components/Loading";

/* context */
import { useEventContext } from "./EventContext";
import { useAttendeeExhibitor, useRole } from "./ExhibitorContext";

/* graphql */
import {
  GET_GATHERING_INFO,
  QUERY_GATHERING_RESERVATION,
} from "../graphql/Gathering";

/* config */
import { ENABLE_GATHERING_EDIT_TIME } from "../utils/globals";

import useEventUpdateEffect from "../lib/hooks/useEventUpdateEffect";

const GatheringContext = React.createContext();

GatheringContext.displayName = "GatheringContext";

const { Provider } = GatheringContext;

const gatheringDefault = {
  ID: null,
  Title: "",
  Date: null,
  Start: null,
  End: null,
  Type: "",
  Participants: "",
  MaxCapacity: "",
  LocationTitle: "",
  Description: "",
  Registration: "",
  RegisterOn: "",
  Moderation: 0,
  StandID: 0,
  Attendees: {
    edges: [],
  },
  Speakers: {
    edges: [],
  },
  Exhibitor: {
    ID: null,
    Title: "",
  },
  Tags: {
    edges: [],
  },
  Reservation: {
    ID: null,
    Location: {
      ID: null,
      Title: "",
    },
  },
};

export const useGatheringContext = () => {
  return useContext(GatheringContext);
};

export const useGathering = () => {
  const { gathering, gatheringLoading } = useGatheringContext();

  return { data: gathering, loading: gatheringLoading };
};

export const useGatheringReservation = () => {
  const { reservation, reservationLoading } = useGatheringContext();

  return { data: reservation, loading: reservationLoading };
};

export const useGatheringIsRoomReservationSet = () => {
  const { isRoomReservationSet } = useGatheringContext();

  return isRoomReservationSet;
};

export const useGatheringIsEditablePermission = () => {
  const Role = useRole();
  const Exhibitor = useAttendeeExhibitor();

  const { data: Gathering, loading } = useGathering();

  const subExhibitors = useMemo(
    () => map(get(Exhibitor, "Subexhibitors.edges", []), "node"),
    [Exhibitor]
  );

  // get gathering exhibitor for check
  const gatheringExhibitorID = useMemo(() => get(Gathering, "Exhibitor.ID"), [
    Gathering,
  ]);

  const isSubGathering = useMemo(
    () => !!find(subExhibitors, { ID: gatheringExhibitorID }),
    [subExhibitors, gatheringExhibitorID]
  );

  const isOwnGathering = useMemo(() => gatheringExhibitorID === Exhibitor.ID, [
    Exhibitor,
    gatheringExhibitorID,
  ]);

  if (loading) {
    return true;
  }

  // must be manager to edit a gathering
  if (!(Role && Role.Code === "MANAGER")) {
    return false;
  }

  // only exhibitors allowed here
  if (!Exhibitor) {
    return false;
  }

  // latest check are subcontractors, they can edit only gathering that belongs to them
  if (!(isSubGathering || isOwnGathering)) {
    return false;
  }

  return true;
};

export const useGatheringIsStandReservationSet = () => {
  const { isStandReservationSet } = useGatheringContext();

  return isStandReservationSet;
};

export const useGatheringIsReservationEditable = () => {
  const canEdit = useGatheringIsEditablePermission();

  const isRoomReservationSet = useGatheringIsRoomReservationSet();
  const isStandReservationSet = useGatheringIsStandReservationSet();

  if (!canEdit) {
    return false;
  }

  if (isRoomReservationSet) {
    return true;
  }

  if (!isStandReservationSet) {
    return true;
  }

  return ENABLE_GATHERING_EDIT_TIME;
};

const GatheringProvider = React.memo(({ children }) => {
  const { replace } = useHistory();

  const { event, events, onChangeActiveEvent } = useEventContext();

  const { ID: GatheringID } = useParams();

  useEventUpdateEffect(() => {
    replace("/gathering");
  });

  const { data: GatheringData, loading: gatheringLoading } = useQuery(
    GET_GATHERING_INFO,
    {
      skip: !GatheringID,
      variables: {
        ID: GatheringID,
      },
      onCompleted: (data) => {
        const gathering = get(data, "readOneProvadaGathering");

        /**
         * Could not find the gathering
         */
        if (!(gathering && gathering.ID)) {
          return replace("/gathering");
        }

        const EventID = get(event, "ID");
        const RecordEventID = get(gathering, "Event.ID");

        if (RecordEventID === EventID) {
          return;
        }

        const RecordEvent = find(events, { ID: RecordEventID });

        if (RecordEvent) {
          return onChangeActiveEvent(RecordEvent);
        }

        return replace("/gathering");
      },
    }
  );

  const gathering = useMemo(
    () => get(GatheringData, "readOneProvadaGathering") || gatheringDefault,
    [GatheringData]
  );

  const { data: ReservationData, loading: reservationLoading } = useQuery(
    QUERY_GATHERING_RESERVATION,
    {
      skip: !GatheringID,
      variables: {
        Filter: { GatheringID__eq: GatheringID },
      },
    }
  );

  const reservation = useMemo(
    () => get(ReservationData, "readProvadaReservations.edges[0].node"),
    [ReservationData]
  );

  const isRoomReservationSet = useMemo(
    () => !!reservation && !!parseInt(reservation.ID),
    [reservation]
  );

  const isStandReservationSet = useMemo(
    () =>
      filter(
        Object.values(
          pick(gathering, ["Date", "Start", "End", "LocationTitle"])
        ),
        Boolean
      ).length === 4,
    [gathering]
  );

  return (
    <Provider
      value={{
        gathering,
        gatheringLoading,

        reservation,
        reservationLoading,

        isRoomReservationSet,
        isStandReservationSet,
      }}
    >
      {gatheringLoading ? <Loading /> : children}
    </Provider>
  );
});

export { gatheringDefault, GatheringProvider };

export default GatheringContext;

import React, { useEffect, useState, useRef } from 'react';

/* packages */
import classNames from 'classnames';
import useSetState from 'react-use/lib/useSetState';

import { some } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

/* context */
import DefaultLayout from '../../layout/DefaultLayout';

/* graphql */
import { UPDATE_PASSWORD } from '../../graphql/User'

const initialErrorState = { CurrentPassword: false, Password: false, ConfirmPassword: false };

const ChangePasswordPage = React.memo(({ history: { push } }) => {
  const { t } = useTranslation(['header', 'changepassword']);

  const [password, setPassword] = useSetState({
    CurrentPassword: "",
    Password: "",
    ConfirmPassword: ""
  });

  const passwordInput = useRef(null);

  const [error, setError] = useState({ ...initialErrorState });

  const [onUpdatePassword, { loading: savingMember }] = useMutation(UPDATE_PASSWORD);

  const isFormValid = () => {
    let nextState = { ...initialErrorState };

    if (password.Password.length < 8) {
      nextState = { ...nextState, Password: true };
    }

    if (!(password.ConfirmPassword.length >= 8 && password.Password.trim() === password.ConfirmPassword.trim())) {
      nextState = { ...nextState, ConfirmPassword: true };
    }

    setError(nextState);

    return !some(nextState, (value) => value);
  }

  //! Focus if field becomes invalid
  useEffect(() => {
    if (!!error.Password) {
      passwordInput.current.focus();
    }

  }, [error.Password])

  const onSubmit = e => {
    e.preventDefault();

    if (!(!savingMember && !!isFormValid())) {
      return;
    }

    const variables = { CurrentPassword: password.CurrentPassword, NewPassword: password.ConfirmPassword };

    return onUpdatePassword({ variables })
      .then(() => push('/'))
      .catch((error) => {
        if (error.message.includes("Your current password doesn't match")) {
          setError({ CurrentPassword: `${t('changepassword:currentMatchError')}` });
        }

        if (error.message.includes("You've already used that password in the past, please choose a new password")) {
          setError({ CurrentPassword: `${t('changepassword:currentPasswordUsed')}` });
        }
      })
  }

  const onChangeValue = ({ target: { value, name } }) => setPassword({ [name]: value });

  return (
    <DefaultLayout>

      <div className='card'>
        <form className='card-body' onSubmit={onSubmit}>
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <h1 className='mb-0'>
              {t('header:changePassword')}
            </h1>
          </div>
          <div className='form-group'>
            <label
              htmlFor='CurrentPassword'
              className='text-primary'
            >
              {t('changepassword:currentPassword')}
            </label>
            <input
              id='CurrentPassword'
              name='CurrentPassword'
              type='password'
              ref={passwordInput}
              className={classNames('form-control', { 'is-invalid': !!error.CurrentPassword })}
              onChange={onChangeValue}
              required
            />
            <div className="invalid-feedback">
              {error.CurrentPassword}
            </div>
          </div>

          <div className='form-group'>
            <label
              htmlFor='Password'
              className='text-primary'
            >
              {t('changepassword:newPassword')}
            </label>
            <input
              id='Password'
              name='Password'
              type='password'
              className={classNames('form-control', { 'is-invalid': !!error.Password })}
              onChange={onChangeValue}
              required
            />
            <small id="passwordHelp" className="form-text text-muted">
              {t('changepassword:requirements')}
            </small>
          </div>

          <div className='form-group'>
            <label
              htmlFor='ConfirmPassword'
              className='text-primary'
            >
              {t("changepassword:repeatPassword")}
            </label>
            <input
              id='ConfirmPassword'
              name='ConfirmPassword'
              type='password'
              className={classNames('form-control', { 'is-invalid': !!error.ConfirmPassword })}
              onChange={onChangeValue}
            />
            <small id="passwordHelp" className="form-text text-muted">
              {t('changepassword:requirements')}
            </small>
          </div>
          <div>
            <input
              className='btn btn-primary'
              type='submit'
              disabled={savingMember}
              value={t("changepassword:save")}
            />
          </div>
        </form>
      </div>

    </DefaultLayout>
  )
});

export default ChangePasswordPage;

import React from 'react';
import Icon from './Icon';

const LinkLikeArrowExternal = (props) => {
  const { children, } = props;

  return (
    <div className="d-inline-flex link-arrow align-items-center icon-secondary">
      <span className="text-primary mr-1">{children}</span>
      {props.showArrow &&
        <Icon className="text-secondary mx-1" iconType="ExternalLink" />
      }
    </div>
  )
}

LinkLikeArrowExternal.defaultProps = {
  showArrow: true,
  onClick: null
}

export default LinkLikeArrowExternal;
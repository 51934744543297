import React, { Fragment } from 'react';

/* packages */
import { useTranslation } from 'react-i18next';

/* components */
import { Rigging, Calendar } from './illustrations';

const EventBlock = React.memo(() => {
  const { t } = useTranslation(['event']);

  return (
    <Fragment>
      <div className="mb-4">
        <h2>
          {t('event:title')}
        </h2>
        <p className="mb-4">
          {t('event:description')}
        </p>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="d-flex mb-4">
            <div className="mr-3 login-desc-icon">
              <Calendar className="icon-primary" />
            </div>
            <div className="flex-1">
              <h2 className="mb-2">
                {t('event:section.first.title')}
              </h2>
              <p>
                {t('event:section.first.description')}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="d-flex mb-4">
            <div className="mr-3 login-desc-icon">
              <Rigging className="icon-primary" />
            </div>
            <div className="flex-1">
              <h2 className="mb-2">
                {t('event:section.second.title')}
              </h2>
              <p>
                {t('event:section.second.description')}
              </p>
            </div>
          </div>
        </div>

      </div>
    </Fragment>
  );
});

export default EventBlock;

import React, { Fragment, useCallback, useEffect, useMemo } from "react";

/* packages */
import dayjs from "dayjs";
import qs from "query-string";
import classNames from "classnames";

import get from "lodash/get";
import map from "lodash/map";
import reduce from "lodash/reduce";
import orderBy from "lodash/orderBy";

import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import { scroller } from "react-scroll";

import { useAgendaVariables } from "../../../../contexts/AgendaContext";

import MeetingListItem from "./MeetingListItem";
import GatheringListItem from "./GatheringListItem";
import VisitorInvitationListItem from "./VisitorInvitationListItem";
import GatheringInvitationListItem from "./GatheringInvitationListItem";

import { QUERY_AGENDA } from "../../../../graphql/Meetings";
import { Link, useLocation } from "react-router-dom";
import { useIsAttendee } from "../../../../contexts/ExhibitorContext";

const MeetingInviteLink = () => {
  const { t } = useTranslation(["agenda", "common"]);

  return (
    <Link
      to="/agenda/meeting-request"
      className="card-body text-decoration-none"
    >
      <h2 className="text-dark">{t("agenda:meetingRequest.title")}</h2>
      <p className="text-dark">{t("agenda:meetingRequest.description")}</p>
    </Link>
  );
};

const AgendaList = React.memo(() => {
  const { t } = useTranslation(["agenda", "common"]);

  const isAttendee = useIsAttendee();

  const { search } = useLocation();

  const [variables] = useAgendaVariables();

  const query = useMemo(
    () =>
      qs.parse(search, {
        parseNumbers: true,
        arrayFormat: "index",
      }),
    [search]
  );

  useEffect(() => {
    if (query.ID) {
      scroller.scrollTo(query.ID, {
        smooth: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: AgendaData } = useQuery(QUERY_AGENDA, {
    variables: {
      Filter: variables,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      if (query.ID) {
        scroller.scrollTo(query.ID, {
          smooth: true,
        });
      }
    },
  });

  const Meetings = useMemo(() => {
    const AgendaList = map(
      get(AgendaData, "readProvadaMyAgenda.edges", []),
      "node"
    );

    const nextMeetings = reduce(
      AgendaList,
      (acc, item) => {
        switch (item.__typename) {
          case "ProvadaGathering": {
            const { Date: GatheringDate, Start, End } = item;

            const nextItem = {
              ...item,
              TimestampStart: dayjs(
                `${GatheringDate} ${Start}`,
                "YYYY-MM-DD HH:mm:ss"
              ).valueOf(),
              TimestampEnd: dayjs(
                `${GatheringDate} ${End}`,
                "YYYY-MM-DD HH:mm:ss"
              ).valueOf(),
            };

            return [...acc, nextItem];
          }

          case "ProvadaMeeting": {
            const { DateTime } = item;

            const nextItem = {
              ...item,
              TimestampStart: dayjs(DateTime, "YYYY-MM-DD HH:mm:ss").valueOf(),
              TimestampEnd: null,
              SuggestedTimes: !!item.SuggestedTimes
                ? JSON.parse(item.SuggestedTimes)
                : [],
            };

            return [...acc, nextItem];
          }

          case "ProvadaInvitedVisitor": {
            const { DateTime } = item;

            const nextItem = {
              ...item,
              TimestampStart: dayjs(DateTime, "YYYY-MM-DD HH:mm:ss").valueOf(),
              TimestampEnd: null,
              SuggestedTimes: [],
            };

            const InvitedForID = get(item, "InvitedFor.ID");

            /**
             * If invitation has been accepted and the user is an attendee,
             * we don't need to show the invitation since a `ProvadaMeeting` will be created for this invitation
             */

            if (InvitedForID && !!parseInt(InvitedForID)) {
              return acc;
            }

            return [...acc, nextItem];
          }

          case "ProvadaInvitedSpeaker": {
            const {
              InvitedFor: { Date: GatheringDate, Start, End },
            } = item;

            const nextItem = {
              ...item,
              TimestampStart: dayjs(
                `${GatheringDate} ${Start}`,
                "YYYY-MM-DD HH:mm:ss"
              ).valueOf(),
              TimestampEnd: dayjs(
                `${GatheringDate} ${End}`,
                "YYYY-MM-DD HH:mm:ss"
              ).valueOf(),
            };

            return [...acc, nextItem];
          }

          default: {
            return acc;
          }
        }
      },
      []
    );

    return orderBy(nextMeetings, ["TimestampStart"], ["asc"]);
  }, [AgendaData]);

  const renderItem = useCallback((item) => {
    const { TimestampStart, TimestampEnd, ID, __typename } = item;

    var overlapping = false;

    //* @todo Finish ti maybe at some point
    // const nextItem = Meetings[index + 1];

    // if (nextItem && __typename === "ProvadaMeeting") {
    //   if (nextItem.__typename === "ProvadaMeeting") {
    //     overlapping = item.DateTime === nextItem.DateTime;
    //   }

    //   if (nextItem.__typename === "ProvadaGathering") {
    //     overlapping = dayjs(item.TimestampStart).isBetween(nextItem.TimestampStart, nextItem.TimestampEnd, null, '[]');
    //   }
    // }

    const key = `${__typename}-${ID}`;

    switch (__typename) {
      case "ProvadaMeeting": {
        return (
          <div className="d-flex" key={key} id={key}>
            <div
              className={classNames(
                "mr-3 text-center font-weight-bold text-primary pt-2 width-40",
                { "text-danger": overlapping }
              )}
            >
              <p className="mb-0">{dayjs(TimestampStart).format("HH:mm")}</p>
            </div>
            <MeetingListItem item={item} overlapping={overlapping} />
          </div>
        );
      }

      case "ProvadaGathering": {
        return (
          <div className="d-flex" key={key} id={key}>
            <div
              className={classNames(
                "mr-3 text-center font-weight-bold text-primary pt-2 width-40",
                { "text-danger": overlapping }
              )}
            >
              <p className="mb-0">{dayjs(TimestampStart).format("HH:mm")}</p>
              <span>{"|"}</span>
              <p className="mb-0">{dayjs(TimestampEnd).format("HH:mm")}</p>
            </div>
            <GatheringListItem item={item} overlapping={overlapping} />
          </div>
        );
      }

      case "ProvadaInvitedVisitor": {
        return (
          <div className="d-flex" key={key} id={key}>
            <div
              className={classNames(
                "mr-3 text-center font-weight-bold text-primary pt-2 width-40",
                { "text-danger": overlapping }
              )}
            >
              <p className="mb-0">{dayjs(TimestampStart).format("HH:mm")}</p>
            </div>
            <VisitorInvitationListItem item={item} overlapping={overlapping} />
          </div>
        );
      }

      case "ProvadaInvitedSpeaker": {
        return (
          <div className="d-flex" key={key} id={key}>
            <div
              className={classNames(
                "mr-3 text-center font-weight-bold text-primary pt-2 width-40",
                { "text-danger": overlapping }
              )}
            >
              <p className="mb-0">{dayjs(TimestampStart).format("HH:mm")}</p>
              <span>{"|"}</span>
              <p className="mb-0">{dayjs(TimestampEnd).format("HH:mm")}</p>
            </div>
            <GatheringInvitationListItem
              item={item}
              overlapping={overlapping}
            />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  }, []);

  if (!Meetings.length) {
    return (
      <div className="col-12">
        {isAttendee && (
          <div className="card">
            <MeetingInviteLink />
          </div>
        )}
        <div className="card">
          <div className="card-body">
            <p className="mb-0">{t("dashboard:agenda.list.empty")}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {isAttendee && (
        <div className="d-flex">
          <div className="mr-3 text-center font-weight-bold text-primary pt-2 width-40" />
          <div className="card w-100">
            <MeetingInviteLink />
          </div>
        </div>
      )}
      {Meetings.map(renderItem)}
    </Fragment>
  );
});

export default AgendaList;

import React, { useMemo, useContext, useEffect } from "react";

/* packages */
import qs from "query-string";

import { get, map, filter, pick, find } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { useHistory, useRouteMatch } from "react-router-dom";

/* context */
import { useEvent } from "./EventContext";

/* graphql */
import {
  QUERY_LOCATION,
  QUERY_CATERING_OPTIONS,
  QUERY_RESERVATION_GATHERING,
} from "../graphql/Location";

/* utils */
import { useSafeSetState } from "../lib/hooks";
import {
  BOOKING_SETUP_PERIOD,
  BOOKING_CLEANUP_PERIOD,
  ROOM_RESERVATION_EDIT_AFTER,
  ROOM_RESERVATION_EDIT_BEFORE,
} from "../utils/globals";
import dayjs from "dayjs";

let RoomReservationContext;

const { Consumer, Provider } = (RoomReservationContext = React.createContext());

const roomReservationDefaultProps = {
  ID: null,
  Date: 0,
  Start: 0,
  End: 0,
  Created: "", //! Used to check when was reservation created so we can limit the user when editing
  PreparationTime: BOOKING_SETUP_PERIOD * 60,
  CleanupTime: BOOKING_CLEANUP_PERIOD * 60,
  // ? Catering option currently set
  CateringID: "0",
  Persons: 1, //! Used to estimate catering price
  Participants: 0, //! Used by FE to check if the final number of people was set on the gathering to show different microcopy.
  Comments: "",
  Exhibitor: null,
  Approved: false,
  //   CurrentStage: "DRAFT",
  Gathering: {
    ID: null,
    Title: "",
  },

  // * Indicates selected location when creating a new reservation.
  LocationID: null,

  // * Used to indicate that we're coming from the Gathering flow.
  // * When this property is set user is nto allowed to change the Gathering from the dropdown.
  // * The param is set *ONCE* form equivalent query param when user visits
  GatheringID: null,
};

const locationDefaultProps = {
  ID: null,
  Title: "",
  Price: "",
  Content: "",
  Places: 0,
  Catering: true,
};

function useReservation() {
  const { reservation } = useContext(RoomReservationContext);

  return reservation;
}

const RoomReservationConsumer = React.memo((props) => {
  return (
    <Consumer>
      {(context) => {
        if (!context) {
          throw new Error(
            "Using RoomReservationConsumer outside of RoomReservationProvider"
          );
        }

        return props.children(context);
      }}
    </Consumer>
  );
});

const RoomReservationProvider = React.memo((props) => {
  const { params } = useRouteMatch();
  const {
    location: { search },
  } = useHistory();

  const event = useEvent();

  const [reservation, setReservation] = useSafeSetState(() => {
    const query = qs.parse(search, { parseNumbers: true });

    let initialState = {
      ...roomReservationDefaultProps,
      // * Get only fields we use from the query params
      // * can make an extra check to filter out invalid values
      ...pick(query, ["Start", "End", "LocationID"]),

      //! We get gathering ID from nested param.
      GatheringID: get(params, "ID", null),
    };

    return initialState;
  });

  const LocationID = get(reservation, "LocationID");
  const GatheringID = get(reservation, "Gathering.ID");

  //! Query latest data for room reservation
  const { data: locationData, loading } = useQuery(QUERY_LOCATION, {
    skip: !LocationID,
    variables: {
      LocationID,
    },
    fetchPolicy: "cache-and-network",
    onError: () => {
      return;
    },
  });

  const location = useMemo(
    () =>
      get(locationData, "readOneProvadaLocation", locationDefaultProps) ||
      locationDefaultProps,
    [locationData]
  );

  //! Query catering options for selected room
  const { data: cateringOptionsData } = useQuery(QUERY_CATERING_OPTIONS, {
    //! Important - Fetch the cateringOptions only after data for location has came back
    //! Why, you may ask. We need location data to be present in order to pre-fill default Catering option.
    skip: !(!!LocationID && !!locationData && !loading),
    variables: {
      Filter: {
        LocationID__eq: LocationID,
      },
    },
    onCompleted: (data) => {
      if (reservation.ID) {
        return;
      }

      const options = map(
        get(data, "readProvadaCateringOptions.edges", []),
        "node"
      );

      if (!(Array.isArray(options) && !!options.length)) {
        return; //! Server fucked up
      }

      if (location.Catering !== "MANDATORY") {
        return;
      }

      return setReservation({ CateringID: get(options, "0.ID", null) });
    },
  });

  const cateringOptions = useMemo(
    () =>
      map(
        get(cateringOptionsData, "readProvadaCateringOptions.edges", []),
        "node"
      ),
    [cateringOptionsData]
  );

  /**
   * Get the number of people that are attending the Gathering so we
   * can estimate a nearby cost of the reservation in the end.
   *
   * Note: This is triggered only for new reservations, since users can manage the number of Persons on
   * a room reservation.
   *
   * Check task #800 - https://app.activecollab.com/119944/my-work?modal=Task-65021-829
   */
  useQuery(QUERY_RESERVATION_GATHERING, {
    skip: !(
      isNaN(parseInt(params.ReservationID, 10)) &&
      !isNaN(parseInt(get(reservation, "Gathering.ID"), 10))
    ),
    variables: {
      ID: get(reservation, "Gathering.ID"),
    },
    onCompleted: ({ readOneProvadaGathering: nextGathering }) => {
      if (!nextGathering) {
        //! @Nemanja fucked something up boys, lets reset the state
        return setReservation({ Persons: 1 });
      }

      //! If participants has been set in the CMS use Participants as number of people that should be included in the Catering
      if (!!nextGathering.Participants) {
        return setReservation({
          Persons: nextGathering.Participants,
          //! Keep for reference
          Participants: nextGathering.Participants,
        });
      }

      //! @todo For future use to trigger QUERY_RESERVATION_PARTICIPANTS
      // return setReservation({ Gathering: { ...reservation.Gathering, Participants: 0 } });

      //! @todo
      //! Possible optimization on splitting this into two queries which will run only if there are no Participants set on the gathering
      //! Look into QUERY_RESERVATION_PARTICIPANTS
      const Attendees = filter(
        map(get(nextGathering, "Attendees.edges", []), "node"),
        ({ Status }) => Status === 1
      );

      // ? @task #647 - https://app.activecollab.com/119944/projects?modal=Task-55116-829
      // ? Based on clients feedback we removed the speakers from the equation when estimating number of people.
      //   const Speakers = map(get(nextGathering, "Speakers.edges", []), "node");

      //   const Persons = 1 + Attendees.length + Speakers.length;

      return setReservation({ Persons: Math.max(1, Attendees.length) });
    },
    onError: () => setReservation({ Persons: 1 }),
  });

  //! Reset persons when we go back to new reservation! :)
  useEffect(() => {
    if (!(!!GatheringID && !!parseInt(GatheringID, 10))) {
      setReservation({ Persons: 1 });
    }
  }, [GatheringID]);

  function clearReservation() {
    return setReservation({ ...roomReservationDefaultProps });
  }

  const isReservationValid = useMemo(() => {
    //? Needs to have Location set
    if (!get(reservation, "LocationID")) {
      return false;
    }

    // ? Must have Gathering ID set if we're editing a reservation.
    if (
      !!reservation.ID &&
      !(get(reservation, "Gathering.ID") || get(reservation, "GatheringID"))
    ) {
      return false;
    }

    // ? Invalid if catering is required by the location but it not picked.
    if (location.Catering === "MANDATORY" && !reservation.CateringID) {
      return false;
    }

    return true;
  }, [reservation, location]);

  /**
   * Task #800: MyPR - Catering with room reservation, amount of people
   * https://app.activecollab.com/119944/my-work?modal=Task-65021-829
   *
   * Lock in reservation edit two weeks before the event starts, however if reservation was created within the
   * locked period, allow edit of reservation for one hour after it has been created.
   */
  const reservationEditableUntil = useMemo(() => {
    const reservationEditableUntil = dayjs(
      event.StartDate,
      "YYYY-MM-DD"
    ).subtract(...ROOM_RESERVATION_EDIT_BEFORE);

    if (dayjs().isBefore(reservationEditableUntil)) {
      return reservationEditableUntil;
    }

    return dayjs().add(...ROOM_RESERVATION_EDIT_AFTER);
  }, [reservation, event]);

  //! We should not allow reservations after event has been finished.
  const isReservationCreatable = useMemo(() => {
    return dayjs().isBefore(dayjs(event.EndDate, "YYYY-MM-DD"));
  }, [event.EndDate]);

  const isReservationEditable = useMemo(() => {
    // New reservation, no need for lock
    if (!reservation.ID) {
      return true;
    }

    // Reservation created time
    const createdAt = dayjs(reservation.Created, "YYYY-MM-DD HH:mm:ss");

    const reservationEditableUntil = dayjs(
      event.StartDate,
      "YYYY-MM-DD"
    ).subtract(...ROOM_RESERVATION_EDIT_BEFORE);

    if (createdAt.isBefore(reservationEditableUntil)) {
      return !dayjs().isAfter(reservationEditableUntil);
    }

    return !dayjs().isAfter(
      dayjs(createdAt).add(...ROOM_RESERVATION_EDIT_AFTER)
    );
  }, [reservation, event]);

  return (
    <Provider
      value={{
        isReservationValid,
        isReservationCreatable,
        isReservationEditable,

        location,
        reservation,
        cateringOptions,
        reservationEditableUntil,

        setReservation,
        clearReservation,
      }}
      {...props}
    />
  );
});

export {
  roomReservationDefaultProps,
  useReservation,
  RoomReservationProvider,
  RoomReservationConsumer,
};

export default RoomReservationContext;

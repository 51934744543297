import React, { Fragment, useCallback, useMemo, useState } from "react";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";

import useToggle from "react-use/lib/useToggle";

import Icon from "../../../components/Icon";
import Dialog from "../../../components/Dialog";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Speakers from "../../../components/illustrations/Speakers";

import { useSnackbarCustom } from "../../../components/Notification";
import { CardHeaderLeft } from "../../../components/CardHeader";

import { useGathering } from "../../../contexts/GatheringContext";
import { useEvent } from "../../../contexts/EventContext";

import {
  QUERY_GATHERING_SPEAKERS_INVITES,
  MUTATION_DELETE_GATHERING_INVITATION,
  MUTATION_RESEND_GATHERING_INVITATION,
} from "../../../graphql/Meetings";

import { GatheringBreadcrumbs } from "../GatheringEdit";

import images from "../../../images";
import {
  GET_GATHERING_SPEAKERS as QUERY_GATHERING_SPEAKERS,
  REMOVE_SPEAKER as MUTATION_REMOVE_GATHERING_SPEAKER,
} from "../../../graphql/Gathering";

const SpeakerInvitationItem = (props) => {
  const {
    item: { ID, Title, Email, Event, Status, InvitedFor: Gathering },
  } = props;

  const { t } = useTranslation(["gatheringSpeakers"]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const snackbar = useSnackbarCustom();

  const [visible, setVisible] = useToggle(false);

  const refetchQueries = [
    {
      query: QUERY_GATHERING_SPEAKERS_INVITES,
      variables: {
        InvitedForID: parseInt(Gathering.ID, 10),
        EventID: Event.ID,
      },
    },
  ];

  const [resendInvitation] = useMutation(MUTATION_RESEND_GATHERING_INVITATION);
  const [deleteInvitation] = useMutation(MUTATION_DELETE_GATHERING_INVITATION);

  return (
    <div className="d-flex border-bottom py-2 last-of-type-border-none">
      <div>
        <div
          className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
          style={{ backgroundImage: `url(${images.noimage})` }}
        />
      </div>
      <div className="flex-1 d-flex align-items-center">
        <div className="flex-1">
          <h3 className="mb-1">
            <span className="text-primary-50 font-weight-normal">{Title}</span>
          </h3>
          <p className="mb-0 text-primary">{Email}</p>
        </div>
      </div>
      {Status === -1 && (
        <Fragment>
          <Icon
            className="font-size-1-5 size-40 flex-center"
            iconType="More"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          />
          <Menu
            id={`speaker-menu-${ID}`}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => setAnchorEl(null)}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);

                setVisible(true);
              }}
            >
              {t("gatheringSpeakers:list.item.button.cancel")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);

                snackbar.enqueueSnackbar(
                  t("gatheringSpeakers:snackbar.resend.title")
                );

                resendInvitation({ variables: { ID } }).catch(
                  snackbar.enqueueSnackbarError
                );
              }}
            >
              {t("gatheringSpeakers:list.item.button.resend")}
            </MenuItem>
          </Menu>
          <Dialog
            open={visible}
            title={t("gatheringSpeakers:dialog.cancel.title")}
            content={
              <Trans
                i18nKey="gatheringSpeakers:dialog.cancel.content"
                values={{ title: Title }}
              />
            }
            onAccept={() => {
              setVisible(false);

              deleteInvitation({
                variables: { ID },
                update: (proxy) => {
                  const data = proxy.readQuery(refetchQueries[0]);

                  const {
                    readProvadaSpeakerInvites: { edges, ...rest },
                  } = data;

                  proxy.writeQuery({
                    ...refetchQueries[0],
                    data: {
                      ...data,
                      readProvadaSpeakerInvites: {
                        ...rest,
                        edges: filter(edges, ({ node }) => node.ID !== ID),
                      },
                    },
                  });
                },
              }).catch(snackbar.enqueueSnackbarError);
            }}
            onCancel={() => {
              setVisible(false);
            }}
          />
        </Fragment>
      )}
    </div>
  );
};

const SpeakerItem = (props) => {
  const {
    item: {
      ID,
      Attendee: {
        Member: { FullName, Email, Picture },
      },
      Gathering: { ID: GatheringID },
    },
  } = props;

  const { t } = useTranslation(["gatheringSpeakers"]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const snackbar = useSnackbarCustom();

  const [visible, setVisible] = useToggle(false);

  const [removeSpeakers] = useMutation(MUTATION_REMOVE_GATHERING_SPEAKER);

  return (
    <div className="d-flex border-bottom py-2 last-of-type-border-none">
      <div>
        <div
          className="background-image bg-1-1 bg-gray-20 w-3-5 mr-2"
          style={{
            backgroundImage: `url(${
              get(Picture, "AbsoluteLink") || images.noimage
            })`,
          }}
        />
      </div>
      <div className="flex-1 d-flex align-items-center">
        <div className="flex-1">
          <h3 className="mb-1">
            <span className="text-primary-50 font-weight-normal">
              {FullName}
            </span>
          </h3>
          <p className="mb-0 text-primary">{Email}</p>
        </div>
      </div>

      <Icon
        className="font-size-1-5 size-40 flex-center"
        iconType="More"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        id={`speaker-menu-${ID}`}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);

            setVisible(true);
          }}
        >
          {t("gatheringSpeakers:list.item.button.remove")}
        </MenuItem>
      </Menu>
      <Dialog
        open={visible}
        title={t("gatheringSpeakers:dialog.remove.title")}
        content={
          <Trans
            i18nKey="gatheringSpeakers:dialog.remove.content"
            values={{ title: FullName }}
          />
        }
        onAccept={() => {
          setVisible(false);

          removeSpeakers({
            variables: { IDs: [ID] },
            update: (proxy) => {
              const refetchQueries = [
                {
                  query: QUERY_GATHERING_SPEAKERS,
                  variables: {
                    Filter: {
                      GatheringID__eq: GatheringID,
                    },
                  },
                },
              ];

              const data = proxy.readQuery(refetchQueries[0]);

              const {
                readProvadaGatheringSpeakers: { edges, ...rest },
              } = data;

              proxy.writeQuery({
                ...refetchQueries[0],
                data: {
                  ...data,
                  readProvadaGatheringSpeakers: {
                    ...rest,
                    edges: filter(edges, ({ node }) => node.ID !== ID),
                  },
                },
              });
            },
          }).catch(snackbar.enqueueSnackbarError);
        }}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

const SpeakersPage = () => {
  const { t } = useTranslation(["gatheringSpeakers"]);

  const history = useHistory();

  const event = useEvent();

  const { data: gathering } = useGathering();

  const { data: speakerInvitesData } = useQuery(
    QUERY_GATHERING_SPEAKERS_INVITES,
    {
      variables: {
        InvitedForID: parseInt(gathering.ID, 10),
        EventID: event.ID,
      },
    }
  );

  const { data: speakersData } = useQuery(QUERY_GATHERING_SPEAKERS, {
    variables: {
      Filter: {
        GatheringID__eq: gathering.ID,
      },
    },
  });

  const speakerInvites = useMemo(
    () =>
      map(get(speakerInvitesData, "readProvadaSpeakerInvites.edges"), "node"),
    [speakerInvitesData]
  );

  const speakers = useMemo(
    () => map(get(speakersData, "readProvadaGatheringSpeakers.edges"), "node"),
    [speakersData]
  );

  const hasSpeakers = useMemo(
    () => !isEmpty(speakerInvites) || !isEmpty(speakers),
    [speakerInvites, speakers]
  );

  const invitations = useMemo(
    () => filter(speakerInvites, (item) => item.Status === -1),
    [speakerInvites]
  );

  const renderSpeakerItem = useCallback((item) => {
    return <SpeakerItem key={item.ID} item={item} />;
  }, []);

  const renderSpeakerInviteItem = useCallback((item) => {
    return <SpeakerInvitationItem key={item.ID} item={item} />;
  }, []);

  return (
    <Fragment>
      <GatheringBreadcrumbs>
        <Breadcrumbs.ListItem>
          {t("gatheringSpeakers:title")}
        </Breadcrumbs.ListItem>
      </GatheringBreadcrumbs>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body bg-primary d-flex justify-content-between align-items-center p-2">
              <CardHeaderLeft
                onClick={() => history.push(`/gathering/${gathering.ID}`)}
              />
              <h1 className="text-white mb-0">
                {t("gatheringSpeakers:title")}
              </h1>
              <div />
            </div>

            {hasSpeakers && (
              <Fragment>
                <div className="card-body">
                  <h2>{t("gatheringSpeakers:section.accepted.title")}</h2>
                  {!!(Array.isArray(speakers) && speakers.length > 0) ? (
                    map(speakers, renderSpeakerItem)
                  ) : (
                    <p>
                      {t("gatheringSpeakers:section.accepted.list.empty.title")}
                    </p>
                  )}

                  <h2 className="mt-4">
                    {t("gatheringSpeakers:section.pending.title")}
                  </h2>
                  {!!(Array.isArray(invitations) && invitations.length > 0) ? (
                    map(invitations, renderSpeakerInviteItem)
                  ) : (
                    <p>
                      {t("gatheringSpeakers:section.pending.list.empty.title")}
                    </p>
                  )}

                  <button
                    type="submit"
                    className="btn btn-secondary mt-4"
                    onClick={() => history.push("speakers/invite")}
                  >
                    {t("gatheringSpeakers:button.invite")}
                  </button>
                </div>
              </Fragment>
            )}
            {!hasSpeakers && (
              <div className="card-body d-flex flex-column flex-center">
                <div className="block-icon-wrapper-lg my-4 flex-center">
                  <Speakers />
                </div>
                <h3 className="text-center">
                  {t("gatheringSpeakers:list.empty.title")}
                </h3>
                <p className="text-center">
                  {t("gatheringSpeakers:list.empty.description")}
                </p>
                <div className="position-relative d-flex mt-4">
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => history.push("speakers/invite")}
                  >
                    {t("gatheringSpeakers:button.invite")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SpeakersPage;

import React, { Fragment, useMemo, useCallback } from "react";

import dayjs from "dayjs";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { Redirect, Switch, Link, Route } from "react-router-dom";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

/* components */
import DefaultLayout from "../../layout/DefaultLayout";

import Icon from "../../components/Icon";
import Breadcrumbs from "../../components/Breadcrumbs";
import LinkArrowExternal from "../../components/LinkArrowExternal";

import * as Illustration from "../../components/illustrations";

/* context */
import { useEvent } from "../../contexts/EventContext";
import { useAttendeeExhibitor } from "../../contexts/ExhibitorContext";

/* pages */
import RAIServicePage from "./RAIServices";
import PreferredSuppliers from "./PreferredSuppliers";
import ProvadaServicesPage from "./ProvadaServices";

import {
  READ_PROVADA_SERVICES,
  READ_PROVADA_RAI_SERVICES,
} from "../../graphql/Service";

import { SERVICE_TYPE } from "../../utils/globals";
import { getCommonUploadStatus } from "../../lib/common";
import classNames from "classnames";

const SHOW_PAST_DEADLINES = true;

export const ICON_NODE = {
  StandDesign: <Illustration.StandDesign className="icon-primary" />,
  SoundPlan: <Illustration.SoundPlan className="icon-primary" />,
  AVRental: <Illustration.AVRental className="icon-primary" />,
  StandPromotion: <Illustration.StandPromotion className="icon-primary" />,
  Rigging: <Illustration.Rigging className="icon-primary" />,
  Electricity: <Illustration.Electricity className="icon-primary" />,
  Wifi: <Illustration.Wifi className="icon-primary" />,
  Catering: <Illustration.Catering className="icon-primary" />,
  Hostesses: <Illustration.Hostesses className="icon-primary" />,
  Halls: <Illustration.Halls className="icon-primary" />,
};

export const ICON_COMPONENT = {
  StandDesign: Illustration.StandDesign,
  SoundPlan: Illustration.SoundPlan,
  AVRental: Illustration.AVRental,
  StandPromotion: Illustration.StandPromotion,
  Rigging: Illustration.Rigging,
  Electricity: Illustration.Electricity,
  Wifi: Illustration.Wifi,
  Catering: Illustration.Catering,
  Hostesses: Illustration.Hostesses,
  Halls: Illustration.Halls,
};

const FileStatusLabel = React.memo(({ Status }) => {
  const { t } = useTranslation(["fileStatus"]);

  return (
    <div className="row flex-center mb-2">
      {Status === -1 && (
        <p className="mb-0 text-center">{t(`fileStatus:${Status}`)}</p>
      )}
      {Status === 1 && (
        <Fragment>
          <Icon className="text-leaf mr-1 " iconType="CheckmarkCircleFull" />
          <p className="mb-0 text-center">{t(`fileStatus:${Status}`)}</p>
        </Fragment>
      )}
      {Status === 0 && (
        <Fragment>
          <Icon className="mr-1 text-danger" iconType="CloseCircleFull" />
          <p className="mb-0 text-center">{t(`fileStatus:${Status}`)}</p>
        </Fragment>
      )}
    </div>
  );
});

const ServiceDeadlineLabel = React.memo(
  ({ Deadline, Type, className = "" }) => {
    const { t } = useTranslation(["service"]);

    if (!(Deadline && Deadline.Date)) {
      return null;
    }

    const isOverdue = dayjs(Deadline.Date).isBefore(dayjs(), "date");

    const { Date, Discount } = Deadline;

    const DiscountValue = parseInt(Discount, 10) || 0;

    const key = DiscountValue
      ? "service:deadline.discount"
      : Type === SERVICE_TYPE.DELIVERABLE
      ? "service:deadline.delivery"
      : "service:deadline.application";

    return (
      <p
        className={classNames(`my-2 text-center ${className}`, {
          "text-decoration-line-through": isOverdue,
        })}
      >
        {t(key, {
          Date: dayjs(Date),
          Discount,
        })}
      </p>
    );
  }
);

const ProvadaService = React.memo(({ item }) => {
  const { ID, Type, Title, Uploads, ServiceDeadlines } = item;

  const Deliverables = useMemo(
    () => map(get(Uploads, "edges", []), "node"),
    [Uploads]
  );

  /**
   * Display deadline when possible.
   * If its deliverable show the status as well
   * Show status and deadline if status is rejected
   */
  const renderDeadline = useCallback(
    (Deadline, index, items) => {
      if (Type !== SERVICE_TYPE.DELIVERABLE) {
        return <ServiceDeadlineLabel Deadline={Deadline} Type={Type} />;
      }

      const prev = items[index - 1];

      /**
       * Find deliverables that were uploaded between the deadlines
       */
      const DeadlineDeliverables = filter(Deliverables, (item) => {
        const uploadDate = dayjs(item.File.Created);
        const deadlineDate = dayjs(Deadline.Date).endOf("day");

        if (!prev) {
          return uploadDate.isBefore(deadlineDate);
        }

        const prevDeadlineDate = dayjs(prev.Date).endOf("day");

        return uploadDate.isBetween(prevDeadlineDate, deadlineDate);
      });

      if (!DeadlineDeliverables.length) {
        return <ServiceDeadlineLabel Deadline={Deadline} Type={Type} />;
      }

      const Status = getCommonUploadStatus(DeadlineDeliverables);

      if (Status === -1 || Status === 1) {
        return <FileStatusLabel Status={Status} />;
      }

      if (Status === 0) {
        return (
          <Fragment>
            <FileStatusLabel Status={Status} />
            <ServiceDeadlineLabel Deadline={Deadline} Type={Type} />
          </Fragment>
        );
      }

      return <ServiceDeadlineLabel Deadline={Deadline} Type={Type} />;
    },
    [Deliverables, Type]
  );

  return (
    <Link
      key={ID}
      to={`/services/provada/${ID}`}
      className="services-item col-6 py-4 px-2 d-block text-decoration-none"
    >
      <div className="block-icon-wrapper mb-2 flex-center">
        {ICON_NODE[item.Icon]}
      </div>
      <h2 className="text-center">{Title}</h2>
      {map(ServiceDeadlines, renderDeadline)}
    </Link>
  );
});

function orderDeadlines(deadlines = []) {
  const serviceDeadlinesData = map(get(deadlines, "edges", []), "node");

  /**
   * Order deadlines by deadline data
   */
  let ServiceDeadlines = orderBy(
    serviceDeadlinesData,
    [(deadline) => dayjs(deadline.Date, "YYYY-MM-DD HH:mm:ss").valueOf()],
    ["ASC"]
  );

  /**
   * Filter out expired deadlines
   */
  if (!SHOW_PAST_DEADLINES) {
    ServiceDeadlines = filter(ServiceDeadlines, ({ Date: DeadlineDate }) =>
      dayjs(DeadlineDate).isAfter()
    );
  }

  return ServiceDeadlines;
}

const ProvadaServices = React.memo(() => {
  const { ID: EventID } = useEvent();

  const { data } = useQuery(READ_PROVADA_SERVICES, {
    variables: {
      Filter: { EventID__eq: EventID, Active__eq: true },
    },
  });

  const services = useMemo(() => {
    const servicesData = map(
      get(data, "readProvadaServices.edges", []),
      "node"
    );

    return map(servicesData, ({ ServiceDeadlines, ...rest }) => ({
      ...rest,
      ServiceDeadlines: orderDeadlines(ServiceDeadlines),
    }));
  }, [data]);

  const renderService = useCallback(
    (item) => <ProvadaService key={item.ID} item={item} />,
    []
  );

  return (
    <div className="services-wrapper row no-gutters bg-white">
      {services.map(renderService)}
    </div>
  );
});

const RAIServices = React.memo(() => {
  const { ID: EventID } = useEvent();

  const { data } = useQuery(READ_PROVADA_RAI_SERVICES, {
    variables: {
      EventID,
    },
  });

  const services = useMemo(() => {
    const servicesData = map(
      get(data, "readProvadaRAIServices.edges", []),
      "node"
    );

    return map(servicesData, ({ RAIServiceDeadlines, ...rest }) => ({
      ...rest,
      RAIServiceDeadlines: orderDeadlines(RAIServiceDeadlines),
    }));
  }, [data]);

  return (
    <div className="services-wrapper row no-gutters bg-white">
      {services.map(({ ID, Icon, Title, Type, RAIServiceDeadlines }) => {
        return (
          <Link
            key={ID}
            to={`/services/rai/${ID}`}
            className="services-item col-6 py-4 px-2 d-block text-decoration-none"
          >
            <div className="block-icon-wrapper mb-2 flex-center">
              {ICON_NODE[Icon]}
            </div>
            <h2 className="text-center">{Title}</h2>
            {map(RAIServiceDeadlines, (Deadline) => (
              <ServiceDeadlineLabel Deadline={Deadline} Type={Type} />
            ))}
          </Link>
        );
      })}
    </div>
  );
});

const RAILinkBlock = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const AttendeeExhibitor = useAttendeeExhibitor();

  if (!AttendeeExhibitor) {
    return null;
  }

  return (
    <LinkArrowExternal to={AttendeeExhibitor.RAILink}>
      {t("standservices:webStore")}
    </LinkArrowExternal>
  );
});

const StandServices = React.memo(() => {
  const { t } = useTranslation(["common", "standservices"]);

  const Event = useEvent();

  return (
    <DefaultLayout>
      <Breadcrumbs>
        <Breadcrumbs.List>
          <Breadcrumbs.ListItem>
            {t("standservices:stand")}
          </Breadcrumbs.ListItem>
        </Breadcrumbs.List>
        <Breadcrumbs.Addition.Exposant />
      </Breadcrumbs>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h1>{t("standservices:provada")}</h1>
              <p>{t("standservices:section.provada.description", { Event })}</p>
            </div>
            <ProvadaServices />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h1>{t("standservices:rai")}</h1>
              <p>{t("standservices:raiservicesdescription")}</p>
              <RAILinkBlock />
            </div>
            <RAIServices />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
});

const StandServicesRoutes = React.memo(({ match: { url } }) => {
  return (
    <Switch>
      <Route exact path={url} component={StandServices} />
      <Route
        exact
        path={`${url}/provada/suppliers`}
        component={PreferredSuppliers}
      />
      <Route path={`${url}/provada/:ID`} component={ProvadaServicesPage} />
      <Route exact path={`${url}/rai/:ID`} component={RAIServicePage} />
      <Redirect from={`${url}/provada`} to={url} />
      <Redirect from={`${url}/rai`} to={url} />
    </Switch>
  );
});

export default StandServicesRoutes;

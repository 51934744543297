import React, { Fragment } from "react";

/* packages */
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* components */
import RoleBlock from "../RoleBlock";
import LinkLikeArrow from "../LinkLikeArrow";

/* context */
import { useEvent } from "../../contexts/EventContext";

const HelpBlock = React.memo(() => {
  const { t } = useTranslation(["dashboard"]);

  const Event = useEvent();

  return (
    <React.Fragment>
      <div className="card triangle triangle-top-right-leaf">
        <Link to="/help" className="card-body text-decoration-none">
          <h1 className="text-dark">{t("dashboard:help.title")}</h1>
          <p className="text-dark">{t("dashboard:help.description")}</p>
          <LinkLikeArrow>{t("dashboard:help.link")}</LinkLikeArrow>
        </Link>

        {!!Event && !!Event.ID && (
          <Fragment>
            <Link
              to="/help/houserules"
              className="card-body text-decoration-none"
            >
              <h1 className="text-dark">
                {Event.HouseRulesTitle || t("dashboard:rules.title")}
              </h1>
              {!!Event.HouseRulesExcerpt && (
                <p className="text-dark">{t("dashboard:rules.description")}</p>
              )}
              <LinkLikeArrow>{t("dashboard:rules.link")}</LinkLikeArrow>
            </Link>

            <RoleBlock roles={["MANAGER"]}>
              <Link
                to="/help/documents"
                className="card-body text-decoration-none"
              >
                <h1 className="text-dark">{t("dashboard:documents.title")}</h1>
                <p className="text-dark">
                  {t("dashboard:documents.description")}
                </p>
                <LinkLikeArrow>{t("dashboard:documents.link")}</LinkLikeArrow>
              </Link>
            </RoleBlock>
          </Fragment>
        )}
      </div>
    </React.Fragment>
  );
});

export default HelpBlock;

import React, { useMemo, useContext } from "react";

/* packages */
import dayjs from "dayjs";

import { map, difference, uniqueId, first, filter } from "lodash";

import classNames from "classnames";
import useList from "react-use/lib/useList";

import { getWorkingHours } from "../../../lib/common";

/* components */
import Icon from "../../../components/Icon";

/* context */
import { useMeeting } from "../../../contexts/AgendaContext";
import {
  useEventDays,
  useEventHoursList,
} from "../../../contexts/EventContext";

const SuggestedTimesContext = React.createContext();

const { Provider } = SuggestedTimesContext;

export const useSuggestions = () => {
  return useContext(SuggestedTimesContext);
};

export const useSuggestionsDefaultOptions = () => {
  const hours = useEventHoursList();

  return useMemo(() => hours.map((ts) => dayjs(ts).format("HH:mm")), [hours]);
};

export const SuggestedDateSelect = React.memo(({ value, onChange }) => {
  const days = useEventDays();

  return (
    <div className="btn-group agenda-btn-group">
      {days.map((day) => {
        const dayString = dayjs(day).format("YYYY-MM-DD");

        const checked = dayString === value;

        return (
          <button
            key={`day-${dayString}`}
            type="button"
            className={classNames("btn", {
              "btn-secondary": checked,
              "btn-outline-primary bg-white": !checked,
            })}
            onClick={() => onChange(dayString)}
          >
            {dayjs(day).format("ddd")}
          </button>
        );
      })}
    </div>
  );
});

SuggestedDateSelect.defaultProps = {
  value: null,
  onChange: () => null,
};

export const SuggestedTimeSelect = React.memo(
  ({ value, options, onChange }) => {
    return (
      <select
        className="custom-select ml-2"
        onChange={({ target: { value } }) => onChange(value)}
        value={value}
        required
      >
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    );
  }
);

export const SuggestedTimesFormGroup = React.memo(() => {
  const {
    value: [list, { removeAt, updateAt }],
    getAvailableOptions,
  } = useSuggestions();

  return list.map((suggestion, index) => {
    const options = getAvailableOptions(suggestion, index);

    function onBeforeChangeDay(date) {
      let nextState = { ...suggestion, date };

      const nextOptions = getAvailableOptions(nextState, index);

      if (!nextOptions.includes(nextState.time)) {
        nextState = { ...nextState, time: first(nextOptions) };
      }

      return updateAt(index, nextState);
    }

    return (
      <div
        key={suggestion.id}
        className={classNames("d-flex", { "mb-2": index !== list.length - 1 })}
      >
        <SuggestedDateSelect
          value={suggestion.date}
          onChange={onBeforeChangeDay}
        />
        <SuggestedTimeSelect
          options={options}
          value={suggestion.time}
          onChange={(time) => updateAt(index, { ...suggestion, time })}
        />
        <Icon
          className="ml-2 font-size-1-5 text-primary"
          iconType="Close"
          onClick={() => removeAt(index)}
        />
      </div>
    );
  });
});

export const SuggestedTimesProvider = React.memo(({ children }) => {
  const {
    Meeting: { SuggestedTimes },
  } = useMeeting();

  const days = useEventDays();

  const defaultOptions = useSuggestionsDefaultOptions();

  const value = useList(() => {
    var initialState = [
      {
        date: dayjs(days[0]).format("YYYY-MM-DD"),
        time: first(defaultOptions),
        id: uniqueId(),
      },
    ];

    if (!!SuggestedTimes && SuggestedTimes.length) {
      initialState = map(SuggestedTimes, (value) => {
        const date = dayjs(value, "YYYY-MM-DD HH:mm:ss");

        return {
          id: uniqueId(),
          time: date.format("HH:mm"),
          date: date.format("YYYY-MM-DD"),
        };
      });
    }

    return initialState;
  });

  const [list, { push }] = value;

  function getAvailableOptions({ date }, index) {
    var usedOptions = list.slice(0);

    usedOptions.splice(index, 1);

    const relativeSuggestions = map(filter(usedOptions, { date }), "time");

    return difference(defaultOptions, relativeSuggestions);
  }

  const onSuggestionAdd = () =>
    push({
      id: uniqueId(),
      date: dayjs(days[0]).format("YYYY-MM-DD"),
      time: first(
        getAvailableOptions(
          { date: dayjs(days[0]).format("YYYY-MM-DD") },
          list.length + 1
        )
      ),
    });

  return (
    <Provider
      children={children}
      value={{
        value,
        defaultOptions,

        getAvailableOptions,
        onSuggestionAdd,
      }}
    />
  );
});

export default SuggestedTimesContext;

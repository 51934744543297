import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";

const DialogBox = ({ title, content, open, onAccept, onCancel }) => {
  const { t } = useTranslation(["common"]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      BackdropProps={{
        style: {
          backgroundColor: "rgb(0, 65, 91, 0.65)",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="mb-0">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button className="btn btn-outline-secondary flex-1" onClick={onCancel}>
          {t("common:no")}
        </button>
        <button className="btn btn-secondary flex-1" onClick={onAccept}>
          {t("common:yes")}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;

import { useRef, useState, useEffect } from "react";

/* Packages */
import useMedia from "react-use/lib/useMedia";
import useSetState from "react-use/lib/useSetState";

export function useSafeSetState(initialState) {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const [state, setState] = useSetState(initialState);

  return [state, (...args) => mounted.current && setState(...args)];
}

export function useSafeState(initialState) {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const [state, setState] = useState(initialState);

  return [state, (...args) => mounted.current && setState(...args)];
}

export function useScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
}

export function useFileUpload(type = "file") {
  const [error, setError] = useSafeState(false);
  const [loading, setLoading] = useSafeState(false);

  function onUpload(file) {
    const token = window.localStorage.getItem("Authorization-Token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const body = new FormData();

    body.append("file", file);

    setError(false);
    setLoading(true);

    return fetch(`${process.env.REACT_APP_WEBSITE_URL}/ajax/upload/${type}`, {
      method: "POST",
      body,
      headers,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);

        if (!!response.error) {
          throw new Error(response.message);
        }

        return response;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);

        throw error;
      });
  }
  return [onUpload, { loading, error }];
}

export const useMobile = () => !useMedia("(min-width: 768px)");
export const useTable = () => useMedia("(min-width: 768px)");
